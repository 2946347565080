import { AnyAction, Dispatch } from 'redux';
import { axios } from '../../../services/axiosService';
import { inProgressActions, InProgressFormatted, InProgressStatusOptions} from './activitiesViewSlice';
import moment from 'moment';
import { openMessageModal } from '../../../components/statusModal/messageModalActionCreators';

export const getStatusInformation = (status: InProgressStatusOptions) => {
    const statusInformation: { translationKey: string, color: string } = {
        translationKey: 'None',
        color: 'gray',
    };
    
    switch (status) {
        case InProgressStatusOptions.Cancelled:
            statusInformation.translationKey = 'Cancelled';
            statusInformation.color = 'red';
            break;
        case InProgressStatusOptions.Complete:
            statusInformation.translationKey = 'Complete';
            statusInformation.color = 'green';
            break;
        case InProgressStatusOptions.Closed:
            statusInformation.translationKey = 'Closed';
            statusInformation.color = 'red';
            break;
        case InProgressStatusOptions.Issued:
            statusInformation.translationKey = 'Issued';
            statusInformation.color = 'orange';
            break;
        case InProgressStatusOptions.Open:
            statusInformation.translationKey = 'Open';
            statusInformation.color = 'blue';
            break;
        case InProgressStatusOptions.Rejected:
            statusInformation.translationKey = 'Rejected';
            statusInformation.color = 'red';
            break;
        case InProgressStatusOptions.Scheduled:
            statusInformation.translationKey = 'Scheduled';
            statusInformation.color = 'orange';
            break;
        case InProgressStatusOptions.Submitted:
            statusInformation.translationKey = 'Submitted';
            statusInformation.color = 'blue';
            break;
        case InProgressStatusOptions.UnderReview:
        case InProgressStatusOptions.Under_Review:
            statusInformation.translationKey = 'UnderReview';
            statusInformation.color = 'yellow';
            break;
        case InProgressStatusOptions.New:
            statusInformation.translationKey = 'New';
            statusInformation.color = 'blue';
            break;
        case InProgressStatusOptions.IssuedPermittingAndLicensing:
            statusInformation.translationKey = 'Issued';
            statusInformation.color = 'green';
            break;
        case InProgressStatusOptions.ReadyforIssuance:
            statusInformation.translationKey = 'ReadyforIssuance';
            statusInformation.color = 'yellow';
            break;
        case InProgressStatusOptions.PendingPayment:
            case InProgressStatusOptions.Pending_Payment:
                statusInformation.translationKey = 'PendingPayment';
                statusInformation.color = 'orange';
                break;
        case InProgressStatusOptions.PermitIssued:
            statusInformation.translationKey = 'PermitIssued';
            statusInformation.color = 'green';
            break;
        case InProgressStatusOptions.PermitIssuedwithconditions:
            statusInformation.translationKey = 'PermitIssuedwithconditions';
            statusInformation.color = 'green';
            break;
        case InProgressStatusOptions.ScheduleInspection:
            statusInformation.translationKey = 'ScheduleInspection';
            statusInformation.color = 'yellow';
            break;
        case InProgressStatusOptions.Closed_NotApproved:
        case InProgressStatusOptions.ClosedNotApproved:
            statusInformation.translationKey = 'ClosedNotApproved';
            statusInformation.color = 'red';
            break;
        case InProgressStatusOptions.GeneratePermit:
            statusInformation.translationKey = 'GeneratePermit';
            statusInformation.color = 'yellow';
            break;
        default:
            statusInformation.translationKey = status ?? 'None';
            break;
    }

    return statusInformation;
}

export const loadInProgressActivities = () => async (dispatch: Dispatch) => {
    try {
        dispatch(inProgressActions.setLoading(true));

        const apiResponse = (await axios.get('/salesforce/inProgress')).data as {completed: InProgressFormatted[], inProgress: InProgressFormatted[] };

        apiResponse.inProgress?.map(ip => {
            ip.lastModifiedDisplay = moment(ip.lastModified).format("MM/DD/YYYY h:mm A")
            ip.statusInformation = getStatusInformation(ip.status as InProgressStatusOptions);
        });

        apiResponse.completed?.map(c => {
            c.lastModifiedDisplay = moment(c.lastModified).format("MM/DD/YYYY h:mm A")
            c.statusInformation = getStatusInformation(c.status as InProgressStatusOptions);
        });

        dispatch(inProgressActions.setActive(apiResponse.inProgress));
        dispatch(inProgressActions.setPrevious(apiResponse.completed));
    } catch (err) {
        console.error(err);
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
    } finally {
        dispatch(inProgressActions.setLoading(false));
    }
};
