import { axios } from '../../../services/axiosService';
import { AnyAction, Dispatch } from 'redux';
import {
    setActivities
} from './activitiesListSlice';
import { Activity, ActivitiesListAppState } from './activitiesListAppState';
import { groupBy } from '../../../services/utilities';
import { AppState } from '../../../store/appState';
import { setLoading } from './activitiesListSlice';
import { openMessageModal } from '../../../components/statusModal/messageModalActionCreators';

export const listActivities = (userId?: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        dispatch(setLoading(true));
        const state = getState();
        const activities = state.activitiesList.activities;
        const apiActivities = (await axios.get('/activities', { params: { userId }})).data as Activity[];
        const groupedActivities = groupBy(apiActivities, (x: Activity) => x.Action) as ActivitiesListAppState['activities'];
        const activityList = {...activities, ...groupedActivities}; // maintain any empty activity actions in state
        dispatch(setActivities(activityList));
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
};
