import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './PermitForm.module.scss';
import { useAppStatePermitFormSelector, setPropertyType, togglePermitOption, PropertyTypes } from './permitFormSlice';
import { Button } from '../../../components/button/Button';
import { PanelRow } from '../../../components/panel/PanelRow';
import { Panel } from '../../../components/panel/Panel';
import { BackArrow } from '../../../components/backArrow/BackArrow';
import { Dropdown, DropdownOptions } from "../../../components/dropdown/Dropdown";
import { Checkbox } from "../../../components/checkbox/Checkbox";
import { PermitOption } from "./permitFormAppState";
import { useAuthSelector } from '../../auth/authSlice';
import { PageContainer } from '../../../components/pageContainer/PageContainer';
import { config } from '../../../config';
import qs, { ParsedUrlQueryInput } from 'querystring';
import { loadActivityDetails } from './permitFormActionCreators';
import { setBackLocation } from '../zoningInquiry/zoningInquirySlice';
import { customFormMap } from '../../../services/activityService';

const PermitOptionDetails = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { propertyType, permitOptions, paymentCode } = useAppStatePermitFormSelector(s => s)
    const authUser = useAuthSelector(state => state.authUser);
    const permitOptionsKeys = Object.keys(permitOptions) as PermitOption[];
    const handleNext = () => {
        if (propertyType === PropertyTypes.NewCommercialIndustrial) {
            dispatch(setBackLocation('/activities/other_permit'));
            history.push(`/activities/zoning_inquiry?activityId=${customFormMap.ZONING_INFORMATION}`);
            return;
        }
        if (propertyType === PropertyTypes.NewSingleFamilyHome) {
            window.open(config.permitFormPdfLinks.newResidential, '_blank');
            console.log('Send to new residential form');
            return;
        }
        if (permitOptions.RemodelingStructure) {
            window.open(config.permitFormPdfLinks.remodelResidential, '_blank');
            console.log('Send to remodeling form');
        }
        // If it wasn't one of the above, use the quick permit

        const inputs = {
            '.Address1': authUser?.salesforceContact?.address1,
            '.Address2': authUser?.salesforceContact?.address2,
            '.City': authUser?.salesforceContact?.city,
            '.State': authUser?.salesforceContact?.state,
            '.Postal': authUser?.salesforceContact?.zip,
            '.Country': 'USA'
        }

        const laserficheParams = {
            applicantFirst_Name: authUser?.FirstName,
            ApplicantLastName: authUser?.LastName,
            applicantEmail: authUser?.Email,
            applicantPhoneNumber: authUser!.PhoneNumber ? authUser!.PhoneNumber!.replace(/\D/g, '') : '',
            applicantStreetAddress: authUser?.salesforceContact?.address1,
            applicantCity: authUser?.salesforceContact?.city,
            applicantState: authUser?.salesforceContact?.state,
            applicantZipCode: authUser?.salesforceContact?.zip,
            applicantLanguage: authUser?.Language,
            customArgs: JSON.stringify({ inputs })
        }
        const formDetails = {
            formTitle: 'Quick Permit',
            bpid: 140,
            paymentCode: paymentCode,
            params: JSON.stringify(laserficheParams),
        };
        const formDetailQueryParam = qs.encode(formDetails as ParsedUrlQueryInput);
        history.push(`/laserfiche/form?${formDetailQueryParam}`);
    }


    const displayCheckboxes = useMemo(() => propertyType !== PropertyTypes.NewSingleFamilyHome, [propertyType]);
    const displayPDFHelpText = useMemo(() => (propertyType === PropertyTypes.NewSingleFamilyHome || permitOptions.RemodelingStructure), [propertyType, permitOptions]);
    const allowNext = useMemo(() => displayPDFHelpText || !(Object.values(permitOptions).every(option => option === false)), [displayPDFHelpText, permitOptions]);

    return (
        <>
            {displayCheckboxes ?
                <>
                    <p>{t('Permit.INeedAPermitFor')}</p>
                    {permitOptionsKeys.map(optionKey => (
                        <div className={styles.checkbox} key={optionKey}>
                            <Checkbox
                                value={permitOptions[optionKey]}
                                text={t(`Permit.${optionKey}`)}
                                onChange={(e: any, data: any) => dispatch(togglePermitOption(optionKey))}
                            />
                        </div>
                    ))}
                </>
                :null}
            {displayPDFHelpText ? <p>{t('Permit.NoOnlinePermitUsePDF')}</p> : null}
            <div className={styles.nextButton}>
                <Button
                    onClick={handleNext}
                    disabled={!(allowNext)}
                >
                    {t('Util.Next')}
                </Button>
            </div>
        </>
    )
}

export const PermitForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const propertyType = useAppStatePermitFormSelector(state => state.propertyType);
    const dropdownOptions: DropdownOptions[] = Object.keys(PropertyTypes).map(propertyType => {
        return {
            key: propertyType,
            text: t(`Permit.${propertyType}`),
            value: propertyType
        }
    });

    useEffect(() => {
        dispatch(loadActivityDetails());
    }, [dispatch]);

    return (
        <PageContainer>
            <h2>{t('Permit.ApplyForPermit')}</h2>
            <BackArrow url="/" text="Back to Dashboard" />
            <PanelRow>
                <Panel>
                    <p className={styles.permitDescription}>{t('Permit.PermitDescription')}</p>
                    <p>{t('Permit.ThePropertyIs')}</p>
                    <div className={styles.dropdownContainer}>
                        <Dropdown
                            placeholder="..."
                            options={dropdownOptions}
                            value={propertyType as string}
                            onChange={(e: any, data: any) => dispatch(setPropertyType(data.value))}
                        />
                    </div>
                    {propertyType &&
                        <PermitOptionDetails />
                    }
                </Panel>
            </PanelRow>
        </PageContainer>
    )
}
