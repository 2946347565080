import React, { FunctionComponent } from 'react';
import styles from './Arrow.module.scss';

export interface ArrowProps  { 
    color?: 'purple' | 'blue' | 'grey';
    size?: 'small' | 'normal';
    direction?: 'left' | 'right' | 'up' | 'down'
    className?: string
}

export const Arrow: FunctionComponent<ArrowProps> = ({color, size, direction, className}) => {
    let getColor = () => {
        switch (color) {
            case 'purple':
                return styles.purple;
            case 'blue':
                return styles.blue;
            default:
                return '';
        }
    }

    let getSize = () => {
        switch (size) {
            case 'small':
                return styles.small;
            default:
                return styles.normal;
        }
    }

    let getDirection = () => {
        switch (direction) {
            case 'left':
                return styles.left;
            case 'up':
                return styles.up;
            case 'down':
                return styles.down;
            default:
                return styles.right;
        }
    }

    return (
        <div className={`${styles.arrow} ${getColor()} ${getSize()} ${getDirection()} ${className}`}>
            <div className={styles.line}></div>
            <div className={styles.point}></div>
        </div>
    );
}

Arrow.defaultProps = {
    color: 'grey',
    size: 'normal',
    direction: 'right',
    className: '',
};