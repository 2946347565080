import {createSlice, SliceCaseReducers} from '@reduxjs/toolkit';
import {SideBarAppState} from './sideBarAppState';
import {buildSubStateSelector} from '../../store/utilities/useAppStateSelector';
import { DropdownOptions } from '../dropdown/Dropdown';
import { personalOrganizationKey } from '../../constants';

export const initialOrganizationOptions: DropdownOptions[] = [
    {
        key: personalOrganizationKey,
        text: 'My Personal Information',
        value: personalOrganizationKey,
    },
];

export const slice = createSlice<SideBarAppState, SliceCaseReducers<SideBarAppState>>({
    name: 'sideBar',
    initialState: {
        organizationOptions: initialOrganizationOptions,
        loadOrganizationsErrorMessage: '',
    },
    reducers: {
        setOrganizationOptions: (state, action) => {
            state.organizationOptions = action.payload;
        },
        setLoadOrganizationsErrorMessage: (state, action) => {
            state.loadOrganizationsErrorMessage = action.payload;
        }
    }
});

export const useAppStateSideBarSelector = buildSubStateSelector(state => state.sideBar);
export const { setOrganizationOptions, setLoadOrganizationsErrorMessage } = slice.actions;
export const sideBarReducer = slice.reducer;
