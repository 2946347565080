import React, { FunctionComponent } from 'react';
import styles from './Dropdown.module.scss';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react'
import { Activity } from '../../features/activities/list/activitiesListAppState';

export interface DropdownProps  { 
    placeholder: string;
    dark?: boolean;
    options: DropdownOptions[];
    onChange(event: any, data: any): void;
    value: string;
    staticText?: string;
    selection?: boolean;
    fluid?: boolean;
    search?: boolean;
    disabled?: boolean;
    inline?: boolean;
    id?: string;
    className?: string;
}

export interface DropdownOptions {
    key: string;
    text: string;
    value: string;
    item?: Activity;
    translation?: string;
}

export const Dropdown: FunctionComponent<DropdownProps> = ({dark, placeholder, staticText, onChange, options, value, selection, fluid, search = true, disabled, inline, className = '', ...rest}) => {
    return (
        <SemanticDropdown
            text={staticText}
            className={`${styles.dropdown} ${dark ? styles.dark : ''} ${className}`}
            placeholder={placeholder}
            options={options}
            onChange={onChange}
            value={value}
            selection={selection}
            fluid={fluid}
            search={search}
            disabled={disabled}
            inline={inline}
            {...rest}
        />
    );
}

Dropdown.defaultProps = {
    selection: true,
    fluid: true,
    dark: false,
    disabled: false
};