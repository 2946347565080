import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { LanguagesEditAppState, TranslationFormData } from './languagesEditAppState';
import { buildSubStateSelector } from '../../../store/utilities/useAppStateSelector';

export const slice = createSlice<LanguagesEditAppState, SliceCaseReducers<LanguagesEditAppState>>({
    name: 'languagesEdit',
    initialState: {
        translations: {},
        fieldErrors: {},
        errorMessage: '',
        loading: false,
    },
    reducers: {
        setTranslations: (state, action) => {
            // Convert grouped translations into array format that API expects
            state.translations = action.payload.value;
        },
        setTranslation: (state, action) => {
            const { Group, Id, plurality, value } : TranslationFormData = action.payload;
            state.translations[Group][Id][plurality] = value;
        },
        setFieldErrors: (state, action: { payload: { errors: { [Id: string]: boolean } } }) => {
            state.fieldErrors = action.payload.errors;
        },
        clearFieldErrors: (state, action) => {
            state.fieldErrors = {};
        },
        setError: (state, action) => {
            state.errorMessage = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        }
    }
});

export const useAppStateLanguagesEditSelector = buildSubStateSelector(state => state.languagesEdit);
export const {
    setTranslations,
    setTranslation,
    setFieldErrors,
    clearFieldErrors,
    setError,
    setLoading,
} = slice.actions;
export const languagesEditReducer = slice.reducer;
