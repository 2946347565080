import React, { FunctionComponent } from 'react';
import styles from './Text.module.scss';
import { Input, InputProps } from 'semantic-ui-react'
import InputMask from 'react-input-mask';

export interface TextProps  { 
    placeholder?: string;
    onChange?(event: any, data?: any): void;
    onKeyDown?(event: any) : void;
    value: string | number;
    type?: 'text' | 'number'
    min?: number;
    max?: number;
    mask?: string;
    maskChar?: any;
    id?: string;
    disabled?: boolean;
    fluid?: boolean;
    fontFamilyLato?: boolean;
    style?: object;
}

export const Text: FunctionComponent<TextProps & InputProps> = ({placeholder, onChange, onKeyDown, value, type, min, max, mask, maskChar, disabled, fluid = true, fontFamilyLato, ...rest}) => {
    let numberInputProps = {};
    if (type === 'number') {
        numberInputProps = {
            min,
            max
        }
    }

    if (mask) {
        return (
            <InputMask mask={mask} maskChar={maskChar} value={value} onChange={onChange} onKeyDown={onKeyDown}>
                {(inputProps: any) => 
                    <Input className={`${styles.text}`} placeholder={placeholder} value={value} fluid={fluid} type={type} {...numberInputProps} {...inputProps} {...rest} />
                }
            </InputMask>
        )
    }
    
    return (
        <Input className={`${styles.text} ${fontFamilyLato ? styles.fontFamilyLato : styles.fontFamilyAvenir}`} placeholder={placeholder} onChange={onChange} onKeyDown={onKeyDown} value={value} fluid={fluid} type={type} disabled={disabled} {...numberInputProps} {...rest} />
    );
}

Text.defaultProps = {
    type: 'text'
};