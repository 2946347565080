export const elginBounds = {
    north: 42.103385,
    east: -88.2227729,
    south: 41.97137290000001,
    west: -88.449438,
}

export const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
export enum activityTypes {
    LASERFICHE_FORM = 'LASERFICHE_FORM',
    CUSTOM_FORM = 'CUSTOM_FORM',
    EXTERNAL_LINK = "EXTERNAL_LINK",
    CONTENT = 'CONTENT',
}

export enum navilinePaymentTypes {
    generalLedger = 'MISC',
    buildingPermit = 'BUILDING',
    codeEnforcement = 'MISC',
    caseManagement = 'MISC',
    utility = 'UTILITY',
    accountsReceivable = 'MISC',
    businessLicense = 'PERMIT',
    businessLicenseRenewal = 'PERMIT',
    parkingTicket = 'MISC',
    planningAndEngineering = 'MISC',
    taxBilling = 'MISC',
}

export const personalOrganizationKey = 'personal';

export enum nodeEnvs {
    production = 'production',
    development = 'development'
}
