import React from 'react';
import Moment from 'moment';
import { NavilinePaymentHistoryV2 } from '../../models/navilinePaymentHistory';
import styles from './billPayDetails.module.scss';
import { useTranslation } from 'react-i18next';

export const BillPayUpcomingPaymentsV2 = ({ paymentHistory }: { paymentHistory: NavilinePaymentHistoryV2 | null | undefined }) => {
    const { t } = useTranslation();

    if (!paymentHistory) return null;
    if (!paymentHistory.navilineAccountInfo.length) return <div className={styles.billPayNoneFound}>{t('Dashboard.NoUpcomingBillsFound')}</div>;
    if (!paymentHistory.navilineAccountInfo.some(ph => ph.upcomingBills.length)) return <div className={styles.billPayNoneFound}>{t('Dashboard.NoUpcomingBillsFound')}</div>;
    
    return (
        <div>
            {paymentHistory.navilineAccountInfo.map(accountHistory => (
                <div key={`${accountHistory.accountInfo.customerId}-${accountHistory.accountInfo.locationId}`} className={styles.leaders}>
                    {accountHistory.upcomingBills.map(({ amount, type, dueDate }) => {
                        const amountSign = amount < 0 ? '-' : '';
                        const unsignedAmount = Math.abs(amount).toFixed(2);
                        const formattedAmount = `${amountSign}$${unsignedAmount}`;
                        return (
                            <div key={type}>
                                <div>
                                    <div className={styles.billPayDetailsOweV2}>
                                        {`${formattedAmount} - ${Moment(dueDate).format('MM/DD/YYYY')}`}
                                    </div>
                                    <div className={styles.billPayDetailsV2}>
                                        <span>{type}: {formattedAmount}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            ))}
        </div>
    )
}
