import * as firebaseService from "firebase/app";

// Add the Firebase services that you want to use
// We only want to use Firebase Auth here
import "firebase/auth";
import { config } from "../config";

// Your app's Firebase configuration
let firebaseConfig = config.firebase;

// Initialize Firebase
firebaseService.initializeApp(firebaseConfig);

// Finally, export it to use it throughout your app
export {firebaseService};