import React, { MouseEvent, useEffect } from 'react';
import styles from './Messages.module.scss';
import { PageContainer } from '../../components/pageContainer/PageContainer';
import { useTranslation } from 'react-i18next';
import { useAppStateMessagesSelector } from './messagesSlice';
import moment from 'moment';
import { PanelRow } from '../../components/panel/PanelRow';
import { Panel } from '../../components/panel/Panel';
import { Message } from './messagesAppState';
import { useDispatch } from 'react-redux';
import { deleteMessage, loadMessages, markRead } from './messagesActionCreators';
import { Spinner } from '../../components/spinner/Spinner';
import { urlRegex } from '../../constants';
import { useHistory } from 'react-router-dom';

interface MobileMessagesProps {
    messages: Message[];
    onMessageClick: (messageId: string, sObjectType?: string, salesforceId?: string) => void;
}

interface WebMessagesProps extends MobileMessagesProps {
    onTrashClick: (e: MouseEvent, messageId: string) => void;
}

const linkifyText = (messageBody: string) => {
    const parts = messageBody.split(urlRegex);
    return parts.map(part => {
        if (urlRegex.test(part)) {
            return <a href={part} key={part} target="_blank" rel="noopener noreferrer">{part}</a>
        }
        return part;
    })
}

export const WebMessages = ({ messages, onMessageClick, onTrashClick }: WebMessagesProps) => {
    const { t } = useTranslation();
    return (
        <>
            <div className={`${styles.webMessage} ${styles.webMessageHeader}`}>
                <div className={styles.webMessageLeftColumn}>
                    {t('Message.Message')}
                </div>
                <div className={styles.webMessageRightColumn}>
                    {t('Message.MessageReceived')}
                </div>
            </div>
            {messages.map(message => (
                <div className="listItemColor" key={`web${message.Id}`} onClick={() => onMessageClick(message.Id, message.SObjectType, message.SalesforceId)}>
                    <div className={styles.webMessage}>
                        <div className={styles.webMessageLeftColumn}>
                            <div className={styles.webMessageReadIndicator}>
                                {message.ReadAt === null ?
                                    <div className={styles.webMessageUnread} />
                                : null}
                            </div>
                            <div>
                                <p className={styles.messageTitle}>{message.Title}</p>
                                <p className={styles.messageBody}>{linkifyText(message.Body)}</p>
                            </div>
                        </div>
                        <div className={styles.webMessageRightColumn}>
                            <p className={styles.webMessageDate}>{moment(message.CreatedAt).format('M/D/YYYY h:mmA')}</p>
                            <div className={styles.trash} onClick={(e) => onTrashClick(e, message.Id)}>
                                <i className="far fa-trash-alt"></i>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export const MobileMessages = ({ messages, onMessageClick }: MobileMessagesProps) => (
    <>
        {messages.map(message => (
            <div className="listItemColor" key={`mobile${message.Id}`} onClick={() => onMessageClick(message.Id, message.SObjectType, message.SalesforceId)}>
                <div className={styles.mobileMessage}>
                    <div className={styles.mobileMessageReadIndicator}>
                        {message.ReadAt === null ?
                            <div className={styles.mobileMessageUnread} />
                        : null}
                    </div>
                    <div className={styles.mobileMessageContent}>
                        <div className={styles.mobileMessageHeader}>
                            <p className={styles.messageTitle}>{message.Title}</p>
                            <p className={styles.mobileMessageDate}>{moment(message.CreatedAt).format('M/D/YYYY h:mmA')}</p>
                        </div>
                        <p className={styles.messageBody}>{linkifyText(message.Body)}</p>
                    </div>
                </div>
            </div>
        ))}
    </>
);

export const Messages = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        messages,
        loadMessagesError,
        deleteMessageError,
        loading
    } = useAppStateMessagesSelector(state => state);
    const onMessageClick = (messageId: string, sObjectType?: string, salesforceId?: string) => {
        dispatch(markRead(messageId));
        if (sObjectType && salesforceId) {
            history.push(`/activity-view/${sObjectType}/${salesforceId}`)
        }
    }
    const onTrashClick = (e: MouseEvent, messageId: string) => {
        dispatch(deleteMessage(messageId));
        e.stopPropagation();
    }
    const sortedMessages = messages.slice().sort((a, b) => Date.parse(b.CreatedAt) - Date.parse(a.CreatedAt));

    useEffect(() => {
        dispatch(loadMessages());
    }, [dispatch]);

    return (
        <PageContainer>
            <div className="headerContainer">
                <h2>{t('Menu.Message_plural')}</h2>
                <Spinner isLoading={loading} />
            </div>
            {loadMessagesError !== '' &&
                <p className={styles.errorText}>{loadMessagesError}</p>
            }
            {deleteMessageError !== '' &&
                <p className={styles.errorText}>{deleteMessageError}</p>
            }
            <PanelRow>
                <Panel>
                    {messages.length ? (
                        <>
                            <WebMessages messages={sortedMessages} onMessageClick={onMessageClick} onTrashClick={onTrashClick} />
                            <MobileMessages messages={sortedMessages} onMessageClick={onMessageClick} />
                        </>
                    ) : <p>{t('Message.NoMessages')}</p>
                    }
                </Panel>
            </PanelRow>
        </PageContainer>
    );
}