import React, { ReactNode } from 'react';
import { ReportAppState } from './reportAppState';
import { Graffiti } from './reportTypes/Graffiti';
import { Trees } from './reportTypes/Trees';
import { SidewalkRepair } from './reportTypes/SidewalkRepair';
import { StreetlightOutage } from './reportTypes/StreetlightOutage';
import { SnowIceRemoval } from './reportTypes/SnowIceRemoval';
import { GarbageRecyclingToter } from './reportTypes/GarbageRecyclingToter';
import { GarbageRecyclingMissed } from './reportTypes/GarbageRecyclingMissed';
import { GarbageRecyclingGeneral } from './reportTypes/GarbageRecyclingGeneral';
import { CodeViolations } from './reportTypes/CodeViolations';
import { HealthComplaints } from './reportTypes/HealthComplaints';
import { StreetUtilitiesRequest } from './reportTypes/StreetUtilitiesRequest';
import { LeafRakeoutProgram } from './reportTypes/LeafRakeoutProgram';
import { AbandonedVehicle } from './reportTypes/AbandonedVehicle';

export interface ReportSpecificSalesforceData {
    RecordTypeId: string; // Used on the salesforce table to identify the type of 311 request
    extraDescription?: string; // Some report types have dropdowns with no associated dropdown in Salesforce. Make a description for them and append them to the end of the description from the textarea.
    [key: string]: any; // Other data that has been mapped to the salesforce keys
}

export interface ReportConfig {
    prepareReportSpecificSalesforceData: (reportState: ReportAppState) => ReportSpecificSalesforceData;
    stepTwoComponent?: ReactNode; // If this is omitted, only steps 1, 3 and 4 will be shown (renumbered accordingly)
    addressNotRequired?: boolean; // If the form can be submitted without an address
    hideAddImages?: boolean; // Don't show "Upload Images"
    showInsteadOfForm?: ReactNode; // A component to show instead of the 311 form
}

enum commonRecordTypeIds {
    genericCase = '012d0000000sVnGAAU',
    wasteManagement = '012d0000000sVnQAAU',
    streetMaintenance = '012d0000000wsWGAAY'
}

export const reportConfigs: { [key: string]: ReportConfig } = {
    '5267101A-7959-4178-B045-E90F2BC1016B': { // Potholes
        prepareReportSpecificSalesforceData: (reportState: ReportAppState) => ({
            RecordTypeId: commonRecordTypeIds.streetMaintenance,
            Service_Request_Type__c: 'Pothole'
        }),
    },
    'EAE1EDF6-876F-4B03-BC9A-8AF777AA7D62': { // Graffiti
        prepareReportSpecificSalesforceData: (reportState: ReportAppState) => ({
            RecordTypeId: '012d0000000sVn8AAE',
            Urgent_Offensive_Agent_Discretion__c: reportState.graffiti.offensive,
            extraDescription: `Location type: ${reportState.graffiti.location}`,
        }),
        stepTwoComponent: <Graffiti />,
    },
    '4615BA1F-229C-404E-A561-62BB8FD78779': { // Trees
        prepareReportSpecificSalesforceData: (reportState: ReportAppState) => ({
            RecordTypeId: '012d0000000sVnJAAU',
            Service_Request_Type__c: reportState.trees.problem
        }),
        stepTwoComponent: <Trees />
    },
    '19531494-4303-41E7-B13D-6543F96CB3E4': { // Dead animal in the roadway
        prepareReportSpecificSalesforceData: (reportState: ReportAppState) => ({
            RecordTypeId: commonRecordTypeIds.streetMaintenance,
            Service_Request_Type__c: 'Dead Animal',
        }),
        hideAddImages: true,
    },
    '93F464E4-C384-4F76-AEEB-5A4F38AD5291': { // Sidewalk repair
        prepareReportSpecificSalesforceData: (reportState: ReportAppState) => ({
            RecordTypeId: commonRecordTypeIds.streetMaintenance,
            Service_Request_Type__c: 'Sidewalk Repair',
            extraDescription: `Issue with the sidewalk: ${reportState.sidewalk.problem}`,
        }),
        stepTwoComponent: <SidewalkRepair />
    },
    '7962DCDD-495C-463C-9F85-FBCA7E471211': { // Street light outage
        prepareReportSpecificSalesforceData: (reportState: ReportAppState) => ({
            RecordTypeId: commonRecordTypeIds.genericCase,
            Service_Request_Type__c: 'Street Light',
            extraDescription: `Pole type: ${reportState.streetlight.poleType} \n Problem: ${reportState.streetlight.problem}`
        }),
        stepTwoComponent: <StreetlightOutage />
    },
    '641CECDF-B7AC-4520-AC16-4F8927296540': { // Snow and ice removal
        prepareReportSpecificSalesforceData: (reportState: ReportAppState) => ({
            RecordTypeId: commonRecordTypeIds.streetMaintenance,
            Service_Request_Type__c: 'Snow & Ice',
            extraDescription: `Street type: ${reportState.snowIce.streetType}`,
        }),
        stepTwoComponent: <SnowIceRemoval />
    },
    '48D12AD7-2439-4A53-BAF5-5DA54DF488C4': { // Garbage or recycling toter repair/replacement
        prepareReportSpecificSalesforceData: (reportState: ReportAppState) => ({
            RecordTypeId: commonRecordTypeIds.wasteManagement,
            Service_Request_Type__c: 'Request for New Waste Container',
            Collection_Day__c: 'Monday', // TODO change me when we get access to arcGIS data to populate this
            Requested_Container_Size__c: reportState.garbageRecyclingToter.size,
            extraDescription: `Requested toter type: ${reportState.garbageRecyclingToter.type}`,
        }),
        stepTwoComponent: <GarbageRecyclingToter />
    },
    'B8926D7C-438D-45FB-8C57-B3EC838D6C82': { // Garbage or recycling missed collection
        prepareReportSpecificSalesforceData: (reportState: ReportAppState) => ({
            RecordTypeId: commonRecordTypeIds.wasteManagement,
            Service_Request_Type__c: 'Missed Collection',
            Collection_Day__c: 'Monday', // TODO change me when we get access to arcGIS data to populate this
            extraDescription: `What was missed: ${reportState.garbageRecyclingMissed.type}`,
        }),
        stepTwoComponent: <GarbageRecyclingMissed />
    },
    'C5737457-31C9-49BA-9F34-FC913BE0F967': { // Garbage or recycling general services
        prepareReportSpecificSalesforceData: (reportState: ReportAppState) => ({
            RecordTypeId: commonRecordTypeIds.wasteManagement,
            Service_Request_Type__c: 'Waste Management General Information',
            extraDescription: `Type of items that need to be collected: ${reportState.garbageRecyclingGeneral.additionalInformation}`,
        }),
        stepTwoComponent: <GarbageRecyclingGeneral />
    },
    '10341658-8CCB-41AF-8FA1-F8706CDA918A': { // Garbage and recycling services feedback
        prepareReportSpecificSalesforceData: (reportState: ReportAppState) => ({
            RecordTypeId: commonRecordTypeIds.wasteManagement,
            Service_Request_Type__c: 'Waste Management Performance Complaint',
        }),
    },
    '72516009-4CFD-4DB8-BBE2-D72EA4D51D60': { // Code violations and requests
        prepareReportSpecificSalesforceData: (reportState: ReportAppState) => ({
            RecordTypeId: commonRecordTypeIds.genericCase,
            extraDescription: `Location type: ${reportState.codeViolation.locationDescription} \nCode violation type: ${reportState.codeViolation.type}`
        }),
        stepTwoComponent: <CodeViolations />
    },
    'CBC87D2F-0940-45B6-B08E-CF282D6668A2': { // Tall weeds and grass
        prepareReportSpecificSalesforceData: (reportState: ReportAppState) => ({
            RecordTypeId: '0120W000002ANM6QAO',
            Service_Request_Type__c: 'Weeds',
        }),
    },
    'D448E13E-E3D7-432A-BEFA-BD7766990280': { // Health complaints
        prepareReportSpecificSalesforceData: (reportState: ReportAppState) => ({
            RecordTypeId: '012d0000000x2d6AAA',
            Service_Request_Type__c: reportState.healthComplaint.type,
            extraDescription: `Location type: ${reportState.healthComplaint.locationDescription}`,
        }),
        stepTwoComponent: <HealthComplaints />
    },
    '649C1AE4-6C57-49F5-8D84-5DF869DCF39D': { // General inquiry
        prepareReportSpecificSalesforceData: (reportState: ReportAppState) => ({
            RecordTypeId: '012d0000000sVnCAAU',
            Topic__c: 'General Information',
        }),
        addressNotRequired: true,
    },
    'A3385927-09F9-4E5E-8B29-AB416EA56988': { // Abandoned vehicle. This doesn't actually submit, it's just informational
        prepareReportSpecificSalesforceData: (reportState: ReportAppState) => ({
            RecordTypeId: commonRecordTypeIds.genericCase,
            Topic__c: 'Abandoned Vehicle',
        }),
        showInsteadOfForm: <AbandonedVehicle />,
    },
    '65993A24-E054-41D0-BDBB-1172B12BFD00': { // Street utilities request
        prepareReportSpecificSalesforceData: (reportState: ReportAppState) => ({
            RecordTypeId: commonRecordTypeIds.genericCase,
            extraDescription: `Request type: ${reportState.streetUtilitiesRequest.type}`
        }),
        stepTwoComponent: <StreetUtilitiesRequest />
    },
    '550F728C-802D-4430-BEA6-4EE237A76C33': { // Fall leaf rake out program
        prepareReportSpecificSalesforceData: (reportState: ReportAppState) => ({
            RecordTypeId: commonRecordTypeIds.genericCase,
        }),
        stepTwoComponent: <LeafRakeoutProgram />
    },
    '65993E24-E054-41D0-BEBB-1172B12BFD00': { // Feedback
        prepareReportSpecificSalesforceData: (reportState: ReportAppState) => ({
            RecordTypeId: commonRecordTypeIds.genericCase,
            Service_Request_Type__c: ''
        }),
        addressNotRequired: true,
    },
}