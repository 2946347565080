import React, { FunctionComponent } from 'react';
import styles from './Panel.module.scss';

export interface PanelRowProps  {
    fluid?: boolean;
    style?: object;
    className?: string;
}

export const PanelRow: FunctionComponent<PanelRowProps> = ({children, fluid, style={}, className=''}) => {
    return (
        <div className={`${styles.panelRow} ${fluid ? styles.fluid : ''} ${className}`} style={style}>
            {children}
        </div>
    );
};
