import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAddAlertModalOpen, setDeleteAlertModalOpen, setEditAlertModalOpen, useAppStateSystemAlertsListSelector } from './systemAlertsListSlice';
import { Alert } from './systemAlertsAppState';
import styles from './SystemAlerts.module.scss';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../components/spinner/Spinner';
import { PageContainer } from '../../components/pageContainer/PageContainer';
import { loadAlerts } from './systemAlertsActionCreators';
import { AlertModal } from './AlertModal';
import { Button } from 'semantic-ui-react';
import moment from 'moment';

interface AlertTableProps {
    alerts: Alert[],
}

export function AlertTable ({ alerts }: AlertTableProps) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { editAlertModalOpen, deleteAlertModalOpen } = useAppStateSystemAlertsListSelector(state => state);

    function editAlert(e: any, index: number) {
        e.preventDefault();
        const arrCopy: boolean[] = [...editAlertModalOpen];
        arrCopy.splice(index, 1, true);
        dispatch(setEditAlertModalOpen(arrCopy));
    }

    function deleteAlert(e: any, index: number) {
        e.preventDefault();
        const arrCopy: boolean[] = [...deleteAlertModalOpen];
        arrCopy.splice(index, 1, true);
        dispatch(setDeleteAlertModalOpen(arrCopy));
    }

    return (
        <div className={styles.tableContainer}>
            <table>
                <tr className={styles.tableHeader}>
                    <th className={styles.active}>
                        {t('Alert.Active')}
                    </th>
                    <th className={styles.alertEnglish}>
                        {t('Alert.EnglishText')}
                    </th>
                    <th className={styles.alertSpanish}>
                        {t('Alert.SpanishText')}
                    </th>
                    <th className={styles.timestamp}>
                        {t('Alert.CreatedDate')}
                    </th>
                    <th className={styles.actions}>
                        {t('Alert.Actions')}
                    </th>
                </tr>
                {alerts?.map((alert, index) =>
                    <tr className={`listItemColor ${styles.item}`} key={alert.Id}>
                        <td className={styles.active}>
                            {alert.Active ? 
                            <div className={styles.greenCheckmark}>
                                <i className="far fa-check fa-2xl" />
                            </div>
                            :
                            <div  className={styles.redX}>
                                <i className="far fa-times fa-2xl" />
                            </div> }
                        </td>
                        <td className={styles.alertText}>
                            <span className={styles.alertHeader}>{alert.TitleAlertEnglish}</span>
                            <span dangerouslySetInnerHTML={{__html: alert.TextEnglish}} />
                        </td>
                        <td className={styles.alertText}>
                            <span className={styles.alertHeader}>{alert.TitleAlertSpanish}</span>
                            <span dangerouslySetInnerHTML={{__html: alert.TextSpanish}} />
                        </td>
                        <td className={styles.timestamp}>
                            {moment(alert.CreatedAt).tz("America/Chicago").format('MM-DD-YY h:mm A')}
                        </td>
                        <td className={styles.actions}>
                            <a href='/' onClick={(e) => editAlert(e, index)}><i className={`far fa-pencil fa-2xl ${styles.editIcon}`} /></a>
                            <a href='/' onClick={(e) => deleteAlert(e, index)}><i className={`far fa-trash fa-2xl ${styles.trashIcon}`} /></a>
                        </td>
                        <AlertModal modalOpen={editAlertModalOpen[index]} alert={alert} index={index} />
                        <AlertModal modalOpen={deleteAlertModalOpen[index]} alert={alert}index={index} />
                    </tr>
                )}
            </table>
        </div>
    )
}

export function SystemAlerts() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { alerts, loading, addAlertModalOpen } = useAppStateSystemAlertsListSelector(state => state);

    useEffect(() => {
        dispatch(loadAlerts());
    }, [dispatch]);

    return (
        <PageContainer>
            <div className='headerContainer'>
                <h1 className={styles.heading}>{t('Alert.SystemAlertManagement')}</h1>
                <Spinner isLoading={loading} />
            </div>
            <div>
                <div className={styles.action_container}>
                    <div className={styles.listing_table}>
                        <h3 className={styles.action_container_heading}>{t('Alert.SystemAlerts')}</h3>
                        <Button onClick={() => dispatch(setAddAlertModalOpen(true))} className={'ui button'}>{t('Alert.AddAlert')}</Button>
                        <AlertModal modalOpen={addAlertModalOpen} alert={null} />
                        {!alerts.length ? 
                            <>
                            <div className={styles.item_text}>
                                <h3 className={styles.action_container_heading}>{t('Alert.NoAlerts')}</h3>
                            </div>
                            </>
                        : 
                        <div className={styles.panelSpacing}>
                            <AlertTable alerts={alerts} />
                        </div>}
                    </div>
                </div>
            </div>
        </PageContainer>
    );
}