import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { buildSubStateSelector } from '../../../store/utilities/useAppStateSelector';
import { SavedPaymentMethod } from '../../../models/coreSavedPaymentsResponse';

export interface NavilineAccountInformation {
    UserId: string;
    OrganizationId: string;
    CustomerId: string;
    LocationId: string;
    Autopay?: {
        AutopayEnabled?: string;
        AutopayProfile?: string;
        AutopayProfileId?: string;
        AutopayStatus?: string;
    }
}

const initialState = {
    userId: '',
    organizationId: '',

    navilineModalOpen: false,
    navilineAccountNumber: '',
    streetNumber: '',
    utilityHelpModalOpen: false,
    navilineListData: [] as {Id: string, Label: string, Data: NavilineAccountInformation}[],
    
    paymentProfile: [],
    authNetModalOpen: false,
    
    autopayAccount: '',
    autopayCustomerId: '',
    autopayLocationId: '',
    autopayModalOpen: false,
    errorModalOpen: false,

    iframeURL: '',

    coreStoredPayments: [] as SavedPaymentMethod[],

    loadingStoredPayments: false,
    loadingNavilineAccounts: false,
};

export type BillingSettingsAppState = typeof initialState;

export const slice = createSlice<BillingSettingsAppState, SliceCaseReducers<BillingSettingsAppState>>({
    name: 'accountEdit',
    initialState,
    reducers: {
        setUserId: (state, action) => {
            state.userId = action.payload;
        },
        setOrganizationId: (state, action) => {
            state.organizationId = action.payload;
        },
        setNavilineModalOpen: (state, action) => {
            state.navilineModalOpen = action.payload;
        },
        setNavilineAccountNumber: (state, action) => {
            state.navilineAccountNumber = action.payload;
        },
        setStreetNumber: (state, action) => {
            state.streetNumber = action.payload;
        },
        setUtilityHelpModal: (state, action) => {
            state.utilityHelpModalOpen = action.payload;
        },
        setNavilineListData: (state, action) => {
            state.navilineListData = action.payload;
        },
        setAuthNetModalOpen: (state, action) => {
            state.authNetModalOpen = action.payload;
        },
        setAutopayAccount: (state, action) => {
            state.autopayAccount = action.payload;
        },
        setAutopayCustomerId: (state, action) => {
            state.autopayCustomerId = action.payload;
        },
        setAutopayLocationId: (state, action) => {
            state.autopayLocationId = action.payload
        },
        setAutopayModalOpen: (state, action) => {
            state.autopayModalOpen = action.payload;
        },
        setCustomerPaymentProfile: (state, action) => {
            state.paymentProfile = action.payload;
        },
        setIframeURL: (state, action) => {
            state.iframeURL = action.payload;
        },
        setErrorModalOpen: (state, action) => {
            state.errorModalOpen = action.payload;
        },
        setCoreStoredPayments: (state, action) => {
            state.coreStoredPayments = action.payload;
        },
        setLoadingStoredPayments: (state, action) => {
            state.loadingStoredPayments = action.payload;
        },
        setLoadingNavilineAccounts: (state, action) => {
            state.loadingNavilineAccounts = action.payload;
        },
    }
});

export const useAppStateBillingSettingsSelector = buildSubStateSelector(state => state.billingSettings);
export const {
    setUserId,
    setOrganizationId,

    setNavilineModalOpen,
    setNavilineAccountNumber,
    setStreetNumber,
    setUtilityHelpModal,
    setNavilineListData,

    setAuthNetModalOpen,
    setAutopayModalOpen,
    setAutopayAccount,
    setAutopayCustomerId,
    setAutopayLocationId,
    setErrorModalOpen,

    setCustomerPaymentProfile,
    setCoreStoredPayments,

    setLoadingStoredPayments,
    setLoadingNavilineAccounts,
} = slice.actions;
export const billingSettingsReducer = slice.reducer;
