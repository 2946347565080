import React, { useEffect } from 'react';
import { Dropdown, DropdownOptions } from '../../../../components/dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setHealthComplaintLocationDescription, setHealthComplaintType, useAppStateReportSelector, setCategoryCanSubmit } from '../reportSlice';

export const HealthComplaints = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const state = useAppStateReportSelector(state => state.healthComplaint);

    const locationDescriptionOptions: DropdownOptions[] = [
        {
            value: "Residential",
            key: "Residential",
            text: t('Report.Residential'),
        },
        {
            value: "Commercial",
            key: "Commercial",
            text: t('Report.Commercial'),
        }
    ];

    const typeOptions: DropdownOptions[] = [
        {
            value: "Air Pollution",
            key: "Air Pollution",
            text: t('Report.AirPollution'),
        },
        {
            value: "Food Complaints",
            key: "Food Complaints",
            text: t('Report.FoodComplaints'),
        },
        {
            value: "Health Miscellaneous",
            key: "Health Miscellaneous",
            text: t('Report.HealthMiscellaneous'),
        },
        {
            value: "Insect & Rodent",
            key: "Insect & Rodent",
            text: t('Report.InsectRodent'),
        },
        {
            value: "Light Pollution",
            key: "Light Pollution",
            text: t('Report.LightPollution'),
        },
        {
            value: "Pollution Complaint",
            key: "Pollution Complaint",
            text: t('Report.Pollution'),
        },
        {
            value: "Public Pool",
            key: "Public Pool",
            text: t('Report.PublicPool'),
        },
        {
            value: "Residential Pool",
            key: "Residential Pool",
            text: t('Report.ResidentialPool'),
        },
        {
            value: "Smoking",
            key: "Smoking",
            text: t('Report.Smoking'),
        },
        {
            value: "Stagnate Water",
            key: "Stagnate Water",
            text: t('Report.StagnantWater'),
        },
        {
            value: "Water Pollution",
            key: "Water Pollution",
            text: t('Report.WaterPollution'),
        },
        {
            value: "Well & Septic",
            key: "Well & Septic",
            text: t('Report.WellSeptic'),
        },
    ];

    useEffect(() => {
        if (state.locationDescription && state.type) {
            dispatch(setCategoryCanSubmit(true));
        }
        else {
            dispatch(setCategoryCanSubmit(false));
        }
    }, [dispatch, state.locationDescription, state.type]);

    return (
        <>
            {t('Report.LocationDescription')}
            <Dropdown
                placeholder={t('Report.LocationDescription')}
                options={locationDescriptionOptions}
                value={state.locationDescription}
                onChange={(e: any, data: any) => dispatch(setHealthComplaintLocationDescription(data.value))}
            />
            {t('Report.NatureOfReport')}
            <Dropdown
                placeholder={t('Report.NatureOfReport')}
                options={typeOptions}
                value={state.type}
                onChange={(e: any, data: any) => dispatch(setHealthComplaintType(data.value))}
            />
        </>
    )
}