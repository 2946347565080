import {createSlice, SliceCaseReducers} from '@reduxjs/toolkit';
import {ForgotPasswordAppState} from './forgotPasswordAppState';
import {buildSubStateSelector} from '../../store/utilities/useAppStateSelector';

export const slice = createSlice<ForgotPasswordAppState, SliceCaseReducers<ForgotPasswordAppState>>({
    name: 'forgotPassword',
    initialState: {
        email: '',
        success: null,
        loading: false,
    },
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload.value;
        },
        forgotPassword: (state, action) => {
            state.email = '';
            state.success = true;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    }
});

export const useAppStateForgotPasswordSelector = buildSubStateSelector(state => state.forgotPassword);
export const { setEmail, forgotPassword, setLoading } = slice.actions;
export const forgotPasswordReducer = slice.reducer;
