import React, { FunctionComponent } from 'react';
import styles from './Search.module.scss';
import { Search as SemanticSearch, SearchResultData, SearchProps as SemanticSearchProps } from 'semantic-ui-react';

export interface SearchProps {
    loading: boolean;
    onResultSelect: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>, data: SearchResultData) => void);
    onSearchChange: ((event: React.MouseEvent<HTMLElement, MouseEvent>, data: SemanticSearchProps) => void);
    results: any;
    value: any;
    minCharacters: number;
    fluid: boolean;
    placeholder: string;
}

export const Search: FunctionComponent<SearchProps> = (props) => {

    return (
        <SemanticSearch
            {...props}
            className={styles.searchBar}
        />
    )
}

Search.defaultProps = {
    placeholder: 'Search'
}