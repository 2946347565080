import {AxiosService} from "./axiosService";
import {NavilinePermitSearchResponse} from "../models/navilinePermitSearchResponse";
import {NavilinePermitInformation} from "../models/navilinePermitInformation";

export class PermitService extends AxiosService {
    public constructor() { super(); }

    async searchByAddress(streetName: string, streetNumber?: string) {
        try {
            return (await this.axios.get<NavilinePermitSearchResponse>(`/permits/search?streetName=${streetName}&streetNumber=${streetNumber}`,)).data;
        } catch(e) {
            console.error('Failed to search for permits: ', e);
            return null;
        }
    }

    async searchByName(ownerName: string) {
        try {
            return (await this.axios.get<NavilinePermitSearchResponse>(`/permits/search?ownerName=${ownerName}`,)).data;
        } catch(e) {
            console.error('Failed to search for permits: ', e);
            return null;
        }
    }

    async getApplicationInfo(year: string, applicationNumber: string) {
        try {
            return (await this.axios.get<NavilinePermitInformation>(`/permits/${year}/${applicationNumber}`,)).data;
        } catch(e) {
            console.error('Failed to search for permits: ', e);
            return null;
        }
    }
}

export const permitService = new PermitService();
