import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { ReportAppState } from './reportAppState';
import { buildSubStateSelector } from '../../../store/utilities/useAppStateSelector';

export const slice = createSlice<ReportAppState, SliceCaseReducers<ReportAppState>>({
    name: 'report',
    initialState: {
        address: '',
        validGoogleMapsAddress: false,
        markerPosition: {
            lat: 42.039344,
            lng: -88.285227
        },
        description: '',
        rating: 0,
        files: [],
        graffiti: {
            offensive: '',
            location: '',
        },
        trees: {
            problem: '',
        },
        sidewalk: {
            problem: '',
        },
        streetlight: {
            poleType: '',
            problem: '',
        },
        snowIce: {
            streetType: '',
        },
        garbageRecyclingToter: {
            type: '',
            size: '',
        },
        garbageRecyclingMissed: {
            type: '',
        },
        garbageRecyclingGeneral: {
            additionalInformation: '',
        },
        codeViolation: {
            locationDescription: '',
            type: '',
        },
        healthComplaint: {
            locationDescription: '',
            type: '',
        },
        streetUtilitiesRequest: {
            type: '',
        },
        invalidAddress: false,
        invalidCategory: false,
        invalidRating: false,
        invalidDescription: false,
        invalidImages: false,
        categoryCanSubmit: false,
        ratingCanSubmit: false,
        fileTooLarge: false,
        submitting: false,
    },
    reducers: {
        setAddress: (state, action) => {
            state.address = action.payload;
        },
        setMarkerPosition: (state, action) => {
            state.markerPosition.lat = action.payload.lat;
            state.markerPosition.lng = action.payload.lng;
        },
        setDescription: (state, action) => {
            state.description = action.payload;
        },
        setRating: (state, action) => {
            state.rating = action.payload;
        },
        addFiles: (state, action) => {
            state.files.push(action.payload);
        },
        clearFiles: (state) => {
            state.files = [];
        },

        setGraffitiOffensive: (state, action) => {
            state.graffiti.offensive = action.payload;
        },
        setGraffitiLocation: (state, action) => {
            state.graffiti.location = action.payload;
        },
        setTreesProblem: (state, action) => {
            state.trees.problem = action.payload;
        },
        setSidewalkProblem: (state, action) => {
            state.sidewalk.problem = action.payload;
        },
        setStreetlightPoleType: (state, action) => {
            state.streetlight.poleType = action.payload;
        },
        setStreetlightProblem: (state, action) => {
            state.streetlight.problem = action.payload;
        },
        setSnowIceStreetType: (state, action) => {
            state.snowIce.streetType = action.payload;
        },
        setGarbageRecyclingToterType: (state, action) => {
            state.garbageRecyclingToter.type = action.payload;
        },
        setGarbageRecyclingToterSize: (state, action) => {
            state.garbageRecyclingToter.size = action.payload;
        },
        setGarbageRecyclingMissedType: (state, action) => {
            state.garbageRecyclingMissed.type = action.payload;
        },
        setGarbageRecyclingGeneralInformation: (state, action) => {
            state.garbageRecyclingGeneral.additionalInformation = action.payload;
        },
        setCodeViolationLocationDescription: (state, action) => {
            state.codeViolation.locationDescription = action.payload;
        },
        setCodeViolationType: (state, action) => {
            state.codeViolation.type = action.payload;
        },
        setHealthComplaintLocationDescription: (state, action) => {
            state.healthComplaint.locationDescription = action.payload;
        },
        setHealthComplaintType: (state, action) => {
            state.healthComplaint.type = action.payload;
        },
        setStreetUtilitiesRequestType: (state, action) => {
            state.streetUtilitiesRequest.type = action.payload;
        },

        setInvalidAddress: (state, action) => {
            state.invalidAddress = action.payload;
        },
        setInvalidCategory: (state, action) => {
            state.invalidCategory = action.payload;
        },
        setInvalidRating: (state, action) => {
            state.invalidRating = action.payload;
        },
        setInvalidDescription: (state, action) => {
            state.invalidDescription = action.payload;
        },
        setInvalidImages: (state, action) => {
            state.invalidImages = action.payload;
        },
        setCategoryCanSubmit: (state, action) => {
            state.categoryCanSubmit = action.payload;
        },
        setRatingCanSubmit: (state, action) => {
            state.ratingCanSubmit = action.payload;
        },
        setFileTooLarge: (state, action) => {
            state.fileTooLarge = action.payload;
        },
        setSubmitting: (state, action) => {
            state.submitting = action.payload;
        },
        setValidGoogleMapsAddress: (state, action) => {
            state.validGoogleMapsAddress = action.payload;
        },
    }
});

export const useAppStateReportSelector = buildSubStateSelector(state => state.report);
export const {
    setAddress,
    setMarkerPosition,
    setDescription,
    setRating,
    addFiles,
    clearFiles,
    
    setValidGoogleMapsAddress,
    setGraffitiOffensive,
    setGraffitiLocation,
    setTreesProblem,
    setSidewalkProblem,
    setStreetlightPoleType,
    setStreetlightProblem,
    setSnowIceStreetType,
    setGarbageRecyclingToterSize,
    setGarbageRecyclingToterType,
    setGarbageRecyclingMissedType,
    setGarbageRecyclingGeneralInformation,
    setCodeViolationLocationDescription,
    setCodeViolationType,
    setHealthComplaintLocationDescription,
    setHealthComplaintType,
    setStreetUtilitiesRequestType,

    setInvalidAddress,
    setInvalidDescription,
    setInvalidImages,
    setInvalidCategory,
    setInvalidRating,
    setCategoryCanSubmit,
    setRatingCanSubmit,
    setFileTooLarge,
    setSubmitting,
} = slice.actions;
export const reportReducer = slice.reducer;