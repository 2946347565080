import { AnyAction, Dispatch } from 'redux';
import { firebaseService } from "../../services/firebaseService";
import { clearLogin, setLoginError, setLoading } from './loginSlice';
import { openMessageModal } from '../../components/statusModal/messageModalActionCreators';
import { authService } from '../../services/authService';

export const login = (email: string, password: string, callback: () => void) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(setLoading(true));
        dispatch(setLoginError(null));
        await firebaseService.auth().signInWithEmailAndPassword(email, password);
        authService.recordUserLogin()
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                dispatch(clearLogin(null));
                callback();
            });
    } catch (err) {
        if (err.code === "auth/wrong-password" || err.code === "auth/user-not-found") {
            // handled together to prevent malicious user from identifying a valid email
            dispatch(setLoginError("Auth.InvalidEmailOrPassword"));
        } else if (err.code === "auth/invalid-email") {
            dispatch(setLoginError("Auth.InvalidEmail"));
        } else {
            dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        }
    } finally {
        dispatch(setLoading(false));
    }
}

export const logout = (callback: () => void) => async (dispatch: Dispatch<any>) => {
    try {
        await firebaseService.auth().signOut();
        window.localStorage.removeItem('organization');
        location.reload(); // eslint-disable-line no-restricted-globals
        callback();
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    }
}

export const signInWithGoogle = () => async (dispatch: Dispatch<any>) => {
    let provider = new firebaseService.auth.GoogleAuthProvider();
    try {
        let result = await firebaseService.auth().signInWithPopup(provider);
        authService.recordUserLogin()
            .finally(() => {
                console.log(result.credential);
                window.location.assign('/');
            });
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.log(err);
    }
}

export const signInWithFacebook = () => async (dispatch: Dispatch<any>) => {
    let provider = new firebaseService.auth.FacebookAuthProvider();
    try {
        let result = await firebaseService.auth().signInWithPopup(provider);
        authService.recordUserLogin()
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                console.log(result.credential);
                window.location.assign('/');
            });
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.log(err);
    }
}

export const signInWithApple = () => async(dispatch: Dispatch<any>) => {
    let provider = new firebaseService.auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    try {
        let result = await firebaseService.auth().signInWithPopup(provider);
        authService.recordUserLogin()
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                console.log(result.credential);
                window.location.assign('/');
            });
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.log(err);
    }
}