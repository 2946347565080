import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { AccountCreateAppState } from './accountCreateAppState';
import { buildSubStateSelector } from '../../../store/utilities/useAppStateSelector';
import { v4 as uuid } from 'uuid';

export const slice = createSlice<AccountCreateAppState, SliceCaseReducers<AccountCreateAppState>>({
    name: 'accountCreate',
    initialState: {
        personalInformation: {
            firstName: '',
            lastName: '',
            phoneNumber: ''
        },
        address: {
            id: 'primary',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            invalidAddress1: false,
            invalidCity: false,
            invalidState: false,
            invalidZip: false,
        },
        accountInformation: {
            hasUtilities: false,
            hasSeveralProperties: false,
            hasBusiness: false,
            hasNoneOfTheAbove: false,
            utilityCustomerId: '',
            utilityStreetNumber: '',
            invalidUtilityCustomerId: false,
            utilityHelpModalOpen: false,
            addressModalOpen: false,
            propertyInput: {
                id: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                invalidAddress1: false,
                invalidCity: false,
                invalidState: false,
                invalidZip: false,
            },
            properties: []
        },
        businessInformation: {
            organizationSearch: '',
            showAddOrganization: false,
            isLoading: false,
            searchResults: [],
            searchValue: '',
            myOrganizations: [],
            organizationList: [],
            newOrganization: {
                name: '',
                taxId: '',
                businessLicenseId: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                invalidName: false,
                invalidTaxId: false,
                invalidAddress1: false,
                invalidCity: false,
                invalidState: false,
                invalidZip: false,
            },
        },
        loading: false,
    },
    reducers: {
        // Page 1: Address
        setAddress1: (state, action) => {
            state.address.address1 = action.payload.value;
        },
        setAddress2: (state, action) => {
            state.address.address2 = action.payload.value;
        },
        setCity: (state, action) => {
            state.address.city = action.payload.value;
        },
        setState: (state, action) => {
            state.address.state = action.payload.value;
        },
        setZip: (state, action) => {
            state.address.zip = action.payload.value;
        },
        setFirstName: (state, action) => {
            state.personalInformation.firstName = action.payload.value;
        },
        setLastName: (state, action) => {
            state.personalInformation.lastName = action.payload.value;
        },
        setPhoneNumber: (state, action) => {
            state.personalInformation.phoneNumber = action.payload.value;
        },
        setInvalidAddress1: (state, action) => {
            state.address.invalidAddress1 = action.payload;
        },
        setInvalidCity: (state, action) => {
            state.address.invalidCity = action.payload;
        },
        setInvalidState: (state, action) => {
            state.address.invalidState = action.payload;
        },
        setInvalidZip: (state, action) => {
            state.address.invalidZip = action.payload;
        },
        setPrimaryAddress: (state) => {
            state.accountInformation.properties = 
                state.accountInformation.properties.filter(property => property.id !== 'primary');
            state.accountInformation.properties.push(state.address);
        },

        // Page 2: Account Information
        setHasUtilities: (state, action) => {
            state.accountInformation.hasUtilities = action.payload;
        },
        setHasSeveralProperties: (state, action) => {
            state.accountInformation.hasSeveralProperties = action.payload;
        },
        setHasBusiness: (state, action) => {
            state.accountInformation.hasBusiness = action.payload;
        },
        setHasNoneOfTheAbove: (state, action) => {
            state.accountInformation.hasUtilities = false;
            state.accountInformation.hasSeveralProperties = false;
            state.accountInformation.hasBusiness = false;
            state.accountInformation.hasNoneOfTheAbove = action.payload;
        },
        setUtilityCustomerId: (state, action) => {
            state.accountInformation.utilityCustomerId = action.payload.value;
        },
        setUtilityStreetNumber: (state, action) => {
            state.accountInformation.utilityStreetNumber = action.payload.value;
        },
        setAdditionalAddress1: (state, action) => {
            state.accountInformation.propertyInput.address1 = action.payload.value;
        },
        setAdditionalAddress2: (state, action) => {
            state.accountInformation.propertyInput.address2 = action.payload.value;
        },
        setAdditionalCity: (state, action) => {
            state.accountInformation.propertyInput.city = action.payload.value;
        },
        setAdditionalState: (state, action) => {
            state.accountInformation.propertyInput.state = action.payload.value;
        },
        setAdditionalZip: (state, action) => {
            state.accountInformation.propertyInput.zip = action.payload.value;
        },
        setInvalidAdditionalAddress1: (state, action) => {
            state.accountInformation.propertyInput.invalidAddress1 = action.payload;
        },
        setInvalidAdditionalCity: (state, action) => {
            state.accountInformation.propertyInput.invalidCity = action.payload;
        },
        setInvalidAdditionalState: (state, action) => {
            state.accountInformation.propertyInput.invalidState = action.payload;
        },
        setInvalidAdditionalZip: (state, action) => {
            state.accountInformation.propertyInput.invalidZip = action.payload;
        },
        toggleUtilityHelpModal: (state) => {
            state.accountInformation.utilityHelpModalOpen = !state.accountInformation.utilityHelpModalOpen;
        },
        toggleAddressModal: (state) => {
            state.accountInformation.addressModalOpen = !state.accountInformation.addressModalOpen;
        },
        addAdditionalAddress: (state) => {
            const id = uuid();
            state.accountInformation.properties.push({...state.accountInformation.propertyInput, id});
            state.accountInformation.propertyInput = {
                id: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                invalidAddress1: false,
                invalidCity: false,
                invalidState: false,
                invalidZip: false
            }
        },
        removeProperty: (state, action) => {
            state.accountInformation.properties = state.accountInformation.properties.filter(p => p.id !== action.payload);
        },
        
        // Page 3: Business Information
        setOrganizationSearch: (state, action) => {
            state.businessInformation.organizationSearch = action.payload.value;
        },
        setBusinessName: (state, action) => {
            state.businessInformation.newOrganization.name = action.payload.value;
        },
        setBusinessTaxId: (state, action) => {
            state.businessInformation.newOrganization.taxId = action.payload.value;
        },
        setBusinessLicenseId: (state, action) => {
            state.businessInformation.newOrganization.businessLicenseId = action.payload.value;
        },
        setBusinessAddress1: (state, action) => {
            state.businessInformation.newOrganization.address1 = action.payload.value;
        },
        setBusinessAddress2: (state, action) => {
            state.businessInformation.newOrganization.address2 = action.payload.value;
        },
        setBusinessCity: (state, action) => {
            state.businessInformation.newOrganization.city = action.payload.value;
        },
        setBusinessState: (state, action) => {
            state.businessInformation.newOrganization.state = action.payload.value;
        },
        setBusinessZip: (state, action) => {
            state.businessInformation.newOrganization.zip = action.payload.value;
        },
        setInvalidBusinessName: (state, action) => {
            state.businessInformation.newOrganization.invalidName = action.payload;
        },
        setInvalidBusinessTaxId: (state, action) => {
            state.businessInformation.newOrganization.invalidTaxId = action.payload;
        },
        setInvalidBusinessLicenseId: (state, action) => {
            state.businessInformation.newOrganization.invalidTaxId = action.payload;
        },
        setInvalidBusinessAddress1: (state, action) => {
            state.businessInformation.newOrganization.invalidAddress1 = action.payload;
        },
        setInvalidBusinessCity: (state, action) => {
            state.businessInformation.newOrganization.invalidCity = action.payload;
        },
        setInvalidBusinessState: (state, action) => {
            state.businessInformation.newOrganization.invalidState = action.payload;
        },
        setInvalidBusinessZip: (state, action) => {
            state.businessInformation.newOrganization.invalidZip = action.payload;
        },
        setIsLoading: (state, action) => {
            state.businessInformation.isLoading = action.payload;
        },
        setSearchResults: (state, action) => {
            state.businessInformation.searchResults = action.payload;
            state.businessInformation.isLoading = false; // TODO: ensure this doesn't cause issues with double loading?
        },
        setSearchValue: (state, action) => {
            state.businessInformation.searchValue = action.payload;
        },
        toggleAddOrganization: (state) => {
            state.businessInformation.showAddOrganization = !state.businessInformation.showAddOrganization;
        },
        addExistingOrganization: (state, action) => {
            state.businessInformation.myOrganizations.push(action.payload);
        },
        addOrganization: (state) => {
            state.businessInformation.myOrganizations.push(state.businessInformation.newOrganization);
            state.businessInformation.newOrganization = {
                name: '',
                taxId: '',
                businessLicenseId: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                invalidName: false,
                invalidTaxId: false,
                invalidAddress1: false,
                invalidCity: false,
                invalidState: false,
                invalidZip: false,
            };
        },
        removeOrganization: (state, action) => {
            state.businessInformation.myOrganizations = state.businessInformation.myOrganizations.filter(c => c.taxId !== action.payload);
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    }
});

export const useAppStateAccountCreateSelector = buildSubStateSelector(state => state.accountCreate);
export const {
    setAddress1,
    setAddress2,
    setCity,
    setState,
    setZip,
    setInvalidAddress1,
    setInvalidCity,
    setInvalidState,
    setInvalidZip,
    setPrimaryAddress,

    setHasUtilities,
    setHasSeveralProperties,
    setHasBusiness,
    setHasNoneOfTheAbove,
    setUtilityCustomerId,
    setUtilityStreetNumber,
    setAdditionalAddress1,
    setAdditionalAddress2,
    setAdditionalCity,
    setAdditionalState,
    setAdditionalZip,
    setInvalidAdditionalAddress1,
    setInvalidAdditionalCity,
    setInvalidAdditionalState,
    setInvalidAdditionalZip,
    toggleUtilityHelpModal,
    toggleAddressModal,
    addAdditionalAddress,
    removeProperty,

    setOrganizationSearch,
    setBusinessName,
    setBusinessTaxId,
    setBusinessLicenseId,
    setBusinessAddress1,
    setBusinessAddress2,
    setBusinessCity,
    setBusinessState,
    setBusinessZip,
    setInvalidBusinessName,
    setInvalidBusinessTaxId,
    setInvalidBusinessLicenseId,
    setInvalidBusinessAddress1,
    setInvalidBusinessCity,
    setInvalidBusinessState,
    setInvalidBusinessZip,
    setIsLoading,
    setSearchResults,
    setSearchValue,
    toggleAddOrganization,
    addExistingOrganization,
    addOrganization,
    removeOrganization,
    setFirstName,
    setLastName,
    setPhoneNumber,
    setInvalidFirstName,
    setInvalidLastName,
    setInvalidPhone,

    loading,
    setLoading,
} = slice.actions;
export const accountCreateReducer = slice.reducer;
