import {createSlice, SliceCaseReducers} from '@reduxjs/toolkit';
import {ActivitiesEditAppState} from './activitiesEditAppState';
import {buildSubStateSelector} from '../../../store/utilities/useAppStateSelector';
import { Activity } from '../list/activitiesListAppState';
import { LaserficheForm } from '../create/activitiesCreateAppState';

const initialState = {
    id: '',
    name: '',
    type: 'LASERFICHE_FORM',
    description: '',
    order: 0,
    published: false,
    salesforceObjectId: 'Test',
    laserficheProcessId: '',
    externalLink: '',
    content: '',
    errorMessage: '',
    action: '',
    deleted: false,
    laserficheForms: null,
    loading: false,
    calendlyLink: '',
    paymentRequired: false,
    guestEnabled: false,
    toggleInAppBrowser: false,
    popularService: false,
    popularServiceUrl: '',
    popularServiceOrder: 0,
};

export const slice = createSlice<ActivitiesEditAppState, SliceCaseReducers<ActivitiesEditAppState>>({
    name: 'activitiesEdit',
    initialState,
    reducers: {
        setName: (state, action) => {
            state.name = action.payload.value;
        },
        setType: (state, action) => {
            state.type = action.payload.value;
        },
        setDescription: (state, action) => {
            state.description = action.payload.value;
        },
        setOrder: (state, action) => {
            state.order = parseInt(action.payload.value);
        },
        setPublished: (state, action) => {
            state.published = action.payload.value;
        },
        setSalesforceObject: (state, action) => {
            state.salesforceObjectId = action.payload.value;
        },
        setLaserficheProcess: (state, action) => {
            state.laserficheProcessId = action.payload.value;
        },
        setCalendlyLink: (state, action) => {
            state.calendlyLink = action.payload.value;
        },
        setExternalLink: (state, action) => {
            state.externalLink = action.payload.value;
        },
        setContentConfiguration: (state, action) => {
            state.content = action.payload.value;
        },
        editActivityFailed: (state, action) => {
            state.errorMessage = action.payload.errorMessage;
        },
        setActivity: (state, action) => {
            const activity = action.payload as Activity;
            state.name = activity.Item;
            state.type = activity.Type;
            state.description = activity.Description;
            state.order = activity.Order;
            state.published = activity.Published;
            state.salesforceObjectId = activity.SalesforceObjectId;
            state.laserficheProcessId = activity.LaserficheProcessId;
            state.externalLink = activity.ExternalLink;
            state.content = activity.Content;
            state.action = activity.Action;
            state.deleted = activity.Deleted;
            state.errorMessage = '';
            state.calendlyLink = activity.CalendlyLink;
            state.paymentRequired = activity.PaymentRequired;
            state.guestEnabled = activity.GuestEnabled ?? false;
            state.toggleInAppBrowser = activity.ToggleInAppBrowser ?? false;
            state.popularService = activity.PopularService ?? false;
            state.popularServiceUrl = activity.PopularServiceIconURL ?? '';
            state.popularServiceOrder = activity.PopularServiceOrder ?? 0;
        },
        setPaymentRequired: (state, {payload}) => {
            state.paymentRequired = payload;
        },
        deleteActivityFailed: (state, action) => {
            state.errorMessage = action.payload.errorMessage;
        },
        resetActivityEditState: (state, action) => {
            return initialState;
        },
        setError: (state, action) => {
            state.errorMessage = action.payload
        },
        setLaserficheForms: (state, action) => {
            state.laserficheForms = action.payload as LaserficheForm;
        },
        getLaserficheFormsFailed: (state, action) => {
            state.errorMessage = action.payload.errorMessage;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setGuestEnabled: (state, {payload}) => {
            state.guestEnabled = payload.value;
        },
        setToggleInAppBrowser: (state, action) => {
            state.toggleInAppBrowser = action.payload.value;
        },
        setPopularService: (state, action) => {
            state.popularService = action.payload.value;
        },
        setPopularServiceUrl: (state, action) => {
            state.popularServiceUrl = action.payload.value;
        },
        setPopularServiceOrder: (state, action) => {
            state.popularServiceOrder = parseInt(action.payload.value);
        },
    }
});

export const useAppStateActivitiesEditSelector = buildSubStateSelector(state => state.activitiesEdit);
export const {
    setName,
    setType,
    setDescription,
    setOrder,
    setPublished,
    setSalesforceObject,
    setLaserficheProcess,
    setCalendlyLink,
    setExternalLink,
    setContentConfiguration,
    editActivityFailed,
    setActivity,
    setPaymentRequired,
    deleteActivityFailed,
    resetActivityEditState,
    setError,
    setLaserficheForms,
    getLaserficheFormsFailed,
    setLoading,
    setGuestEnabled,
    setToggleInAppBrowser,
    setPopularService,
    setPopularServiceUrl,
    setPopularServiceOrder,
} = slice.actions;
export const activitiesEditReducer = slice.reducer;
