import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { config } from './config';

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        // Without lng you get warnings in the console
        // also if spanish lng is set, when the page loads you get a flash of english, then a flash of "loading", then the correct language
        // With lng you get no warnings, you see english for a split second on first load, then spanish, no flash of "loading"
        lng: 'en',
        fallbackLng: 'en',
        debug: false,

        interpolation: {
            escapeValue: false
        },

        backend: {
            crossDomain: true,
            withCredentials: true,
            loadPath: `${config.apiUrl}/i18n/processed/{{lng}}/{{ns}}`
        },
    });

export default i18n;