import React, { useEffect } from 'react';
import { Dropdown, DropdownOptions } from '../../../../components/dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setGarbageRecyclingToterSize, setGarbageRecyclingToterType, useAppStateReportSelector, setCategoryCanSubmit } from '../reportSlice';

export const GarbageRecyclingToter = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const state = useAppStateReportSelector(state => state.garbageRecyclingToter);

    const toterTypeOptions: DropdownOptions[] = [
        {
            value: "Refuse/Waste toter",
            key: "Refuse/Waste toter",
            text: t('Report.RefuseWasteToter'),
        },
        {
            value: "Recycling Toter",
            key: "Recycling Toter",
            text: t('Report.RecyclingToter'),
        },
    ];

    const toterSizeOptions: DropdownOptions[] = [
        {
            value: "35 Gallon",
            key: "35 Gallon",
            text: `35 ${t('Report.Gallon')}`,
        },
        {
            value: "35 - Senior Rate",
            key: "35 - Senior Rate",
            text: `35 ${t('Report.Gallon')} - ${t('Report.SeniorRate')}`,
        },
        {
            value: "64 Gallon",
            key: "64 Gallon",
            text: `64 ${t('Report.Gallon')}`,
        },
    ];

    useEffect(() => {
        if (state.size && state.type) {
            dispatch(setCategoryCanSubmit(true));
        }
        else {
            dispatch(setCategoryCanSubmit(false));
        }
    }, [dispatch, state.size, state.type]);

    return (
        <>
            {t('Report.WhatTypeOfToter')}
            <Dropdown
                placeholder={t('Report.WhatTypeOfToter')}
                options={toterTypeOptions}
                value={state.type}
                onChange={(e: any, data: any) => dispatch(setGarbageRecyclingToterType(data.value))}
            />
            {t('Report.WhatSizeOfToter')}
            <Dropdown
                placeholder={t('Report.WhatSizeOfToter')}
                options={toterSizeOptions}
                value={state.size}
                onChange={(e: any, data: any) => dispatch(setGarbageRecyclingToterSize(data.value))}
            />
        </>
    )
}