import React, {useEffect} from 'react'
import {useAuthSelector} from './authSlice';
import {useDispatch} from 'react-redux';
import {authActionCreators} from './authActionCreators';
import { firebaseService } from '../../services/firebaseService';
import { Spinner } from '../../components/spinner/Spinner';

export const AuthInitializer: React.FunctionComponent = props => {
    const dispatch = useDispatch();

    const { initializationState, loading }  = useAuthSelector(state => state);
    const isInitialized = initializationState === "initialized";

    useEffect(() => {
        const unsub = firebaseService.auth().onAuthStateChanged(async (user) => {
            dispatch(authActionCreators.initializeAuth());
        });
        return () => {
            unsub();
        };
    }, [dispatch]);

    return (
        <>
            <Spinner fullPage isLoading={loading} />
            {isInitialized === true && (
                <>
                    {props.children}
                </> 
            )}
        </>
    );
}