import React, { useEffect } from 'react';
import { Dropdown } from '../dropdown/Dropdown';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './ActivitiesWizard.module.scss';
import { ColoredCircle } from '../coloredCircle/ColoredCircle';
import { Arrow } from '../arrow/Arrow';
import { Button } from '../button/Button';
import { useAppStateActivitiesWizardSelector, clearSelection } from './activitiesWizardSlice';
import { loadActivities, selectAction, selectItem } from './activitiesWizardActionCreators';
import { useAuthSelector } from '../../features/auth/authSlice';
import { personalOrganizationKey } from '../../constants';
import { Activity } from '../../features/activities/list/activitiesListAppState';
import { customFormMap, navigateToActivity } from '../../services/activityService';

export const ActivitiesWizard = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const {
        actions,
        items,
        action,
        item,
        selectedItem,
        errorMessage
    } = useAppStateActivitiesWizardSelector(state => state);
    const { authUser, selectedOrganization } = useAuthSelector(state => state);

    useEffect(() => {
        dispatch(clearSelection(null));
        dispatch(loadActivities(authUser?.Id));
    }, [dispatch, authUser]);

    let handleOnStart = () => {
        if (!authUser && selectedItem.Id.toUpperCase() === customFormMap.WATER_BILL.toUpperCase()) {
            history.push(`/login?redirectto=payment&guest_enabled=true`);
            return;
        }

        if(!authUser && selectedItem.Id.toUpperCase() === customFormMap.QUICK_PERMIT.toUpperCase()) {
            history.push(`/login`);
            return;
        }

        if (!authUser?.Email && !selectedItem.GuestEnabled) {
            history.push(`/login?activityId=${selectedItem.Id}`);
            return;
        }

        const { historyPushLocation, dispatchFunction } = navigateToActivity(selectedItem as Activity);

        if (dispatchFunction) dispatch(dispatchFunction);
        if (historyPushLocation) history.push(historyPushLocation);
    }

    const filteredActions = selectedOrganization === personalOrganizationKey ?
        actions :
        actions.filter(action => action.key !== 'REPORT');

    // Easiest to do this here - if text was translated in loadActivities/selectAction,
    // those functions would have to be rerun when the language changes
    // This does mean that the text values supplied in those functions are pointless
    const translatedActions = filteredActions.map(a => ({ ...a, text: t(`Activity.Action${a.value}`)}))
    const translatedItems = items.map(i => ({ ...i, text: t(`Activity.${action}_${i.value}`)}))

    return (
        <>
            <div className={styles.topInnerPanelContainer}>
                <div className={styles.actionOutterTopContainer}>
                    <div className={`${styles.actionInnerContainer} ${styles.actionInnerLeftContainer}`}>
                        <div className={styles.numberHeaderContainer}>
                            <ColoredCircle opaque size='xs' color={'green'} value={'1'} />
                            <h4 className={styles.numberHeader} style={{textTransform: 'capitalize'}}>{t('Activity.IWantTo')}...</h4>
                        </div>
                        <Dropdown
                            className={styles.dropdown}
                            value={action}
                            placeholder="..."
                            options={translatedActions}
                            onChange={(e: any, data: any) => dispatch(selectAction(data.value))}
                        />
                    </div>
                    <div className={styles.arrowContainer}>
                        <Arrow className={styles.arrow} color='purple'/>
                    </div>
                    <div className={`${styles.actionInnerContainer} ${styles.actionInnerMiddleContainer}`}>
                        <div className={styles.numberHeaderContainer}>
                            <ColoredCircle opaque size='xs' color={'green'} value={'2'} />
                            <h4 className={styles.numberHeader} style={{textTransform: 'capitalize'}}>
                                {action ? t(`Activity.Action${action}`) : '...'}
                            </h4>
                        </div>
                        <Dropdown
                            className={styles.dropdown}
                            value={item}
                            placeholder="..."
                            options={translatedItems}
                            disabled={action ? false : true}
                            onChange={(e: any, data: any) => dispatch(selectItem(data.value))}
                        />
                    </div>
                    <div className={styles.arrowContainer}>
                        <Arrow className={styles.arrow} color='purple'/>
                    </div>
                    <div className={`${styles.actionInnerContainer} ${styles.actionInnerRightContainer}`}>
                        <div className={styles.numberHeaderContainer}>
                            <ColoredCircle opaque size='xs' color={'green'} value={'3'} />
                            <h4 className={styles.numberHeader}>{t('Activity.GetStarted')}!</h4>
                        </div>
                        <Button
                            color='blue'
                            disabled={item ? false : true}
                            onClick={handleOnStart}
                        >
                            {t('Util.Start')}
                        </Button>
                    </div>
                </div>
                <div className={styles.actionOutterBottomContainer}>
                    { errorMessage ?
                        <div className={styles.errorMessage}>
                            {errorMessage}
                        </div>
                    : null }
                    { action && item ?
                        <>
                            <h4 style={{textAlign: 'center', textTransform: 'capitalize'}}>
                                {t(`Activity.Action${action}`)}: {t(`Activity.${action}_${item}`)}
                            </h4>
                            {`Activity.${action}_${item}` !== 'Activity.APPLY_FOR_BUILDING_PERMIT' ?
                                <div>{t(`Activity.${action}_${selectedItem.Item}_DESCRIPTION`)}</div>
                                :
                                <div>
                                    <p className={styles.buildingPermitDescParagraph}>{t(`Activity.BUILDING_PERMIT_DESC_PT1`)}</p>
                                    {(authUser && authUser.Language === 'es') ?
                                        <p className={styles.buildingPermitDescParagraph}>
                                            Para ver una lista completa de permisos en línea, haga clic <a href="https://cityofelgin.org/307/Permits" 
                                                    target="_blank" rel="noopener noreferrer" 
                                                    style={{ color: '#4183c4', textDecoration: 'underline' }}>aquí.</a>
                                        </p>
                                        :
                                        <p className={styles.buildingPermitDescParagraph}>
                                            For a full listing of online permits, please click <a href="https://cityofelgin.org/307/Permits" 
                                                    target="_blank" rel="noopener noreferrer" 
                                                    style={{ color: '#4183c4', textDecoration: 'underline' }}>here.</a>
                                        </p>
                                    }
                                    <p className={styles.buildingPermitDescParagraph}>{t(`Activity.BUILDING_PERMIT_DESC_PT3`)}</p>
                                    <p className={styles.buildingPermitDescParagraph}>{t(`Activity.BUILDING_PERMIT_DESC_PT4`)}</p>
                                </div>
                            }
                        </>
                    :
                        <div className={styles.disclaimer}>
                            <Trans i18nKey="Activity.DontSeeWhatYoureLookingFor">
                                Don't see what you're looking for? We're actively working on adding more services to the portal. <a href="https://www.cityofelgin.org/" target="_blank" rel="noopener noreferrer">Visit our website</a> for more information and resources
                            </Trans>
                        </div>
                    }
                </div>
            </div>
            <div className={styles.topInnerPanelMobileContainer}>
                <div className={styles.actionOutterTopContainer}>
                    <div className={`${styles.actionInnerContainer}`}>
                        <div className={styles.numberHeaderContainer}>
                            <ColoredCircle opaque size='xs' color={'green'} value={'1'} />
                            <h4 className={styles.numberHeader} style={{textTransform: 'capitalize'}}>
                                {t('Activity.IWantTo')}...
                            </h4>
                        </div>
                        <div className={styles.inputContainer}>
                            <Dropdown
                                value={action}
                                placeholder="..."
                                options={translatedActions}
                                onChange={(e: any, data: any) => dispatch(selectAction(data.value))}
                            />
                        </div>
                        <div className={styles.arrowContainer}>
                            <Arrow color='purple' direction='down'/>
                        </div>
                    </div>
                    <div className={`${styles.actionInnerContainer}`}>
                        <div className={styles.numberHeaderContainer}>
                            <ColoredCircle opaque size='xs' color={'green'} value={'2'} />
                            <h4 className={styles.numberHeader} style={{textTransform: 'capitalize'}}>
                                {action ? t(`Activity.Action${action}`) : '...'}
                            </h4>
                        </div>
                        <div className={styles.inputContainer}>
                            <Dropdown
                                value={item}
                                placeholder="..."
                                options={translatedItems}
                                onChange={(e: any, data: any) => dispatch(selectItem(data.value))}
                            />
                        </div>
                        <div className={styles.arrowContainer}>
                            <Arrow color='purple' direction='down'/>
                        </div>
                    </div>
                </div>
                <div className={styles.actionOutterMiddleContainer}>
                    { action && item ?
                        <>
                            <h4 style={{textAlign: 'center'}}>
                                {t(`Activity.Action${action}`)}: {t(`Activity.${action}_${item}`)}
                            </h4>
                            <div>{t(`Activity.${action}_${selectedItem.Item}_DESCRIPTION`)}</div>
                        </>
                    : null}
                </div>
                <div className={styles.actionOutterBottomContainer}>
                    <div className={`${styles.actionInnerContainer}`}>
                        <div className={styles.numberHeaderContainer}>
                            <ColoredCircle opaque size='xs' color={'green'} value={'3'} />
                            <h4 className={styles.numberHeader}>{t('Activity.GetStarted')}!</h4>
                        </div>
                        <div className={styles.startButtonContainer}>
                            <Button color='blue' onClick={handleOnStart}>
                                {t('Util.Start')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
