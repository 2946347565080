import React, { useEffect } from 'react';
import { Dropdown, DropdownOptions } from '../../../../components/dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setStreetlightPoleType, setStreetlightProblem, useAppStateReportSelector, setCategoryCanSubmit } from '../reportSlice';

export const StreetlightOutage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const state = useAppStateReportSelector(state => state.streetlight);

    const poleTypeOptions: DropdownOptions[] = [
        {
            value: "METAL",
            key: "METAL",
            text: t('Report.MetalPole'),
        },
        {
            value: "WOODEN",
            key: "WOODEN",
            text: t('Report.WoodenPole'),
        }
    ];

    const problemOptions: DropdownOptions[] = [
        {
            value: "LIGHT_BURNED_OUT",
            key: "LIGHT_BURNED_OUT",
            text: t('Report.NoLightBurnedOut'),
        },
        {
            value: "LIGHT_ALWAYS_ON",
            key: "LIGHT_ALWAYS_ON",
            text: t('Report.LightAlwaysOn'),
        },
        {
            value: "SERIES_OF_LIGHTS_WITHOUT_POWER",
            key: "SERIES_OF_LIGHTS_WITHOUT_POWER",
            text: t('Report.SeriesOfLightsWithoutPower'),
        },
        {
            value: "LIGHT_GLASS_BROKEN_OUT",
            key: "LIGHT_GLASS_BROKEN_OUT",
            text: t('Report.LightGlassBroken'),
        },
        {
            value: "LIGHT_FLICKERS",
            key: "LIGHT_FLICKERS",
            text: t('Report.LightFlickers'),
        },
        {
            value: "OTHER",
            key: "OTHER",
            text: t('Report.Other'),
        },
    ];

    useEffect(() => {
        if (state.poleType && state.problem) {
            dispatch(setCategoryCanSubmit(true));
        }
        else {
            dispatch(setCategoryCanSubmit(false));
        }
    }, [dispatch, state.poleType, state.problem]);

    return (
        <>
            {t('Report.WhatTypeOfPole')}
            <Dropdown
                placeholder={t('Report.WhatTypeOfPole')}
                options={poleTypeOptions}
                value={state.poleType}
                onChange={(e: any, data: any) => dispatch(setStreetlightPoleType(data.value))}
            />
            {state.poleType === 'WOODEN' ?
                <>
                    <br />
                    <p>{t('Report.ComEdNotificationDescription')}</p>
                    <a href="https://comed.streetlightoutages.com/public/default.html">ComEd</a>
                    <br />
                </>
            : null}
            {t('Report.IdentifyTheProblem')}
            <Dropdown
                placeholder={t('Report.IdentifyTheProblem')}
                options={problemOptions}
                value={state.problem}
                onChange={(e: any, data: any) => dispatch(setStreetlightProblem(data.value))}
            />
        </>
    )
}