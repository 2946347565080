import { toast, ToastOptions } from 'react-semantic-toasts';
import i18n from '../i18n'

// ToastMessages corrolate to Translations
// if you add a Message, add a Translation
export enum ToastMessage {
    success = 'Success',
    successAdd = 'SuccessAdd',
    successRemove = 'SuccessRemove',
    successUpdate = 'SuccessUpdate',
    error = 'Error',
    errorAdd = 'ErrorAdd',
    errorRemove = 'ErrorRemove',
    errorUpdate = 'ErrorUpdate',
}

export const elginToast = (
    optionsOrMessage: ToastOptions | ToastMessage,
    onClose: () => void = () => { },
    onClick: () => void = () => { },
    onDismiss: () => void = () => { },
) => {
    let options: ToastOptions = {
        title: '',
        animation: 'fade',
        type: 'info',
        time: 5000,
    };

    if (optionsOrMessage instanceof Object){
        const customOptions = optionsOrMessage as ToastOptions;
        options = { ...customOptions }
    } else {
        const message = optionsOrMessage as ToastMessage;
        options.title = i18n.t(`Toast.${message}`);

        switch (message) {
            case ToastMessage.success:
            case ToastMessage.successAdd:
            case ToastMessage.successRemove:
            case ToastMessage.successUpdate:
                options.type = 'success';
                break;

            case ToastMessage.error:
            case ToastMessage.errorAdd:
            case ToastMessage.errorRemove:
            case ToastMessage.errorUpdate:
                options.type = 'error';
                options.description = i18n.t('Toast.ErrorContactSupport');
                break;

            default:
                break;
        }
    }

    toast(options, onClose, onClick, onDismiss);
}