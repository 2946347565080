import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Arrow } from '../../components/arrow/Arrow';
import { Panel } from '../../components/panel/Panel';
import { loadInProgressActivities } from '../activities/view/activitiesViewActionCreators';
import { InProgressFormatted, InProgressStatusOptions, useAppStateActivitiesInProgressSelector } from '../activities/view/activitiesViewSlice';
import styles from './Dashboard.module.scss';
import { Spinner } from '../../components/spinner/Spinner';

const DashboardRecentActivityItem = ({ item }: { item: InProgressFormatted }) => {
    let status = item.statusInformation.translationKey;
    switch(status) {
        case InProgressStatusOptions.UnderReview:
            status = 'Under Staff Review';
            break;
        case InProgressStatusOptions.PendingPayment:
            status = 'Pending Payment';
            break;
        case InProgressStatusOptions.ReadyforIssuance:
            status = 'Permit Ready';
            break;
        case InProgressStatusOptions.PermitIssued:
            status = 'Permit Issued';
            break;
        case InProgressStatusOptions.PermitIssuedwithconditions:
            status = 'Permit Issued With Conditions';
            break;
        case InProgressStatusOptions.ScheduleInspection:
            status = 'Call for Inspection';
            break;
        case InProgressStatusOptions.ClosedNotApproved:
            status = 'Closed: Not Approved';
            break;
        case InProgressStatusOptions.GeneratePermit:
            status = 'Generating Permit';
            break;
        default:
            status = status ?? 'None';
            break;
    }

    return (
        <tr>
            <td className={styles.status}>
                <Link to={`/activity-view/${item.data.attributes.type}/${item.id}`}>
                    <div className={`${styles.activityStatus} ${styles.gray}`}>{item.type}</div>
                    <div className={styles.activityDate}>{item.activityName}</div>
                </Link>
            </td>
            <td className={styles.arrow}>
                <Arrow size='small' />
            </td>
            <td className={styles.status}>
                <Link to={`/activity-view/${item.data.attributes.type}/${item.id}`}>
                    <div className={`${styles.activityStatus} ${styles[item.statusInformation.color]}`}>{status}</div>
                    <div className={styles.activityDate}>{item.lastModifiedDisplay}</div>
                </Link>
            </td>
        </tr>
    );
};

export interface DashboardRecentActivitiesProps {}

export const DashboardRecentActivities = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    let recentActivites = useRef<InProgressFormatted[]>();

    const { active, previous, loading } = useAppStateActivitiesInProgressSelector(state => state);

    useEffect(() => {
        dispatch(loadInProgressActivities());
    }, [dispatch]);

    useEffect(() => {
        let allActivities = [...active, ...previous];
        // Sort by most recent activities first
        allActivities = allActivities.sort((a, b) => b.lastModified > a.lastModified ? 1: -1);
        recentActivites.current = allActivities.slice(0, 3);
    }, [active, previous]);

    let handleViewAllActivity = useCallback(() => {
        history.push('/in_progress');
    }, [history]);

    return (
        <Panel size={2}>
            <div className={styles.activityInnerPanelContainer}>
                <div className={styles.activityTopContainer}>
                    <i className={`far fa-clock`}></i>
                    <h3 className={styles.attentionHeader}>{t('Dashboard.RecentActivity')}</h3>
                </div>
                {loading ? 
                    <div className={styles.recentActivityLoadingContainer}>
                        <Spinner isLoading={loading} />
                    </div>
                :
                    <div className={styles.activityMiddleContainer}>
                        <table>
                            <tbody className={styles.activityGrid}>
                                {recentActivites.current ?
                                    recentActivites.current?.map(item => <DashboardRecentActivityItem key={item.id} item={item} />)
                                :
                                    <tr>
                                        <td className={styles.noActivities}>{t('Activity.NoRecentActivities')}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                }
                <div className={styles.activityBottomContainer} onClick={handleViewAllActivity}>
                    <div>{t('Dashboard.ViewAllActivities')}</div>
                </div>
            </div>
        </Panel>
    );
}