import React, {useState} from 'react';
import {PageContainer} from "../../components/pageContainer/PageContainer";
import {useTranslation} from "react-i18next";
import { PanelRow } from '../../components/panel/PanelRow';
import {Panel} from "../../components/panel/Panel";
import styles from './Renewal.module.scss';
import {Text} from "../../components/text/Text";
import moment from "moment";
import {useAuthSelector} from "../auth/authSlice";
import {Button} from "../../components/button/Button";
import {navilineService} from "../../services/navilineService";
import {useHistory} from "react-router-dom";
import {navilinePaymentTypes} from "../../constants";

export const BusinessLicenseRenewal = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [year, setYear] = useState(moment().subtract(1, 'years').year());
    const [accountNumber, setAccountNumber] = useState<string>('');
    const [pin, setPin] = useState('');
    const { isAuthenticated } = useAuthSelector(s => s);

    const goToPayment = async () => {
        if (!year || !accountNumber || !pin) {
            alert('BusinessLicense.Required');
            return;
        }

        const response = await navilineService.getBusinessLicenseFeesDue(year.toString(), accountNumber, pin);
        if (!response) {
            alert('Something went wrong!');
            return;
        }

        if (!response.success) {
            alert(response.message);
        } else if (response.fees! && response.fees! < 1) {
            alert('BusinessLicense.NoFees');
        } else {
            // navigate the user to the payment screen with the amount due
            history.push(`/payment?accountYear=${year.toString().slice(-2)}&accountNumber=${year.toString().slice(-2)}-${accountNumber}&accountType=${navilinePaymentTypes.businessLicenseRenewal}&amount=${response.fees!}&penaltyAmount=${response.penalty!}`);
        }
    }

    return (
        <PageContainer style={isAuthenticated ? {} : { maxWidth: 700, width: '100%'}}>
            <div className='headerContainer'>
                <h2>{t('Activity.PAY_FOR_LICENSE_RENEWAL')}</h2>
            </div>
            <PanelRow>
                <Panel>
                    <p>{t('BusinessLicense.Instructions')}</p>
                    <div className={styles.row}>
                        <div className={styles.inputLabel}>{t('BusinessLicense.Year')}</div>
                        <Text value={year}
                              fluid={false}
                              step={1}
                              min={2020}
                              type={'number'}
                              required
                              onChange={(event: any, data: any) => setYear(data.value)} />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.inputLabel}>{t('BusinessLicense.AccountNumber')}</div>
                        <Text value={accountNumber}
                              fluid={false}
                              required
                              onChange={(event: any, data: any) => setAccountNumber(data.value)} />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.inputLabel}>{t('BusinessLicense.PIN')}</div>
                        <Text value={pin}
                              fluid={false}
                              required
                              onChange={(event: any, data: any) => setPin(data.value)} />
                    </div>
                    <div className={styles.row}>
                        <Button onClick={goToPayment} color={'blue'}>Continue</Button>
                    </div>
                </Panel>
            </PanelRow>
        </PageContainer>
    )
};
