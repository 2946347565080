import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/button/Button';
import { PageContainer } from '../../components/pageContainer/PageContainer';
import { Panel } from '../../components/panel/Panel';
import { PanelRow } from '../../components/panel/PanelRow';
import { Spinner } from '../../components/spinner/Spinner';
import { Text } from '../../components/text/Text';
import styles from './Search.module.scss';
import { loadSearchResults } from './searchActionCreators';
import { setPage, setSearchTerm, useAppStateSearchSelector } from './searchSlice';

export const Search = ({location} : {location: Location}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    
    useEffect(() => {
        const originalVal = new URLSearchParams(location.search).get("term");
        dispatch(setSearchTerm(originalVal || ''));
        dispatch(loadSearchResults(originalVal || '', 0));
    }, [dispatch, location.search]);
    
    const {
        loading,
        searchTerm,
        searchResults,
        page,
        total,
    } = useAppStateSearchSelector(state => state);

    const search = () => {
        dispatch(loadSearchResults(searchTerm, page));
        history.replace({
            pathname: "/search",
            search: `?term=${encodeURIComponent(searchTerm)}`
        });
    };

    const onTextChange = (_e: any, data: { value: any; }) => {
        dispatch(setSearchTerm(data.value))
    }

    const previousPage = () => {
        if (page > 0) {
            dispatch(setPage(page-1));
            dispatch(loadSearchResults(searchTerm, page-1));
        }
    }

    const nextPage = () => {
        if (page < Math.ceil(total / 15) - 1) {
            dispatch(setPage(page+1));
            dispatch(loadSearchResults(searchTerm, page+1));
        }
    }

    const onKeyDown = (e: { key: string; }) => {
        if (e.key === 'Enter') {
            search();
        }
    };
    
    return (
        <PageContainer>
            <div className="headerContainer">
                <h2>{t('Search')}</h2>
                <Spinner isLoading={loading} />
            </div>
            <PanelRow>
                <Panel>
                    <div className={styles.termWrapper}>
                        <Text onKeyDown={onKeyDown} value={searchTerm} onChange={onTextChange} />
                        <Button onClick={search}>Search</Button>
                    </div>
                    <h3>Search Results</h3>
                    { loading && 
                    
                        <Spinner isLoading={loading} />
                    }
                    {
                        total < 1 && !loading &&
                        <div>No search results found.</div>
                    }
                    {
                        total > 0 && !loading &&
                        <>
                            <div>
                                {
                                    searchResults.map((result, index) => (
                                        <div key={index} className={styles.searchResult}>
                                            <a className={styles.titleLink} href={result.url} target="_blank" rel='noopener noreferrer'>
                                                <div className={styles.urlDisplay}>{result.url}</div>
                                                <div className={styles.titleDisplay}>{result.title}</div>
                                            </a>
                                            <div className={styles.highlightDisplay}>
                                                {result.highlight}...<br/>
                                                <a href={result.url} target="_blank" rel='noopener noreferrer'>Read More</a>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div>
                                {
                                    page > 0 && 
                                    <span className={styles.pageButton} onClick={previousPage}><i className="fa fa-chevron-left"></i></span>
                                }

                                Page {page+1} of {(Math.ceil(total / 15))}


                                {
                                    page < (Math.ceil(total / 15) - 1)  && 
                                    <span className={styles.pageButton} onClick={nextPage}><i className="fa fa-chevron-right"></i></span>
                                }
                            </div>
                        </>
                    }
                </Panel>
            </PanelRow>
        </PageContainer>
    )
};
