import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PageContainer } from '../../../../components/pageContainer/PageContainer';
import { Panel } from '../../../../components/panel/Panel';
import { PanelRow } from '../../../../components/panel/PanelRow';
import { Spinner } from '../../../../components/spinner/Spinner';
import { loadInProgressActivities } from '../activitiesViewActionCreators';
import { useAppStateActivitiesInProgressSelector } from '../activitiesViewSlice';
import { ActivityTable } from './ActivitiesViewInProgress';

export const ActivitiesCompleted = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(loadInProgressActivities());
    }, [dispatch]);

    const { loading, previous } = useAppStateActivitiesInProgressSelector(state => state);
    
    return (
        <PageContainer>
            <div className='headerContainer'>
                <h2>{t('SideBar.CompletedActivity', { count: 2 })}</h2>
                <Spinner isLoading={loading} /> 
            </div>
            <PanelRow>
                <Panel>
                    <ActivityTable items={previous} />
                </Panel>
            </PanelRow>
        </PageContainer>
    );
}