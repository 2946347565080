import { AnyAction, Dispatch } from "redux";
import moment from "moment";
import { indexOf } from "lodash";

import {
    GarbageSearchRecord,
    garbageSearchActions,
    daysOfWeek,
    holidays,
    Holidays
} from "./garbageSearchSlice";
import { openMessageModal } from "../../components/statusModal/messageModalActionCreators";
import { axios } from "../../services/axiosService";

interface Params {
    streetNumber?: string;
    street?: string;
}

export const loadGarbageSearch = (
    streetNumber: string,
    street: string
) => async (dispatch: Dispatch) => {
    try {
        const { isHolidayThisWeek, indexOfHoliday, holidayName } = getIsHoliday();
        const params: Params = {};

        dispatch(garbageSearchActions.setLoading({ isLoading: true }))
        
        if (streetNumber && street) {
            params["streetNumber"] = streetNumber;
            params["street"] = street;
        } else if (streetNumber) { 
            params["streetNumber"] = streetNumber; 
        } else if (street) { 
            params["street"] = street; 
        } else {
            dispatch(garbageSearchActions.setRecord([]));
            return null;
        }
        
        const apiResponse = (
            await axios.get("/salesforce/landParcelMaster/garbagePickupDay", { params: params })
        ).data as GarbageSearchRecord[];

        const apiResponseSorted = sortArray(apiResponse, "Parcel_Address__c");

        if (isHolidayThisWeek) {
            const apiResponseIsHoliday = updateGarbageDaysIfHoliday(apiResponseSorted, indexOfHoliday, holidayName);
            dispatch(garbageSearchActions.setRecord(apiResponseIsHoliday));
        } else {
            dispatch(garbageSearchActions.setRecord(apiResponseSorted));
        }
    } catch {
        dispatch(openMessageModal(null, "error") as unknown as AnyAction);
    } finally {
        dispatch(garbageSearchActions.setLoading({ isLoading: false }))
    }
};

const sortArray = (
    array: any[], 
    property: string
) => {
    const apiResponseSorted = array.sort((record1, record2) => {
        if (record1[property] > record2[property]) {
            return 1;
        } else if (record1[property] < record2[property]) {
            return -1;
        } else {
            return 0;
        }
    });

    return apiResponseSorted;
};

const getTodayInfo = () => {
    const todayDate: string = moment().format("YYYY-MM-DD");
    const todayDayOfWeek: string = moment().format("dddd");

    const todayIndexResult = daysOfWeek.findIndex(({ day }) => day === todayDayOfWeek);

    if (todayIndexResult !== -1) {
        daysOfWeek[todayIndexResult].date = todayDate;

        return {
            todayDate: todayDate,
            todayIndex: todayIndexResult
        };
    }

    return { todayDate: "",  todayIndex: -1 };
};

const getIsHoliday = () => {
    const { todayDate, todayIndex } = getTodayInfo();
    
    for (let dayObjects in daysOfWeek) {
        const indexOfDayOfWeek = indexOf(daysOfWeek, daysOfWeek[dayObjects]);
        const indexPositionOffsetFromTodayIndex = indexOfDayOfWeek - todayIndex;
        
        if (indexOfDayOfWeek < todayIndex) {
            daysOfWeek[dayObjects].date = 
                moment(todayDate).subtract(-indexPositionOffsetFromTodayIndex, "days").format("YYYY-MM-DD");
        } else if (indexOfDayOfWeek > todayIndex) {
            daysOfWeek[dayObjects].date = 
                moment(todayDate).add(indexPositionOffsetFromTodayIndex, "days").format("YYYY-MM-DD");
        }   
    }

    getFloatingHolidays(holidays);

    for (let holidayObjects in holidays) {
        const indexOfHolidayResult = 
            daysOfWeek.findIndex(({ date }) => date === holidays[holidayObjects].date);

        if (indexOfHolidayResult !== -1 && daysOfWeek[indexOfHolidayResult].day !== "Sunday") {  
            return {
                isHolidayThisWeek: true,
                holidayName: holidays[holidayObjects].holiday,
                indexOfHoliday: indexOfHolidayResult
            };  
        }
    }
   
    return { isHolidayThisWeek: false, holidayName: "", indexOfHoliday: -1 }; 
};

const getFloatingHolidays = (holidays: Holidays[]) => {
    const currentMonth = moment().format("MM");
    
    if (currentMonth === "05" || currentMonth === "06") {
        const endOfMay = moment(`${moment().year()}-05`).endOf("month");
        const lastMondayOfMay = endOfMay.clone().day("Monday");  
        
        if (lastMondayOfMay.isAfter(endOfMay)) {
            lastMondayOfMay.subtract(7, "days");
        }

        const memorialDay = holidays.find(({ holiday }) => holiday === "Memorial Day");

        if (memorialDay) {
            memorialDay.date = lastMondayOfMay.format("YYYY-MM-DD");
        }
        
    } else if (currentMonth === "08" || currentMonth === "09") {
        const startOfSeptember = moment(`${moment().year()}-09`).startOf("month");
        const firstMondayOfSeptember = startOfSeptember.clone().day("Monday");

        if (firstMondayOfSeptember.isBefore(startOfSeptember)) {
            firstMondayOfSeptember.add(7, "days");
        }

        const laborDay = holidays.find(({ holiday }) => holiday === "Labor Day");

        if (laborDay) {
            laborDay.date = firstMondayOfSeptember.format("YYYY-MM-DD");
        }
    } else if (currentMonth === "11" || currentMonth === "12") {
        const startOfNovember = moment(`${moment().year()}-11`).startOf("month");
        const firstThursdayOfNovember = startOfNovember.clone().day("Thursday");

        if (firstThursdayOfNovember.isBefore(startOfNovember)) {
            firstThursdayOfNovember.add(1, "week");
        }

        const fourthThursdayOfNovember = firstThursdayOfNovember.add(3, "weeks");

        const thanksgivingDay = holidays.find(({ holiday }) => holiday === "Thanksgiving Day");

        if (thanksgivingDay) {
            thanksgivingDay.date = fourthThursdayOfNovember.format("YYYY-MM-DD");
        }
    }
};

const updateGarbageDaysIfHoliday = (
    apiResponse: GarbageSearchRecord[], 
    indexOfHoliday: number, 
    holidayName: string
) => {
    for (let record in apiResponse) {
        const indexOfDayOfWeekResult = 
            daysOfWeek.findIndex(({ day }) => day === apiResponse[record].Refuse_Pickup_Day__c);

        if (indexOfDayOfWeekResult >= indexOfHoliday) {
            apiResponse[record].Refuse_Pickup_Day__c = daysOfWeek[indexOfDayOfWeekResult + 1].day;
            apiResponse[record].isDelayedFromHoliday = true;
            apiResponse[record].holidayName = holidayName;
        }
    }

    return apiResponse;
};