import React, { ReactNode, CSSProperties } from 'react';
import styles from './PageContainer.module.scss';

// Should work as is for most pages, requiring minor style modification for some
// Not worth trying to use for a few (Home due to background, login/signup because they're very different)

interface PageContainerProps {
    children: ReactNode,
    className?: string, // In case things like media queries need to be added
    style?: CSSProperties,
}

export const PageContainer = (props: PageContainerProps) => (
    <div
        className={`${styles.page} ${props.className ?? ''}`}
        style={props.style ?? {}}
    >
        {props.children}
    </div>
);