import React, { useState } from 'react';
import styles from './BulkPushNotifications.module.scss';
import { Button, Input, GridRow, GridColumn, Grid } from 'semantic-ui-react';
import { Toggle } from '../../components/toggle/Toggle';
import 'semantic-ui-css/semantic.min.css';
import { Textarea } from '../../components/textArea/Textarea';
import { axios } from '../../services/axiosService';
import { AnyAction } from 'redux';
import { openMessageModal } from '../../components/statusModal/messageModalActionCreators';
import { useDispatch } from 'react-redux';

interface BulkPushNotificationProps {
    onClose: () => void;
}
export const BulkPushNotification = (props: BulkPushNotificationProps) => {
    const dispatch = useDispatch();
    const [titleEnglish, setEnglishTitle] = useState('');
    const [titleSpanish, setSpanishTitle] = useState('');
    const [englishEditorContent, setEnglishEditorContent] = useState('');
    const [spanishEditorContent, setSpanishEditorContent] = useState('');
    const [loading, setLoading] = useState(false);

    const [autoPayToggle, setAutoPayToggle] = useState(false);
    const [hasSavedCardToggle, setHasSavedCardToggle] = useState(false);
    const [hasUtilityAccountToggle, setHasUtilityAccountToggle] =
        useState(false);
    const [noActivitiesToggle, setNoActivitiesToggle] = useState(false);

    const onEnglishTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEnglishTitle(e.target.value);
    };

    const onSpanishTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSpanishTitle(e.target.value);
    };

    const onEnglishEditorChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
        setEnglishEditorContent(e.currentTarget.value);
    };

    const onSpanishEditorChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
        setSpanishEditorContent(e.currentTarget.value);
    };

    const onSubmit = async () => {
        setLoading(true);
        await axios.post('/notifications/bulk', {
            englishTitle: titleEnglish,
            spanishTitle: titleSpanish,
            englishBody: englishEditorContent,
            spanishBody: spanishEditorContent,
            options: {
                autoPayEnabled: autoPayToggle,
                hasSavedPaymentMethod: hasSavedCardToggle,
                hasUtilityAccount: hasUtilityAccountToggle,
                noActivities: noActivitiesToggle,
            },
        });

        setLoading(false);
        props.onClose();
        dispatch(
            openMessageModal(
                'success-sumbit-form',
                'success',
            ) as unknown as AnyAction,
        );
    };

    return (
        <div className={styles.modalContent}>
            <div>
                <div className={styles.textEditorContainer}>
                    <h3>English Title</h3>
                    <Input
                        value={titleEnglish}
                        onChange={onEnglishTitleChange}
                        className={styles.titleContainer}
                        placeholder={'English Title'}
                    />
                </div>
                <div className={styles.textEditorContainer}>
                    <h3>English Message</h3>
                    <Textarea
                        onChange={onEnglishEditorChange}
                        value={englishEditorContent}
                        placeholder={'Enter English Message'}
                    />
                </div>
                <div className={styles.textEditorContainer}>
                    <h3>Spanish Title</h3>
                    <Input
                        value={titleSpanish}
                        onChange={onSpanishTitleChange}
                        className={styles.titleContainer}
                        placeholder={'Spanish Title'}
                    />
                </div>
                <div className={styles.textEditorContainer}>
                    <h3>Spanish Message</h3>
                    <Textarea
                        placeholder={'Enter Spanish Message'}
                        onChange={onSpanishEditorChange}
                        value={spanishEditorContent}
                    />
                </div>

                <div className={styles.toggleContainer}>
                    <Grid>
                        <GridRow columns={2} className={styles.toggleRow}>
                            <GridColumn>
                                <Toggle
                                    value={autoPayToggle}
                                    onChange={(e: any, data: any) =>
                                        setAutoPayToggle(data.checked)
                                    }
                                    text={'Has Auto Pay Enabled'}
                                />
                            </GridColumn>
                            <GridColumn>
                                <Toggle
                                    value={hasSavedCardToggle}
                                    onChange={(e: any, data: any) =>
                                        setHasSavedCardToggle(data.checked)
                                    }
                                    text={'Has Saved Card'}
                                />
                            </GridColumn>
                        </GridRow>
                        <GridRow columns={2} className={styles.toggleRow}>
                            <GridColumn>
                                <Toggle
                                    value={hasUtilityAccountToggle}
                                    onChange={(e: any, data: any) =>
                                        setHasUtilityAccountToggle(data.checked)
                                    }
                                    text={'Has Utility Account'}
                                />
                            </GridColumn>
                            <GridColumn>
                                <Toggle
                                    value={noActivitiesToggle}
                                    onChange={(e: any, data: any) =>
                                        setNoActivitiesToggle(data.checked)
                                    }
                                    text={'No Activities'}
                                />
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </div>

                <div className={styles.submitContainer}>
                    <div className={styles.buttonRight}>
                        <Button
                            onClick={onSubmit}
                            color={'blue'}
                            fluid={false}
                            loading={loading}
                            disabled={
                                titleEnglish.trim().length === 0 ||
                                titleSpanish.trim().length === 0
                            }
                        >
                            Send
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
