import React from 'react';
import styles from './BillingSettings.module.scss';
import { Modal } from 'semantic-ui-react';
import { Icon } from '../../../components/icon/Icon';
import { setNavilineAccountNumber, setStreetNumber, setUtilityHelpModal, useAppStateBillingSettingsSelector } from './billingSettingsSlice';
import { useDispatch } from 'react-redux';
import { Text } from '../../../components/text/Text';
import { useTranslation } from 'react-i18next';
import { UtilityHelpModal } from '../create/AccountCreateAccountInformation';
import { Button } from '../../../components/button/Button';

interface NavilineAddModalProps {
    modalOpen: boolean;
    onClose(accountNumber?: string, streetNumber?: string): void;
}

export const NavilineAddModal = ({modalOpen, onClose}: NavilineAddModalProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { navilineAccountNumber, streetNumber, utilityHelpModalOpen } = useAppStateBillingSettingsSelector(state => state);

    const submit = () => {
        onClose(navilineAccountNumber, streetNumber);
    }

    return (
        <Modal open={modalOpen} onClose={() => onClose()} size="mini">
            <Modal.Header>
                {t('Billing.AddWaterBillingAccountInformation')}
                <div style={{'float': 'right'}}>
                    <Icon
                        icon='x'
                        color='black'
                        size="large"
                        onClick={() => onClose()}
                    />
                </div>
            </Modal.Header>
            <Modal.Content>
                <div className={styles.modalContent}>
                    <form onSubmit={submit}>
                        {t('Account.CreateCustomerID')} ({t('Account.CreateCustomerIDNote')})
                        <span style={{ 'color': 'red' }}>*</span>{" "}
                        <span
                            role="button"
                            className={styles.linkText}
                            onClick={() => dispatch(setUtilityHelpModal(true))}
                        >
                            {t('Account.CreateWhatsThis')}
                        </span>
                        <UtilityHelpModal
                            modalOpen={utilityHelpModalOpen}
                            text={{
                                header: t('Account.CreateFindYourCustomerId'),
                                line1: t('Account.CreateToFindYourCustomerId'),
                                line2: t('Account.CreatePleaseEnterAsItAppears'),
                            }}
                            onClose={() => dispatch(setUtilityHelpModal(false))}
                        />
                        <Text
                            value={navilineAccountNumber}
                            onChange={(e: any) => {
                                // Remove any spaces and leading 0's that users enter
                                const enteredNavilineAccountNumber = e.target.value.replace(/^0+/, '').replace(/-0+/, '-').replace(/ /g, '');
                                dispatch(setNavilineAccountNumber(enteredNavilineAccountNumber));
                            }}
                            placeholder={t('Account.CreateCustomerID')}
                        />
                        {t('Account.StreetNumberOfServiceAddress')}
                        <Text
                            value={streetNumber}
                            onChange={(e: any) => {
                                const enteredStreetNumber = e.target.value.replace(/ /g, '');
                                dispatch(setStreetNumber(enteredStreetNumber));
                            }}
                            placeholder={t('Account.ServiceAddressStreetNumber')}
                            style={{marginBottom: 0}}
                        />
                        <span>
                            {t('Account.NoteStreetNumberOnly')}
                        </span>
                        <div className={styles.submitContainer}>
                            <div>
                                <Button
                                    onClick={submit}
                                    color="blue"
                                    fluid={false}
                                    disabled={!navilineAccountNumber || !streetNumber}
                                >
                                    {t('Util.Submit')}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Content>
        </Modal>
    );
};
