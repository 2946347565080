import React, { useEffect } from 'react';
import styles from './Home.module.scss';
import { ActivitiesWizard } from '../../components/activitiesWizard/ActivitiesWizard';
import { NavigationCircles } from '../../components/navigationCircles/NavigationCircles';
import { News } from '../../components/news/News';
import { Events } from '../../components/events/Events';
import { setFavicon } from '../../services/utilities';
import { config } from '../../config';
import { PopularServices } from '../../components/popularServices/PopularServices';
import { useFeatureFlag } from '../featureFlags/FeatureFlag';
import { useAppStateFeatureFlagSelector } from '../featureFlags/featureFlagSlice';

export function Home() {
    const popularServicesFF = useFeatureFlag('EnablePopularServices');
    const { loadingFeatureFlags } = useAppStateFeatureFlagSelector(state => state);

    useEffect(() => {
        setFavicon(config.webUrl + '/elgin-favicon.ico');
    });

    return (
        <div className={styles.page}>
            <div className={styles.topPage}>
                <div className={styles.headerLogo} />
                <div className={styles.activityContainer} style={popularServicesFF?.enabled ? { minHeight: '297px' } : {}}>
                        {!loadingFeatureFlags ?
                            popularServicesFF?.enabled ?
                                <PopularServices />
                                :
                                <ActivitiesWizard />
                            : null
                        }
                </div>
                <div className={styles.circleContainer}>
                    <NavigationCircles />
                </div>
            </div>
            <div className={styles.bottomPage}>
                <News/>
                <Events/>
            </div>
        </div>
    );
}
