import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationSearch } from '../../../components/organizationSearch/OrganizationSearch';
import { PageContainer } from '../../../components/pageContainer/PageContainer';
import { Panel } from '../../../components/panel/Panel';
import { PanelRow } from '../../../components/panel/PanelRow';

export const AddOrganization = () => {
    const { t } = useTranslation();
    return (
        <PageContainer>
            <PanelRow>
                <Panel>
                    <h1>{t('Organization.AddOrJoinANewOrganization')}</h1>
                    <p>{t('Organization.SearchForExistingOrAddNew')}</p>
                    <p>{t('Organization.ForExistingOrganizations')}</p>
                    <p>{t('Organization.ForNewOrganizations')}</p>
                    <p>{t('Organization.InBothInstances')}</p>
                </Panel>
            </PanelRow>
            <PanelRow>
                <Panel>
                    <h3>{t('Organization.OrganizationInformation')}</h3>
                    <OrganizationSearch postSignUp />
                </Panel>
            </PanelRow>
        </PageContainer>
    );
};
