import React, { useEffect } from 'react';
import styles from './AccountCreate.module.scss';
import { useAppStateAccountCreateSelector } from './accountCreateSlice';
import { AccountCreateAddress } from './AccountCreateAddress';
import { AccountCreateProgressBar } from './AccountCreateProgressBar';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { AccountCreateAccountInformation } from './AccountCreateAccountInformation';
import { Spinner } from '../../../components/spinner/Spinner';
import { PageContainer } from '../../../components/pageContainer/PageContainer';
import { useTranslation } from 'react-i18next';
import { OrganizationSearch } from '../../../components/organizationSearch/OrganizationSearch';
import { PanelRow } from '../../../components/panel/PanelRow';
import { Panel } from '../../../components/panel/Panel';
import { setFavicon } from '../../../services/utilities';
import { config } from '../../../config';

export function AccountCreateContainer() {
    const { t } = useTranslation();
    const state = useAppStateAccountCreateSelector(state => state);
    const { path } = useRouteMatch();

    useEffect(() => {
        setFavicon(config.webUrl + '/elgin-favicon.ico');
    }, []);

    return (
        <PageContainer className={styles.pageContainer}>
            <div className={styles.titleContainer}>
                <div className='headerContainer'>
                    <h2>{t('Account.CreateAnAccount')}</h2>
                    <Spinner isLoading={state.loading}/>
                </div>
            </div>
            <Switch>
                <Route path={`${path}/address`}>
                    <AccountCreateProgressBar step={1}/>
                    <AccountCreateAddress address={state.address} personalInformation={state.personalInformation} />
                </Route>
                <Route path={`${path}/account-information`}>
                    <AccountCreateProgressBar step={2}/>
                    <AccountCreateAccountInformation accountInformation={state.accountInformation} personalInformation={state.personalInformation} />
                </Route>
                <Route path={`${path}/business-information`}>
                    <AccountCreateProgressBar step={3}/>
                    <PanelRow>
                        <Panel>
                            <div className={styles.addOrganizationInformation}>
                                <h3>{t('Organization.SearchForExistingOrAddNew')}</h3>
                                <p>{t('Account.CreateAddOrgAfterSignup')}</p>
                            </div>
                            <div className={styles.formContainer}>
                                <OrganizationSearch />
                            </div>
                        </Panel>
                    </PanelRow>
                </Route>
                <Route path={`${path}`}>
                    <Redirect to={`${path}/address`} />
                </Route>
            </Switch>
        </PageContainer>
    )
}