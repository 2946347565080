import React from 'react';
import { useDispatch } from "react-redux";
import { Modal } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";

import { loadGarbageSearch } from './garbageSearchActionCreators';
import { 
  GarbageSearchRecord, 
  garbageSearchActions, 
  useAppStateGarbageSearchSelector 
} from './garbageSearchSlice';

import { PageContainer } from '../../components/pageContainer/PageContainer';
import { Spinner } from '../../components/spinner/Spinner';
import { PanelRow } from '../../components/panel/PanelRow';
import { Panel } from '../../components/panel/Panel';
import { Text } from '../../components/text/Text';
import { Button } from '../../components/button/Button';
import { Icon } from '../../components/icon/Icon';

import styles from './GarbageSearch.module.scss';

export const GarbageSearch = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { 
    records, 
    searchTerm: { streetNumber, street },
    loading: { isLoading },
    modal: { isOpen },
    details: {
      Parcel_Address__c,
      Refuse_Pickup_Day__c,
      isDelayedFromHoliday,
      holidayName
    } 
  } = useAppStateGarbageSearchSelector((state) => state);

  const handleOnChange = (event: any, data: any) => {
    const { name, value } = data;

    dispatch(
      garbageSearchActions.setSearchTerm({
        streetNumber: name === "streetNumber" ? value : streetNumber,
        street: name === "street" ? value : street
      })
    );
  };

  const handleOnClick = () => {
    dispatch(loadGarbageSearch(streetNumber, street));
  };

  const handleOnModalOpen = (
    Parcel_Address__c: string,
    Refuse_Pickup_Day__c: string,
    isDelayedFromHoliday: boolean,
    holidayName: string
  ) => {
    dispatch(garbageSearchActions.setModal({ isOpen: true }));
    dispatch(garbageSearchActions.setDetails({
      Parcel_Address__c: Parcel_Address__c,
      Refuse_Pickup_Day__c: Refuse_Pickup_Day__c,
      isDelayedFromHoliday: isDelayedFromHoliday,
      holidayName: holidayName
    }));
  };

  const handleOnModalClose = () => {
    dispatch(garbageSearchActions.setModal({ isOpen: false }));
  };

  const SearchResultsTable = () => {
    if (records.length) {
      return (
        <div
          className={styles.row}
          style={{ overflowX: "scroll" }} 
        >
          <table className={styles.table}>
            <thead>
              <tr>
                <th>
                  {t("GarbageSearch.TableHeader")}
                </th>
              </tr>
            </thead>
            <tbody>
              {records.map(
                (
                  {
                    Parcel_Address__c,
                    Refuse_Pickup_Day__c,
                    isDelayedFromHoliday,
                    holidayName,
                  }: GarbageSearchRecord,
                  index
                ) => (
                  <tr 
                    key={index}
                    onClick={
                      () => handleOnModalOpen(
                        Parcel_Address__c,
                        Refuse_Pickup_Day__c,
                        isDelayedFromHoliday,
                        holidayName
                    )}
                  >
                    <td>
                      {Parcel_Address__c}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div>
          {t("GarbageSearch.NoResults")}
        </div>
      );
    }   
  };

  const ModalHolidayDelay = () => {
    if (isDelayedFromHoliday) {
      return (
        <>
          <div className={styles.detailRow}>
            <strong>{t("GarbageSearch.ModalHolidayNotice")}</strong>
          </div>
          <div className={styles.detailRow}>
            {`${t("GarbageSearch.ModalHolidayNoticeDescription")} ${holidayName}.`}
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  if (isLoading) { return <Spinner isLoading={isLoading} fullPage/> }

  return (
    <PageContainer style={{ maxWidth: 1000, width: "100%" }}>
      <>
        <div className="headerContainer">
          <h2>
            {t("Activity.FIND_GARBAGE_SEARCH")}
          </h2>
        </div>
        <PanelRow>
          <Panel>
            <p>
              {t("GarbageSearch.Description")}
            </p>
            <p>
              {t("GarbageSearch.Instructions")}
            </p>
            <div
              className={styles.row}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div>
                <div className={styles.inputLabel}>
                  {t("GarbageSearch.StreetNumber")}
                </div>
                <Text 
                  name={"streetNumber"} 
                  value={streetNumber} 
                  onChange={handleOnChange}
                />
              </div>
              <div style={{ flexGrow: 1, marginLeft: 10 }}>
                <div className={styles.inputLabel}>
                  {t("GarbageSearch.StreetName")}
                </div>
                <Text
                  name={"street"}
                  value={street}
                  onChange={handleOnChange} 
                />
              </div>
            </div>
            <div className={styles.row}>
              <Button onClick={handleOnClick}>
                Search
              </Button>
            </div>
          </Panel>
        </PanelRow>
        <PanelRow>
          <Panel>
            <h3>
              {t("GarbageSearch.Results")}
            </h3>
            <SearchResultsTable/>
          </Panel>
        </PanelRow>
      </>
  
      <Modal 
        open={isOpen} 
        onClose={handleOnModalClose} 
        size="small"
      >
        <Modal.Header>
          {t("GarbageSearch.ModalHeader")}
          <div style={{ float: "right" }}>
            <Icon
              icon="x"
              color="black"
              size="large"
              onClick={handleOnModalClose}
            />
          </div>
        </Modal.Header>
        <Modal.Content>
          <div className={styles.detailsWrapper}>
            <div>
              <ModalHolidayDelay/>
              <div className={styles.detailRow}>
                <strong>
                  {t("GarbageSearch.Address")} 
                </strong>
                {Parcel_Address__c}
              </div>
              <div className={styles.detailRow}>
                <strong>
                  {t("GarbageSearch.PickupDay")}
                </strong>
                {Refuse_Pickup_Day__c}
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="blue"
            onClick={handleOnModalClose}
          >
            {t("Util.OK")}
          </Button>
        </Modal.Actions>
      </Modal>
    </PageContainer>
  );
};
