import {createSlice, SliceCaseReducers} from '@reduxjs/toolkit';
import { OrganizationManagementAppState } from './organizationManagementAppState';
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';

export const slice = createSlice<OrganizationManagementAppState, SliceCaseReducers<OrganizationManagementAppState>>({
    name: 'organizationManagement',
    initialState: {
        loading: false,
        members: [],
        organizationManagementErrorMessage: '',
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setMembers: (state, action) => {
            state.members = action.payload;
        },
        setOrganizationManagementErrorMessage: (state, action) => {
            state.organizationManagementErrorMessage = action.payload;
        }
    }
});

export const useAppStateOrganizationManagementSelector = buildSubStateSelector(state => state.organizationManagement);
export const { setLoading, setMembers, setOrganizationManagementErrorMessage } = slice.actions;
export const organizationManagementReducer = slice.reducer;
