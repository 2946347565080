import React from 'react';
import styles from './AccountCreateProgressBar.module.scss';
import { useTranslation } from 'react-i18next';

export interface AccountCreateProgressBarProps {
    step: number;
}

export function AccountCreateProgressBar(props: AccountCreateProgressBarProps) {
    const { t } = useTranslation();
    return (
        <div className={styles.progressBarContainer}>
            <div className={styles.barContainer}>
                <ul className={ styles.progressBar }>
                    <li className={props.step === 1 ? styles.stop : ''}/>
                    <li className={props.step === 2 ? styles.stop : ''}/>
                    <li className={props.step === 3 ? styles.stop : ''}/>
                </ul>
            </div>
            <div className={styles.progressBarRow}>
                <div className={`${styles.progressBarItem} ${props.step === 1 ? styles.active : ''}`}>{t('Account.CreatePersonal')}<br />{t('Account.CreateInformation')}</div>
                <div className={`${styles.progressBarItem} ${props.step === 2 ? styles.active : ''}`}>{t('Account.CreateAccount')}<br />{t('Account.CreateInformation')}</div>
                <div className={`${styles.progressBarItem} ${props.step === 3 ? styles.active : ''}`}>{t('Account.CreateBusiness')}<br />{t('Account.CreateInformation')} ({t('Account.CreateOptional')})</div>
            </div>
        </div>
    )
}