import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './textEditor.module.scss';

interface TextEditorState {
  editorState: EditorState;
}

interface Props {
  text?: string;
  spanish?: boolean;
  // callback function that is called when the content of the editor changes
  onEditorChange: (editorContent: string) => void;
  // optional callback function that has access to the whitespace state
  onWhiteSpace?: (hasWhiteSpace: boolean) => void;
}

class RichTextEditor extends Component<Props, TextEditorState> {
  constructor(props: any) {
    super(props);
    // converts text/html into something this editor can read
    const html = this.props.text;
    const contentBlock = htmlToDraft(html ?? '');
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
      };
    }
  }

  onEditorStateChange = (editorState: EditorState) => {
    this.setState({
      editorState,
    });
    const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.props.onEditorChange(content);
    if (this.props.onWhiteSpace) {
      this.props.onWhiteSpace(editorState.getCurrentContent().getPlainText().trim() === '');
    }
  };

  getTextFromEditor = () => {
    const { editorState } = this.state;
    return editorState.getCurrentContent();
  }

  render() {
    const { editorState } = this.state;

    return (
      <div className={styles.editorContainer}>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          localization={{
            locale: this.props.spanish ? 'es' : 'en',
          }}
          editorStyle={{paddingRight: '20px', paddingLeft: '20px', height: '15vh'}}
        />
      </div>
    );
  }
}

export default RichTextEditor;
