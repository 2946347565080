export const config = {
    webUrl: process.env.REACT_APP_WEB_URL || 'https://localhost:3001',
    apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:3000',
    googleMapsKey: process.env.REACT_APP_GOOGLE_MAPS_KEY || "AIzaSyDNen7BKwq-VGwstVKQ7cRqnWQYNkCIEBA",
    laserfiche: {
        formUrl: process.env.REACT_APP_LASERFICHE_FORM_URL || 'https://lf-forms.cityofelgin.net/Forms',
    },
    firebase: {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyDVFUwq2xOWwBnS9kpv_wI0S_rRfSx3QSU",
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "elgin-test-app.firebaseapp.com",
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || "https://elgin-test-app.firebaseio.com",
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "elgin-test-app",
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "elgin-test-app.appspot.com",
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID || "335076254684",
        appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:335076254684:web:71069397e7819a6a12e519",
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "G-BW58HFEKTH",
    },
    authorizeNet: {
        clientKey: process.env.REACT_APP_AUTHORIZE_NET_CLIENT_KEY || '7JyZ9xhM5pEaFBz8wgjjWTbChF47A4FqdJ4mHr332kYpNY474c5AHp2VWVzU7JR3',
        apiLoginID: process.env.REACT_APP_AUTHORIZE_NET_API_LOGIN_ID || '6AVZ42zbwFB',
    },
    permitFormPdfLinks: {
        newResidential: process.env.REACT_APP_PERMIT_FORM_PDF_LINKS_NEW_RESIDENTIAL || 'https://www.cityofelgin.org/DocumentCenter/View/96/New-Construction-and-Remodeling-Quick-Guide',
        newCommercialConstruction: process.env.REACT_APP_PERMIT_FORM_PDF_LINKS_NEW_COMMERCIAL_CONSTRUCTION || 'https://www.cityofelgin.org/DocumentCenter/View/96/New-Construction-and-Remodeling-Quick-Guide',
        remodelResidential: process.env.REACT_APP_PERMIT_FORM_PDF_LINKS_REMODEL_RESIDENTIAL || 'https://www.cityofelgin.org/DocumentCenter/View/96/New-Construction-and-Remodeling-Quick-Guide',
    },
    acceptJsUrl: process.env.REACT_APP_ACCEPT_JS_URL || 'https://jstest.authorize.net/v1/Accept.js',
    paymentus: {
        tokenizationUrl: process.env.REACT_APP_PAYMENTUS_TOKEN_URL || 'https://[uat].paymentus.com/xotp/pm/[tla]?authToken=[SSO Token]'
    },
    displayOldAutoPayNotice: process.env.REACT_APP_DISPLAY_OLD_AUTOPAY_NOTICE ? process.env.REACT_APP_DISPLAY_OLD_AUTOPAY_NOTICE === 'true' : true,
    arcGIS: {
        layerUrls: {
            masterAddress: process.env.REACT_APP_ARC_GIS_LAYER_URL_MASTER_ADDRESS || 'https://utility.arcgis.com/usrsvcs/servers/ce54b33629a0482d9ff30e279348ef94/rest/services/Portal/ElginMasterAddressPlus_V5/FeatureServer/0',
            cookParcel: process.env.REACT_APP_ARC_GIS_LAYER_URL_COOK_PARCEL || 'https://utility.arcgis.com/usrsvcs/servers/3d6e6f6583c0430aa208c718863ce0ee/rest/services/Portal/ParcelsCook_V5/FeatureServer/0',
            kaneParcel: process.env.REACT_APP_ARC_GIS_LAYER_URL_KANE_PARCEL || 'https://utility.arcgis.com/usrsvcs/servers/d7d7e3df49e741e685f20df73df1850e/rest/services/Portal/ParcelsKane_V5/FeatureServer/0',
            zoning: process.env.REACT_APP_ARC_GIS_LAYER_URL_ZONING || 'https://utility.arcgis.com/usrsvcs/servers/cf268c78b4ba4d09814f679bb094cd25/rest/services/Portal/ElginZoning_V5/FeatureServer/0',
        }
    },
    core: {
        iframeOriginUrl: process.env.REACT_APP_CORE_IFRAME_ORIGIN_URL || 'https://il-elgin.egovstaging.com',
    }
};
