import React, { FunctionComponent } from 'react';
import styles from './Toggle.module.scss';
import { Checkbox } from 'semantic-ui-react'

export interface ToggleProps  { 
    onChange(event: any, data: any): void;
    value: boolean;
    text: string;
}

export const Toggle: FunctionComponent<ToggleProps> = ({onChange, value, text}) => {
    return (
        <div className={styles.toggleContainer}>
            <Checkbox className={`${styles.toggle}`} toggle onChange={onChange} checked={value} />
            <div style={{ fontSize: 16, fontFamily: 'Lato' }}>{text}</div>
        </div>
    );
}

Toggle.defaultProps = {

};