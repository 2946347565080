import React, { FunctionComponent } from 'react';
import styles from './Icon.module.scss';
import { Icon as SemanticIcon, SemanticICONS } from 'semantic-ui-react';
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';

export interface IconProps {
    icon: SemanticICONS;
    size?: IconSizeProp;
    loading?: boolean;
    disabled?: boolean;
    onClick?(): void;
    color?: 'blue' | 'green' | 'red' | 'black' | 'white' | 'orange';
}

export const Icon: FunctionComponent<IconProps> = ({icon, size, loading, disabled, onClick, color}) => {
    const clickable = onClick && !disabled ? true : false;
    color = color ? color : clickable ? 'blue' : 'black';
    return (
        <div className={styles.iconContainer}>
            <SemanticIcon
                name={icon}
                size={size}
                loading={loading}
                disabled={disabled}
                className={`${styles.icon} ${styles[color]} ${clickable ? styles.clickable : null}`}
                onClick={disabled ? null : onClick}
            />
        </div>
    )
}