import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BackArrow } from '../../../components/backArrow/BackArrow';
import { Dropdown, DropdownOptions } from '../../../components/dropdown/Dropdown';
import { PageContainer } from '../../../components/pageContainer/PageContainer';
import { Panel } from '../../../components/panel/Panel';
import { PanelRow } from '../../../components/panel/PanelRow';

export const BusinessApplicationScreener = () => {
    const { t } = useTranslation();

    const [wantToSelection, setWantToSelection] = useState("");
    const [newBusinessSelection, setNewBusinessSelection] = useState("");
    const [homeBusinessSelection, setHomeBusinessSelection] = useState("");

    const wantToOptions: DropdownOptions[] = [
        {
            key: 'OPEN',
            text: t('BusinessApplication.OpenABusiness'),
            value: 'OPEN'
        },
        {
            key: 'CHANGE',
            text: t('BusinessApplication.MaintainOrGrowBusiness'),
            value: 'CHANGE'
        }
    ];

    const yesNoOptions: DropdownOptions[] = [
        {
            key: 'YES',
            text: t('Util.Yes'),
            value: 'YES'
        },
        {
            key: 'NO',
            text: t('Util.No'),
            value: 'NO'
        },
    ];

    return (
        <PageContainer>
            <h2>{t('BusinessApplication.BusinessApplication')}</h2>
            <BackArrow url="/" text="Back to Dashboard" />
            <PanelRow>
                <Panel>
                    <p>{t('Activity.IWantTo')}</p>
                    <div>
                        <Dropdown
                            placeholder="..."
                            options={wantToOptions}
                            value={wantToSelection}
                            onChange={(e: any, data: any) => setWantToSelection(data.value)}
                        />
                    </div>
                    {wantToSelection === 'OPEN' ?
                        <>
                            <p>{t('Zoning.IsNewBusiness')}</p>
                            <div>
                                <Dropdown
                                    placeholder="..."
                                    options={yesNoOptions}
                                    value={newBusinessSelection}
                                    onChange={(e: any, data: any) => setNewBusinessSelection(data.value)}
                                />
                            </div>
                            <p>{t('Zoning.IsHomeBasedBusiness')}</p>
                            <div>
                                <Dropdown
                                    placeholder="..."
                                    options={yesNoOptions}
                                    value={homeBusinessSelection}
                                    onChange={(e: any, data: any) => setHomeBusinessSelection(data.value)}
                                />
                            </div>
                        </>
                    :
                        <>
                        </>
                    }
                </Panel>
            </PanelRow>
        </PageContainer>
    )
}