import { AnyAction, Dispatch } from 'redux';
import { axios } from '../../../services/axiosService';
import {
    setSubmitErrorMessage,
    setSubmitLoading
} from './zoningInquirySlice';
import { ZoningInquiryData } from './zoningInquiryAppState';
import { openMessageModal } from '../../../components/statusModal/messageModalActionCreators';

export const submitZoningInquiry = (activityId: string, data: ZoningInquiryData, history: any) => async (dispatch: Dispatch) => {
    dispatch(setSubmitErrorMessage(''));
    dispatch(setSubmitLoading(true));
    try {
        await axios.post(`/salesforce/zoningInquiry/${activityId}`, { ...data });
        history.push('/dashboard');
        dispatch(openMessageModal(null, 'success') as unknown as AnyAction);
    } catch (err) {
        dispatch(setSubmitErrorMessage('An error occurred while attempting to submit form'));
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
    } finally {
        dispatch(setSubmitLoading(false));
    }
}