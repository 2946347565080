import { startFailed } from "../components/activitiesWizard/activitiesWizardSlice";
import { Activity } from "../features/activities/list/activitiesListAppState";
import { setBackLocation } from "../features/customForms/zoningInquiry/zoningInquirySlice";
import { setInitialForm } from "../features/laserfiche/embed/laserficheEmbedSlice";

interface navigateToActivityResponseType {
    historyPushLocation?: string;
    dispatchFunction?: any;
}

export const customFormMap = {
    OTHER_PERMIT: 'E092D985-55CD-4EE6-A683-306B9334D537',
    BUSINESS_APPLICATION: '32902B79-CE87-463C-8C93-4096398D6EA5',
    ZONING_INFORMATION: 'ECBD5892-3F19-4311-A257-7DAE8024F50E',
    WATER_BILL: '6C8ACE75-8CAC-4924-AB70-9329897C5C12',
    PERMIT_SEARCH: '4021c613-e8f7-4947-a1cf-af41a38be725',
    GARBAGE_SEARCH: '437f6937-6798-4b5e-bfd5-f512739d91d1',
    BOARDS_AND_COMMISSIONS: 'd5db7960-99b4-4c43-b2ce-fd63f838789d',
    LICENSE_RENEWAL: '3C02E947-4752-4D55-9389-ED15E98DA007',
    FEEDBACK: '65993E24-E054-41D0-BEBB-1172B12BFD00',
    QUICK_PERMIT: '1eb68143-0206-4948-b251-5f3302cbdf60',
}

export const navigateToActivity: (selectedItem: Activity) => navigateToActivityResponseType = (selectedItem: Activity) =>  {
    let response: navigateToActivityResponseType = {};

    if (selectedItem.Type === 'LASERFICHE_FORM') {
        if (!selectedItem.LaserficheProcessId) {
            const errMsg = `Item "${selectedItem.Item}" is a Laserfiche Form with no configured LaserficheProcessId`;
            console.error(errMsg);
            response = {
                historyPushLocation: '',
                dispatchFunction: startFailed({ errorMessage: errMsg })
            };
            return response;
        }
        const formDetails = {
            formTitle: selectedItem.Item,
            bpid: selectedItem.LaserficheProcessId,
            params: {}
        };

        response = {
            historyPushLocation: '/laserfiche/form',
            dispatchFunction: setInitialForm(formDetails)
        };
        return response;
    }

    // CUSTOM ACTIVITIES START
    if (selectedItem.Id.toUpperCase() === customFormMap.OTHER_PERMIT.toUpperCase()) {
        response = {
            historyPushLocation: '/activities/other_permit'
        };
        return response;
    }
    if (selectedItem.Id.toUpperCase() === customFormMap.BUSINESS_APPLICATION.toUpperCase() ||
        selectedItem.Id.toUpperCase() === customFormMap.ZONING_INFORMATION.toUpperCase()
    ) {
        response = {
            historyPushLocation: `/activities/zoning_inquiry?activityId=${selectedItem.Id.toUpperCase()}`,
            dispatchFunction: setBackLocation('/dashboard'),
        };
        return response;
    }
    if (selectedItem.Id.toUpperCase() === customFormMap.WATER_BILL.toUpperCase()) {
        response = {
            historyPushLocation: '/dashboard?highlight=billing',
        };
        return response;
    }
    if (selectedItem.Id.toUpperCase() === customFormMap.PERMIT_SEARCH.toUpperCase()) {
        response = {
            historyPushLocation: '/permit-search',
        };
        return response;
    }
    if (selectedItem.Id.toUpperCase() === customFormMap.GARBAGE_SEARCH.toUpperCase()) {
        response = {
            historyPushLocation: '/garbage-search',
        };
        return response;
    }
    if (selectedItem.Id.toUpperCase() === customFormMap.BOARDS_AND_COMMISSIONS.toUpperCase()) {
        response = {
            historyPushLocation: '/boards-and-commissions',
        };
        return response;
    }
    if (selectedItem.Id.toUpperCase() === customFormMap.LICENSE_RENEWAL.toUpperCase()) {
        response = {
            historyPushLocation: '/businesslicense',
        };
        return response;
    }
    if (selectedItem.Id.toUpperCase() === customFormMap.QUICK_PERMIT.toUpperCase()) {
        // User needs to be logged in to access this activity
        response = {
            historyPushLocation: '/sso/saml',
        };
        return response;
    }
    // CUSTOM ACTIVITIES END

    if (selectedItem.Type === 'EXTERNAL_LINK' && selectedItem.ExternalLink && selectedItem.ExternalLink.length) {
        window.open(selectedItem.ExternalLink,'_blank');
        return {};
    }

    if (selectedItem.Action === 'REPORT') {
        response = {
            historyPushLocation: `/report?item=${selectedItem.Item}&id=${selectedItem.Id}`,
        };
        return response;
    }

    const errMsg = `Activity type "${selectedItem.Type}" not yet supported`;
    console.error(errMsg);
    response = {
        historyPushLocation: '',
        dispatchFunction: startFailed({ errorMessage: errMsg })
    };
    return response;
}