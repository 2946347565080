import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    setEmail,
    useAppStateForgotPasswordSelector
} from './forgotPasswordSlice'
import {
    resetRequest
} from './forgotPasswordActionCreators';
import styles from './ForgotPassword.module.scss';
import { Input, Message } from 'semantic-ui-react';
import { NavLink, useHistory } from 'react-router-dom';
import { Reasoning, ErrorValidationLabel } from '../../components/errorValidationLabel/ErrorValidationLabel';
import { Button } from '../../components/button/Button';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../components/spinner/Spinner';

export function ForgotPassword() {
    const { email, success, loading } = useAppStateForgotPasswordSelector(state => state);
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    let [invalidEmail, setInvalidEmail] = useState<Reasoning | null>(null);

    const navigateToLogin = () => {
        history.push('/login');
    }

    const handleForgotPassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let valid = validateForm();
        if (valid) {
            dispatch(resetRequest(email, navigateToLogin));
        }
    }

    let validateForm = () => {
        clearValidators();
        let validForm = true;

        if (email.length === 0) {
            setInvalidEmail('Required');
            validForm = false;
        }

        return validForm;
    }

    let clearValidators = () => {
        setInvalidEmail(null);
    }

    let message = null;
    switch (success) {
        case true:
            message = (
                <Message positive>
                    <Message.Header>{t('Login.CheckYourEmail')}</Message.Header>
                </Message>
            );
            break;
        case false:
            message = (
                <Message negative>
                    <Message.Header>{t('Login.SomethingWentWrong')}</Message.Header>
                </Message>
            );
            break;
    }

    return (
        <div className={styles.page}>
            <form onSubmit={handleForgotPassword}  className={styles.form}>
                {message}
                <div className={styles.row}>
                    <div className='headerContainer'>
                        <h2>{t('Login.ForgotPassword')}</h2>
                        <Spinner isLoading={loading}/>
                    </div>
                </div>
                <div className={styles.row}>
                    <NavLink exact to="/login">
                        {t('Login.ReturnToLogin')}
                    </NavLink>
                </div>
                <div className={styles.row}>
                    {t('Login.EmailAddress')}
                    <Input
                        fluid
                        value={email}
                        onChange={(e: any) => dispatch(setEmail({ value: e.target.value }))}
                    />
                    {invalidEmail ? <ErrorValidationLabel fieldDescription={'Email'} reasoning={invalidEmail} /> : null}
                </div>

                <div className={styles.row}>
                    <Button color='blue' type="submit">
                        {t('Login.ResetPassword')}
                    </Button>
                </div>
            </form>
        </div>
    );
}
