import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { SystemAlertsListAppState } from './systemAlertsAppState';
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';

export const slice = createSlice<SystemAlertsListAppState, SliceCaseReducers<SystemAlertsListAppState>>({
    name: 'systemAlertList',
    initialState: {
        alerts: [],
        userStoredAlerts: [],
        unreadAlertIndex: -1,
        focusedAlert: null,
        loading: false,
        addAlertModalOpen: false,
        editAlertModalOpen: [],
        deleteAlertModalOpen: [],
    },
    reducers: {
        setAlerts: (state, action) => {
            state.alerts = action.payload;
        },
        setUserStoredAlerts: (state, action) => {
            state.userStoredAlerts = action.payload;
        },
        setFocusedAlert: (state, action) => {
            state.focusedAlert = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setAddAlertModalOpen: (state, action) => {
            state.addAlertModalOpen = action.payload;
        },
        setEditAlertModalOpen: (state, action) => {
            state.editAlertModalOpen = action.payload;
        },
        setDeleteAlertModalOpen: (state, action) => {
            state.deleteAlertModalOpen = action.payload;
        },
        setUnreadAlertIndex: (state, action) => {
            state.unreadAlertIndex = action.payload;
        },
    }
});

export const useAppStateSystemAlertsListSelector = buildSubStateSelector<SystemAlertsListAppState>(state => state.systemAlertList);
export const { 
    setAlerts, 
    setFocusedAlert,
    setLoading,
    setAddAlertModalOpen,
    setEditAlertModalOpen,
    setDeleteAlertModalOpen,
    setUserStoredAlerts,
    setUnreadAlertIndex,
} = slice.actions;
export const systemAlertListReducer = slice.reducer;
