import { AnyAction, Dispatch } from 'redux';
import { axios } from '../../../services/axiosService';
import { AppState } from '../../../store/appState';
import { Translation, GroupedTranslations } from './languagesEditAppState';
import { setTranslations, setError, setLoading } from './languagesEditSlice';
import { openMessageModal } from '../../../components/statusModal/messageModalActionCreators';

const languageMap : { [key: string] : string } = {
    English: "en",
    Spanish: "es"
};

export const getTranslations = (language: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        dispatch(setLoading(true));
        const languageCode = languageMap[language];
        const getTranslationsResponse = (await axios.get(`/i18n/raw/${languageCode}/translation`)).data as GroupedTranslations;
        dispatch(setTranslations({ value: getTranslationsResponse }));
    } catch (err) {
        dispatch(setError({errorMessage: 'An error occurred while attempting to fetch translations'}));
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
}

export const saveTranslations = (groupedTranslations : GroupedTranslations) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        // Convert translations from grouped object to a plain array that the API expects
        // Attempting to send in current format requires the API to validate two arbritrary keys (Group, Id)
        const translationsArray: Translation[] = [];
        Object.values(groupedTranslations).forEach(translations => {
            Object.values(translations).forEach(translation => {
                translationsArray.push(translation);
            })
        })
        const saveTranslationsResponse = await axios.put(`/i18n`, translationsArray);
        console.log(saveTranslationsResponse);
        dispatch(openMessageModal(null, 'success') as unknown as AnyAction);
    } catch (err) {
        dispatch(setError({errorMessage: 'An error occurred while attempting to save translations'}))
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
}