import { AnyAction, Dispatch } from 'redux';
import { axios } from '../../../services/axiosService';
import { AppState } from '../../../store/appState';
import { v4 as uuid } from 'uuid';
import { setFileTooLarge, setSubmitting } from './reportSlice';
import { ReportSpecificSalesforceData } from './reportConfigs';
import { openMessageModal } from '../../../components/statusModal/messageModalActionCreators';
import { useHistory } from 'react-router-dom';

export const submitReport = (files: any[], reportSpecificSalesforceData: ReportSpecificSalesforceData, activityId: string, activityLanguageKey: string, history: ReturnType<typeof useHistory>) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        dispatch(setSubmitting(true));
        const state = getState();

        const reportState = state.report;
        const reportId = uuid();

        const description = reportState.description;

        let { extraDescription, ...restOfSpecificSalesforceData } = reportSpecificSalesforceData;
        if(reportState.rating && reportState.rating !== 0) extraDescription = "Rating: " + reportState.rating.toString() + "/5";

        const payload = {
            id: reportId,
            address: reportState.address,
            activityId,
            activityLanguageKey,
            description,
            extraDescription,
            ...restOfSpecificSalesforceData,
        };

        const formData = new FormData();

        formData.append("reportData", JSON.stringify(payload));

        let fileCounter = 1;
        let fileSize = 0;
        if (!reportState.fileTooLarge) {
            for (const file of files) {
                formData.append(`file-${fileCounter}-${file.name}`, file);
                fileCounter += 1;
                fileSize += file.size;
            }

            if (fileSize > 10485760) {
                dispatch(setFileTooLarge(true));
            }
        }

        const reportPostResponse = (await axios.post('/reports', formData)).data;
        console.log(reportPostResponse);
        // Ensure the user sees the success message w/ case number for their activity
        dispatch(openMessageModal('success-submit-case', 'success', {
            caseNumber: reportPostResponse.caseNumber
        }) as unknown as AnyAction);
        dispatch(setSubmitting(false));
        history.push('/dashboard');
    } catch (err) {
        console.error(err);
        if (err?.response?.status === 413) {
            console.error('File Upload too Large');
            dispatch(setFileTooLarge(true));
        } else {
            dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        }
        dispatch(setSubmitting(false));
    }
};
