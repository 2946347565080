import React, { useEffect } from 'react';
import { Dropdown, DropdownOptions } from '../../../../components/dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setSnowIceStreetType, useAppStateReportSelector, setCategoryCanSubmit } from '../reportSlice';

export const SnowIceRemoval = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const state = useAppStateReportSelector(state => state.snowIce);

    const streetTypeOptions: DropdownOptions[] = [
        {
            value: "Alley",
            key: "Alley",
            text: t('Report.Alley'),
        },
        {
            value: "Court",
            key: "Court",
            text: t('Report.Court'),
        },
        {
            value: "City street",
            key: "City street",
            text: t('Report.CityStreet'),
        },
        {
            value: "Cul de sac",
            key: "Cul de sac",
            text: t('Report.CulDeSac'),
        },
    ];

    useEffect(() => {
        if (state.streetType) {
            dispatch(setCategoryCanSubmit(true));
        }
        else {
            dispatch(setCategoryCanSubmit(false));
        }
    }, [dispatch, state.streetType]);

    return (
        <>
            {t('Report.WhatTypeOfStreet')}
            <Dropdown
                placeholder={t('Report.WhatTypeOfStreet')}
                options={streetTypeOptions}
                value={state.streetType}
                onChange={(e: any, data: any) => dispatch(setSnowIceStreetType(data.value))}
            />
        </>
    )
}