import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCategoryCanSubmit } from '../reportSlice';

export const LeafRakeoutProgram = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setCategoryCanSubmit(true));
    }, [dispatch]);

    return (
        <>
            <p>On-Street Program</p>
            <p>Designated areas of the city. See map.</p>
            <p>All other areas area of the city are rake and bag- Fall Leaf Bagging Program</p>
            <p>Requirements:</p>
            <ul>
                <li>Rake loose leaves out into the street along the curb by 6 a.m. the first day of scheduled pickup (For example, Monday pickup areas must have leaves raked to the street by 6 a.m. on Monday).</li>
                <li>Do not mix trash, branches or other yard waste with leaf piles.</li>
                <li>Avoid parking on the street on scheduled leaf-collection days, leaf piles blocked by cars will not be collected.</li>
                <li>For safety reasons, please do not allow children to play in leaf piles in the street.</li>
                <li>Leaves may also be placed in paper yard waste bags for weekly pickup days October 1 - November 30.</li>
                <li>Purple refuse stickers are not required on yard-waste bags during this time period.</li>
            </ul>
        </>
    );
};