import { Dispatch } from 'redux';
import {
    FeatureFlag,
    setFeatureFlags,
    setLoadingFeatureFlags
} from './featureFlagSlice';
import { axios } from '../../services/axiosService';

export const getFeatureFlags = (userId: undefined | null | string) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoadingFeatureFlags(true));
        const featureFlags = (await axios.get('/featureflags', { params: { id: userId } })).data as FeatureFlag[];
        dispatch(setLoadingFeatureFlags(false));
        dispatch(setFeatureFlags(featureFlags));
    } catch (err) {
        console.error(err);
    }
};

export const getFeatureFlag = (name: string) => (featureFlags: FeatureFlag[]) => {
    const result = featureFlags.find(obj => obj.name === name);
    return result;
};
