import {createSlice, SliceCaseReducers} from '@reduxjs/toolkit';
import {HeaderAppState} from './headerAppState';
import {buildSubStateSelector} from '../../store/utilities/useAppStateSelector';

export const slice = createSlice<HeaderAppState, SliceCaseReducers<HeaderAppState>>({
    name: 'header',
    initialState: {
        search: '',
        mobileSearchVisibility: false,
        mobileSideBarVisibility: false,
        languageOptions: [],
        selectedLanguage: '',
    },
    reducers: {
        setSearch: (state, action) => {
            state.search = action.payload;
        },
        setMobileSearchVisibility: (state, action) => {
            state.mobileSearchVisibility = action.payload.value;
            state.search = '';
        },
        setMobileSideBarVisibility: (state, action) => {
            state.mobileSideBarVisibility = action.payload.value;
        },
        setLanguageOptions: (state, action) => {
            state.languageOptions = action.payload;
        },
        setSelectedLanguage: (state, action) => {
            state.selectedLanguage = action.payload;
        }
    }
});

export const useAppStateHeaderSelector = buildSubStateSelector(state => state.header);
export const {
    setSearch,
    setMobileSearchVisibility,
    setMobileSideBarVisibility,
    setLanguageOptions,
    setSelectedLanguage,
} = slice.actions;
export const headerReducer = slice.reducer;
