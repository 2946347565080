import { axios } from '../../services/axiosService';
import { AnyAction, Dispatch } from 'redux';
import { AppState } from '../../store/appState';
import { setLoading, setMembers, setOrganizationManagementErrorMessage } from './organizationManagementSlice';
import { openMessageModal } from '../../components/statusModal/messageModalActionCreators';

export const loadMembers = () => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        dispatch(setLoading(true));
        const state = getState();
        const organizationId = state.auth.selectedOrganization;
        const getMembersResponse = (await axios.get('/organizations/members', { params: { organizationId }})).data as any[];
        dispatch(setMembers(getMembersResponse));
        dispatch(setOrganizationManagementErrorMessage(''));
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.log(err)
        dispatch(setOrganizationManagementErrorMessage('Organization.LoadOrganizationMembersError'));
    } finally {
        dispatch(setLoading(false));
    }
};

export const verifyOrganizationMember = (userId: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        dispatch(setLoading(true));
        const state = getState();
        const organizationId = state.auth.selectedOrganization;
        const verifyMemberResponse = (await axios.put('/organizations/members/verify', { userId, organizationId })).data as any;
        if (verifyMemberResponse.success) {
            dispatch(openMessageModal('success-remove', 'success') as unknown as AnyAction);
            dispatch(loadMembers() as any);
        }
        else {
            dispatch(openMessageModal('error-remove', 'error') as unknown as AnyAction);
            dispatch(setOrganizationManagementErrorMessage('Organization.CouldNotVerifyOrganizationMemberError'));
        }
    } catch(err) {
        dispatch(openMessageModal('error-add', 'error') as unknown as AnyAction);
        dispatch(setOrganizationManagementErrorMessage('Organization.CouldNotVerifyOrganizationMemberError'));
    } finally {
        dispatch(setLoading(false));
    }
}

export const removeOrganizationMember = (userId: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        dispatch(setLoading(true));
        const state = getState();
        const organizationId = state.auth.selectedOrganization;
        const removeMemberResponse = (await axios.delete('/organizations/members', { data: { userId, organizationId } })).data as any;
        if (removeMemberResponse.success) {
            dispatch(loadMembers() as any);
            dispatch(openMessageModal('success-remove', 'success') as unknown as AnyAction);
        }
        else {
            dispatch(openMessageModal('error-remove', 'error') as unknown as AnyAction);
            dispatch(setOrganizationManagementErrorMessage('Organization.CouldNotRemoveOrganizationMemberError'));
        }
    } catch(err) {
        dispatch(openMessageModal('error-remove', 'error') as unknown as AnyAction);
        dispatch(setOrganizationManagementErrorMessage('Organization.CouldNotRemoveOrganizationMemberError'));
    } finally {
        dispatch(setLoading(false));
    }
}