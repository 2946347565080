import { Dispatch } from 'redux';
import { User } from '../../../models/user';
import { authService } from '../../../services/authService';
import { axios } from '../../../services/axiosService';
import { setLoading, setIsVerified } from './verificationRequiredSlice';

export const checkVerification = () => async (dispatch: Dispatch) => {
    try {
        const dbUser = (await axios.get<User>('/auth')).data;
        const fbUser = authService.getFirebaseUser();

        if (dbUser.Verified) {
            dispatch(setIsVerified(true));
        } else if (fbUser?.emailVerified) {
            const verificationResponse = await axios.post('/verify')
                .catch(err => {
                    console.error(err);
                    return false;
                });
            dispatch(setIsVerified(verificationResponse));
        }

        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
    }
};