import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { buildSubStateSelector } from '../../../store/utilities/useAppStateSelector';

const initialState = {
    users: [] as any[],
    totalPages: 0,
    pageIndex: 0,
    pageSize: 25,
    sortedColumnName: 'LastName',
    sortDirection: 'asc',
    searchTerm: '',
    loading: false,
};

export type UserTableAppState = typeof initialState;

export const slice = createSlice<UserTableAppState, {
    setUsers : CaseReducer<UserTableAppState, PayloadAction<any[]>>,
    setTotalPages : CaseReducer<UserTableAppState, PayloadAction<number>>,
    setPageIndex : CaseReducer<UserTableAppState, PayloadAction<number>>,
    setPageSize : CaseReducer<UserTableAppState, PayloadAction<number>>,
    setSortedColumnName : CaseReducer<UserTableAppState, PayloadAction<string>>,
    setSortDirection : CaseReducer<UserTableAppState, PayloadAction<string>>,
    setSearchTerm : CaseReducer<UserTableAppState, PayloadAction<string>>,
    setLoading : CaseReducer<UserTableAppState, PayloadAction<boolean>>,
}>({
    name: 'userTable',
    initialState,
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        setTotalPages: (state, action) => {
            state.totalPages = action.payload;
        },
        setPageIndex: (state, action) => {
            state.pageIndex = action.payload;
        },
        setPageSize: (state, action) => {
            state.pageSize = action.payload;
        },
        setSortedColumnName: (state, action) => {
            state.sortedColumnName = action.payload;
        },
        setSortDirection: (state, action) => {
            state.sortDirection = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    }
});

export const useAppStateUserTableSelector = buildSubStateSelector<UserTableAppState>(state => state.userTable);
export const {
    setUsers,
    setTotalPages,
    setPageIndex,
    setPageSize,
    setSortedColumnName,
    setSortDirection,
    setSearchTerm,
    setLoading,
} = slice.actions;
export const userTableReducer = slice.reducer;
