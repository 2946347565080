import { AnyAction, Dispatch } from "redux";
import { axios } from "../../../services/axiosService";
import { Activity } from "../../activities/list/activitiesListAppState";
import { openMessageModal } from "../../../components/statusModal/messageModalActionCreators";
import { customFormMap } from "../../../services/activityService";

export const loadActivityDetails = () => async (dispatch: Dispatch) => {
    try {
        const activityDetails = (await axios.get(`/activities/${customFormMap.OTHER_PERMIT}`)).data as Activity;
        console.log(activityDetails);
    } catch (err) {
        console.log(err);
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
    }
}