import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from './SystemAlert.module.scss';
import { setUnreadAlertIndex, useAppStateSystemAlertsListSelector } from '../../features/systemAlerts/systemAlertsListSlice';
import { useAuthSelector } from '../../features/auth/authSlice';
import { storeUserAlertsArray } from '../../features/systemAlerts/systemAlertsActionCreators';
import { Modal } from 'semantic-ui-react';
import { Button } from '../button/Button';

export const SystemAlert: React.FunctionComponent = () =>  {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { loading, userStoredAlerts, unreadAlertIndex } = useAppStateSystemAlertsListSelector(state => state);
    const { authUser } = useAuthSelector(x => x);

    function dismissAlert() {
        // Mark this alert as read
        let arrCopy = [];
        for (let i = 0; i < userStoredAlerts.length; i++) {
            const alert = userStoredAlerts[i];
            if (i === unreadAlertIndex) {
                arrCopy.push({
                    ...alert,
                    Read: true
                });
            } else {
                arrCopy.push({
                    ...alert,
                });
            }
        }
        dispatch(storeUserAlertsArray(arrCopy));

        // Check if there is a next unread/active alert, if not set index to -1
        if (unreadAlertIndex+1 < userStoredAlerts.length && userStoredAlerts[unreadAlertIndex+1].Active) {
            dispatch(setUnreadAlertIndex(unreadAlertIndex+1));
        } else {
            dispatch(setUnreadAlertIndex(-1));
        }
    }

    return (
        <>
            {!loading && userStoredAlerts && unreadAlertIndex !== -1 &&
                <Modal open={true} onClose={() => dismissAlert()} size="mini" style={{
                        width: '50%',
                        margin: 'auto',
                        marginTop: '0px',
                        marginBottom: '0px',
                        maxWidth: '600px',
                        backgroundColor: 'white',
                        borderLeft: '10px solid #e8a317',
                    }}>
                        <div>
                            <div className={styles.alertInnerPanelContainer}>
                                <i className={`far fa-exclamation-triangle ${styles.alertHeaderIcon}`}></i>
                                {(authUser && authUser.Language === 'es') ?
                                    <h3 className={styles.alertHeaderText}>{userStoredAlerts[unreadAlertIndex].TitleAlertSpanish}</h3>
                                    :
                                    <h3 className={styles.alertHeaderText}>{userStoredAlerts[unreadAlertIndex].TitleAlertEnglish}</h3>
                                }
                                <div id={styles.spacer}></div>
                            </div>
                            <div className={styles.alertRightInnerPanelContainer}>
                                <div className={styles.alertBody}>
                                    <div style={{fontSize: '120%'}}>
                                        {(authUser && authUser.Language === 'es') ?
                                            <span dangerouslySetInnerHTML={{__html: userStoredAlerts[unreadAlertIndex] ? userStoredAlerts[unreadAlertIndex].TextSpanish: ''}} />
                                            : 
                                            <span dangerouslySetInnerHTML={{__html: userStoredAlerts[unreadAlertIndex] ? userStoredAlerts[unreadAlertIndex].TextEnglish: ''}} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={styles.alertInnerButtonContainer}>
                                <Button color={'yellow'} onClick={() => dismissAlert()} style={styles.dismissAlert} biggerFont={true}>{t('Alert.Dismiss')}</Button>
                            </div>
                        </div>
                </Modal>
            }
        </>
    );
}