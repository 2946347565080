import React from 'react';
import styles from './AccountCreate.module.scss';
import { AccountCreateAccountInformationAppState, AccountCreateAddressAppState, AccountCreatePersonalInformationAppState } from './accountCreateAppState';
import { Checkbox } from '../../../components/checkbox/Checkbox';
import { Text } from '../../../components/text/Text';
import { Icon } from '../../../components/icon/Icon';
import { useDispatch } from 'react-redux';

import {
    setHasUtilities,
    setHasSeveralProperties,
    setHasBusiness,
    setHasNoneOfTheAbove,
    setUtilityCustomerId,
    removeProperty,
    toggleUtilityHelpModal,
    toggleAddressModal,
    addAdditionalAddress,
    setInvalidAdditionalAddress1,
    setInvalidAdditionalCity,
    setInvalidAdditionalState,
    setInvalidAdditionalZip,
    setUtilityStreetNumber,
    useAppStateAccountCreateSelector,
} from './accountCreateSlice';
import { Button } from '../../../components/button/Button';
import { Modal } from 'semantic-ui-react';
import { AccountCreateAddress } from './AccountCreateAddress';
import { submitAccountInformation } from './accountCreateActionCreators';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppStateActivitiesWizardSelector } from '../../../components/activitiesWizard/activitiesWizardSlice';
import { getActivity } from '../../../components/activitiesWizard/activitiesWizardActionCreators';
import { useAppStateHeaderSelector } from '../../../components/header/headerSlice';
import { navigateToActivity } from '../../../services/activityService';

export interface AccountCreateAccountInformationProps {
    personalInformation: AccountCreatePersonalInformationAppState;
    accountInformation: AccountCreateAccountInformationAppState;
}

interface UtilityHelpModalProps {
    modalOpen: boolean;
    text: {
        header: string;
        line1: string;
        line2: string;
    }
    onClose(): void;
}

export const UtilityHelpModal = (props: UtilityHelpModalProps) => {
    return (
        <Modal open={props.modalOpen} onClose={props.onClose}>
            <Modal.Header>
                <div className={styles.modalHeader}>
                    <div>{props.text.header}</div>
                    <Icon
                        icon='x'
                        color='black'
                        size="large"
                        onClick={props.onClose}
                    />
                </div>
            </Modal.Header>
            <Modal.Content>
                <div className={styles.utilityHelpContainer}>
                    <div className={styles.utilityHelpText}>
                        {props.text.line1}
                        <br/>
                        <br/>
                        {props.text.line2}
                    </div>
                    <div className={styles.utilityHelpImageContainer}>
                        <div className={styles.utilityHelpImage}/>
                    </div>
                </div>
            </Modal.Content>
        </Modal>
    )
}

interface AddressModalProps {
    personalInformation: AccountCreatePersonalInformationAppState;
    propertyInput: AccountCreateAddressAppState;
    modalOpen: boolean;
    onClose(): void;
    add(): void;
}

const AddressModal = (props: AddressModalProps) => {
    const { t } = useTranslation();
    return (
        <Modal open={props.modalOpen} onClose={props.onClose}>
            <Modal.Header>{t('Account.CreateAddAnotherAddress')}</Modal.Header>
            <Modal.Content>
                <AccountCreateAddress additional address={props.propertyInput} personalInformation={props.personalInformation} />
                <Button onClick={props.add}>{t('Util.Add')}</Button>
            </Modal.Content>
        </Modal>
    )
}

export function AccountCreateAccountInformation(props: AccountCreateAccountInformationProps) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const {
        hasUtilities,
        hasSeveralProperties,
        hasBusiness,
        hasNoneOfTheAbove,
        utilityCustomerId,
        utilityStreetNumber,
        propertyInput,
        properties,
        utilityHelpModalOpen,
        addressModalOpen,
    } = props.accountInformation;
    const personalInformation = props.personalInformation;
    const requestIsLoading = useAppStateAccountCreateSelector(state => state.loading);
    const activity = useAppStateActivitiesWizardSelector(state => state.selectedItem);
    const deviceHasMobileDimensions = useAppStateHeaderSelector(state => state.mobileSideBarVisibility);
    const activityRedirectId = localStorage.getItem('activityRedirectId');

    if (activityRedirectId && (!activity || !activity.Id)) {
        dispatch(getActivity(activityRedirectId));
    }

    const navigateBusiness = () => {
        if (hasBusiness) {
            history.push('/account/create/business-information');
        } else if (activityRedirectId && activity && activity.Id) {
            const { historyPushLocation, dispatchFunction } = navigateToActivity(activity);

            localStorage.removeItem('activityRedirectId');

            if (dispatchFunction) dispatch(dispatchFunction);
            if (historyPushLocation) history.push(historyPushLocation);
        } else {
            if (deviceHasMobileDimensions) {
                // push to component to check & open mobile app if available
                history.push('/openMobileApp');
            } else {
                history.push('/dashboard');
            }
        }
    }

    const clearAddressValidators = () => {
        dispatch(setInvalidAdditionalAddress1(null));
        dispatch(setInvalidAdditionalCity(null));
        dispatch(setInvalidAdditionalState(null));
        dispatch(setInvalidAdditionalZip(null));
    }

    const validateAddressForm = () => {
        clearAddressValidators();

        let validForm = true;

        if (propertyInput.address1.length === 0) {
            dispatch(setInvalidAdditionalAddress1('Required'));
            validForm = false;
        }
        if (propertyInput.city.length === 0) {
            dispatch(setInvalidAdditionalCity('Required'));
            validForm = false;
        }
        if (propertyInput.state.length === 0) {
            dispatch(setInvalidAdditionalState('Required'));
            validForm = false;
        }
        if (propertyInput.zip.length !== 5) {
            dispatch(setInvalidAdditionalZip('Required'));
            validForm = false;
        }

        return validForm;
    }

    const isInvalidAccountInformation = Boolean(
        (hasUtilities && (!utilityCustomerId || !utilityStreetNumber)) ||
        (hasSeveralProperties && properties.length < 2) ||
        (!hasUtilities && !hasSeveralProperties && !hasBusiness && !hasNoneOfTheAbove)
    );

    const submit = () => {
        if (isInvalidAccountInformation) return;
        dispatch(submitAccountInformation(navigateBusiness));
    }

    return (
        <>
            <p className={styles.label}>{t('Util.CheckAtLeastOneBox')}</p>
            <div className={styles.formContainer}>
                <div className={styles.choiceContainer}>
                    <Checkbox
                        value={hasUtilities}
                        text={t('Account.CreateIHaveCityUtilities')}
                        onChange={(e: any, data: any) => dispatch(setHasUtilities(data.checked))}
                        isDisabled={hasNoneOfTheAbove}
                    />
                    {hasUtilities ?
                        <div className={styles.choiceInformation}>
                            {t('Account.CreateCustomerID')} ({t('Account.CreateCustomerIDNote')})
                            <span style={{ 'color': 'red' }}>*</span>{" "}
                            <span
                                role="button"
                                className={styles.linkText}
                                onClick={() => dispatch(toggleUtilityHelpModal(null))}
                            >
                                {t('Account.CreateWhatsThis')}
                            </span>
                            <UtilityHelpModal
                                modalOpen={utilityHelpModalOpen}
                                text={{
                                    header: t('Account.CreateFindYourCustomerId'),
                                    line1: t('Account.CreateToFindYourCustomerId'),
                                    line2: t('Account.CreatePleaseEnterAsItAppears'),
                                }}
                                onClose={() => dispatch(toggleUtilityHelpModal(null))}
                            />
                            <Text
                                value={utilityCustomerId}
                                onChange={(e: any) => dispatch(setUtilityCustomerId({ value: e.target.value }))}
                                placeholder={t('Account.CreateCustomerID')}
                            />
                            {t('Account.StreetNumberOfServiceAddress')}
                            <span style={{ 'color': 'red' }}>*</span>{" "}
                            <Text
                                value={utilityStreetNumber}
                                onChange={(e: any) => dispatch(setUtilityStreetNumber({ value: e.target.value }))}
                                placeholder={t('Account.ServiceAddressStreetNumber')}
                                style={{marginBottom: 0}}
                            />
                            <span>
                                {t('Account.NoteStreetNumberOnly')}
                            </span>
                        </div>
                        : null}
                </div>
                <div className={styles.choiceContainer}>
                    <Checkbox
                        value={hasSeveralProperties}
                        text={t('Account.CreateIOwnSeveralProperties')}
                        onChange={(e: any, data: any) => dispatch(setHasSeveralProperties(data.checked))}
                        isDisabled={hasNoneOfTheAbove}
                    />
                    {hasSeveralProperties ?
                        <div className={styles.choiceInformation}>
                            {properties.length < 2 &&
                                <p style={{'color': 'red'}}>{t('Account.CreateAtLeastOneAdditionalProperty')}</p>
                            }
                            <Button color='grey' onClick={() => dispatch(toggleAddressModal(null))}>
                                {t('Account.CreateAddProperty')}
                            </Button>
                            <AddressModal
                                personalInformation={personalInformation}
                                propertyInput={propertyInput}
                                modalOpen={addressModalOpen}
                                onClose={() => dispatch(toggleAddressModal(null))}
                                add={() => {
                                    const valid = validateAddressForm();
                                    if (valid) {
                                        dispatch(addAdditionalAddress(null));
                                        dispatch(toggleAddressModal(null));
                                    }
                                }}
                            />
                            <div className={styles.propertiesContainer}>
                                <div className={styles.propertyListingContainer}>
                                    {properties.map(p => (
                                        <div key={p.id} className={styles.propertyListing}>
                                            <div className={styles.address}>
                                                <div>{p.address1}</div>
                                                {p.address2 ? <div>{p.address2}</div> : null}
                                                <div>{p.city}, {p.state} {p.zip}</div>
                                            </div>
                                            {p.id !== 'primary' ?
                                                <>
                                                    <Icon
                                                        icon='x'
                                                        color='red'
                                                        size="large"
                                                        onClick={() => dispatch(removeProperty(p.id))}
                                                    />
                                                </>
                                                : null}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={styles.propertySpace}></div>
                        </div>
                        : null}
                </div>
                <div className={styles.choiceContainer}>
                    <Checkbox
                        value={hasBusiness}
                        text={t('Account.CreateIOwnOrOperateABusiness')}
                        onChange={(e: any, data: any) => dispatch(setHasBusiness(data.checked))}
                        isDisabled={hasNoneOfTheAbove}
                    />
                </div>
                <div className={styles.choiceContainer}>
                    <Checkbox
                        value={hasNoneOfTheAbove}
                        text={t('Util.NoneOfTheAbove')}
                        onChange={(e: any, data: any) => dispatch(setHasNoneOfTheAbove(data.checked))}
                        id="noneOfTheAbove"
                        isDisabled={hasUtilities || hasSeveralProperties || hasBusiness}
                    />
                </div>
                <div className={styles.buttonContainer}>
                    <Button
                        onClick={submit}
                        disabled={isInvalidAccountInformation || requestIsLoading}
                        color="blue"
                        data-testid="nextButton"
                    >
                        {t('Util.Next')}
                    </Button>
                </div>
            </div>
        </>
    )
}