import React from 'react';
import styles from './MobileSideBarMenu.module.scss';
import { NavLink } from 'react-router-dom';

interface LoginButtonProps {
    onClick(): void;
}

export const MobileSideBarMenuLoginButton: React.FunctionComponent<LoginButtonProps> = ({onClick}) => {
    return (
        <NavLink to='/login' className={styles.mobileSideBarMenuItemContainer} onClick={onClick}>
            <div className={styles.mobileSideBarMenuItemLink}>
                Login
            </div>
        </NavLink>
    );
}