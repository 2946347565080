import React, { useEffect, useState } from 'react';
import styles from './NotificationSettings.module.scss';
import { useTranslation } from 'react-i18next';
import { userService } from '../../services/userService';
import { Toggle } from '../toggle/Toggle';
import { useAuthSelector } from '../../features/auth/authSlice';
import { Modal } from 'semantic-ui-react';
import { Button } from '../button/Button';
import { Button as SemanticButton } from 'semantic-ui-react';

type NotificationSettingsProps = {
    userId: string;
    asModal: boolean;
    viewOnly: boolean;
};

export const NotificationSettings = (props: NotificationSettingsProps) => {
    const { t } = useTranslation();
    const [userNotificationSettings, setUserNotificationSettings] =
        useState<any[]>();
    const { authUser } = useAuthSelector((state) => state);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        (async () => {
            const userId = props.userId ? props.userId : authUser?.Id;

            const userNotificationSettings =
                await userService.getUserNotificationSettings(userId!);
            setUserNotificationSettings(userNotificationSettings);

            const hasUserOptedOut = userNotificationSettings.some(
                (setting: any) => setting.optedOut === true,
            );

            // if the user has opted out we don't want to show them the modal
            if (!hasUserOptedOut && props.asModal) {
                setModalOpen(true);
            }
        })();
    }, [authUser, props.userId, props.asModal]);

    const toggleUserNotification = async (index: number) => {
        if (props.viewOnly) {
            return;
        }

        const newArray = [...userNotificationSettings!];
        const value = newArray[index];
        value.enabled = !value.enabled;
        newArray[index] = value;
        setUserNotificationSettings(newArray);
        if (authUser) {
            await userService.setUserNotificationSetting(
                authUser?.Id,
                value.notificationId,
                value.enabled,
            );
        }
    };

    const setGlobalSettings = async (index: number, toValue: boolean) => {
        if (props.viewOnly) {
            return;
        }

        const newArray = [...userNotificationSettings!];
        const value = newArray[index];
        value.enabled = toValue;
        newArray[index] = value;
        setUserNotificationSettings(newArray);
        if (authUser) {
            await userService.setUserNotificationSetting(
                authUser?.Id,
                value.notificationId,
                value.enabled,
            );
        }
    };

    const NotificationToggles = (
        <>
            {userNotificationSettings &&
                userNotificationSettings.length > 0 &&
                userNotificationSettings.map((record, index) => {
                    return (
                        <div
                            className={styles.slightlyLargerMarginBottom}
                            key={index}
                        >
                            <Toggle
                                value={record.enabled === true}
                                onChange={(e: any, data: any) => {
                                    toggleUserNotification(index);
                                }}
                                text={t(
                                    `Notification.${record.notificationName}`,
                                )}
                            />
                        </div>
                    );
                })}
        </>
    );

    if (props.asModal) {
        return (
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                size="small"
            >
                <Modal.Header>
                    {t('Notification.NotificationSettingsModal')}
                </Modal.Header>
                <Modal.Content>
                    <p className={styles.billingNotificationSettingsNote}>
                        {t('Notification.TwilioDisclaimer')}
                    </p>
                </Modal.Content>
                <Modal.Content>
                    {NotificationToggles}
                    <SemanticButton
                        onClick={() => {
                            userNotificationSettings?.forEach(
                                (record, index) => {
                                    setGlobalSettings(index, true);
                                },
                            );
                        }}
                    >
                        Select All
                    </SemanticButton>
                </Modal.Content>
                <Modal.Content></Modal.Content>
                <Modal.Actions className={styles.actionsContainer}>
                    <Button
                        style={styles.saveBtn}
                        onClick={() => {
                            userNotificationSettings?.forEach(
                                (record, index) => {
                                    const value =
                                        userNotificationSettings![index];

                                    userService.setUserNotificationSetting(
                                        authUser?.Id!,
                                        value.notificationId,
                                        value.enabled,
                                    );
                                },
                            );

                            setModalOpen(false);
                        }}
                        color={'blue'}
                        fluid={false}
                    >
                        {t('Util.Save')}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    return NotificationToggles;
};
