import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { ElginTodayAppState } from './elginTodayAppState';
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';

export const slice = createSlice<ElginTodayAppState, SliceCaseReducers<ElginTodayAppState>>({
    name: 'elginToday',
    initialState: {
        offset: 0,
        elginTodayPosts: [],
        elginTodayLoading: true,
        elginTodayEndReached: false,
    },
    reducers: {
        setOffset: (state, action) => {
            state.offset = action.payload;
        },
        addElginTodayPosts: (state, action) => {
            state.elginTodayPosts.push(...action.payload);
        },
        setElginTodayLoading: (state, action) => {
            state.elginTodayLoading = action.payload;
        },
        setElginTodayEndReached: (state, action) => {
            state.elginTodayEndReached = action.payload;
        },
    }
});

export const useAppStateElginTodaySelector = buildSubStateSelector(state => state.elginToday);
export const { setOffset, addElginTodayPosts, setElginTodayLoading, setElginTodayEndReached } = slice.actions;
export const elginTodayReducer = slice.reducer;
