import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { PaymentAppState } from './paymentAppState';
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';

export const slice = createSlice<PaymentAppState, SliceCaseReducers<PaymentAppState>>({
    name: 'payment',
    initialState: {
        amount: 0, // this is just for demo purposes if no bills are available
        customerId: '',
        locationId: '',
        accountType: '',
        accountNumber: '',
        paymentCode: '',
        iframeURL: '',
        coreIframeHtml: '',
        // Below state tracks if the user is authenticated or not for payments,
        // so if they log out, they will not be able to make a payment on
        // their authenticated account
        previousAuthStateForPayments: false,
    },
    reducers: {
        setAmount: (state, action) => {
            state.amount = action.payload;
        },
        setCustomerId: (state, action) => {
            state.customerId = action.payload;
        },
        setLocationId: (state, action) => {
            state.locationId = action.payload;
        },
        setAccountType: (state, action) => {
            state.accountType = action.payload;
        },
        setAccountNumber: (state, {payload}) => {
            state.accountNumber = payload
        },
        setCustomerPaymentProfile: (state, action) => {
            state.customerProfile = action.payload;
        },
        setDefaultPaymentProfile: (state, action) => {
            state.defaultPaymentProfileId = action.payload;
        },
        setIframeURL: (state, action) => {
            state.iframeURL = action.payload;
        },
        setCoreIframeHtml: (state, action) => {
            state.coreIframeHtml = action.payload;
        },
        setPreviousAuthStateForPayments: (state, action) => {
            state.previousAuthStateForPayments = action.payload;
        }
    }
});

export const useAppStatePaymentSelector = buildSubStateSelector(state => state.payment);
export const {
    setAmount,
    setCustomerId,
    setLocationId,
    setAccountType,
    setAccountNumber,
    setPaymentCode,
    setCustomerPaymentProfile,
    setDefaultPaymentProfile,
    setIframeURL,
    setCoreIframeHtml,
    setPreviousAuthStateForPayments,
} = slice.actions;
export const paymentReducer = slice.reducer;
