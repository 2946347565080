import { navilineService } from '../../services/navilineService';
import { AnyAction, Dispatch } from 'redux';
import { AppState } from '../../store/appState';
import { axios } from '../../services/axiosService';
import { setActivitiesRequiringAction, setBillingHistory, setDelinquentStatuses, setFlattenedPaymentHistory, setLoadingPaymentInformation, setPaymentHistory, setPaymentHistoryV2, setShowAddBillingHelp, setTotalAmountDue, setTotalAmountForFlattenedHistory } from './dashboardSlice';
import { personalOrganizationKey } from '../../constants';
import { openMessageModal } from '../../components/statusModal/messageModalActionCreators';
import moment from 'moment';

export const loadPaymentHistory = (waterBillRedesignFFEnabled: boolean = false, flattenPaymentHistory: boolean = false) => async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setLoadingPaymentInformation(true));
    try {
        const state = getState()
        const organizationId = state.auth.selectedOrganization !== personalOrganizationKey ? state.auth.selectedOrganization : null;
        let paymentHistory;
        if (waterBillRedesignFFEnabled) {
            paymentHistory = await navilineService.getPaymentHistoryV2(organizationId);
            dispatch(setPaymentHistoryV2(paymentHistory));
        } else {
            paymentHistory = await navilineService.getPaymentHistory(organizationId);
            if (flattenPaymentHistory && paymentHistory) {
                let totalAmount = 0;
                const combinedArray = paymentHistory
                    .flatMap(accountHistory => {
                        const billAmount = accountHistory.upcomingBills.find(bill => bill.type === accountHistory.accountInfo.type);
                        const amountSign = billAmount!.amount < 0 ? '-' : '';
                        const unsignedAmount = Math.abs(billAmount!.amount).toFixed(2);
                        const formattedAmount = `${amountSign}$${unsignedAmount}`;
                        if (billAmount?.amount && billAmount.amount > 0) {
                            totalAmount += billAmount.amount;
                        }
                        return accountHistory.pastPayments.map(pastPayment => {
                            return {
                                paymentDate: moment(pastPayment.paymentDate.valueOf()).format('M/DD/YYYY'),
                                paymentAmount: `$${pastPayment.amount.toFixed(2)}`,
                                formattedAmount,
                                type: accountHistory.accountInfo.type,
                            };
                        })
                });

                const twelveMonthsAgo = moment().subtract(12, 'months');
                const sortedArray = combinedArray.sort((a, b) =>
                    moment(b.paymentDate, 'M/DD/YYYY').toDate().getTime() -
                    moment(a.paymentDate, 'M/DD/YYYY').toDate().getTime()
                ).filter(payment =>
                    moment(payment.paymentDate, 'M/DD/YYYY').isSameOrAfter(twelveMonthsAgo)
                );
                dispatch(setTotalAmountForFlattenedHistory(totalAmount));
                dispatch(setFlattenedPaymentHistory(sortedArray));
            }
            dispatch(setPaymentHistory(paymentHistory));
        }
    } catch (err) {
        dispatch(setPaymentHistory(undefined)); // null indicates loading, undefined indicates error
        if (err.isAxiosError && err.response.status === 404) {
            dispatch(setShowAddBillingHelp(true));
        } else {
            dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
            console.error(err);
        }
    }
    dispatch(setLoadingPaymentInformation(false));
}

export const loadBillingHistory = (showLoading: boolean = false) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        if (showLoading) {
            dispatch(setLoadingPaymentInformation(true));
        }
        const billingHistoryResponse = await navilineService.getBillingHistory();
        if (billingHistoryResponse) {
            dispatch(setBillingHistory(billingHistoryResponse.historyMonthly));
            dispatch(setTotalAmountDue(billingHistoryResponse.totalAmountDue));
            dispatch(setShowAddBillingHelp(billingHistoryResponse.historyMonthly.length === 0));
            dispatch(setDelinquentStatuses(billingHistoryResponse.accountDelinquentStatuses));
        } else {
            dispatch(setBillingHistory([]));
            dispatch(setTotalAmountDue(0));
            dispatch(setShowAddBillingHelp(true));
        }
    } catch (err) {
        dispatch(setShowAddBillingHelp(true));
        console.log(err);
    }
    if (showLoading) {
        dispatch(setLoadingPaymentInformation(false));
    }
}

export const loadDelinquentInformation = () => async (dispatch: Dispatch) => {
    try {
        const delinquentStatuses = await navilineService.getDelinquencyInformation();
        if (delinquentStatuses) {
            dispatch(setDelinquentStatuses(delinquentStatuses));
        } else {
            dispatch(setDelinquentStatuses([]));
        }
    } catch (err) {
        dispatch(setDelinquentStatuses([]));
        console.log(err);
    }
}

export const loadActivitiesPendingAction = () => async (dispatch: Dispatch) => {
    try {
        const pendingActivitiesResponse = (await axios.get('/activitiesPendingAction')).data;
        dispatch(setActivitiesRequiringAction(pendingActivitiesResponse.length));
    } catch (err) {
        console.error(err);
    }
}
