import React, { useCallback, useEffect } from 'react';
import styles from './ActivitiesViewItem.module.scss';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { PageContainer } from '../../../../components/pageContainer/PageContainer';
import { Panel } from '../../../../components/panel/Panel';
import { PanelRow } from '../../../../components/panel/PanelRow';
import { Spinner } from '../../../../components/spinner/Spinner';
import { cancelActivity, loadActivity, submitAdditionalInformation } from './activitiesViewItemActionCreators';
import { activitiesViewItemActions, SFFormDetailDisplayItem, useAppStateActivitiesViewItemSelector } from './activitiesViewItemSlice';
import { Button } from '../../../../components/button/Button';
import { ActivitiesViewProgressBar } from './ActivitiesViewProgressBar';
import { ActivityMessages } from '../../../messages/ActivityMessages';
import { BoxIntegration } from '../../../../components/boxIntegration/BoxIntegration';
import { Textarea } from '../../../../components/textArea/Textarea';
import { useTranslation } from 'react-i18next';
import { setAmount } from '../../../payment/paymentSlice';
import { setPaymentCode } from '../../create/activitiesCreateSlice';
import { ActivityTable } from '../lists/ActivitiesViewInProgress';
import { InProgressFormatted } from '../activitiesViewSlice';
import { Icon } from '../../../../components/icon/Icon';
import { useFeatureFlag } from '../../../featureFlags/FeatureFlag';

export const ActivitiesViewItem = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const lpiFF = useFeatureFlag('EnableLPI');

    const [, , activitySObjectType, sfId] = location.pathname.split('/');

    const { loading, activity, additionalInformationText } = useAppStateActivitiesViewItemSelector(s => s);

    const addBoxActivity = (action: "create" | "delete" | "upload", res: any) => {
        console.log(action, res);
        if (action === 'create') {
            dispatch(activitiesViewItemActions.pushBoxActivityLog(`User created folder ${res.name}`));
        } else if (action === 'delete') {
            dispatch(activitiesViewItemActions.pushBoxActivityLog(`User deleted item(s) ${res.map((f: any) => f.name).join(', ')}`));
        } else if (action === 'upload') {
            dispatch(activitiesViewItemActions.pushBoxActivityLog(`User uploaded item(s) ${res.map((f: any) => f.name).join(', ')}`));
        }
    }

    useEffect(() => {
        dispatch(loadActivity(activitySObjectType, sfId));
    }, [dispatch, activitySObjectType, sfId]);

    const navigateToPayment = () => {
        dispatch(setAmount(activity.paymentAmount));
        dispatch(setPaymentCode(activity.paymentCode));
        history.push(`/payment`);
    }

    const navigateToLPIPayment = () => {
        history.push(`/paymentLPI?activityId=${activity.userActivityId}`);
    }

    const handleHelp = () => {
        window.location.href = 'mailto:311@cityofelgin.org';
    };

    const activityCancelCallback = useCallback(() => {
        history.push('/in_progress');
    }, [history])

    const handleCancel = useCallback(() => {
        dispatch(cancelActivity(activitySObjectType, sfId, activityCancelCallback))
    }, [dispatch, activitySObjectType, sfId, activityCancelCallback]);

    return (
        <PageContainer>
            {loading || (!lpiFF?.enabled && activity.activityType === 'Building Permit') ?
                <Spinner isLoading={loading} fullPage />
            :
                <>
                    <div className={`${styles.header} ${(activity.activityType === 'Building Permit') ? '' : styles.marginBottom}`}>
                        <h2>
                            {activity.activityType} {activity.name && activity.name.length > 0 ? ` (${activity.name})`: ""}
                        </h2>
                        <div className={styles.headerButtons}>
                            <Button color="orange" onClick={handleHelp} >{t('Activity.Help')}</Button>
                            <Button color="red" onClick={handleCancel} disabled={!activity.isCancelable}>{t('Activity.CancelRequest')}</Button>
                        </div>
                    </div>
                    <div className={styles.header}>
                        <h2>
                            {(activity.activityType === 'Building Permit') && (
                                <div className={`${styles.statusTag} ${styles.marginBottom}`}>
                                    {t('InProgress.Status') + ': ' + activity.status}
                                </div>
                            )}
                        </h2>
                    </div>
                    {(activity.activityType !== 'Building Permit') && (
                        <ActivitiesViewProgressBar
                            statusOptions={activity.statuses}
                            currentStatus={activity.status}
                            useUpdatedStatusPath={['Case'].includes( activitySObjectType )}
                        />
                    )}
                    {activity.paymentRequired && (
                        <PanelRow>
                            <Panel>
                                <div className={styles.informationNeeded}>
                                    <div className={styles.informationNeededHeader}>
                                        <Icon
                                            icon="exclamation triangle"
                                            color="orange"
                                            size="large"
                                        />
                                        <h3>{t('Activity.PaymentRequired')}</h3>
                                    </div>
                                    {
                                        activity.activityType !== 'Building Permit' ?
                                        <p>{t('Activity.PaymentRequiredMessage', {amount: activity.paymentAmountDisplay})}</p>
                                        :
                                        <div className={styles.informationNeededDiv}>
                                            <p className={styles.informationNeededParagraph}>{t('Payment.PaymentRequiredMessageLPIPt1', {amount: `$${activity.paymentAmount}`})}</p>
                                            <p className={styles.informationNeededParagraph}>{t('Payment.PaymentRequiredMessageLPIPt2')}</p>
                                        </div>
                                    }
                                    
                                    <Button
                                        color="blue"
                                        onClick={activity.activityType !== 'Building Permit' ? navigateToPayment : navigateToLPIPayment}
                                        disabled={additionalInformationText.length < 0}
                                    >
                                        {t('Activity.ContinueToPayment')}
                                    </Button>
                                </div>
                            </Panel>
                        </PanelRow>
                    )}
                    {activity.actionRequired && (activity.activityType === 'Building Permit' ? activity.actionRequiredMessage !== '' : true) && (
                        <PanelRow>
                            <Panel>
                                <div className={styles.informationNeeded}>
                                    <h3>Information Needed</h3>
                                    <p>{activity.actionRequiredMessage}</p>
                                    <Textarea value={additionalInformationText} onChange={(e: any, data: any) => dispatch(activitiesViewItemActions.setAdditionalInformationText(data.value))} />
                                    <BoxIntegration
                                        sfId={sfId}
                                        onCreate={folder => addBoxActivity('create', folder)}
                                        onDelete={fileArray => addBoxActivity('delete', fileArray)}
                                        onUpload={fileArray => addBoxActivity('upload', fileArray)}
                                    />
                                    <Button
                                        color="blue"
                                        onClick={() => dispatch(submitAdditionalInformation(activitySObjectType, sfId))}
                                        disabled={additionalInformationText.length < 0}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Panel>
                        </PanelRow>
                    )}
                    {activity?.formDetails?.map((dt, i) =>
                        <PanelRow key={i}>
                            <Panel>
                                <h3>{dt.label}</h3>
                                {dt.label === "Related Permits" ?
                                    <div>
                                        {/* <pre>{JSON.stringify(dt.data, null, 2)}</pre> */}
                                        <ActivityTable
                                            items={(dt.data as unknown as InProgressFormatted[])}
                                        />
                                    </div>
                                :
                                    <div className={styles.detailContainer}>
                                        {(dt.data as SFFormDetailDisplayItem[]).map((info, j) =>
                                            <div className={styles.detailItemContainer} key={j}>
                                                <p className={styles.detailItemLabel}>{info.label}</p>
                                                <p className={styles.detailItemInfo}>{info.data}</p>
                                            </div>
                                        )}
                                    </div>
                                }
                            </Panel>
                        </PanelRow>
                    )}
                    <PanelRow>
                        <Panel>
                            <h3>Files</h3>
                            <BoxIntegration sfId={sfId} readonly />
                        </Panel>
                    </PanelRow>
                    <PanelRow>
                        <Panel>
                            <ActivityMessages sfId={sfId} />
                        </Panel>
                    </PanelRow>
                </>
            }
        </PageContainer>
    )
};
