import React from 'react';
import { Loader, Dimmer } from 'semantic-ui-react';
import styles from './Spinner.module.scss';

export interface SpinnerProps {
    fullPage?: boolean;
    isLoading: boolean;
}

const Spinner: React.FunctionComponent<SpinnerProps> = ({ fullPage = false, isLoading }) => {
    if (fullPage) return (
        <Dimmer active={isLoading}>
            <Loader active={isLoading} />
        </Dimmer>
    )
    return <Loader className={styles.spinner} active={isLoading} inline />
}

export { Spinner };