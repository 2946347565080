import React from 'react';
import { Panel } from '../../../../components/panel/Panel';
import { PanelRow } from '../../../../components/panel/PanelRow';
import { useTranslation } from 'react-i18next';

export const AbandonedVehicle = () => {
    const { t } = useTranslation();
    return (
        <PanelRow>
            <Panel>
                <p>{t('Report.AbandonedVehicleDescription')}</p>
            </Panel>
        </PanelRow>
    );
}