import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import { userService } from '../../../services/userService';
import './UsersListV2.module.scss';
import { PanelRow } from '../../../components/panel/PanelRow';
import { Panel } from '../../../components/panel/Panel';
import { Spinner } from '../../../components/spinner/Spinner';
import styles from './UsersListV2.module.scss';
import { useTranslation } from 'react-i18next';
import { UserSearch } from './UserSearch';
import { openMessageModal } from '../../../components/statusModal/messageModalActionCreators';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setLoading, setPageIndex, setPageSize, setSearchTerm, setSortDirection, setSortedColumnName, setTotalPages, setUsers, useAppStateUserTableSelector } from './UserSearchV2Slice';
import { MessageModal } from '../../../components/statusModal/MessageModal';

export const UsersListV2 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
      users,
      totalPages,
      pageIndex,
      pageSize,
      sortedColumnName,
      sortDirection,
      searchTerm,
      loading,
  } = useAppStateUserTableSelector(state => state);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState('');
  const [isLoadingPageForFirstTime, setIsLoadingPageForFirstTime] = useState(true);

  const handleDeleteUser = async (Id: string) => {
    try {
      dispatch(setLoading(true));
      await userService.deleteUser(Id);
      // Deleted user not immediately recognized, need to wait before completing deletion
      setTimeout(() => {
        dispatch(openMessageModal('success-remove', 'success'));
        fetchData(pageIndex, pageSize, sortedColumnName, sortDirection, searchTerm);
      }, 500);
    }
    catch (err) {
      dispatch(openMessageModal('error-remove', 'error'));
    }
    finally {
        setDeleteUserModalOpen(false);
        dispatch(setLoading(false));
    }
  }

  const handleEditUser = (Id: string) => {
    history.push(`/users/edit/${Id}/users`);
  }

  const handleViewUser = (Id: string) => {
      history.push(`/users/view/${Id}/users`);
  }

  const columns: Array<{
    id: string;
    Header: any;
    accessor: string | ((d: any) => any);
    minWidth?: number;
    maxWidth?: number;
    width?: number;
    sortable?: boolean;
    resizable?: boolean;
    Cell?: any;
  }> = [
    {
      id: 'Email', // unique id for the table
      Header: () => {
        return (
          <span>
            <span>{t('Account.Email')}</span>
            <span>
              {sortedColumnName === 'Email' && sortDirection === 'asc' &&
                <i className={`far fa-long-arrow-up ${styles.sortIcon}`}></i>
              }
              {sortedColumnName === 'Email' && sortDirection === 'desc' &&
                <i className={`far fa-long-arrow-down ${styles.sortIcon}`}></i>
              }
            </span>
          </span>
        ); // custom header component
      },
      accessor: 'Email', // which key to grab data
      width: 250,
    },
    {
      id: 'FirstName',
      Header: () => {
        return (
          <span>
            <span>{t('Account.FirstName')}</span>
            <span>
              {sortedColumnName === 'FirstName' && sortDirection === 'asc' &&
                <i className={`far fa-long-arrow-up ${styles.sortIcon}`}></i>
              }
              {sortedColumnName === 'FirstName' && sortDirection === 'desc' &&
                <i className={`far fa-long-arrow-down ${styles.sortIcon}`}></i>
              }
            </span>
          </span>
        );
      },
      accessor: 'FirstName',
      width: 200,
    },
    {
      id: 'LastName',
      Header: () => {
        return (
          <span>
            <span>{t('Account.LastName')}</span>
            <span>
              {sortedColumnName === 'LastName' && sortDirection === 'asc' &&
                <i className={`far fa-long-arrow-up ${styles.sortIcon}`}></i>
              }
              {sortedColumnName === 'LastName' && sortDirection === 'desc' &&
                <i className={`far fa-long-arrow-down ${styles.sortIcon}`}></i>
              }
            </span>
          </span>
        );
      },
      accessor: 'LastName',
    },
    {
      id: 'PhoneNumber',
      Header: () => {
        return (
          <span>
            <span>{t('Account.PhoneNumber')}</span>
            <span>
              {sortedColumnName === 'PhoneNumber' && sortDirection === 'asc' &&
                <i className={`far fa-long-arrow-up ${styles.sortIcon}`}></i>
              }
              {sortedColumnName === 'PhoneNumber' && sortDirection === 'desc' &&
                <i className={`far fa-long-arrow-down ${styles.sortIcon}`}></i>
              }
            </span>
          </span>
        );
      },
      accessor: 'PhoneNumber',
      width: 150,
    },
    {
      id: 'SalesforceId',
      Header: () => {
        return (
          <span>
            <span>{t('Account.SalesforceID')}</span>
            <span>
              {sortedColumnName === 'SalesforceId' && sortDirection === 'asc' &&
                <i className={`far fa-long-arrow-up ${styles.sortIcon}`}></i>
              }
              {sortedColumnName === 'SalesforceId' && sortDirection === 'desc' &&
                <i className={`far fa-long-arrow-down ${styles.sortIcon}`}></i>
              }
            </span>
          </span>
        );
      },
      accessor: 'SalesforceId',
      width: 200,
    },
    {
      id: 'Actions',
      Header: '', // display name of the column header
      accessor: 'Actions',
      Cell: (props: any) => {
        return (
          <span className='user-table-cell-actions'>
            <i className={`far fa-solid fa-pencil`} style={{ cursor: 'pointer' }} onClick={() => handleEditUser(props.original.Id)}></i>
            <i className={`far fa-solid fa-trash`} style={{ color: 'red', marginLeft: 10, cursor: 'pointer' }} onClick={() => {
              setDeleteUserId(props.original.Id);
              dispatch(openMessageModal('warning-delete-user-account', 'warning'));
              setDeleteUserModalOpen(true);
            }}></i>
          </span>
        );
      }, // custom table cell component
      width: 80,
      sortable: false,
      resizable: false,
    },
  ];

  const fetchData = async (
    pageIndex: number, 
    pageSize: number, 
    sortedColumnName: string, 
    sortDirection: string, 
    searchTerm: string
  ) => {
    try {
      const paginationData = (await userService.getElasticUsers(
        pageIndex,
        pageSize,
        sortedColumnName,
        sortDirection,
        searchTerm ? searchTerm.trim() : '',
      ));
      dispatch(setUsers(paginationData.data));
      dispatch(setTotalPages(paginationData.totalPages));
    } catch (error) {
      console.error('Error fetching users:', error);
      dispatch(openMessageModal(null, 'error'));
    } finally {
      dispatch(setLoading(false));
    }
  }

  // Called to populate the user table when the page loads
  useEffect(() => {
    if (isLoadingPageForFirstTime) {
      setIsLoadingPageForFirstTime(false);
      fetchData(pageIndex, pageSize, sortedColumnName, sortDirection, searchTerm);
    } else {
      // Use debounce for search component
      const delayInputTimeoutId = setTimeout(() => {
        dispatch(setPageIndex(0));
        // if loading the page for the first time, save the pageIndex
        // if not, set it back to the pageIndex
        fetchData(0, pageSize, sortedColumnName, sortDirection, searchTerm);
      }, 500);
      return () => clearTimeout(delayInputTimeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <PanelRow style={{ padding: 0, marginRight: 0, marginLeft: 0 }}>
      <MessageModal modalOpen={deleteUserModalOpen} onConfirmationButtonClick={() => {handleDeleteUser(deleteUserId)}} onCloseButtonClick={() => {setDeleteUserModalOpen(false)}} />
      <Panel style={{ fontFamily: 'Lato', fontSize: 14 }}>
        <h1 className={styles.heading}>{t('SideBar.User', { count: 2 })}</h1>
        <div>
          <UserSearch
              value={searchTerm}
              onChange={(e: any) => {
                dispatch(setSearchTerm(e.target.value));
              }}
              placeholder={t('User.SearchForUsers')}
          />
        </div>
        <Spinner isLoading={loading} />
        <ReactTable
          manual // indicates that we do the paging/sorting on the api-side
          data={users}
          pages={totalPages}
          loading={loading}
          columns={columns}
          page={pageIndex}
          resizable={false}
          pageSize={pageSize}
          pageSizeOptions={[25, 50, 75, 100]}
          getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
            if (rowInfo) {
              return {
                style: {
                  paddingTop: 20,
                  paddingBottom: 20,
                  cursor: 'pointer',
                },
                // Overwrite click functionality of table rows
                onClick: (e: any, handleOriginal: any) => {
                  if (column.id !== 'Actions') {
                    handleViewUser(rowInfo.original.Id);
                  }
                }
              }
            }
            return {
              style: {
                paddingTop: 20,
                paddingBottom: 20,
              }
            }
          }}
          getTrGroupProps={(state: any, rowInfo: any, column: any) => {
            if (rowInfo && rowInfo.index !== pageSize-1) {
              return {
                style: {
                  borderBottom: 'solid 1px #AAAAAA',
                }
              };
            }
            return {
              style: {
                
              }
            };
          }}
          getTrProps={(state: any, rowInfo: any, column: any) => {
            return {
              style: {
                color: '#1a3567',
                fontWeight: 'bold',
                textAlign: 'left',
              }
            }
          }}
          getTheadThProps={(state: any, rowInfo: any, column: any) => {
            return {
              style: {
                backgroundColor: '#F8F8F8',
                color: '#3b3b3b',
                fontWeight: 'bold',
                borderColor: 'transparent',
                textAlign: 'left',
                paddingBottom: 10,
                paddingTop: 10,
              },
            };
          }}
          getTableProps={() => {
            return {
              style: {
                border: 'none'
              }
            }
          }}
          getPaginationProps={(obj: any) => {
            return {
              style: {
                background: 'white',
              }
            }
          }}
          onPageChange={(pageIndex) => {
            dispatch(setPageIndex(pageIndex));
            fetchData(pageIndex, pageSize, sortedColumnName, sortDirection, searchTerm);
          }}
          onPageSizeChange={(pageSize, pageIndex) => {
            dispatch(setPageSize(pageSize));
            dispatch(setPageIndex(pageIndex));
            fetchData(pageIndex, pageSize, sortedColumnName, sortDirection, searchTerm);
          }}
          onSortedChange={(newSorted, column, shiftKey) => {
            dispatch(setSortedColumnName(newSorted[0].id));
            dispatch(setSortDirection(newSorted[0].desc ? 'desc' : 'asc'));
            dispatch(setPageIndex(0));
            fetchData(0, pageSize, newSorted[0].id, newSorted[0].desc ? 'desc' : 'asc', searchTerm);
          }}
          className='-highlight' // when hovering, highlight user
        ></ReactTable>
      </Panel>
    </PanelRow>
  );
};
