import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { AddOrganizationAppState } from './addOrganizationAppState';
import { buildSubStateSelector } from '../../../store/utilities/useAppStateSelector';

export const slice = createSlice<AddOrganizationAppState, SliceCaseReducers<AddOrganizationAppState>>({
    name: 'organizationManagement',
    initialState: {
        loading: false,
        error: '',
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    }
});

export const useAppStateAddOrganizationSelector = buildSubStateSelector(state => state.addOrganization);
export const {
    setLoading,
    setError,
} = slice.actions;
export const addOrganizationReducer = slice.reducer;
