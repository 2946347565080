import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import 'semantic-ui-css/semantic.min.css'
import './i18n';
import './styles/index.scss';

import { store } from './store/store';
import { AppContainer } from './app/AppContainer';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import firebase from 'firebase/app';
import { AuthInitializer } from './features/auth/AuthInitializer';
import { ScrollToTop } from './components/scrollToTop/ScrollToTop';
import { BoxMobileWebView } from './features/mobileWebViews/BoxMobileWebView';

const rrfConfig = { };

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch
}

ReactDOM.render(
    <>
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <Router>
                    <Switch>
                        <Route exact path="/mobileWebView/box">
                            <Suspense fallback="loading">
                                <BoxMobileWebView />
                            </Suspense>
                        </Route>
                        <Route>
                            <ScrollToTop />
                            <AuthInitializer>
                                <AppContainer />
                            </AuthInitializer>
                        </Route>
                    </Switch>
                </Router>
            </ReactReduxFirebaseProvider>
        </Provider>
    </>,
    document.getElementById('root'));