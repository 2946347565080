import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IntlProvider } from 'react-intl';
import { Loader } from 'semantic-ui-react';
import { axios } from '../../services/axiosService';
const ContentExplorer: any = require('box-ui-elements').ContentExplorer;
// https://github.com/box/box-ui-elements/blob/master/src/elements/content-explorer/README.md
// Several available options like canDelete, canCreateNewFolder, etc
// Also several callbacks in case we want to do something after the user submits a document
// caseId goes in as a request param, it gets used as a folder name and the folder id is returned
// so that the content explorer can use that as the root folder

// const hardcodedCaseId = 'salesforce-id-here';

export interface BoxIntegrationProps {
    sfId?: string;
    utilityAccount?: string;
    initBoxToken?: string;
    initFolderId?: string;
    readonly?: boolean;
    onCreate?: (folder: any) => void;
    onDelete?: (fileArray: any) => void;
    onUpload?: (fileArray: any) => void;
}
export const BoxIntegration = ({ sfId, utilityAccount, initBoxToken, initFolderId, readonly = false, onCreate, onDelete, onUpload }: BoxIntegrationProps) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [boxToken, setBoxToken] = useState('');
    const [folderId, setFolderId] = useState('0');

    useEffect(() => {
        setLoading(true);
        setFolderId('0');
        setBoxToken('');
        if (initBoxToken && initFolderId) {
            setFolderId(initFolderId);
            setBoxToken(initBoxToken);
            setLoading(false);
        } else if (sfId) {
            axios.get(`/box/${sfId}`).then(response => {
                setFolderId(response.data.folderId);
                setBoxToken(response.data.accessToken);
                setLoading(false);
            })
        } else if (utilityAccount) {
            axios.get(`/box/util/${utilityAccount}`).then(response => {
                setFolderId(response.data.folderId);
                setBoxToken(response.data.accessToken);
                setLoading(false);
            })
        }
    }, [sfId, initBoxToken, initFolderId, utilityAccount]);

    if (loading) {
        return (
            <Loader active />
        );
    }

    if (!boxToken) {
        return (
            <div>
                <h3>{t('Util.NoFolderFound')}</h3>
            </div>
        );
    }

    return (
        <div>
            {folderId ?
                <IntlProvider locale="en">
                    <ContentExplorer
                        language='en-US'
                        logoUrl='box'
                        token={boxToken}
                        canDelete={!readonly}
                        canRename={!readonly}
                        canUpload={!readonly}
                        canCreateNewFolder={!readonly}
                        canShare={false}
                        onCreate={onCreate}
                        onDelete={onDelete}
                        onUpload={onUpload}
                        contentPreviewProps={{
                            contentSidebarProps: {
                                hasActivityFeed: true,
                                hasSkills: true,
                                hasMetadata: true,
                                detailsSidebarProps: {
                                    hasProperties: true,
                                    hasNotices: true,
                                    hasAccessStats: true,
                                    hasVersions: true,
                                }
                            }
                        }}
                        rootFolderId={folderId}
                    />
                </IntlProvider>
            :
            <div>
                <p>{t('Box.NoFolderForActivity')}</p>
            </div>}
        </div>
    )
}
