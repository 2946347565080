import React, { useEffect, useState } from 'react';
import { navilinePaymentTypes } from '../../constants';
import { axios } from '../../services/axiosService';

export interface PaymentusTokenizationFormProps {
    afterToken: (nonce?:string | object) => void;
    fullToken: boolean;
    transactionType: 'SINGLE' | 'WALLET';
    accountNumber: string | null;
    paymentType?: navilinePaymentTypes;
    amount?: string;
    activityId?: string;
};

export const PaymentusTokenizationForm = (props: PaymentusTokenizationFormProps) => {
    const { afterToken, fullToken, transactionType, amount, accountNumber, paymentType, activityId } = props;
    const [iframeURL, setIframeURL] = useState<string>();
    const [frameHeight, setFrameHeight] = useState(570);

    useEffect(() => {
        var listener = (event: any) => {
            if (event.origin === "https://secure1.paymentus.com") {
                var cpmessage = event.data.split(':');
                var cpvar = cpmessage[0];
                if (cpvar === "height") {
                    setFrameHeight(Math.round(cpmessage[1]) + 145);
                } else if (cpvar === "formError") {
                }
            } else if (event.data && event.data.source && event.data.source === 'process-token') {
                // handle the token passed from paymentus
                const cardInfo = event.data.message;
                afterToken(fullToken ? cardInfo : cardInfo.Token);
            } else if (event.data && event.data.source && event.data.source === 'process-payment') {
                // navigate to the dashboard
                afterToken();
            }
        }

        // Setup the listener.
        // eslint-disable-next-line no-restricted-globals
        addEventListener("message", listener, false);

        // Specify how to clean up after this effect:
        return function cleanup() {
            // eslint-disable-next-line no-restricted-globals
            removeEventListener('message', listener);
        };
    }, [afterToken, fullToken]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        (async () => {
            try {
                const result = (await axios.get('/paymentus-token', {params: {transactionType, amount, accountNumber, appCode: paymentType, activityId: activityId }})).data.iframeURL as string;
                setIframeURL(result);
            } catch(err) {
                console.error(err);
            }
        })();
    }, [accountNumber, amount, paymentType, transactionType, activityId]);

    return (
        <>
            {iframeURL &&
                <iframe style={{border: 'none', width: '100%'}} src={iframeURL} title="Paymentus Form" height={frameHeight}></iframe>
            }

        </>
    );
};
