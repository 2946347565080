import React from 'react';
import { useLocation } from 'react-router-dom';
import { BoxIntegration } from '../../components/boxIntegration/BoxIntegration';

export const BoxMobileWebView = () => {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const folderId = queryParams.get('folderId');
    const boxToken = queryParams.get('boxToken');
    const sfId = queryParams.get('sfId');
    const utilityAccount = queryParams.get('utilityAccount');
    const readonly = queryParams.get('readonly');

    if (folderId && folderId.length && boxToken && boxToken.length) {
        if (sfId && sfId.length) {
            // return sfid box integration
            return (
                <div>
                    <BoxIntegration
                        sfId={sfId}
                        initBoxToken={boxToken}
                        initFolderId={folderId}
                        readonly={readonly === 'true' ? true : false}
                    />
                </div>
            )
        } else if (utilityAccount && utilityAccount.length) {
            // return utility account integration
            return (
                <div>
                    <BoxIntegration
                        utilityAccount={utilityAccount}
                        initBoxToken={boxToken}
                        initFolderId={folderId}
                        readonly={readonly === 'true' ? true : false}
                    />
                </div>
            )
        }
    }

    return null;
}