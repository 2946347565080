import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './ZoningInquiry.module.scss';
import { BackArrow } from '../../../components/backArrow/BackArrow';
import { PanelRow } from '../../../components/panel/PanelRow';
import { Panel } from '../../../components/panel/Panel';
import {
    useAppStateZoningInquirySelector,
    setProposedUsage,
    toggleIsHomeBasedBusiness,
    toggleIsNewBusiness,
    toggleAllThatApply,
    setSubmitErrorMessage,
    setAllThatApplyErrorMessage,
    setProposedUsageErrorMessage,
    setSalesforceLandParcel,
    setActivityId,
} from './zoningInquirySlice';
import { submitZoningInquiry } from './zoningInquiryActionCreators';
import { Button } from '../../../components/button/Button';
import { Textarea } from '../../../components/textArea/Textarea';
import { Checkbox } from '../../../components/checkbox/Checkbox';
import { PageContainer } from '../../../components/pageContainer/PageContainer';
import { ArcGISMapView } from '../../../components/arcGISMapView/ArcGISMapView';
import { useAuthSelector } from '../../auth/authSlice';

const SelectAddress = () => {
    const [currentZoomLevel, reportCurrentZoomLevel] = useState<number | null>(null);
    const { t } = useTranslation();
    return (
        <PanelRow>
            <Panel>
                <div className={styles.inner_panel_container}>
                    <h3>{t('Zoning.EnterAddress')}</h3>
                    <p>{t('Zoning.EnterAddressDescription')}</p>
                    <small className={styles.zoomInWarning}>{currentZoomLevel && currentZoomLevel < 17 ? t('Zoning.ZoomInNotice') : " "}</small>
                    <ArcGISMapView
                        width="100%"
                        height="450px"
                        setSalesforceLandParcel={setSalesforceLandParcel}
                        reportCurrentZoomLevel={reportCurrentZoomLevel}
                    />
                </div>
            </Panel>
        </PanelRow>
    )
}

const ProposedUsage = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { 
        salesforceLandParcel,
        proposedUsage,
        proposedUsageErrorMessage,
        allThatApply,
        allThatApplyErrorMessage,
        isHomeBasedBusiness,
        isNewBusiness,
        submitErrorMessage,
        submitLoading,
        activityId,
    } = useAppStateZoningInquirySelector(state => state);
    const { authUser, selectedOrganizationInfo } = useAuthSelector(state => state);
    const onSubmit = async () => {
        let canSubmit = true;
        const isAnyAllThatApplyOptionChecked = allThatApply.find(option => option.checked === true);
        if (!isAnyAllThatApplyOptionChecked) {
            canSubmit = false;
            dispatch(setAllThatApplyErrorMessage(t('Zoning.AllThatApplyErrorMessage')));
        }
        if (!proposedUsage) {
            canSubmit = false;
            dispatch(setProposedUsageErrorMessage(t('Zoning.ProposedUsageErrorMessage')));
        }
        if (!canSubmit) {
            dispatch(setSubmitErrorMessage(t('Util.PleaseReviewFormForErrors')));
        } else {
            const data = {
                Land_Parcel_Master_Address__c: salesforceLandParcel!.salesforceId,
                Business_Details__c: proposedUsage,
                Mark_all_that_apply_to_you_inquiry__c: allThatApply.filter(option => option.checked).map(option => option.salesforceValue).join(';'),
                Is_this_a_home_based_business__c: isHomeBasedBusiness ? 'Yes' : 'No',
                Is_this_a_new_business__c: isNewBusiness ? 'Yes' : 'No',
                SFDC_ContactID__c: selectedOrganizationInfo && selectedOrganizationInfo.SalesforceId ? selectedOrganizationInfo.SalesforceId : authUser?.SalesforceId,
            }
            dispatch(submitZoningInquiry(activityId, data, history));
        }
    }
    return (
        <PanelRow>
            <Panel>
                <div className={styles.inner_panel_container}>
                    <h3>{t('Zoning.ProposedUsage')} for {salesforceLandParcel!.address}</h3>
                    <div className={styles.checkbox}>
                        <Checkbox
                            value={isNewBusiness}
                            text={t(`Zoning.IsNewBusiness`)}
                            onChange={(e: any, data: any) => dispatch(toggleIsNewBusiness(null))}
                        />
                    </div>
                    <div className={styles.checkbox}>
                        <Checkbox
                            value={isHomeBasedBusiness}
                            text={t(`Zoning.IsHomeBasedBusiness`)}
                            onChange={(e: any, data: any) => dispatch(toggleIsHomeBasedBusiness(null))}
                        />
                    </div>
                    <p className={styles.label}>{t('Activity.IWantTo')}:</p>
                    {allThatApplyErrorMessage && <p className={styles.errorMessage}>{allThatApplyErrorMessage}</p>}
                    { allThatApply.map(option => (
                        <div className={styles.checkbox} key={option.key}>
                            <Checkbox
                                value={option.checked}
                                text={t(`Zoning.${option.key}`)}
                                onChange={(e: any, data: any) => dispatch(toggleAllThatApply(option.key))}
                            />
                        </div>
                    ))}
                    <br />
                    <p>{t('Zoning.ProposedUsageDescription')}</p>
                    <div className={styles.label}>{t('Zoning.ProposedUsage')}</div>
                    {proposedUsageErrorMessage && <p className={styles.errorMessage}>{proposedUsageErrorMessage}</p>}
                    <Textarea value={proposedUsage} onChange={(e: any) => dispatch(setProposedUsage(e.target.value))} />
                    <br />
                    {submitErrorMessage && <p className={`centerText ${styles.errorMessage}`}>{submitErrorMessage}</p>}
                    <Button
                        disabled={submitLoading}
                        onClick={onSubmit}
                        loading={submitLoading}
                    >
                        {t('Util.Submit')}
                    </Button>
                </div>
            </Panel>
        </PanelRow>
    )
}

export const ZoningInquiry = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const { salesforceLandParcel, backLocation } = useAppStateZoningInquirySelector(state => state);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        dispatch(setActivityId(urlParams.get("activityId")));
    }, [dispatch, location])


    let backText = t('Util.Back');
    if (backLocation === '/activities/other_permit') {
        backText = t('Util.BackToPermitSelection');
    }

    return (
        <PageContainer>
            <h2>{t('Zoning.FindZoningInquiry')}</h2>
            <BackArrow url={backLocation} text={backText} />
            <SelectAddress />
            {!!salesforceLandParcel && <ProposedUsage />}
        </PageContainer>
    )
}