import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Arrow } from '../arrow/Arrow';
import styles from './BackArrow.module.scss';

interface BackArrowProps {
    url?: string,
    text?: string
}

export const BackArrow = ({ url, text } : BackArrowProps) => {
    const history = useHistory();
    const { t } = useTranslation();
    if (url) {
        return (
            <Link to={url} className={styles.link}>
                <div className={styles.container}>
                    <div className={styles.arrow_container}>
                        <Arrow size='small' direction='left' color='blue' />
                    </div>
                    <div className={styles.back_text}>{text}</div>
                </div>
            </Link>
        )
    } else {
        return (
            <div onClick={() => history.goBack()} className={styles.link}>
                <div className={styles.container}>
                    <div className={styles.arrow_container}>
                        <Arrow size='small' direction='left' color='blue' />
                    </div>
                    <div className={styles.back_text}>{ text ? text : t('Util.Back')}</div>
                </div>
            </div>
        )
    }
};