import React, { FunctionComponent } from 'react';
import styles from './Checkbox.module.scss';
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react';

export interface CheckboxProps {
    onChange(event: any, data: any): void;
    value: boolean;
    text: string;
    isDisabled?: boolean;
    id?: string;
}

export const Checkbox: FunctionComponent<CheckboxProps> = ({onChange, value, text, isDisabled=false, ...rest}) => {
    return (
        <div className={styles.checkboxContainer}>
            <label>
                <SemanticCheckbox className={styles.checkbox} onChange={onChange} checked={value} disabled={isDisabled} {...rest} />
            {text}</label>
        </div>
    )
}
