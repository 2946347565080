import React, { useCallback, useEffect, useState } from 'react';
import { coreService } from '../../services/coreService';
import styles from './CoreAddStoredPaymentForm.module.scss';
import { useAuthSelector } from '../../features/auth/authSlice';
import { PanelRow } from '../panel/PanelRow';
import { Panel } from '../panel/Panel';
import { Spinner } from '../spinner/Spinner';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openMessageModal } from '../statusModal/messageModalActionCreators';
import { config } from '../../config';

export interface CoreAddStoredPaymentFormProps {
    height?: number;
}

enum StoredPaymentEventCallType {
    OVERLAY_OFF = 'OVERLAY-OFF',
    OVERLAY_ON = 'OVERLAY-ON',
    PAYMENT_ADD_SUCCESS = 'PAYMENTMETHOD-ADD-SUCCESS',
}

export const CoreAddStoredPaymentForm = (
    props: CoreAddStoredPaymentFormProps,
) => {
    const dispatch = useDispatch();
    const [iframeContent, setIframeContent] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const frameHeight = props.height;
    const { authUser } = useAuthSelector((state) => state);
    const history = useHistory();

    const updateIFrame = useCallback((iframe: HTMLIFrameElement) => {
        if (iframe) {
            // find height of the inner iframe element and set that to the iframe height
            const iframeHeight =
                iframe.contentDocument?.getElementById(
                    'eGov_content',
                )?.offsetHeight;

            if (iframeHeight) {
                iframe.style.height = iframeHeight + 60 + 'px';
            }

            // If there is an iframe error modal, scroll to the top to show it
            const saveChangesButton = iframe.contentDocument?.getElementById(
                'eGov_functionSave',
            );
            if (saveChangesButton) {
                saveChangesButton.addEventListener('click', scrollToTopOfIFrame);
            }
        }
    }, []);

    const scrollToTopOfIFrame = () => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 250);
    };

    // Listen for iframe postbacks when adding payment method
    useEffect(() => {
        const listener = (event: any) => {
            console.log(event);
            scrollToTopOfIFrame();
            if (
                event.origin !== config.webUrl
            ) {
                const iframe = document.getElementById('addCardIframe');
                if (iframe) {
                    iframe.setAttribute('scrolling', 'yes');
                }
            }

            if (
                event?.data?.calltype &&
                event.data.calltype === StoredPaymentEventCallType.OVERLAY_ON
            ) {
                setLoading(true);
            }

            if (
                event?.data?.calltype &&
                event.data.calltype === StoredPaymentEventCallType.OVERLAY_OFF
            ) {
                setLoading(false);
            }

            // request was successfully submitted
            if (
                event?.data?.calltype &&
                event.data.calltype ===
                    StoredPaymentEventCallType.PAYMENT_ADD_SUCCESS
            ) {
                history.push(`/account/${authUser?.Id}`);
                dispatch(openMessageModal('success-add', 'success'));
            }
        };
        window.addEventListener('message', listener, false);
        return () => {
            window.removeEventListener('message', listener);
        };
    }, [authUser, history, dispatch]);

    useEffect(() => {
        (async () => {
            try {
                if (authUser && !iframeContent) {
                    setLoading(true);
                    const result = await coreService.getAddStoredPaymentHTML();
                    setIframeContent(result);
                    const iframe = document.getElementById(
                        'addCardIframe',
                    ) as HTMLIFrameElement;
                    if (iframe) {
                        iframe.srcdoc = result;

                        iframe.onload = () => {
                            updateIFrame(iframe);
                        };
                    }
                }
                setLoading(false);
            } catch (err) {
                setLoading(false);
                console.error(err);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {props.height ? (
                <>
                    <Spinner isLoading={loading} fullPage />
                    {iframeContent && (
                        <iframe
                            className={styles.mobileStyle}
                            title="Add Stored Payment Form"
                            height={frameHeight}
                            id="addCardIframe"
                        ></iframe>
                    )}
                </>
            ) : (
                <PanelRow style={{ width: '100%' }}>
                    <Panel size={1} style={{ minHeight: 80 }}>
                        <Spinner isLoading={loading} fullPage />
                        {iframeContent && (
                            <iframe
                                className={styles.webStyle}
                                title="Add Stored Payment Form"
                                id="addCardIframe"
                                scrolling="no"
                            ></iframe>
                        )}
                    </Panel>
                </PanelRow>
            )}
        </>
    );
};
