import React from 'react';
import Moment from 'moment';
import { NavilinePaymentHistory } from '../../models/navilinePaymentHistory';
import styles from './billPayDetails.module.scss';

export const BillPayDetails = ({ paymentHistory }: { paymentHistory: NavilinePaymentHistory[] | null | undefined }) => {
    if (!paymentHistory) return null;
    if (!paymentHistory.length) return <div className={styles.billPayDetails}>No upcoming bills found</div>;
    if (!paymentHistory.some(ph => ph.upcomingBills.length)) return <div className={styles.billPayDetails}>No upcoming bills found</div>;
    return (
        <div>
            {paymentHistory.map(accountHistory => (
                <ul key={`${accountHistory.accountInfo.customerId}-${accountHistory.accountInfo.locationId}`} className={styles.leaders}>
                    {accountHistory.upcomingBills.map(({ amount, type, dueDate }) => {
                        const amountSign = amount < 0 ? '-' : '';
                        const unsignedAmount = Math.abs(amount).toFixed(2);
                        const formattedAmount = `${amountSign}$${unsignedAmount}`;
                        return (
                            <li className={styles.billPayDetails} key={type}>
                                <span>{type}: {formattedAmount}</span>
                                <span>{Moment(dueDate).format('MM/DD/YYYY')}</span>
                            </li>
                        )
                    })}
                </ul>
            ))}
        </div>
    )
}