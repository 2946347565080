import { sortBy } from 'lodash';
import { AnyAction, Dispatch } from 'redux';
import { axios } from '../../services/axiosService';
import { AppState } from '../../store/appState';
import { salesforcePublicFieldsListActions as sfFieldsActions } from './salesforcePublicFieldsSlice';
import { openMessageModal } from '../../components/statusModal/messageModalActionCreators';

export const getSObjectsWithPublicFields = () => async (dispatch: Dispatch) => {
    try {
        dispatch(sfFieldsActions.setLoading(true));
        const response = (await axios.get('/salesforcePublicField/sObjects')).data;
        const sObjectsWithFields = response.map((resObject: any) => ({ Id: resObject.SalesforceObject, Label: resObject.SalesforceObject, Data: resObject }))
        dispatch(sfFieldsActions.setData(sObjectsWithFields));
    } catch (err) {
        console.error(err);
    } finally {
        dispatch(sfFieldsActions.setLoading(false));
    }
}

export const getSObjectFieldOptions = (sObjectType: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(sfFieldsActions.setFieldsLoading(true));
        const activityFields = (await axios.get(`/salesforce/activity/${sObjectType}/fields`)).data;
        const sortedFields = sortBy(activityFields, 'value');
        dispatch(sfFieldsActions.setSalesforceObjectFieldsOptions(sortedFields));
    } catch (err) {
        console.error(err);
    } finally {
        dispatch(sfFieldsActions.setFieldsLoading(false));
    }
}

export const getSObjectPublicFields = () => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        dispatch(sfFieldsActions.setLoading(true));
        const state = getState().salesforcePublicFields;
        const response = (await axios.get(`/salesforcePublicField/${state.selectedSObject}`)).data;
        console.log(response);
        const fieldOptions = response.map((f: any) => f.SalesforceFieldName);
        dispatch(sfFieldsActions.setSalesforceObjectFields(fieldOptions));
    } catch (err) {
        console.error(err);
    } finally {
        dispatch(sfFieldsActions.setLoading(false));
        getSObjectsWithPublicFields()(dispatch);
    }
}

export const saveSObjectPublicFields = () => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        dispatch(sfFieldsActions.setLoading(true));
        const state = getState().salesforcePublicFields;
        const response = (await axios.post(`/salesforcePublicField/${state.selectedSObject}`, {fields: state.salesforceObjectFields})).data;
        dispatch(openMessageModal(null, 'success') as unknown as AnyAction);
        console.log(response);
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(sfFieldsActions.setLoading(false));
        getSObjectsWithPublicFields()(dispatch);
    }
}