import React, {Dispatch, SetStateAction, useCallback, useState} from 'react';
import {PageContainer} from "../../components/pageContainer/PageContainer";
import {PanelRow} from "../../components/panel/PanelRow";
import {Panel} from "../../components/panel/Panel";
import {Text} from "../../components/text/Text";
import {useAuthSelector} from "../auth/authSlice";
import {useTranslation} from "react-i18next";
import {Dropdown} from "../../components/dropdown/Dropdown";
import styles from './PermitSearch.module.scss';
import {NavilinePermitSearchResponse} from "../../models/navilinePermitSearchResponse";
import {Button} from "../../components/button/Button";
import {permitService} from "../../services/permitService";
import {Modal} from "semantic-ui-react";
import {Icon} from "../../components/icon/Icon";
import {NavilinePermitInformation} from "../../models/navilinePermitInformation";
import moment from "moment";

interface PermitSearchProps {
    onResults: Dispatch<SetStateAction<NavilinePermitSearchResponse | undefined>>;
}

interface ApplicationNumberSearchProps {
    onResults: Dispatch<SetStateAction<NavilinePermitInformation | undefined>>;
}

const AddressSearch = (props: PermitSearchProps) => {
    const { t } = useTranslation();
    const [streetNumber, setStreetNumber] = useState('');
    const [streetName, setStreetName] = useState('');

    const search = useCallback(async () => {
        const results = await permitService.searchByAddress(streetName, streetNumber);
        if (results) {
            props.onResults(results);
        }
        else {
            props.onResults({Rows: []} as unknown as NavilinePermitSearchResponse);
        }
    }, [props, streetName, streetNumber]);

    return (
        <div className={styles.subformWrapper}>
            <p>{t('PermitSearch.SearchHelpText')}</p>
            <div className={styles.row} style={{display: "flex", flexDirection: "row"}}>
                <div>
                    <div className={styles.inputLabel}>{t('PermitSearch.StreetNumber')}</div>
                    <Text value={streetNumber}
                          fluid={false}
                          onChange={(event: any, data: any) => setStreetNumber(data.value)} />
                </div>
                <div style={{flexGrow: 1, marginLeft: 10}}>
                    <div className={styles.inputLabel}>{t('PermitSearch.StreetName')}</div>
                    <Text value={streetName}
                          fluid={true}
                          required
                          onChange={(event: any, data: any) => setStreetName(data.value)} />
                </div>
            </div>
            <div className={styles.row}>
                <Button onClick={search}>Search</Button>
            </div>
        </div>
    );
};

const ApplicationSearch = (props: ApplicationNumberSearchProps) => {
    const { t } = useTranslation();
    const [year, setYear] = useState(moment().year());
    const [applicationNumber, setApplicationNumber] = useState('');

    const search = useCallback(async () => {
        const result = await permitService.getApplicationInfo(year.toString(), applicationNumber,);
        if (result && !result.OutputParms.ErrorCode) {
            props.onResults(result);
        }
        else {
            alert(result!.OutputParms.ErrorCode);
        }
    }, [props, applicationNumber, year]);

    return (
        <div className={styles.subformWrapper}>
            <div className={styles.row}>
                <div className={styles.inputLabel}>{t('PermitSearch.ApplicationYear')}</div>
                <Text value={year}
                      fluid={false}
                      required
                      type={"number"}
                      step={1}
                      onChange={(event: any, data: any) => setYear(data.value)} />
            </div>
            <div className={styles.row}>
                <div className={styles.inputLabel}>{t('PermitSearch.ApplicationNumber')}</div>
                <Text value={applicationNumber}
                      fluid={false}
                      required
                      onChange={(event: any, data: any) => setApplicationNumber(data.value)} />
            </div>
            <div className={styles.row}>
                <Button onClick={search}>Search</Button>
            </div>
        </div>
    );
};

const ParcelSearch = (props: PermitSearchProps) => {
    const { t } = useTranslation();
    const [township, setTownship] = useState('');
    const [section, setSection] = useState('');
    const [block, setBlock] = useState('');
    const [lot, setLot] = useState('');
    const [unit, setUnit] = useState('');
    return (
        <div className={styles.subformWrapper}>
            <div className={styles.row}>
                <div className={styles.inputLabel}>{t('PermitSearch.Township')}</div>
                <Text value={township}
                      fluid={false}
                      required
                      onChange={(event: any, data: any) => setTownship(data.value)} />
            </div>
            <div className={styles.row}>
                <div className={styles.inputLabel}>{t('PermitSearch.Section')}</div>
                <Text value={section}
                      fluid={false}
                      required
                      onChange={(event: any, data: any) => setSection(data.value)} />
            </div>
            <div className={styles.row}>
                <div className={styles.inputLabel}>{t('PermitSearch.Block')}</div>
                <Text value={block}
                      fluid={false}
                      required
                      onChange={(event: any, data: any) => setBlock(data.value)} />
            </div>
            <div className={styles.row}>
                <div className={styles.inputLabel}>{t('PermitSearch.Lot')}</div>
                <Text value={lot}
                      fluid={false}
                      required
                      onChange={(event: any, data: any) => setLot(data.value)} />
            </div>
            <div className={styles.row}>
                <div className={styles.inputLabel}>{t('PermitSearch.Unit')}</div>
                <Text value={unit}
                      fluid={false}
                      required
                      onChange={(event: any, data: any) => setUnit(data.value)} />
            </div>
            <div className={styles.row}>
                <Button onClick={() => {}}>Search</Button>
            </div>
        </div>
    );
};

const NameSearch = (props: PermitSearchProps) => {
    const { t } = useTranslation();
    const [name, setName] = useState('');

    const search = useCallback(async () => {
        const results = await permitService.searchByName(name);
        if (results) {
            props.onResults(results);
        }
        else {
            props.onResults({Rows: []} as unknown as NavilinePermitSearchResponse);
        }
    }, [props, name]);

    return (
        <div className={styles.subformWrapper}>
            <div className={styles.row}>
                <div className={styles.inputLabel}>{t('PermitSearch.Name')}</div>
                <Text value={name}
                      fluid={false}
                      required
                      onChange={(event: any, data: any) => setName(data.value)} />
            </div>
            <div className={styles.row}>
                <Button onClick={search}>Search</Button>
            </div>
        </div>
    );
};

export const PermitSearch = () => {
    const { isAuthenticated } = useAuthSelector(s => s);
    const { t } = useTranslation();
    const [results, setResults] = useState<NavilinePermitSearchResponse>();
    const [details, setDetails] = useState<NavilinePermitInformation>();
    const [modalOpen, setModalOpen] = useState(false);
    const [searchMethod, setSearchMethod] = useState<'Address' | 'Application Number' | 'Parcel Number' | 'Name'>('Address');
    const options = [
        {key: 'Address', text: t('PermitSearch.Address'), value: 'Address'},
        {key: 'Application Number', text: t('PermitSearch.ApplicationNumber'), value: 'Application Number'},
        {key: 'Parcel Number', text: t('PermitSearch.ParcelNumber'), value: 'Parcel Number'},
        {key: 'Name', text: t('PermitSearch.Name'), value: 'Name'},
    ];

    let subComponent = null;
    if (searchMethod === 'Address') {
        subComponent = <AddressSearch onResults={setResults} />;
    } else if (searchMethod === 'Application Number') {
        subComponent = <ApplicationSearch onResults={(result) => {
            setDetails(result);
            setModalOpen(true);
        }} />;
    } else if (searchMethod === 'Parcel Number') {
        subComponent = <ParcelSearch onResults={setResults} />;
    } else if (searchMethod === 'Name') {
        subComponent = <NameSearch onResults={setResults}/>;
    }

    const loadPermitInfo = async (applicationNumber: string, year: string) => {
        const newDetails = await permitService.getApplicationInfo(year, applicationNumber);
        if (newDetails) {
            setDetails(newDetails);
            setModalOpen(true);
        } else {
            setDetails(undefined);
            alert('Something went wrong.');
        }
    };

    return (
        <PageContainer style={isAuthenticated ? {} : { maxWidth: 1000, width: '100%'}}>
            <div className='headerContainer'>
                <h2>{t('Activity.FIND_PERMIT_SEARCH')}</h2>
            </div>
            <PanelRow>
                <Panel>
                    <p>{t('PermitSearch.Instructions')}</p>
                    <div className={styles.row}>
                        <div className={styles.inputLabel}>{t('PermitSearch.SearchBy')}:</div>
                        <Dropdown value={searchMethod}
                                  placeholder={'Search Method'}
                                  fluid={false}
                                  options={options}
                                  onChange={(event: any, data: any) => setSearchMethod(data.value)} />
                    </div>
                    <div className={styles.row}>
                        {subComponent}
                    </div>
                </Panel>
            </PanelRow>
            {results?.Rows ?
            <PanelRow>
                <Panel>
                    <h3>{t('PermitSearch.Results')}</h3>
                    {results.Rows.length ?
                    <div className={styles.row} style={{overflowX: 'scroll'}}>
                        <table className={styles.table}>
                            <thead>
                            <tr>
                                <th>Application Number</th>
                                <th>Application Year</th>
                                <th>Address</th>
                                <th>Name</th>
                            </tr>
                            </thead>
                            <tbody>
                            {results.Rows.map(x => (
                                <tr onClick={() => loadPermitInfo(x.ApplicationNumber, x.ApplicationYear)} key={JSON.stringify(x)}>
                                    <td>{x.ApplicationNumber}</td>
                                    <td>{x.ApplicationYear}</td>
                                    <td>
                                        {x.StreetNumber} {x.StreetName} {x.StreetSuffix}
                                    </td>
                                    <td>
                                        {x.Name}
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    : <div>{t('PermitSearch.NoResults')}</div>
                    }
                </Panel>
            </PanelRow>
            : null}

            <Modal open={modalOpen} onClose={() => setModalOpen(false)} size="small">
                <Modal.Header>
                    {t('PermitSearch.Details')}
                    <div style={{ 'float': 'right' }}>
                        <Icon
                            icon='x'
                            color='black'
                            size="large"
                            onClick={() => setModalOpen(false)}
                        />
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <div className={styles.detailsWrapper}>
                        <div>
                            <div className={styles.detailRow}>
                                <strong>{t('PermitSearch.ParcelID')}: </strong>{details?.OutputParms.Parcel}
                            </div>
                            <div className={styles.detailRow}>
                                <strong>{t('PermitSearch.ApplicationDate')}: </strong>{moment(details?.OutputParms.ApplicationDate, 'MMDDYY').format('MM/DD/YYYY')}
                            </div>
                            <div className={styles.detailRow}>
                                <strong>{t('PermitSearch.Valuation')}: </strong>{details?.OutputParms.EstimatedValue}
                            </div>
                            <div className={styles.detailRow}>
                                <strong>{t('PermitSearch.TenantName')}: </strong>{details?.OutputParms.TenantName}
                            </div>
                            <div className={styles.detailRow}>
                                <strong>{t('PermitSearch.TenantUnit')}: </strong>{details?.OutputParms.TenantNumber}
                            </div>
                            <div className={styles.detailRow}>
                                <strong>{t('PermitSearch.ZoningDescription')}: </strong>{details?.OutputParms.ZoneDesc}
                            </div>
                        </div>
                        <div>
                            <div className={styles.detailRow}>
                                <strong>{t('PermitSearch.Address')}: </strong>{details?.OutputParms.Address}
                            </div>
                            <div className={styles.detailRow}>
                                <strong>{t('PermitSearch.Owner')}: </strong>{details?.OutputParms.Owner}
                            </div>
                            <div className={styles.detailRow}>
                                <strong>{t('PermitSearch.ApplicationType')}: </strong>{details?.OutputParms.ApplicationType}
                            </div>
                            <div className={styles.detailRow}>
                                <strong>{t('PermitSearch.SquareFootage')}: </strong>{details?.OutputParms.SquareFootage}
                            </div>
                            <div className={styles.detailRow}>
                                <strong>{t('PermitSearch.ApplicationStatus')}: </strong>{details?.OutputParms.ApplicationStatus}
                            </div>
                            <div className={styles.detailRow}>
                                <strong>{t('PermitSearch.Contractor')}: </strong>{details?.OutputParms.ContractorName}
                            </div>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='blue' onClick={() => setModalOpen(false)}>OK</Button>
                </Modal.Actions>
            </Modal>
        </PageContainer>
    );
}
