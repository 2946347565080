import React, { useEffect } from 'react';
import { Dropdown, DropdownOptions } from '../../../../components/dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setTreesProblem, useAppStateReportSelector, setCategoryCanSubmit } from '../reportSlice';

export const Trees = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const state = useAppStateReportSelector(state => state.trees);

    const problemOptions: DropdownOptions[] = [
        {
            value: "Tree/Branch Down",
            key: "Tree/Branch Down",
            text: t('Report.BranchTreeDown'),
        },
        {
            value: "Tree Trimming",
            key: "Tree Trimming",
            text: t('Report.TreeTrimming'),
        },
        {
            value: "Dead and Decaying Trees",
            key: "Dead and Decaying Trees",
            text: t('Report.DeadDecayingTrees'),
        }
    ];

    useEffect(() => {
        if (state.problem) {
            dispatch(setCategoryCanSubmit(true));
        }
        else {
            dispatch(setCategoryCanSubmit(false));
        }
    }, [dispatch, state.problem]);

    return (
        <>
            {t('Report.IdentifyTheProblem')}
            <Dropdown
                placeholder={t('Report.IdentifyTheProblem')}
                options={problemOptions}
                value={state.problem}
                onChange={(e: any, data: any) => dispatch(setTreesProblem(data.value))}
            />
        </>
    )
}