import React, { useEffect } from 'react';
import { Dropdown, DropdownOptions } from '../../../../components/dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setSidewalkProblem, useAppStateReportSelector, setCategoryCanSubmit } from '../reportSlice';

export const SidewalkRepair = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const state = useAppStateReportSelector(state => state.sidewalk);

    const problemOptions: DropdownOptions[] = [
        {
            value: "Cracked",
            key: "Cracked",
            text: t('Report.Cracked'),
        },
        {
            value: "Uneven",
            key: "Uneven",
            text: t('Report.Uneven'),
        },
        {
            value: "Other",
            key: "Other",
            text: t('Report.Other'),
        },
    ];

    useEffect(() => {
        if (state.problem) {
            dispatch(setCategoryCanSubmit(true));
        }
        else {
            dispatch(setCategoryCanSubmit(false));
        }
    }, [dispatch, state.problem]);

    return (
        <>
            {t('Report.IdentifyTheProblem')}:
            <Dropdown
                placeholder={t('Report.IdentifyTheProblem')}
                options={problemOptions}
                value={state.problem}
                onChange={(e: any, data: any) => dispatch(setSidewalkProblem(data.value))}
            />
        </>
    )
}