import React from 'react';
import { ColoredCircle } from '../coloredCircle/ColoredCircle';
import { useTranslation } from 'react-i18next';
import styles from './NavigationCircles.module.scss';
import { NavLink } from 'react-router-dom';

export const NavigationCircles = () => {
    const { t } = useTranslation();
    // Did not use ColoredCircleRow because it tries to add a style prop to each child which does not work if the circles may be wrapped in a link
    return (
        <div className={styles.circleContainer}>
            <a href="https://www.governmentjobs.com/careers/elginil" target="_blank" rel="noopener noreferrer">
                <ColoredCircle
                    size="large"
                    color="green"
                    value={t('Home.Employment')}
                    onClick={() => null}
                />
            </a>
            <a href="https://www.cityofelgin.org/business" target="_blank" rel="noopener noreferrer">
                <ColoredCircle
                    size="large"
                    color="purple"
                    value={t('Home.Business')}
                    onClick={() => null}
                />
            </a>
            <a href="https://www.cityofelgin.org/1937/Transparency" target="_blank" rel="noopener noreferrer">
                <ColoredCircle
                    size="large"
                    color="orange"
                    value={t('Home.Transparency')}
                    onClick={() => null}
                />
            </a>
            <a href="https://gis-maps-site-cityofelgin.hub.arcgis.com/" target="_blank" rel="noopener noreferrer">
                <ColoredCircle
                    size="large"
                    color="blue"
                    value={t('Home.Map', { count: 2 })}
                    onClick={() => null}
                />
            </a>
            <NavLink to="knowledgebase" rel="noopener noreferrer">
                <ColoredCircle
                    size="large"
                    color="gray"
                    value={t('Home.FAQs')}
                    onClick={() => null}
                />
            </NavLink>
        </div>
    );
}