import React, { useEffect, useState } from "react";
import DataGrid from "react-data-grid";
import { NavLink } from "react-router-dom";
import styles from "./UsersList.module.scss";
import { userService } from "../../../services/userService";
import { PageContainer } from "../../../components/pageContainer/PageContainer";
import { Spinner } from "../../../components/spinner/Spinner";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openMessageModal } from "../../../components/statusModal/messageModalActionCreators";
import { AnyAction } from "redux";

export const UsersList = () => {
    const [rows, setRows] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const onDelete = async (id: string, dispatch: Function) => {
        const confirmed = window.confirm("Are you sure you want to delete this user?");
        if (confirmed) {
            setLoading(true);
            try {
                await userService.deleteUser(id);
                dispatch(openMessageModal('success-remove', 'success'));
                const users = await userService.getUsers();
                setRows(users);
            }
            catch (err) {
                dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
            }
            finally {
                setLoading(false);
            }
        }
    };

    const columns = [
        { key: "Id", name: "User Id" },
        { key: "FirstName", name: "First Name" },
        { key: "LastName", name: "Last Name" },
        { key: "Email", name: "Email" },
        { key: "PhoneNumber", name: "Phone Number" },
        { key: "SalesforceId", name: "Salesforce ID" },
        {
            key: "actions",
            name: "",
            formatter: (props: any) => {
                return (
                    <>
                        <NavLink to={`/users/${props.row.Id}`}>Edit</NavLink>&nbsp;&nbsp;&nbsp;
                        <span className={styles.deleteLink} onClick={() => onDelete(props.row.Id, dispatch)}>Delete</span>
                    </>
                )
            }
        },
    ];

    useEffect(() => {
        (async (setRows, setLoading) => {
            const users = await userService.getUsers();
            setRows(users);
            setLoading(false);
        })(setRows, setLoading);
    }, [setRows, setLoading]);


    return (
        <PageContainer>
            <div className="headerContainer">
                <h1 className={styles.heading}>{t("Users")}</h1>
                <Spinner isLoading={loading} />
            </div>
            <div>
                <DataGrid
                    columns={columns}
                    rowKeyGetter={(row: any) => row.Id}
                    defaultColumnOptions={{
                        sortable: false,
                        resizable: true
                    }}
                    rows={rows} />
            </div>
        </PageContainer>
    );
};
