import {Dispatch} from 'redux';
import {
    setMobileSideBarVisibility, setLanguageOptions, setSelectedLanguage
} from './headerSlice';
import { axios } from '../../services/axiosService';
import { DropdownOptions } from '../dropdown/Dropdown';
import { AppState } from '../../store/appState';
import { userService } from '../../services/userService';

export const toggleSideBar = (value: boolean) => (dispatch: Dispatch) => {
    dispatch(setMobileSideBarVisibility({value}));
};

export const loadLanguageOptions = () => async (dispatch: Dispatch) => {
    const languages = (await axios.get('/i18n/languages')).data;
    const languageOptions: DropdownOptions[] = languages.map((l: any) => ({ key: l.i18nCode, value: l.i18nCode, text: `${l.nativeName} (${l.enName})`}));
    dispatch(setLanguageOptions(languageOptions));
}

export const setLanguage = (language: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    localStorage.setItem('language', language);
    dispatch(setSelectedLanguage(language));
    const state = getState();
    if (!state.auth.isAuthenticated) return;
    await userService.updateUserInfo({ language });
}