import { authService } from "../../services/authService";
import { elginToast, ToastMessage } from "../../services/toastService";

export const sendSamlAssertion = async (saml: string | undefined) => {
    try {
        const response = await authService.assertSAMLRequest(saml);
        return response;
    } catch (err) { 
        elginToast(ToastMessage.error);
    }
};