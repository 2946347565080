import { AnyAction, Dispatch } from 'redux';
import { firebaseService } from "../../services/firebaseService";
import { authService } from '../../services/authService';
import { clearSignup, setSignupError, setLoading } from './signupSlice';
import { openMessageModal } from '../../components/statusModal/messageModalActionCreators';
import { setSfAccountExists } from '../account/verification/verificationRequiredSlice';

export const signup = (email: string, password: string, callback: () => void) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(setLoading(true));
        dispatch(setSignupError(null));
        let result = await firebaseService.auth().createUserWithEmailAndPassword(email, password);
        const response = await authService.signup(email, result.user!.uid);
        if (response.message !== 'salesforce-email-exists') {
            await authService.sendVerificationLinkToEmail();
            dispatch(setSfAccountExists(false));
            dispatch(clearSignup);
            callback();
        }
        else {
            try {
                await firebaseService.auth().signOut();
            } catch (e) {
                console.log(e);
            }
            dispatch(setSfAccountExists(true));
            dispatch(openMessageModal('error-sf-account-exists', 'error'));
        }
    } catch (err) {
        if (err.code === "auth/wrong-password" || err.code === "auth/user-not-found") {
            // handled together to prevent malicious user from identifying a valid email
            dispatch(setSignupError("Auth.InvalidEmailOrPassword"));
        } else if (err.code === "auth/invalid-email") {
            dispatch(setSignupError("Auth.InvalidEmail"));
        } else if (err.code === "auth/email-already-in-use") {
            dispatch(setSignupError("Auth.EmailAlreadyInUse"));
        } else {
            dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        }
    } finally {
        dispatch(setLoading(false));
    }
}