import { AxiosService } from "./axiosService";
import { AxiosError } from "axios";
import { User } from "../models/user";

export class UserService extends AxiosService {
    public constructor() { super(); }

    async updateUserInfo(payload: any, id: string | null = null) {
        try {
            const { data } = await this.axios.put<User>(`/users${id ? "/profile/" + id : ""}`, payload);
            return data;
        } catch (e) {
            // if the status code is a 401 we do NOT want to throw
            if ((e as AxiosError)?.response?.status !== 401) {
                throw e;
            }
        }
    }

    async getElasticUsers(
        pageIndex: number, 
        pageSize: number, 
        sortedColumnName: string, 
        sortDirection: string, 
        searchTerm: string
    ) {
        try {
            let url = `/elastic-users?pageNumber=${pageIndex}&pageSize=${pageSize}&sortByField=${sortedColumnName}&sortDirection=${sortDirection}`;
            if (searchTerm && searchTerm.trim() !== '') {
                url += `&searchTerm=${searchTerm}`;
            }
            const { data } = await this.axios.get(url);
            return data;
        } catch (e) {
            if ((e as AxiosError)?.response?.status !== 401) {
                throw e;
            }
        }
    }

    async getUsers() {
        try {
            const { data } = await this.axios.get("/users");
            return data;
        } catch (e) {
            if ((e as AxiosError)?.response?.status !== 401) {
                throw e;
            }
        }
    }

    async getUser(id: string) {
        try {
            const { data } = await this.axios.get(`/users/profile/${id}`);
            return data;
        } catch(e) {
            if((e as AxiosError)?.response?.status !== 401) {
                throw e;
            }
        }
    }

    async getUserProfileDetails(id: string) {
        try {
            const { data } = await this.axios.get(`/users/profile-details/${id}`);
            return data;
        } catch(e) {
            if((e as AxiosError)?.response?.status !== 401) {
                throw e;
            }
        }
    }

    async getUserNotificationSettings(id: string) {
        try {
            const { data } = await this.axios.get(`/users/notification-prefs/${id}`);
            return data;
        } catch(e) {
            throw e;
        }
    }

    async setUserNotificationSetting(userId: string, notificationId: string, enabled: string) {
        try {
            const { data } = await this.axios.post(`/users/notification-prefs/${userId}/${notificationId}`, {
                enabled,
            });
            return data;
        } catch(e) {
            throw e;
        }
    }

    async deleteUser(id: string) {
        try {
            const { data } = await this.axios.delete(`/users/${id}`);
            return data;
        } catch (e) {
            if ((e as AxiosError)?.response?.status !== 401) {
                throw e;
            }
        }
    }

    async sendForgetPasswordEmail(email: string) {
        try {
            const { data } = await this.axios.post(`/users/reset-password`, {
                email,
            });
            return data;
        } catch(e) {
            if((e as AxiosError)?.response?.status !== 401) {
                throw e;
            }
        }
    }

    async sendVerificationEmail(email: string) {
        try {
            const { data } = await this.axios.post(`/users/resend-registration`, {
                email,
            });
            return data;
        } catch(e) {
            if((e as AxiosError)?.response?.status !== 401) {
                throw e;
            }
        }
    }
}

export const userService = new UserService();