import React, { useState } from 'react';
import styles from './AccountCreate.module.scss';
import { Text } from '../../../components/text/Text';
import { useDispatch } from 'react-redux';
import { AccountCreateAddressAppState, AccountCreatePersonalInformationAppState } from './accountCreateAppState';
import { submitAddress } from './accountCreateActionCreators';
import { Button } from '../../../components/button/Button';
import { useHistory } from 'react-router-dom';
import { Dropdown } from '../../../components/dropdown/Dropdown';
import { states } from '../../../static/json/states';

import {
    setAddress1, 
    setAddress2,
    setCity,
    setZip,
    setState,
    setAdditionalAddress1,
    setAdditionalAddress2,
    setAdditionalCity,
    setAdditionalZip,
    setAdditionalState,
    setInvalidAddress1,
    setInvalidCity,
    setInvalidState,
    setInvalidZip,
    setFirstName,
    setLastName,
    setPhoneNumber
} from './accountCreateSlice';
import { ErrorValidationLabel, Reasoning } from '../../../components/errorValidationLabel/ErrorValidationLabel';
import { useTranslation } from 'react-i18next';
import { useAuthSelector } from '../../auth/authSlice';

export interface AccountCreateAddressProps {
    address: AccountCreateAddressAppState;
    personalInformation: AccountCreatePersonalInformationAppState;
    additional?: boolean;
}

export function AccountCreateAddress(props: AccountCreateAddressProps) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const user = useAuthSelector(x=> x.authUser);
    const { address1, address2, city, state, zip, invalidAddress1, invalidCity, invalidState, invalidZip } = props.address;
    const { firstName, lastName, phoneNumber } = props.personalInformation;
    let [invalidFirstName, setInvalidFirstName] = useState<Reasoning | null>(null);
    let [invalidLastName, setInvalidLastName] = useState<Reasoning | null>(null);
    let [invalidPhoneNumber, setInvalidPhoneNumber] = useState<Reasoning | null>(null);

    if (props.additional) {

    }
    const submit = () => {
        const valid = validateForm();
        if (valid) {
            dispatch(submitAddress(missingUserData));
            history.push('/account/create/account-information');
        }
    }

    const clearValidators = () => {
        dispatch(setInvalidAddress1(null));
        dispatch(setInvalidCity(null));
        dispatch(setInvalidState(null));
        dispatch(setInvalidZip(null));
        setInvalidFirstName(null);
        setInvalidLastName(null);
        setInvalidPhoneNumber(null);
    }

    const validateForm = () => {
        clearValidators();

        let validForm = true;

        if (address1.length !== 0 || city.length !== 0 || state.length !== 0 || zip.length !== 0) {
            if (address1.length === 0) {
                dispatch(setInvalidAddress1('Required'));
                validForm = false;
            }
            if (city.length === 0) {
                dispatch(setInvalidCity('Required'));
                validForm = false;
            }
            if (state.length === 0) {
                dispatch(setInvalidState('Required'));
                validForm = false;
            }
            if (zip.length !== 5) {
                dispatch(setInvalidZip('Required'));
                validForm = false;
            }
        }

        if (user) {
            if (user.FirstName?.trim().length === 0 || firstName.trim().length === 0) {
                setInvalidFirstName('Required');
                validForm = false;
            }

            if (user.LastName?.trim().length === 0 || lastName.trim().length === 0) {
                setInvalidLastName('Required');
                validForm = false;
            }

            var phoneNumberRegex = new RegExp(/^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/); // eslint-disable-line
            if (phoneNumber && !phoneNumberRegex.test(phoneNumber)) {
                setInvalidPhoneNumber('Invalid');
                validForm = false;
            }
        }

        return validForm;
    }

    const set = (field: string, value: string) => {
        if (field === 'address1') !props.additional ? dispatch(setAddress1({ value })) : dispatch(setAdditionalAddress1({ value }))
        if (field === 'address2') !props.additional ? dispatch(setAddress2({ value })) : dispatch(setAdditionalAddress2({ value }))
        if (field === 'city') !props.additional ? dispatch(setCity({ value })) : dispatch(setAdditionalCity({ value }))
        if (field === 'state') !props.additional ? dispatch(setState({ value })) : dispatch(setAdditionalState({ value }))
        if (field === 'zip' && (value.match(/^\d+$/) || value === '') && value.length <= 5) !props.additional ? dispatch(setZip({ value })) : dispatch(setAdditionalZip({ value }));
        if (field === 'firstName') dispatch(setFirstName({value}));
        if (field === 'lastName') dispatch(setLastName({value}));
        if (field === 'phoneNumber') dispatch(setPhoneNumber({value}));
    }

    let missingUserData = false;
    if (user) {
        if ((!user!.FirstName || user!.FirstName.length === 0) || (!user!.LastName || user!.LastName.length === 0) || (!user!.PhoneNumber || user!.PhoneNumber.length === 0)) {
            missingUserData = true;
        }
    }

    return (
        <div className={styles.formContainer}>
            <>
                <div className={styles.label}>{t('Account.PersonalInformation')}</div>
                <div className={styles.inputContainer}>
                    <div className={styles.firstName}>
                        <Text
                            value={firstName}
                            onChange={(e: any) => set('firstName', e.target.value)}
                            placeholder={'First Name'}
                            style={{marginBottom: 0}}
                            id="firstNameField"
                        />
                        {invalidFirstName ? <ErrorValidationLabel fieldDescription='First Name' /> : null}
                    </div>
                    <div className={styles.lastName}>
                        <Text
                            value={lastName}
                            onChange={(e: any) => set('lastName', e.target.value)}
                            placeholder={'Last Name'}
                            style={{marginBottom: 0}}
                            id="lastNameField"
                        />
                        {invalidLastName ? <ErrorValidationLabel fieldDescription='Last Name' /> : null}
                    </div>
                </div>
                <Text
                    value={phoneNumber}
                    onChange={(e: any) => set('phoneNumber', e.target.value)}
                    placeholder={'Phone Number'}
                    mask="(999) 999-9999"
                    style={{marginBottom: 0}}
                    id="phoneField"
                />
                {invalidPhoneNumber ? <ErrorValidationLabel fieldDescription='Phone Number' reasoning={invalidPhoneNumber} /> : null}
                <div style={{height: 5}}></div>
            </>

            <div className={styles.label}>{t('Account.CreateAddress')}</div>
            <div className={`${styles.slightMarginBottom} ${styles.slightMarginTop}`}>
                <Text
                    value={address1}
                    onChange={(e: any) => set('address1', e.target.value)}
                    placeholder={t('Account.CreateAddressLine1')}
                    style={{marginBottom: 0}}
                    id="address1Field"
                />
                {invalidAddress1 ? <ErrorValidationLabel fieldDescription='Address 1' /> : null}
            </div>
            <Text
                value={address2}
                onChange={(e: any) => set('address2', e.target.value )}
                placeholder={t('Account.CreateAddressLine2')}
                id="address2Field"
            />
            <div className={styles.cityStateZip}>
                <div className={styles.city}>
                    <Text
                        value={city}
                        onChange={(e: any) => set('city', e.target.value )}
                        placeholder={t('Account.CreateCity')}
                        style={{marginBottom: 0}}
                        id="cityField"
                    />
                    {invalidCity ? <ErrorValidationLabel fieldDescription='City' /> : null}
                </div>
                <div className={styles.state}>
                    <Dropdown
                        placeholder={t('Account.CreateState')}
                        value={state}
                        options={states}
                        onChange={(e: any, data: any) => set('state', data.value )}
                        id="stateField"
                    />
                    {invalidState ? <ErrorValidationLabel fieldDescription='State' /> : null}
                </div>
                <div className={styles.zip}>
                    <Text
                        value={zip}
                        onChange={(e: any) => set('zip', e.target.value )}
                        placeholder={t('Account.CreateZip')}
                        style={{marginBottom: 0}}
                        id="zipField"
                    />
                    {invalidZip ? <ErrorValidationLabel fieldDescription='Zip' /> : null}
                </div>
            </div>
            { !props.additional ?
                <div className={styles.buttonContainer}>
                    <Button
                        onClick={() => submit()}
                        color='blue'
                        data-testid="nextButton"
                    >
                        {t('Util.Next')}
                    </Button>
                </div>
            : null}
        </div>
    );
};