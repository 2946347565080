import React from 'react';
import {useDispatch} from 'react-redux';
import styles from './MobileSideBarMenu.module.scss';
import { logout as signout } from '../../features/login/loginActionCreators'

interface LogoutButtonProps {
    onClick(): void;
}

export const MobileSideBarMenuLogoutButton: React.FunctionComponent<LogoutButtonProps> = ({onClick}) => {
    const dispatch = useDispatch();

    const logout = async () => {
        await dispatch(signout(onClick))
    }

    return (
        <div className={styles.mobileSideBarMenuItemContainer} onClick={logout}>
            <div className={`${styles.mobileSideBarMenuItemLink} ${styles.normalLinks}`}>
                <i className={`far fa-sign-out`}></i>Logout
            </div>
        </div>
    );
}