import { axios } from '../../services/axiosService';
import { AnyAction, Dispatch } from 'redux';
import { Alert } from './systemAlertsAppState';
import { setAlerts, setDeleteAlertModalOpen, setEditAlertModalOpen, setFocusedAlert, setLoading, setUnreadAlertIndex, setUserStoredAlerts } from './systemAlertsListSlice';
import { openMessageModal } from '../../components/statusModal/messageModalActionCreators';

export const loadAlerts = () => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        const alerts = (await axios.get('/systemAlerts')).data as Alert[];
        dispatch(setAlerts(alerts));
        dispatch(setEditAlertModalOpen(new Array(alerts.length).fill(false)));
        dispatch(setDeleteAlertModalOpen(new Array(alerts.length).fill(false)));
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
};

export const sortAlerts = (alertArray: Alert[]): Alert[] => {
    return alertArray.sort((a: Alert, b: Alert) => {
        if (a.Active !== b.Active) {
          return a.Active ? -1 : 1;
        }
        if (a.Read !== b.Read) {
          return a.Read ? 1 : -1;
        }
        const dateB: any = b.CreatedAt;
        const dateA: any = a.CreatedAt;
        return dateB - dateA;
    });
}

// Check next active, unread alert
export const checkNextUnreadIndex = (alertArray: Alert[]): number => {
    let unreadIndex = -1;
    for(let i = 0; i < alertArray.length; i++) {
        const alert = alertArray[i];

        if (alert.Active) {
            unreadIndex = i;
            break;
        }
    }
    return unreadIndex;
}

export const loadUserAlerts = () => async (dispatch: Dispatch) => {
    try {
        // Load all the alerts from api
        dispatch(setLoading(true));
        const alerts = (await axios.get('/systemAlerts')).data as Alert[];
        dispatch(setAlerts(alerts));
        dispatch(setEditAlertModalOpen(new Array(alerts.length).fill(false)));
        dispatch(setDeleteAlertModalOpen(new Array(alerts.length).fill(false)));

        let arrCopy = [];
        for (const alert of alerts) {
            arrCopy.push({
                ...alert,
                Read: false,
            });
        }

        arrCopy = sortAlerts(arrCopy);
        dispatch(setUserStoredAlerts(arrCopy));
        if (arrCopy.length > 0 && arrCopy[0].Active) {
            dispatch(setUnreadAlertIndex(0));
        } else {
            dispatch(setUnreadAlertIndex(-1));
        }
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
};

export const storeUserAlertsArray = (alerts: Alert[]) => async (dispatch: Dispatch) => {
    try {
        sessionStorage.setItem('systemAlertArray', JSON.stringify(alerts));
        dispatch(setUserStoredAlerts(alerts));
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
};

export const loadAlert = (id: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        const alert = (await axios.get(`/systemAlerts/${id}`));
        dispatch(setFocusedAlert(alert));
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
};

export const createAlert = (titleEnglish: string, titleSpanish: string, textEnglish: string, textSpanish: String) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        (await axios.post(`/systemAlerts`, {
            titleEnglish,
            titleSpanish,
            textEnglish,
            textSpanish,
        }));
        await loadAlerts()(dispatch);
        // Display success message that alert was created
        dispatch(openMessageModal('add-system-alert', 'success') as unknown as AnyAction);
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
};

export const updateAlert = (id: string,
    titleEnglish: string,
    titleSpanish: string,
    textEnglish: string,
    textSpanish: string,
    active: boolean) => async (dispatch: Dispatch) => {
        try {
            dispatch(setLoading(true));
            (await axios.put(`/systemAlerts/${id}`, {
                titleEnglish,
                titleSpanish,
                textEnglish,
                textSpanish,
                active
            }));
            await loadAlerts()(dispatch);
            // Display success message that alert was updated
            dispatch(openMessageModal('update-system-alert', 'success') as unknown as AnyAction);
        } catch (err) {
            dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
            console.error(err);
        } finally {
            dispatch(setLoading(false));
        }
    };

export const deleteAlert = (id: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        (await axios.delete(`/systemAlerts/${id}`));
        await loadAlerts()(dispatch);
        // Display success message that alert was deleted
        dispatch(openMessageModal('delete-system-alert', 'success') as unknown as AnyAction);
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
};
