import { AnyAction, Dispatch } from 'redux';
import {
    resetPassword,
    setExpiredUrl,
    setLoading,
} from './resetPasswordSlice'
import { firebaseService } from '../../services/firebaseService';
import { openMessageModal } from '../../components/statusModal/messageModalActionCreators';

export const resetPasswordRequest = (token: string, password: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        await firebaseService.auth().confirmPasswordReset(token, password);
        dispatch(resetPassword(null));
        window.location.assign('/login');
        dispatch(openMessageModal(null, 'success') as unknown as AnyAction);
    } catch (err) { 
        dispatch(setExpiredUrl(true));
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
    } finally {
        dispatch(setLoading(false));
    }
};