import {createSlice, SliceCaseReducers} from '@reduxjs/toolkit';
import {buildSubStateSelector} from '../../store/utilities/useAppStateSelector';
import { LoginAppState } from './loginAppState';

export const slice = createSlice<LoginAppState, SliceCaseReducers<LoginAppState>>({
    name: 'login',
    initialState: {
        email: '',
        password: '',
        loginError: null,
        loading: false,
    },
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload.value;
        },
        setPassword: (state, action) => {
            state.password = action.payload.value;
        },
        clearLogin: (state, action) => {
            state.email = '';
            state.password = '';
        },
        setLoginError: (state, action) => {
            state.loginError = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    }
});

export const useAppStateLoginSelector = buildSubStateSelector(state => state.login);
export const {
    setEmail,
    setPassword,
    clearLogin,
    setLoginError,
    setLoading,
} = slice.actions;
export const loginReducer = slice.reducer;
