import React from 'react';
import { useDispatch } from 'react-redux';
import {
    decrement,
    increment,
    incrementByAmount,
    setIncrementAmount,
    useAppStateCounterSelector,
} from './counterSlice';
import styles from './Counter.module.css';

export function Counter() {
    const count = useAppStateCounterSelector(state => state.value);
    const incrementAmount = useAppStateCounterSelector(state => state.incrementAmount);
    const dispatch = useDispatch();

    return (
        <div>
            <div className={styles.row}>
                <button
                    className={styles.button}
                    aria-label="Increment value"
                    onClick={() => dispatch(increment(null))}
                >
                    +
                </button>
                <span className={styles.value}>{count}</span>
                <button
                    className={styles.button}
                    aria-label="Decrement value"
                    onClick={() => dispatch(decrement(null))}
                >
                    -
                </button>
            </div>
            <div className={styles.row}>
                <input
                    className={styles.textbox}
                    value={incrementAmount}
                    onChange={(e: any) => dispatch(setIncrementAmount({ value: e.target.value }))}
                />
                <button
                    className={styles.button}
                    onClick={() =>
                        dispatch(
                            incrementByAmount({ amount: Number(incrementAmount) ?? 0 })
                        )
                    }
                >
                    Add Amount
                </button>
            </div>
        </div>
    );
}