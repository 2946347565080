import React, { FunctionComponent } from 'react';
import styles from './Button.module.scss';
import { Button as SemanticButton } from 'semantic-ui-react'

export interface ButtonProps  {
    color?: 'blue' | 'green' | 'red' | 'grey' | 'lightBlue' | 'darkBlue' | 'orange' | 'black' | 'vibrantGreen' | 'yellow';
    fluid?: boolean;
    disabled?: boolean;
    onClick?(): void;
    style?: string;
    loading?: boolean;
    type?: string;
    biggerFont?: boolean;
}

export const Button: FunctionComponent<ButtonProps> = ({children, color, fluid, disabled, style, biggerFont, onClick, ...rest}) => {
    let getColor = () => {
        switch (color) {
            case 'green':
                return styles.green;
            case 'vibrantGreen':
                return styles.vibrantGreen;
            case 'yellow':
                return styles.yellow;
            case 'blue':
                return styles.blue;
            case 'red':
                return styles.red;
            case 'grey':
                return styles.grey;
            case 'lightBlue':
                return styles.lightBlue;
            case 'darkBlue':
                return styles.darkBlue;
            case 'orange':
                return styles.orange;
            case 'black':
                return styles.black;
        }
    }
    return (
        <SemanticButton 
            className={`${biggerFont ? styles.biggerButtonFont : styles.button} ${getColor()} ${style}`}  
            fluid={fluid}
            disabled={disabled}
            onClick={onClick}
            {...rest}>
                {children}
        </SemanticButton>
    );
}

Button.defaultProps = {
    color: 'blue',
    style: ''
};