import { axios } from '../../services/axiosService';
import { AnyAction, Dispatch } from 'redux';
import { startFailed, setActivities, setActions, setAction, setItems, setItem, setPopularItems } from './activitiesWizardSlice';
import { groupBy } from '../../services/utilities';
import { Activity, ActivityActions } from '../../features/activities/list/activitiesListAppState';
import { DropdownOptions } from '../dropdown/Dropdown';
import { AppState } from '../../store/appState';
import { setLoadingActivities } from '../../components/activitiesWizard/activitiesWizardSlice';

export const getStarted = () => async (dispatch: Dispatch) => {
    try {

    } catch (err) {
        console.error(err);
        dispatch(startFailed({errorMessage: 'Start Failed'}));
    }
};

export const loadActivities = (userId?: string, itemType?: undefined | null | 'all' | 'popular') => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoadingActivities(true));
        const activities = (await axios.get('/activities', { params: { userId }})).data as Activity[];
        const publishedActivities = activities.filter(a => a.Published);
        const groupedActivities = groupBy(publishedActivities, (x: Activity) => x.Action);
        let actions: DropdownOptions[] = [];
        for (const action in groupedActivities) {
            if (groupedActivities.hasOwnProperty(action)) {
                const lowerCaseAction = action.toLocaleLowerCase().replace('_', ' ');
                const readableAction = `${lowerCaseAction[0].toUpperCase()}${lowerCaseAction.substr(1)}`;
                actions.push({
                    key: action,
                    value: action,
                    text: readableAction
                });
            }
        }
        dispatch(setActivities(groupedActivities));
        dispatch(setActions(actions));
        if (itemType === 'all') {
            dispatch(getAllItems() as unknown as AnyAction);
        } else if (itemType === 'popular') {
            dispatch(getPopularItems() as unknown as AnyAction);
        }
    } catch (err) {
        console.error(err);
    } finally {
        dispatch(setLoadingActivities(false));
    }
}

export const getActivity = (id: string) => async (dispatch: Dispatch) => {
    try {
        const apiActivity = (await axios.get(`/activities/${id}`)).data as Activity;
        if (apiActivity) dispatch(setItem({ item: '', selectedItem: apiActivity }));
    } catch (err) {
        console.error(err);
    }
}

export const selectAction = (action: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const activitiesWizard = state.activitiesWizard;

    dispatch(setAction(action));
    const actionActivities = activitiesWizard.activities[action as ActivityActions] as Activity[];
    const itemsDropdown: DropdownOptions[] = [];
    actionActivities.map(aa => aa.Published ? itemsDropdown.push({ key: aa.Item, value: aa.Item, text: aa.Item}) : null);
    dispatch(setItems(itemsDropdown));
}

export const getAllItems = () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const activitiesWizard = state.activitiesWizard;

    const actionActivities = [] as Activity[];
    for (const key in activitiesWizard.activities) {
        if (Object.prototype.hasOwnProperty.call(activitiesWizard.activities, key)) {
            const activities = (activitiesWizard.activities as Record<string, Activity[]>)[key];
            actionActivities.push(...activities);
        }
    }
    let items: DropdownOptions[] = [];
    actionActivities.map(aa => aa.Published ? items.push({ key: aa.Item, value: aa.Item, text: aa.Item, translation: `Activity.${aa.Action}_${aa.Item}`, item: aa }) : null);
    items.sort((a, b) => {
        let textA = a.text.toUpperCase();
        let textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    dispatch(setItems(items));
    
    const popularItems = [...items]
        .filter((item) => item.item?.PopularService)
        .sort((a, b) => {
            let orderA = a.item ? a.item.PopularServiceOrder : 0;
            let orderB = b.item ? b.item.PopularServiceOrder : 0;
            return (orderA < orderB) ? -1 : (orderA > orderB) ? 1 : 0;
        });
    dispatch(setPopularItems(popularItems));
}

export const getPopularItems = () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const activitiesWizard = state.activitiesWizard;

    const actionActivities = [] as Activity[];
    for (const key in activitiesWizard.activities) {
        if (Object.prototype.hasOwnProperty.call(activitiesWizard.activities, key)) {
            const activities = (activitiesWizard.activities as Record<string, Activity[]>)[key];
            actionActivities.push(...activities);
        }
    }
    let items: DropdownOptions[] = [];
    actionActivities.map(aa => aa.Published && aa.PopularService ? items.push({ key: aa.Item, value: aa.Item, text: aa.Item, translation: `Activity.${aa.Action}_${aa.Item}`, item: aa }) : null);
    items = items.sort((a, b) => {
        let textA = a.text.toUpperCase();
        let textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    items = items.sort((a, b) => {
        let orderA = a.item? a.item.PopularServiceOrder : 0;
        let orderB = b.item? b.item.PopularServiceOrder : 0;
        return (orderA < orderB) ? -1 : (orderA > orderB) ? 1 : 0;
    });
    dispatch(setPopularItems(items));
}

export const selectItem = (item: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const activitiesWizard = state.activitiesWizard;

    const selectedItem = activitiesWizard.activities[activitiesWizard.action as ActivityActions].filter(a => a.Item === item)[0];

    dispatch(setItem({item, selectedItem}));
}
