import React from 'react';
import styles from './MobileSideBarMenu.module.scss';
import { NavLink } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import {MobileSideBarMenuLoginButton} from './MobileSideBarMenuLoginButton';
import {MobileSideBarMenuLogoutButton} from './MobileSideBarMenuLogoutButton';
import { useTranslation } from 'react-i18next';
import { useAuthSelector } from '../../features/auth/authSlice';
import { useFeatureFlag } from '../../features/featureFlags/FeatureFlag';

export interface MobileSideBarProps {
    closeSideBar(): void;
    visible: boolean;
    isSignedIn: boolean;
}

const MenuLinks = ({ closeSideBar } : { closeSideBar: () => void }) => {
    const { t } = useTranslation();
    return (
        <>
            <NavLink className={styles.mobileSideBarMenuItemContainer} exact to="/" onClick={closeSideBar}>
                <div className={styles.mobileSideBarMenuItemLink}>{t('Menu.Home')}</div>
            </NavLink>
            <NavLink className={styles.mobileSideBarMenuItemContainer} exact to="/residents" onClick={closeSideBar}>
                <div className={styles.mobileSideBarMenuItemLink}>{t('Menu.Resident_plural')}</div>
            </NavLink>
            <NavLink className={styles.mobileSideBarMenuItemContainer} exact to="/business" onClick={closeSideBar}>
                <div className={styles.mobileSideBarMenuItemLink}>{t('Menu.Business')}</div>
            </NavLink>
            <NavLink className={styles.mobileSideBarMenuItemContainer} exact to="/visitors" onClick={closeSideBar}>
                <div className={styles.mobileSideBarMenuItemLink}>{t('Menu.Visitor_plural')}</div>
            </NavLink>
            <NavLink className={styles.mobileSideBarMenuItemContainer} exact to="/government" onClick={closeSideBar}>
                <div className={styles.mobileSideBarMenuItemLink}>{t('Menu.Government')}</div>
            </NavLink>
            <NavLink className={styles.mobileSideBarMenuItemContainer} exact to="/about" onClick={closeSideBar}>
                <div className={styles.mobileSideBarMenuItemLink}>{t('Menu.AboutElgin')}</div>
            </NavLink>
        </>
    );
}

export function MobileSideBarMenu(props: MobileSideBarProps) {
    const { t } = useTranslation();
    const { authUser} = useAuthSelector(state => state);
    const waterBillPaymentRecordsFF = useFeatureFlag('EnableWaterBillHistoryPage');
    const boardsAndCommissionsFF = useFeatureFlag('EnableBoardsAndCommissions');

    return (
        <Menu isOpen={props.visible} className={styles.mobileSideBar} customCrossIcon={ false } customBurgerIcon={false} onStateChange={(state) => !state.isOpen ? props.closeSideBar() : null} width={338} >
            <div className={styles.mobileSideBarHeader}>
                <div className={styles.mobileSideBarHeaderMenu}>{t('SideBar.Menu')}</div>
                <div className={styles.mobileSideBarHeaderClose} onClick={props.closeSideBar}>
                    <i className={`far fa-times`}></i>
                </div>
            </div>
            <div className={styles.mobileSideBarTop}>
                {!props.isSignedIn ?
                    <>
                        <MenuLinks closeSideBar={props.closeSideBar} />
                        <MobileSideBarMenuLoginButton onClick={props.closeSideBar} />
                    </>
                    :
                    <>
                        <NavLink className={styles.mobileSideBarMenuItemContainer} exact to="/dashboard" onClick={props.closeSideBar}>
                            <div className={`${styles.mobileSideBarMenuItemLink} ${styles.normalLinks}`}>
                                <i className={`far fa-home`}></i>{t('SideBar.Home')}
                            </div>
                        </NavLink>
                        <NavLink className={styles.mobileSideBarMenuItemContainer} exact to="/in_progress" onClick={props.closeSideBar}>
                            <div className={`${styles.mobileSideBarMenuItemLink} ${styles.normalLinks}`}>
                                <i className={`far fa-spinner`}></i>{t('SideBar.InProgress')}
                            </div>
                        </NavLink>
                        <NavLink className={styles.mobileSideBarMenuItemContainer} exact to="/completed_activites" onClick={props.closeSideBar}>
                            <div className={`${styles.mobileSideBarMenuItemLink} ${styles.normalLinks}`}>
                                <i className={`far fa-check-circle`}></i>{t('SideBar.CompletedActivity', { count: 2 })}
                            </div>
                        </NavLink>
                        {waterBillPaymentRecordsFF?.enabled &&
                            <NavLink className={styles.mobileSideBarMenuItemContainer} exact to="/payment/water-bill-payment-records" onClick={props.closeSideBar}>
                                <div className={`${styles.mobileSideBarMenuItemLink} ${styles.normalLinks}`}>
                                    <i className={`far fa-credit-card`}></i>{t('SideBar.Payment')}
                                </div>
                            </NavLink>
                        }
                        <NavLink className={styles.mobileSideBarMenuItemContainer} exact to={`/account/${authUser?.Id}`} onClick={props.closeSideBar}>
                            <div className={`${styles.mobileSideBarMenuItemLink} ${styles.normalLinks}`}>
                                <i className={`far fa-user-circle`}></i>{t('SideBar.MyProfile')}
                            </div>
                        </NavLink>
                        {boardsAndCommissionsFF?.enabled &&
                            <NavLink className={styles.mobileSideBarMenuItemContainer} exact to={"/boards-and-commissions"} onClick={props.closeSideBar}>
                                <div className={`${styles.mobileSideBarMenuItemLink} ${styles.normalLinks}`}>
                                    <i className={`far fa-users`}></i>{t('SideBar.BoardsAndCommissions')}
                                </div>
                            </NavLink>
                        }
                        {/* {(selectedOrganization === personalOrganizationKey) &&
                            <NavLink className={styles.mobileSideBarMenuItemContainer} exact to="/add_organization" onClick={props.closeSideBar}>
                            <div className={`${styles.mobileSideBarMenuItemLink} ${styles.normalLinks}`}>
                                <i className="far fa-users-cog" />
                                {t('SideBar.AddOrJoinOrganization')}
                            </div>
                        </NavLink>
                        }
                        {(selectedOrganization !== personalOrganizationKey) &&
                            <NavLink className={styles.mobileSideBarMenuItemContainer} exact to="/organization_management" onClick={props.closeSideBar}>
                            <div className={`${styles.mobileSideBarMenuItemLink} ${styles.normalLinks}`}>
                                <i className="far fa-users-cog" />
                                {t('SideBar.OrganizationManagement')}
                            </div>
                        </NavLink>
                        } */}
                        <MobileSideBarMenuLogoutButton onClick={props.closeSideBar} />
                    </>
                 }
            </div>
            <div className={styles.mobileSideBarBottom}>
                 {props.isSignedIn ?
                    <>
                        <div className={styles.mobileSideBarMenuOtherLinks}>{t('SideBar.OtherLinks')}</div>
                        <MenuLinks closeSideBar={props.closeSideBar} />
                    </>
                : null}
            </div>
        </Menu>
    );
}
