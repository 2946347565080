import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import { buildSubStateSelector } from "../../store/utilities/useAppStateSelector";

export interface SearchAppState {
    loading: boolean;
    searchTerm: string;
    page: number;
    total: number;
    searchResults: any[];
}

export const slice = createSlice<SearchAppState, SliceCaseReducers<SearchAppState>>({
    name: 'search',
    initialState: {
        loading: false,
        searchTerm: '',
        page: 0,
        total: 0,
        searchResults: []
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setSearchResults: (state, action) => {
            state.searchResults = action.payload.hits;
            state.total = action.payload.total;
        },
        setPage: (state, action) => {
            state.page = action.payload
        }
    }
});

export const useAppStateSearchSelector = buildSubStateSelector(state => state.search);
export const {
    setLoading,
    setSearchTerm,
    setSearchResults,
    setPage,
} = slice.actions;
export const searchReducer = slice.reducer;
