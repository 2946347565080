import React from 'react';
import { Link } from 'react-router-dom';
import styles from './LanguagesList.module.scss';
import { PanelRow } from '../../../components/panel/PanelRow';
import { Panel } from '../../../components/panel/Panel';
import { PageContainer } from '../../../components/pageContainer/PageContainer';

const languages = ['English', 'Spanish'];

const LanguageListings = languages.map((language, index) => {
    return (
        <div className="listItemColor" key={language}>
            <Link to={`/languages/${language}`} style={{color: 'inherit'}}>
                <div className={styles.list_item}>
                <div className={styles.item_text}>
                    {language}
                </div>
                </div>
            </Link>
        </div>
    )
});

export const LanguagesList = () => (
    <PageContainer>
        <h2>Language Management</h2>
        <PanelRow>
            <Panel>
                <div className='headerContainer'>
                    <h3>Languages</h3>
                </div>
                <div className={styles.listing_table}>
                    {LanguageListings}
                </div>
            </Panel>
        </PanelRow>
    </PageContainer>
);