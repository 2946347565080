import React, { useEffect } from 'react';
import { PanelRow } from '../../components/panel/PanelRow';
import { Panel } from '../../components/panel/Panel';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getElginToday } from './elginTodayActionCreators';
import { useAppStateElginTodaySelector } from './elginTodaySlice';
import 'moment-timezone';
import moment from 'moment';
import { PageContainer } from '../../components/pageContainer/PageContainer';
import { Spinner } from '../../components/spinner/Spinner';
import styles from './ElginToday.module.scss';
import { Loader } from 'semantic-ui-react';
import { Button } from '../../components/button/Button';

export const ElginToday = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { elginTodayPosts, elginTodayLoading, elginTodayEndReached } = useAppStateElginTodaySelector(state => state);
    useEffect(() => {
        if (!elginTodayPosts.length) {
            dispatch(getElginToday());
        }
    }, [dispatch, elginTodayPosts])
    return (
        <PageContainer style={{padding: '50px'}}>
            <div className='headerContainer'>
                <h2>{t('Menu.ElginToday')}</h2>
                <Spinner isLoading={elginTodayLoading && !elginTodayPosts.length} />
            </div>
            {(!elginTodayLoading && (!elginTodayPosts.length)) ?
                <PanelRow style={{ minHeight: '100px' }}>
                    <Panel>
                        <p>{t('ElginToday.NoNewUpdates')}</p>
                    </Panel>
                </PanelRow>
                :
                <>
                    {elginTodayPosts.map(post => {
                        let postContents = <p className={styles.content}>{post.Update__c}</p>;
                        if (post.Additional_Info__c) {
                            postContents = <a href={post.Additional_Info__c} target="_blank" rel="noopener noreferrer">{postContents}</a>
                        }
                        return (
                            <PanelRow key={post.Id} style={{ minHeight: '100px' }}>
                                <Panel>
                                    <div className={styles.innerPanelContainer}>
                                        <div className={styles.panelHeader}>
                                            <small>{post.Category__c}</small>
                                            <small className={styles.date}>
                                                {moment(post.Show_On__c).tz("America/Chicago").format('MM-DD-YY h:mm A')}
                                            </small>
                                        </div>
                                        {postContents}
                                    </div>
                                </Panel>
                            </PanelRow>
                        );
                    })}
                    {elginTodayPosts.length !== 0 ?
                        elginTodayEndReached ? 
                        (
                            <div className={styles.buttonContainer}>
                                <Button
                                    onClick={() => dispatch(getElginToday())}
                                    loading={elginTodayLoading}
                                    disabled={true}
                                >
                                    {t('ElginToday.EndReached')}
                                </Button>
                            </div>
                        )
                        :
                        (
                            <div className={styles.buttonContainer}>
                                <Button
                                    onClick={() => dispatch(getElginToday())}
                                    loading={elginTodayLoading}
                                    disabled={elginTodayLoading}
                                >
                                    {t('ElginToday.LoadMore')}
                                </Button>
                            </div>
                        )
                    : null }
                    <Loader active={elginTodayLoading && elginTodayPosts.length !== 0} />
                </>
            }
        </PageContainer>
    )
}
