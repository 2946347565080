import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit'; 
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';

const initialState = {
    messageModalOpen: false,
    header: 'N/A',
    body: 'N/A',
    image: 'warning',
    buttonColor: 'red',
    firstButtonText: 'Notification.Close',
    containsTwoButtons: false,
    caseNumber: '',
    paymentNumber: ''
};

export type MessageModalAppState = typeof initialState;

export const slice = createSlice<MessageModalAppState, {
    setMessageModalOpen: CaseReducer<MessageModalAppState, PayloadAction<boolean>>,
    setHeader: CaseReducer<MessageModalAppState, PayloadAction<string>>,
    setBody: CaseReducer<MessageModalAppState, PayloadAction<string>>,
    setImage: CaseReducer<MessageModalAppState, PayloadAction<string>>,
    setContainsTwoButtons: CaseReducer<MessageModalAppState, PayloadAction<boolean>>,
    setButtonColor: CaseReducer<MessageModalAppState, PayloadAction<string>>,
    setFirstButtonText: CaseReducer<MessageModalAppState, PayloadAction<string>>,
    setCaseNumber: CaseReducer<MessageModalAppState, PayloadAction<string>>,
    setPaymentNumber: CaseReducer<MessageModalAppState, PayloadAction<string>>,
}>({
    name: 'messageModal',
    initialState,
    reducers: {
        setMessageModalOpen: (state, { payload }) => {
            state.messageModalOpen = payload;
        },
        setHeader: (state, { payload }) => {
            state.header = payload;
        },
        setBody: (state, { payload }) => {
            state.body = payload;
        },
        setImage: (state, { payload }) => {
            state.image = payload;
        },
        setContainsTwoButtons: (state, { payload }) => {
            state.containsTwoButtons = payload;
        },
        setButtonColor: (state, { payload }) => {
            state.buttonColor = payload;
        },
        setFirstButtonText: (state, { payload }) => {
            state.firstButtonText = payload;
        },
        setCaseNumber: (state, { payload }) => {
            state.caseNumber = payload;
        },
        setPaymentNumber: (state, { payload }) => {
            state.paymentNumber = payload;
        },
    }
});

export const useAppStateMessageModalSelector = buildSubStateSelector<MessageModalAppState>(state => state.messageModal);
export const messageModalActions = slice.actions;
export const messageModalReducer = slice.reducer;