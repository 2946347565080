import React, { FunctionComponent, useState, useEffect, useRef } from "react"
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { NavilinePaymentHistory, Payment } from "../../models/navilinePaymentHistory";
import styles from './BillPayGraph.module.scss';
import { VictoryChart, VictoryLine, VictoryAxis, VictoryGroup, VictoryTooltip, VictoryVoronoiContainer, VictoryScatter } from 'victory';

export interface GraphProps {
    paymentHistory: NavilinePaymentHistory[] | null | undefined;
}

const MIN_DESKTOP_WIDTH = 1236;

const roundUp = (toRound: number, roundingTarget: number) => Math.ceil(toRound / roundingTarget) * roundingTarget;

export const BillPayGraph: FunctionComponent<GraphProps> = ({paymentHistory}) => {
    const { t } = useTranslation();
    let targetRef = useRef<HTMLDivElement | null>(null);
    const [dimensions, setDimensions] = useState<{width: any, height: number}>({ width:0, height: 400 });
    const [refVisible, setRefVisible] = useState(false)

    const onResize = () => {
        const docSize = document.querySelector('body');
        const chartContainer = document.querySelector('#chartContainer');
        const mobileChartContainer = document.querySelector('#chartContainerMobile');
        setDimensions({
            width: docSize!.clientWidth >= MIN_DESKTOP_WIDTH ? chartContainer!.clientWidth : mobileChartContainer!.clientWidth,
            height: docSize!.clientWidth >= MIN_DESKTOP_WIDTH ? chartContainer!.clientHeight : mobileChartContainer!.clientHeight,
        });
    }

    useEffect(() => {
        if (!refVisible) {
            return
        }
        onResize();
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize);
        });

    }, [refVisible]);

    const xTicks = () => {
        const ticks = [];
        const allPayments: Payment[] = [];
        paymentHistory?.map(ph => {
            allPayments.push(...ph.pastPayments);
        });

        if (allPayments.length > 0) {
            const max_month = moment(Math.max.apply(Math, allPayments.map(p => p.paymentDate.valueOf())));
            const min_month = moment(Math.min.apply(Math, allPayments.map(p => p.paymentDate.valueOf())));
            let month = max_month;
            // Start with the last month and work backwards, adding to the front of the array

            // allow for up to 18m on graph (used to properly display multiple billing accounts while testing)
            for (let i = 0; i < 18; i++) {
                ticks.unshift(month.valueOf());
                month = month.subtract(1, 'M');

                // if all months are displayed, break after 1y on graph
                if ((month < min_month) && (i >= 12)) break;
            }
        }
        return ticks;
    };

    const formatXAxis = (tickItem: number) => {
        return moment(tickItem).format('MMM');
    }

    const formatYAxis = (tickItem: number) => {
        if (tickItem === 0) {
            return '';
        }
        return `$${tickItem}`;
    }

    const getGraphData = (index: number) => {
        return paymentHistory![index].pastPayments.map((p) => {
            return {
                x: p.paymentDate,
                y: p.amount
            }
        });
    }

    if (paymentHistory === null) return (
        <div className={styles.graphNoData}>{t('Util.Loading')}...</div>
    )

    if (paymentHistory === undefined) return (
        <div className={styles.graphNoData}>{t('Dashboard.PaymentHistoryError')}</div>
    )

    if (!paymentHistory[0].pastPayments.length) return (
        <div className={styles.graphNoData}>{t('Dashboard.PaymentHistoryEmpty')}</div>
    )

    // This is a fairly arbitrary choice, but if it wasn't getting multiplied and rounded up, the highest bill
    // would look like they it was as high as they could go
    const domainEnd = roundUp(Math.max(...paymentHistory[0].pastPayments.map(p => p.amount)) * 1.5, 50);

    return (
        <div style={{flex: 1}} ref={el => { targetRef.current = el; setRefVisible(!!el); }}>
            <h3 className={styles.graphHeader}>{t("Dashboard.WaterBillingHistory")}</h3>
            <VictoryChart
                height={dimensions.height} width={dimensions.width}
                containerComponent={<VictoryVoronoiContainer responsive={false} width={dimensions.width}/>}
            >
                <VictoryAxis
                    dependentAxis
                    domain={[0, domainEnd]}
                    tickFormat={formatYAxis}
                    style={{
                        grid: { stroke: "#f0f0f0" },
                        tickLabels: { fill: '#707070', fontFamily: 'Avenir', fontSize: 14 },
                        axis: { stroke: '#C1C1C1', strokeWidth: 2 },
                    }}
                />
                <VictoryAxis
                    scale='time'
                    tickValues={xTicks()}
                    tickFormat={(x) => formatXAxis(x)}
                    style={{
                        tickLabels: {angle: -45, fill: '#707070', fontFamily: 'Avenir', fontSize: 14 },
                        axis: { stroke: '#C1C1C1', strokeWidth: 2 },
                    }}
                />
                {paymentHistory.length && paymentHistory.map((ph, i) => (
                        <VictoryGroup
                            data={getGraphData(i)}
                            key={i}
                            labels={({datum}) => `$${datum.y.toFixed(2)}`}
                            labelComponent={<VictoryTooltip/>}
                        >
                            <VictoryLine
                                interpolation='natural'
                                animate={{
                                    duration: 2000,
                                    onLoad: { duration: 1000 }
                                }}
                                style={{
                                    data: { stroke: "#77974C", strokeWidth: 1 }
                                }}
                            />
                            <VictoryScatter
                                size={4}
                                style={{
                                    data: {
                                        fill: 'white',
                                        stroke: "#77974C",
                                        strokeWidth: 1.0
                                    }
                                }}
                                labelComponent={<VictoryTooltip/>}
                            />
                        </VictoryGroup>
                ))}
            </VictoryChart>
        </div>
    )
}
