import React, { FunctionComponent } from 'react';
import styles from './Textarea.module.scss';
import { TextArea as SemanticTextArea } from 'semantic-ui-react'

export interface TextAreaProps  { 
    placeholder?: string;
    onChange(event: any, data: any): void;
    value: string;
}

export const Textarea: FunctionComponent<TextAreaProps> = ({placeholder, onChange, value}) => {
    return (
        <SemanticTextArea className={`${styles.textarea}`} placeholder={placeholder} onChange={onChange} value={value} />
    );
}

Textarea.defaultProps = {

};