import React, { MouseEvent, useEffect } from 'react';
import styles from './Messages.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppStateMessagesSelector } from './messagesSlice';
import { useDispatch } from 'react-redux';
import { deleteMessage, loadActivityMessages, markRead } from './messagesActionCreators';
import { Spinner } from '../../components/spinner/Spinner';
import { WebMessages, MobileMessages } from './Messages';

export interface ActivityMessagesProps {
    sfId: string;
}

export const ActivityMessages = ({ sfId }: ActivityMessagesProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        activityMessages,
        loadMessagesError,
        deleteMessageError,
        loading
    } = useAppStateMessagesSelector(state => state);

    useEffect(() => {
        dispatch(loadActivityMessages(sfId));
    }, [dispatch, sfId]);

    const onMessageClick = (messageId: string) => {
        dispatch(markRead(messageId, sfId));
    }
    const onTrashClick = (e: MouseEvent, messageId: string) => {
        dispatch(deleteMessage(messageId, sfId));
        e.stopPropagation();
    }
    const sortedMessages = activityMessages.slice().sort((a, b) => Date.parse(b.CreatedAt) - Date.parse(a.CreatedAt));
    return (
        <>
            <div className="headerContainer">
                <h3>{t('Menu.Message_plural')}</h3>
                <Spinner isLoading={loading} />
            </div>
            {loadMessagesError !== '' &&
                <p className={styles.errorText}>{loadMessagesError}</p>
            }
            {deleteMessageError !== '' &&
                <p className={styles.errorText}>{deleteMessageError}</p>
            }
            {activityMessages.length ? (
                <>
                    <WebMessages messages={sortedMessages} onMessageClick={onMessageClick} onTrashClick={onTrashClick} />
                    <MobileMessages messages={sortedMessages} onMessageClick={onMessageClick} />
                </>
            ) : <p>{t('Message.NoMessages')}</p>
            }
        </>
    );
}