import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { OrganizationSearchAppState } from './organizatioSearchAppState';
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';

const initialState = {
    organizationSearch: '',
    showAddOrganization: false,
    searchLoading: false,
    searchResults: [],
    searchValue: '',
    myOrganizations: [],
    organizationList: [],
    loading: false,
    error: false,
    newOrganization: {
        name: '',
        taxId: '',
        businessLicenseId: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        invalidName: false,
        invalidTaxId: false,
        invalidAddress1: false,
        invalidCity: false,
        invalidState: false,
        invalidZip: false,
    },
};

export const slice = createSlice<OrganizationSearchAppState, SliceCaseReducers<OrganizationSearchAppState>>({
    name: 'accountCreate',
    initialState,
    reducers: {
        resetState: (state, action) => {
            state.organizationSearch = initialState.organizationSearch;
            state.showAddOrganization = initialState.showAddOrganization;
            state.searchLoading = initialState.searchLoading;
            state.searchResults = initialState.searchResults;
            state.searchValue = initialState.searchValue;
            state.myOrganizations = initialState.myOrganizations;
            state.organizationList = initialState.organizationList;
            state.newOrganization = initialState.newOrganization;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setOrganizationSearch: (state, action) => {
            state.organizationSearch = action.payload.value;
        },
        setBusinessName: (state, action) => {
            state.newOrganization.name = action.payload.value;
        },
        setBusinessTaxId: (state, action) => {
            state.newOrganization.taxId = action.payload.value;
        },
        setBusinessLicenseId: (state, action) => {
            state.newOrganization.businessLicenseId = action.payload.value;
        },
        setBusinessAddress1: (state, action) => {
            state.newOrganization.address1 = action.payload.value;
        },
        setBusinessAddress2: (state, action) => {
            state.newOrganization.address2 = action.payload.value;
        },
        setBusinessCity: (state, action) => {
            state.newOrganization.city = action.payload.value;
        },
        setBusinessState: (state, action) => {
            state.newOrganization.state = action.payload.value;
        },
        setBusinessZip: (state, action) => {
            state.newOrganization.zip = action.payload.value;
        },
        setInvalidBusinessName: (state, action) => {
            state.newOrganization.invalidName = action.payload;
        },
        setInvalidBusinessTaxId: (state, action) => {
            state.newOrganization.invalidTaxId = action.payload;
        },
        setInvalidBusinessLicenseId: (state, action) => {
            state.newOrganization.invalidTaxId = action.payload;
        },
        setInvalidBusinessAddress1: (state, action) => {
            state.newOrganization.invalidAddress1 = action.payload;
        },
        setInvalidBusinessCity: (state, action) => {
            state.newOrganization.invalidCity = action.payload;
        },
        setInvalidBusinessState: (state, action) => {
            state.newOrganization.invalidState = action.payload;
        },
        setInvalidBusinessZip: (state, action) => {
            state.newOrganization.invalidZip = action.payload;
        },
        setSearchLoading: (state, action) => {
            state.searchLoading = action.payload;
        },
        setSearchResults: (state, action) => {
            state.searchResults = action.payload;
            state.searchLoading = false;
        },
        setSearchValue: (state, action) => {
            state.searchValue = action.payload;
        },
        toggleAddOrganization: (state) => {
            state.showAddOrganization = !state.showAddOrganization;
            state.searchValue = ""; // Always clear search bar on toggle
        },
        addExistingOrganization: (state, action) => {
            state.myOrganizations.push(action.payload);
        },
        addOrganization: (state) => {
            state.myOrganizations.push(state.newOrganization);
            state.newOrganization = {
                name: '',
                taxId: '',
                businessLicenseId: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                invalidName: false,
                invalidTaxId: false,
                invalidAddress1: false,
                invalidCity: false,
                invalidState: false,
                invalidZip: false,
            };
        },
        removeOrganization: (state, action) => {
            state.myOrganizations = state.myOrganizations.filter(c => c.taxId !== action.payload);
        },
    }
});

export const useAppStateOrganizationSearchSelector = buildSubStateSelector(state => state.organizationSearch);
export const {
    resetState,
    setLoading,
    setError,
    setOrganizationSearch,
    setBusinessName,
    setBusinessTaxId,
    setBusinessLicenseId,
    setBusinessAddress1,
    setBusinessAddress2,
    setBusinessCity,
    setBusinessState,
    setBusinessZip,
    setInvalidBusinessName,
    setInvalidBusinessTaxId,
    setInvalidBusinessLicenseId,
    setInvalidBusinessAddress1,
    setInvalidBusinessCity,
    setInvalidBusinessState,
    setInvalidBusinessZip,
    setSearchLoading,
    setSearchResults,
    setSearchValue,
    toggleAddOrganization,
    addExistingOrganization,
    addOrganization,
    removeOrganization,
    setFirstName,
    setLastName,
    setPhoneNumber,
    setInvalidFirstName,
    setInvalidLastName,
    setInvalidPhone,
} = slice.actions;
export const organizationSearchReducer = slice.reducer;
