import React, { useEffect } from 'react';
import { Dropdown, DropdownOptions } from '../../../../components/dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setGarbageRecyclingMissedType, useAppStateReportSelector, setCategoryCanSubmit } from '../reportSlice';

export const GarbageRecyclingMissed = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const state = useAppStateReportSelector(state => state.garbageRecyclingMissed);

    const missedOptions: DropdownOptions[] = [
        {
            value: "REFUSE",
            key: "REFUSE",
            text: t('Report.Refuse'),
        },
        {
            value: "RECYCLING",
            key: "RECYCLING",
            text: t('Report.Recycling'),
        },
        {
            value: "YARD_WASTE",
            key: "YARD_WASTE",
            text: t('Report.YardWaste'),
        },
        {
            value: "ORGANICS",
            key: "ORGANICS",
            text: t('Report.Organics'),
        },
        {
            value: "CHRISTMAS_TREE",
            key: "CHRISTMAS_TREE",
            text: t('Report.ChristmasTree'),
        },
    ];

    useEffect(() => {
        if (state.type) {
            dispatch(setCategoryCanSubmit(true));
        }
        else {
            dispatch(setCategoryCanSubmit(false));
        }
    }, [dispatch, state.type]);

    return (
        <>
            {t('Report.WhatWasMissed')}
            <Dropdown
                placeholder={t('Report.WhatWasMissed')}
                options={missedOptions}
                value={state.type}
                onChange={(e: any, data: any) => dispatch(setGarbageRecyclingMissedType(data.value))}
            />
        </>
    )
}