import React from 'react';
import styles from './ActivitiesViewProgressBar.module.scss';
import { Arrow } from '../../../../components/arrow/Arrow';
import { useTranslation } from 'react-i18next';

export interface ActivitiesViewProgressBarProps {
    statusOptions: string[];
    currentStatus: string;
    useUpdatedStatusPath?: boolean;
}

const getStatusPath = (statusOptions: string[], currentStatus: string) => {
    const defaultEndState = 'Completed';
    let nonDefaultEndState = false;
    const endResults = statusOptions.filter(s => [defaultEndState, 'Closed', 'Cancelled'].includes(s));
    const path = statusOptions.filter(s => !endResults.includes(s));
    const endState = endResults.find(s => s === currentStatus);

    if (endState) {
        nonDefaultEndState = endState !== defaultEndState;
        path.push(endState);
    } else {
        path.push(defaultEndState);
    }

    return {
        path,
        nonDefaultEndState
    }
}

export const ActivitiesViewProgressBar = ({ statusOptions, currentStatus, useUpdatedStatusPath = false }: ActivitiesViewProgressBarProps) => {
    const { t } = useTranslation();
    let currentFound = false;
    let statusPath = statusOptions;
    let nonDefaultEndState = false;
    if (useUpdatedStatusPath && statusOptions && statusOptions.length) {
        const {path, nonDefaultEndState: ndes} = getStatusPath(statusOptions, currentStatus);
        console.log({path, ndes});
        statusPath = path;
        nonDefaultEndState = ndes;
    }

    return (
        <div className={styles.statusContainer}>
            {currentStatus ? 
                statusPath?.map((opt, i) => {
                    if (!currentFound) currentFound = opt === currentStatus;

                    let statusColor = "";
                    if (opt === currentStatus && i === statusPath.length-1) { // in the end status
                        statusColor = nonDefaultEndState ? styles.terminated : styles.past;
                    } else if (opt === currentStatus) { // in the active status
                        statusColor = styles.active;
                    } else if (!currentFound) { // in a past status
                        statusColor = styles.past;
                    } // in an unreached status (no additional css)
                    return (
                        <div className={styles.statusContainer} key={opt}>
                            <div className={` ${styles.statusBadge} ${statusColor}`}>
                                <p>{opt}</p>
                            </div>
                            { i + 1 < statusPath.length && <Arrow />}
                        </div>
                    )
                })
            :
                <div className={styles.statusContainer}>
                    <div className={`${styles.statusBadge}`}>
                        <p>{t('InProgress.None')}</p>
                    </div>
                </div>
            }
        </div>
    )
}