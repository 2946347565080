import { AnyAction, Dispatch } from "redux";
import { axios } from "../../services/axiosService";

import { BoardOrCommissionDetails, boardsAndCommissionsActions } from "./boardsAndCommissionsSlice";
import { openMessageModal } from "../../components/statusModal/messageModalActionCreators";

export const loadBoardOrCommissionDetails = () => async (dispatch: Dispatch) => {
    try {
        dispatch(boardsAndCommissionsActions.setLoading(true));

        const apiResponse = (await axios.get("/salesforce/boardsAndCommissions/details")).data as BoardOrCommissionDetails[];

        dispatch(boardsAndCommissionsActions.setDetails(apiResponse));
    } catch {
        dispatch(openMessageModal(null, "error") as unknown as AnyAction);
    } finally {
        dispatch(boardsAndCommissionsActions.setLoading(false));
    }
};