import React, { FunctionComponent, useState, useEffect, useRef } from "react"
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { NavilinePaymentHistoryV2, Payment } from "../../models/navilinePaymentHistory";
import styles from './BillPayGraph.module.scss';
import { VictoryChart, VictoryAxis, VictoryTooltip, VictoryVoronoiContainer, VictoryBar, VictoryStack } from 'victory';

export interface GraphProps {
    paymentHistory: NavilinePaymentHistoryV2 | null | undefined;
}

const MIN_DESKTOP_WIDTH = 1236;

const roundUp = (toRound: number, roundingTarget: number) => Math.ceil(toRound / roundingTarget) * roundingTarget;

class CustomFlyout extends React.Component<any> {
    render() {
      const {x, y, orientation, datum} = this.props;
      const newY = orientation === "bottom" ? y - 55 : y + 55;
      const newYTriangle = orientation === "bottom" ? y - 13 : y + 13;
      return (
        <g>
            {/* Dialog box */}
            <rect x={x - 20} y={newY - 30} width="138" height="68" rx="3" ry="3" fill="#f4f4f4" stroke='black' />
            <polygon points={`${x},${newYTriangle - 5} ${x + 20},${newYTriangle - 5} ${x + 10},${newYTriangle + 5}`} fill="#f4f4f4" stroke='black' />
            <polygon points={`${x},${newYTriangle - 7} ${x + 20},${newYTriangle - 7} ${x + 10},${newYTriangle + 5}`} fill="#f4f4f4" />
            <rect x={x - 1} y={newY + 33} width="23" height="6" fill="#f4f4f4" rx="15" />
            {/* Labels */}
            <text x={x - 10} y={newY - 10} fill="#302E2E" fontFamily="Lato" fontSize="14px" fontStyle="bold" fontWeight="900">${datum.y.toFixed(2)}</text>
            <text x={x - 10} y={newY + 5} fill="#302E2E" fontFamily="Lato" fontSize="9px" fontStyle="normal" fontWeight="500">{datum.gallons ? `Water usage: ${datum.gallons.toLocaleString("en-US")} Gal. used` : `Water data unavailable`}</text>
            {datum.billedUnits &&
                <text x={x - 10} y={newY + 18} fill="#302E2E" fontFamily="Lato" fontSize="9px" fontStyle="normal" fontWeight="500">{`Units billed: ${datum.billedUnits.toLocaleString("en-US")}`}</text>
            }
            <text x={x - 10} y={newY + 30} fill="#302E2E" fontFamily="Lato" fontSize="7px" fontStyle="italic" fontWeight="400">{moment(datum.x).format('MMMM, YYYY')}</text>
        </g>
      );
    }
}

export const BillPayGraphV2: FunctionComponent<GraphProps> = ({paymentHistory}) => {
    const { t } = useTranslation();
    let targetRef = useRef<HTMLDivElement | null>(null);
    const [dimensions, setDimensions] = useState<{width: any, height: number}>({ width:0, height: 400 });
    const [refVisible, setRefVisible] = useState(false);

    const onResize = () => {
        const docSize = document.querySelector('body');
        const chartContainer = document.querySelector('#chartContainer');
        const mobileChartContainer = document.querySelector('#chartContainerMobile');
        setDimensions({
            width: docSize!.clientWidth >= MIN_DESKTOP_WIDTH ? chartContainer!.clientWidth : mobileChartContainer!.clientWidth,
            height: docSize!.clientWidth >= MIN_DESKTOP_WIDTH ? chartContainer!.clientHeight : mobileChartContainer!.clientHeight,
        });
    }

    useEffect(() => {
        if (!refVisible) {
            return
        }
        onResize();
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize);
        });

    }, [refVisible]);

    const xTicks = () => {
        const ticks = [];
        const allPayments: Payment[] = [...paymentHistory!.pastPayments];

        if (allPayments.length > 0) {
            const max_month = moment(Math.max.apply(Math, allPayments.map(p => p.paymentDate.valueOf()))).date(15);
            const min_month = moment(Math.min.apply(Math, allPayments.map(p => p.paymentDate.valueOf()))).date(15);
            let month = max_month;
            // Start with the last month and work backwards, adding to the front of the array

            // allow for up to 11m on graph
            for (let i = 0; i < 11; i++) {
                ticks.unshift(month.valueOf());
                month = month.subtract(1, 'M');

                // if all months are displayed, break after 1y on graph
                if ((month < min_month) && (i >= 12)) break;
            }
        }
        return ticks;
    };

    const formatXAxis = (tickItem: number) => {
        return moment(tickItem).format('MMM');
    }

    const formatYAxis = (tickItem: number) => {
        if (tickItem === 0) {
            return '';
        }
        return `$${tickItem}`;
    }

    const getGraphData = () => {
        let finalPaymentData;
        if (paymentHistory!.pastPayments.length > 11) {
            finalPaymentData = paymentHistory!.pastPayments.slice(-11);
        } else {
            finalPaymentData = paymentHistory!.pastPayments;
        }

        return finalPaymentData.map((p) => {
            // convert date to the 13th of every month
            const date = moment.utc(p.paymentDate).date(15).hour(0);
            const convertedTimestamp = date.unix() * 1000;
            return {
                x: convertedTimestamp,
                y: p.amount,
                gallons: p.gallons ? p.gallons : 0,
                billedUnits: p.billedUnits ? p.billedUnits : 0,
            };
        });
    };

    if (paymentHistory === null || paymentHistory?.pastPayments === null) return (
        <div className={styles.graphNoData}>{t('Util.Loading')}...</div>
    )

    if (paymentHistory === undefined || paymentHistory?.pastPayments === undefined) return (
        <div className={styles.graphNoData}>{t('Dashboard.PaymentHistoryError')}</div>
    )

    if (!paymentHistory.pastPayments.length) return (
        <div className={styles.graphNoData}>{t('Dashboard.PaymentHistoryEmpty')}</div>
    )

    // This is a fairly arbitrary choice, but if it wasn't getting multiplied and rounded up, the highest bill
    // would look like they it was as high as they could go
    const domainEnd = roundUp(Math.max(...paymentHistory.pastPayments.map(p => p.amount)) * 1.5, 50);

    return (
        <div style={{flex: 1}} ref={el => { targetRef.current = el; setRefVisible(!!el); }}>
            <h3 className={styles.graphHeaderV2}>{t('Dashboard.WaterBillingData')}</h3>
            <VictoryChart
                height={dimensions.height} width={dimensions.width}
                domainPadding={{ x: [10, 30] }}
                containerComponent={<VictoryVoronoiContainer 
                    voronoiDimension="x"
                    responsive={false} 
                    voronoiPadding={200}
                    width={dimensions.width}/>}
            >
                <VictoryAxis
                    dependentAxis
                    domain={[0, domainEnd]}
                    tickFormat={formatYAxis}
                    style={{
                        grid: { stroke: "#B5B5B5" },
                        tickLabels: { fill: '#302E2E', fontFamily: 'Avenir', fontSize: 14 },
                        axis: { stroke: '#C1C1C1', strokeWidth: 2 },
                    }}
                />
                <VictoryAxis
                    scale='time'
                    tickValues={xTicks()}
                    tickFormat={(x) => formatXAxis(x)}
                    style={{
                        tickLabels: {angle: -45, fill: '#302E2E', fontFamily: 'Avenir', fontSize: 14, fontWeight: '400' },
                        axis: { stroke: '#C1C1C1', strokeWidth: 2 },
                    }}
                />
                {paymentHistory.pastPayments.length &&
                    <VictoryStack
                        labels={({datum}) => ` `}
                        labelComponent={
                        <VictoryTooltip
                            flyoutComponent={<CustomFlyout/>}
                        />
                        }
                    >
                        <VictoryBar
                            data={getGraphData()}
                            barWidth={25}
                            alignment="start"
                            style={{
                                data: { fill: "#005EA9" },
                            }}
                        />
                    </VictoryStack>
                }
            </VictoryChart>
            <p className={styles.unitsBilledNote}>{t('Dashboard.UnitsBilledNote')}</p>
        </div>
    )
}
