import React, { FunctionComponent } from 'react';
import { Input, InputOnChangeData } from 'semantic-ui-react';
import styles from './SiteSearch.module.scss';

export interface SearchProps {
    onSearch: () => void;
    onChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        data: InputOnChangeData,
    ) => void;
    value: any;
    placeholder?: string;
    className?: string;
}

export const SiteSearch: FunctionComponent<SearchProps> = ({
    onSearch,
    ...props
}) => {
    const onKeyDown = (e: { key: string }) => {
        if (e.key === 'Enter') {
            onSearch();
        }
    };

    return (
        <div className={styles.searchWrapper}>
            <div className={styles.searchInputWrapper}>
                <Input
                    {...props}
                    onChange={props.onChange}
                    onKeyDown={onKeyDown}
                    className={styles.searchBar}
                    value={props.value}
                    placeholder={'Search...'}
                />
            </div>
            <div
                className={styles.searchIconWrapper}
                onClick={() => {
                    onSearch();
                }}
            >
                <div className={styles.searchIcon}>
                    <i className="far fa-search"></i>
                </div>
            </div>
        </div>
    );
};

SiteSearch.defaultProps = {
    placeholder: 'Search',
};
