import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Modal } from "semantic-ui-react";
import { Icon } from "../../../components/icon/Icon";
import { PaymentusTokenizationForm } from "../../../components/paymentusTokenizationForm/PaymentusTokenizationForm";
import { axios } from "../../../services/axiosService";
import styles from './BillingSettings.module.scss';
import { loadPaymentProfile } from "./billingSettingsActionCreators";
import { setAuthNetModalOpen } from "./billingSettingsSlice";
import { openMessageModal } from "../../../components/statusModal/messageModalActionCreators";
import { AnyAction } from "redux";
import { CoreAddStoredPaymentForm } from "../../../components/coreIframe/CoreAddStoredPaymentForm";
import { useFeatureFlag } from "../../featureFlags/FeatureFlag";

interface AddCardModalProps {
    modalOpen: boolean;
}

export const AddCardModal = ({modalOpen}: AddCardModalProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [apiError, setApiError] = useState('');
    const coreFF = useFeatureFlag('EnableCORE');

    const onClose = () => {
        setApiError('');
        dispatch(setAuthNetModalOpen(false))
    }

    const submit = async (nonce?: string | object) => {
        setApiError('');

        try {
            const postData = typeof(nonce) == typeof('') ? {
                nonce,
            } : nonce;
            const postPaymentResponse = (await axios.post('/payment-methods', {
                ...(postData as object),
            }));

            if (postPaymentResponse.data && postPaymentResponse.data.success) {
                dispatch(loadPaymentProfile());
                dispatch(setAuthNetModalOpen(false))
                dispatch(openMessageModal('success-add', 'success') as unknown as AnyAction);
            } else {
                console.log(postPaymentResponse);
                setApiError(t('Payment.GeneralPaymentError'));
            }
        } catch (err) {
            console.error(err);
            // @ts-ignore
            if (err.messages && err.messages.resultCode) { // It's an Accept.dispatchData error
                setApiError(t('Payment.APICouldNotValidateError'));
            } else {
                setApiError(t('Payment.GeneralPaymentError'));
            }
        }
    }

    return (
        <Modal open={modalOpen} onClose={onClose} size="small">
            <Modal.Header>
                {t('Billing.AddNewPaymentMethod')}
                <div style={{'float': 'right'}}>
                    <Icon
                        icon='x'
                        color='black'
                        size="large"
                        onClick={onClose}
                    />
                </div>
            </Modal.Header>
            <Modal.Content>
                <div className={styles.modalContent}>
                    {(apiError !== '') &&
                        <p className={styles.errorMessage}>{apiError}</p>
                    }
                    {coreFF?.enabled ?
                        <CoreAddStoredPaymentForm height={570} />
                        :
                        <PaymentusTokenizationForm accountNumber={null} transactionType={'WALLET'} afterToken={submit} fullToken={true} /> 
                    }
                </div>
            </Modal.Content>
        </Modal>
    );
};