import { AnyAction, Dispatch } from 'redux';
import {
    setActivity,
    resetActivityEditState,
    setError,
    setLaserficheForms,
    setPopularServiceUrl,
} from './activitiesEditSlice';
import {axios} from '../../../services/axiosService';
import { AppState } from '../../../store/appState';
import { Activity } from '../list/activitiesListAppState';
import { LaserficheForm } from '../create/activitiesCreateAppState';
import { setLoading } from './activitiesEditSlice';
import { ActivitiesEditAppState } from './activitiesEditAppState';
import { sortBy } from 'lodash';
import { openMessageModal } from '../../../components/statusModal/messageModalActionCreators';

export const editActivity = (history: any, id: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const {
        name,
        type,
        order,
        description,
        published,
        salesforceObjectId,
        laserficheProcessId,
        externalLink,
        content,
        action,
        deleted,
        calendlyLink,
        paymentRequired,
        guestEnabled,
        toggleInAppBrowser,
        popularService,
        popularServiceOrder,
    } = state.activitiesEdit as ActivitiesEditAppState;
    try {
        dispatch(setLoading(true));

        const payload = {
            item: name,
            type: type.toUpperCase(),
            order,
            description,
            published,
            deleted,
            action: action.toUpperCase(),
            calendlyLink: calendlyLink ?? "",
            paymentRequired: paymentRequired ?? false,
            guestEnabled,
            toggleInAppBrowser: toggleInAppBrowser ?? false,
            popularService: popularService ?? false,
            popularServiceOrder: popularServiceOrder,
        };
        switch (type) {
            case 'CONTENT':
                Object.assign(payload, {content});
                break;
            case 'EXTERNAL_LINK':
                Object.assign(payload, {externalLink});
                break;
            case 'LASERFICHE_FORM':
                Object.assign(payload, {salesforceObjectId, laserficheProcessId});
        }

        const activityEditResponse = (await axios.put(`/activities/${id}`, payload)).data;
        console.log(activityEditResponse);
        let imagefile = document.getElementById('activityIconFile') as any;
        if (imagefile && popularService && imagefile.files.length) {
            let formData = new FormData();
            formData.append(`image`, imagefile.files[0]);
            const response = (await axios.put(`/activities/setPopularService/${id}`, formData)).data;
            dispatch(setPopularServiceUrl(response.urlResponse));
            console.log('Icon set to this image: ', response.urlResponse);
        }
        dispatch(resetActivityEditState({}));
        history.push('/activities');
        dispatch(openMessageModal('success-update', 'success') as unknown as AnyAction);
    } catch (err) {
        console.error(err);
        dispatch(openMessageModal('error-update', 'error') as unknown as AnyAction);
    } finally {
        dispatch(setLoading(false));
    }
};

export const getActivity = (id: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        dispatch(setLoading(true));
        const apiActivity = (await axios.get(`/activities/${id}`)).data as Activity;
        if (apiActivity.SalesforceObjectId) {
            getActivitySObjectFields(apiActivity.SalesforceObjectId)(dispatch);
        }
        dispatch(setActivity(apiActivity));
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        dispatch(setError('Activity could not be found'));
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
}

export const deleteActivity = (id: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        const activityDeleteResponse = (await axios.delete(`/activities/${id}`)).data;
        console.log(activityDeleteResponse);
        dispatch(openMessageModal('success-remove', 'success') as unknown as AnyAction);
    } catch (err) {
        dispatch(openMessageModal('error-remove', 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
};

export const getLaserficheForms = () => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        const getLaserficheFormsResponse = (await axios.get('/laserfiche/forms')).data as LaserficheForm;
        console.log(getLaserficheFormsResponse);
        dispatch(setLaserficheForms(getLaserficheFormsResponse));
    } catch (err) {
        console.error(err);
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
    } finally {
        dispatch(setLoading(false));
    }
}

export const getActivitySObjectFields = (sObjectType: string) => async (dispatch: Dispatch) => {
    try {
        const activityFields = (await axios.get(`/salesforce/activity/${sObjectType}/fields`)).data;
        const sortedFields = sortBy(activityFields, 'value');
        console.log(sortedFields);
        // dispatch(setActivitySObjectFieldOptions(sortedFields));
    } catch (err) {
        console.error(err);
    }
}
