
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import globalStyles from '../../app/App.module.scss';

export interface LabelProps {
    fieldDescription: string;
    reasoning?: Reasoning;
    requirements?: string[];
    darkRedError?: boolean;
}

export type Reasoning = 'Invalid' | 'Required' | 'RequirementsNotMet' | 'Match';

export const ErrorValidationLabel: FunctionComponent<LabelProps> = ({reasoning, fieldDescription, requirements, darkRedError }) => {
    const { t } = useTranslation();
    let label = '';

    switch (reasoning) {
        case 'Invalid':
            label = t('ErrorValidation.Invalid', {fieldDescription});
            break;
        case 'Required':
            label = t('ErrorValidation.Required', {fieldDescription});
            break;
        case 'RequirementsNotMet':
            label = t('ErrorValidation.RequirementsNotMet', {fieldDescription});
            break;
        case 'Match':
            label = t('ErrorValidation.Match', {fieldDescription});
            break;
    }
    return (
        <label className={darkRedError ? globalStyles.darkRedErrorMessage : globalStyles.errorMessage}>
            {label}
            { requirements?.length && reasoning === 'RequirementsNotMet' ?
                <ul>
                    {requirements?.map(req => <li key={req}>{t(req)}</li>)}
                </ul>
            : null }
        </label>
    )
};

ErrorValidationLabel.defaultProps = {
    reasoning: 'Required'
}