import React, { useEffect } from 'react';
import { Dropdown, DropdownOptions } from '../../../../components/dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setGarbageRecyclingGeneralInformation, useAppStateReportSelector, setCategoryCanSubmit } from '../reportSlice';

export const GarbageRecyclingGeneral = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const state = useAppStateReportSelector(state => state.garbageRecyclingGeneral);

    const additionalInformationOptions: DropdownOptions[] = [
        {
            value: "BULK_ITEM",
            key: "BULK_ITEM",
            text: t('Report.BulkItems'),
        },
        {
            value: "APPLIANCE",
            key: "APPLIANCE",
            text: t('Report.Appliance'),
        },
        {
            value: "TIRES",
            key: "TIRES",
            text: t('Report.Tires'),
        },
    ];

    useEffect(() => {
        if (state.additionalInformation) {
            dispatch(setCategoryCanSubmit(true));
        }
        else {
            dispatch(setCategoryCanSubmit(false));
        }
    }, [dispatch, state.additionalInformation]);

    return (
        <>
            {t('Report.WhatTypeOfItemsNeedToBeCollected')}
            <Dropdown
                placeholder={t('Report.WhatTypeOfItemsNeedToBeCollected')}
                options={additionalInformationOptions}
                value={state.additionalInformation}
                onChange={(e: any, data: any) => dispatch(setGarbageRecyclingGeneralInformation(data.value))}
            />
            <br />
            {state.additionalInformation === "BULK_ITEM" ?
                <p>{t('Report.BulkItemCollectionDescription')}</p>
            : state.additionalInformation === "APPLIANCE" ?
                <>
                    <p>{t('Report.PlaceApplianceCurbsideTime')}</p>
                    <p>{t('Report.ApplianceStickersRequired')}</p>
                    <ul>
                        <li>{t('Report.HotWaterHeater_plural')}</li>
                        <li>{t('Report.Dishwasher_plural')}</li>
                        <li>{t('Report.Refrigerator_plural')}</li>
                        <li>{t('Report.Freezer_plural')}</li>
                        <li>{t('Report.Stove_plural')}</li>
                        <li>{t('Report.Washer_plural')}</li>
                        <li>{t('Report.Dryer_plural')}</li>
                        <li>{t('Report.Dehumidifier_plural')}</li>
                        <li>{t('Report.AirConditioner_plural')}</li>
                        <li>{t('Report.WindowACUnit_plural')}</li>
                        <li>{t('Report.Microwave')}</li>
                    </ul>
                </>
            : state.additionalInformation === "TIRES" ?
                <>
                    <p>{t('Report.RimRemovedAndStickered')}</p>
                    <p>{t('Report.TruckTiresInstructions')}</p>
                </>
            : null}
        </>
    )
}