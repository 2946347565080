import { Dispatch, AnyAction } from 'redux';
import { axios } from '../../services/axiosService';
import { AppState } from '../../store/appState';
import { Message } from './messagesAppState';
import { setLoadMessagesError, setDeleteMessageError, setMessages, setLoading, setActivityMessages } from './messagesSlice';
import { openMessageModal } from '../../components/statusModal/messageModalActionCreators';

export const loadMessages = () => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        dispatch(setLoading(true));
        const getMessagesResponse = (await axios.get('/notifications')).data as Message[];
        dispatch(setMessages(getMessagesResponse));
        dispatch(setLoadMessagesError(''));
    } catch (err) {
        dispatch(setLoadMessagesError('Message.LoadMessagesError'));
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
    } finally {
        dispatch(setLoading(false));
    }
};

export const deleteMessage = (id: string, sfId?: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        await axios.delete(`/notifications/${id}`);
        if (sfId) {
            dispatch(loadActivityMessages(sfId) as unknown as AnyAction);
        }
        else {
            dispatch(loadMessages() as unknown as AnyAction);
        }
        dispatch(setDeleteMessageError(''));
        dispatch(openMessageModal('success-remove', 'success') as unknown as AnyAction);
    } catch(err) {
        dispatch(openMessageModal('error-remove', 'error') as unknown as AnyAction);
        dispatch(setDeleteMessageError('Message.DeleteMessageError'));
    }
}

export const markRead = (id: string, sfId?: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        await axios.put(`/notifications/${id}/read`);
        if (sfId) {
            dispatch(loadActivityMessages(sfId) as unknown as AnyAction);
        }
        else {
            dispatch(loadMessages() as unknown as AnyAction);
        }
    } catch(err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error('Failed to mark as read');
    }
}

export const loadActivityMessages = (sfId: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        dispatch(setLoading(true));
        const getMessagesResponse = (await axios.get(`/notifications?sfId=${sfId}`)).data as Message[];
        dispatch(setActivityMessages(getMessagesResponse));
        dispatch(setLoadMessagesError(''));
    } catch (err) {
        dispatch(setLoadMessagesError('Message.LoadMessagesError'));
    } finally {
        dispatch(setLoading(false));
    }
};