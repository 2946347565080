import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import { buildSubStateSelector } from "../../store/utilities/useAppStateSelector";

export interface GarbageSearchRecord {
    Parcel_Address__c: string;
    Refuse_Pickup_Day__c: string;
    isDelayedFromHoliday: boolean;
    holidayName: string;
}

export interface SearchAppState {
    streetNumber: string;
    street: string;
}

export interface LoadingAppState {
    isLoading: boolean;
}

export interface ModalAppState {
    isOpen: boolean;
}

interface DaysOfWeek {
    day: string;
    date: string;
}

export interface Holidays {
    holiday: string;
    date: string;
}

export const daysOfWeek: DaysOfWeek[] = [
    { day: "Sunday", date: "" },
    { day: "Monday", date: "" },
    { day: "Tuesday", date: "" },
    { day: "Wednesday", date: "" },
    { day: "Thursday", date: "" },
    { day: "Friday", date: "" },
    { day: "Saturday", date: "" }
];

export const holidays: Holidays[] = [
    { holiday: "New Years Day", date: moment().format("MM") === "12" ? `${moment().year() + 1}-01-01` : `${moment().year()}-01-01` },
    { holiday: "Memorial Day", date: "" }, // Floating Monday
    { holiday: "Independence Day", date: `${moment().year()}-07-04` },
    { holiday: "Labor Day", date: "" },  // Floating Monday
    { holiday: "Thanksgiving Day", date: "" }, // Floating Thursday
    { holiday: "Christmas Day", date: `${moment().year()}-12-25` }
];

const initialState = {
    records: [] as GarbageSearchRecord[],
    searchTerm: {
        streetNumber: "",
        street: "",
    } as SearchAppState,
    loading: { isLoading: false } as LoadingAppState,
    modal: { isOpen: false } as ModalAppState,
    details: {} as GarbageSearchRecord
};

export type GarbageSearchAppState = typeof initialState;

export const slice = createSlice<
    GarbageSearchAppState,
    {
        setRecord: CaseReducer<
            GarbageSearchAppState,
            PayloadAction<GarbageSearchRecord[]>
        >;
        setSearchTerm: CaseReducer<
            GarbageSearchAppState,
            PayloadAction<SearchAppState>
        >;
        setLoading: CaseReducer<
            GarbageSearchAppState,
            PayloadAction<LoadingAppState>
        >;
        setModal: CaseReducer<
            GarbageSearchAppState,
            PayloadAction<ModalAppState>
        >;
        setDetails: CaseReducer<
            GarbageSearchAppState,
            PayloadAction<GarbageSearchRecord>
        >;
    }
>({
    name: "garbageSearch",
    initialState,
    reducers: {
        setRecord: (state, { payload }) => {
            state.records = payload;
        },
        setSearchTerm: (state, { payload }) => {
            state.searchTerm = payload;
        },
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setModal: (state, { payload }) => {
            state.modal = payload;
        },
        setDetails: (state, { payload }) => {
            state.details = payload;
        }
    }
});

export const useAppStateGarbageSearchSelector = 
    buildSubStateSelector<GarbageSearchAppState>(
        (state) => state.garbageSearch
    );
export const garbageSearchActions = slice.actions;
export const garbageSearchReducer = slice.reducer;
