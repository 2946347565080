/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { PageContainer } from '../../components/pageContainer/PageContainer';
import { useAppStatePaymentSelector } from './paymentSlice';
import styles from './Payment.module.scss';
import { useTranslation } from 'react-i18next';
import { navilinePaymentTypes } from '../../constants';
import { PanelRow } from '../../components/panel/PanelRow';
import { Panel } from '../../components/panel/Panel';
import { useDispatch } from 'react-redux';
import { getPaymentusIframeURL, loadPaymentProfile } from './paymentActionCreators';
import { Button } from '../../components/button/Button';
import { Checkbox } from '../../components/checkbox/Checkbox';
import { PaymentusTokenizationForm } from '../../components/paymentusTokenizationForm/PaymentusTokenizationForm';
import { useHistory, useLocation } from 'react-router-dom';
import { axios } from '../../services/axiosService';
import { useQuery } from '../../hooks/useQuery';
import { useAuthSelector } from '../auth/authSlice';
import { Spinner } from '../../components/spinner/Spinner';
import { useFeatureFlag } from '../featureFlags/FeatureFlag';
import { openMessageModal } from '../../components/statusModal/messageModalActionCreators';

interface PaymentSuccessDetails {
    amount: number;
    paymentType: string;
    authNetTransId: string;
    navilineRoutingIds: string[];
}

interface ItemizedBillItem {
    applicationName: string;
    applicationNumber: string;
    applicationFee?: number;
};

const PaymentConfirmation = ({ successDetails }: { successDetails: PaymentSuccessDetails }) => {
    const { amount, paymentType, authNetTransId, navilineRoutingIds } = successDetails;
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <div className={styles.paymentConfirmationContainer}>
            <h3>
                <span className={`far fa-check-circle ${styles.successIcon}`} />
                {t('Payment.ThankYouForPayment')}
            </h3>
            {amount &&
                <p className={styles.confirmationDetail}><b>{t('Payment.PaymentAmount')}: </b>${amount.toFixed(2)}</p>
            }
            {paymentType &&
                <p className={styles.confirmationDetail}><b>{t('Payment.PaymentType')}: </b>{paymentType}</p>
            }
            
            {authNetTransId &&
                <p className={styles.confirmationDetail}><b>{t('Payment.TransactionId')}: </b>{authNetTransId}</p>
            }
            { navilineRoutingIds && navilineRoutingIds.length !== 0 &&
                <p className={styles.confirmationDetail}><b>{t('Payment.RoutingId')}: </b>{navilineRoutingIds.join(', ')}</p>
            }
            <Button
                onClick={() => history.replace('/dashboard')}
                color={'blue'}
            >
                {t('Dashboard.BackToDashboard')}
            </Button>
        </div>
    )
}

enum PaymentStatus {
    NONE = 'NONE',
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
}

export const PaymentLPI = () => {
    const dispatch = useDispatch();
    const lpiFF = useFeatureFlag('EnableLPI');
    const history = useHistory();
    const { t } = useTranslation();
    const { search } = useLocation();
    const [apiError] = useState('');
    const [autoPay, setAutoPay] = useState<boolean>(false);
    const [autoPayDisabled, setAutoPayDisabled] = useState<boolean>(false);
    const [successDetails, setSuccessDetails] = useState<PaymentSuccessDetails>();
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(PaymentStatus.NONE);
    const [itemizedBill, setItemizedBill] = useState<ItemizedBillItem[]>([]);
    const querystring = useQuery();
    const [accountNumber, setAccountNumber] = useState<string | null>();
    const accountType = navilinePaymentTypes.buildingPermit;
    const [amount, setAmount] = useState<number>(0);
    const { isAuthenticated } = useAuthSelector(s => s);
    const { customerProfile, iframeURL } = useAppStatePaymentSelector(state => state);
    const [paymentAmount, setPaymentAmount] = useState<number | null>(Math.max(amount, 1));
    const [selectedPaymentProfile, setSelectedPaymentProfile] = useState<string>(!isAuthenticated ? '0' : '');
    const [loadingPayment, setLoadingPayment] = useState(true);

    useEffect(() => {
        const id = querystring.get("activityId") || undefined;
        axios.get(`/lpiPayment/${id}`)
            .then((res) => {
                setLoadingPayment(false);
                setAccountNumber(res.data.accountNumber);
                setAmount(res.data.amount ?? 0);
                setPaymentAmount(res.data.amount ?? 0);
                setItemizedBill(res.data.itemizedBill);
                dispatch(getPaymentusIframeURL('SINGLE', accountNumber!, res.data.amount, accountType, id));
            }).catch((err) => {
                setLoadingPayment(false);
                dispatch(openMessageModal(null, 'error'));
                console.log(err);
            });

        if (isAuthenticated) {
            dispatch(loadPaymentProfile());
        }
    }, [dispatch, accountNumber, isAuthenticated, querystring, amount, accountType, search]);

    const onSubmit = async () => {
        const queryParams = new URLSearchParams(search);
        const activityId = queryParams.get('activityId');

        try {
            setPaymentStatus(PaymentStatus.LOADING);
            const selectedProfile = customerProfile?.find(x => x.Id === selectedPaymentProfile);
            const response = await axios.post('/paymentLPI', {
                nonce: selectedProfile!.Token,
                amount: amount,
                navilineCustomerId: accountNumber!.split('-')[0],
                navilineLocationId: accountNumber!.split('-')[1],
                autoPay: false,
                activityId: activityId
            });
            history.replace('/dashboard');
            dispatch(openMessageModal('success-submit-payment', 'success', {
                paymentNumber: response.data.authNetTransId
            }));
            afterSubmit();
        } catch (e) {
            console.error(e);
            dispatch(openMessageModal('error-add', 'error'));
            setPaymentStatus(PaymentStatus.NONE);
        }
    }

    useEffect(() => {
        const defaultPaymentProfile = customerProfile ? customerProfile.find(x => x.Default) : null;

        if (defaultPaymentProfile) {
            setSelectedPaymentProfile(defaultPaymentProfile.Id);
        }
    }, [customerProfile]);

    useEffect(() => {
        if (selectedPaymentProfile === '0') {
            setAutoPay(false);
            setAutoPayDisabled(true);
        } else {
            setAutoPayDisabled(false);
        }
    }, [selectedPaymentProfile]);


    const afterSubmit = async () => {
        history.replace('/dashboard');
    }

    return (
        loadingPayment || !lpiFF?.enabled ?
        <Spinner fullPage isLoading={loadingPayment} />
        :
        <PageContainer style={isAuthenticated ? {} : { maxWidth: 700, width: '100%' }}>
            <div className="headerContainer">
                <h2>{t('Payment.Payment')}</h2>
            </div>

            {isAuthenticated ?
                <PanelRow>
                    <Panel>
                        {successDetails ?
                            <PaymentConfirmation successDetails={successDetails} />
                            :
                            <>
                                <h3>{t('Payment.ItemizedBill')}</h3>
                                <table className={styles.itemizedTable}>
                                    {itemizedBill?.map((item, index) =>
                                        <tr key={index}>
                                            <td>
                                                {item.applicationNumber}
                                            </td>
                                            <td>
                                                {item.applicationName}
                                            </td>
                                            <td>
                                                ${item.applicationFee ?? 0}
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td></td>
                                        <td className={styles.itemizedBillTotal}>
                                            {t('Payment.TotalDue')}
                                        </td>
                                        <td className={styles.itemizedBillTotal}>
                                            ${amount.toFixed(2)}
                                        </td>
                                    </tr>
                                </table>
                            </>
                        }
                    </Panel>
                </PanelRow>
                : null}
            {!successDetails &&
                <PanelRow>
                    <Panel>
                        {isAuthenticated ?
                            <>
                                <div>
                                    <h3>{t('Payment.SelectPaymentBelow')}</h3>
                                </div>
                                <div className={styles.orSpacer}></div>
                                <p><strong>{t('Payment.SelectAccount')}</strong></p>
                                {customerProfile &&
                                    /* Card selection */
                                    customerProfile.map(profile => (
                                        <div key={profile.Id}
                                            className={`${styles.paymentProfileContainer} ${selectedPaymentProfile === profile.Id ? styles.selected : null}`}
                                        >
                                            <div className={styles.checkboxRow}>
                                                <Checkbox
                                                    value={selectedPaymentProfile === profile.Id}
                                                    text={`${profile.MaskedAccountNumber} - ${profile.Type}${profile.ProfileDescription ? ` (${profile.ProfileDescription})` : ""}`}
                                                    onChange={() => selectedPaymentProfile === profile.Id ? setSelectedPaymentProfile('') : setSelectedPaymentProfile(profile.Id)}
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                                {selectedPaymentProfile !== '0' &&
                                    /* Submit button for card selection */
                                    <div className={styles.submitButtonContainer}>
                                        <Button onClick={() => onSubmit()}
                                            loading={paymentStatus === PaymentStatus.LOADING}
                                            disabled={!selectedPaymentProfile || paymentStatus === PaymentStatus.LOADING}
                                            color={'blue'} >
                                            {t('Util.Submit')}
                                        </Button>
                                    </div>
                                }
                                <div className={styles.orSpacer}>
                                    OR
                                </div>
                                {/* Click to open paymentus iframe */}
                                <div className={`${styles.paymentProfileContainer} ${selectedPaymentProfile === '0' ? styles.selected : null}`}
                                    onClick={() => setSelectedPaymentProfile('0')}>
                                    <span>{t('Payment.OneTimePaymentMethod')}</span>
                                </div>
                            </> : null
                        }
                        {selectedPaymentProfile === '0' && iframeURL &&
                            <PaymentusTokenizationForm
                                transactionType={'SINGLE'}
                                afterToken={afterSubmit}
                                fullToken={false}
                                accountNumber={accountNumber!}
                                amount={paymentAmount!.toFixed(2)}
                                paymentType={accountType as unknown as navilinePaymentTypes}
                                activityId={querystring.get("activityId") || undefined} />
                        }
                        {(apiError !== '') &&
                            <p className={styles.errorMessage}>{apiError}</p>
                        }
                    </Panel>
                </PanelRow>
            }

        </PageContainer>
    )
}
