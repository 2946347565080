import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { ActivitiesListAppState } from './activitiesListAppState';
import { buildSubStateSelector } from '../../../store/utilities/useAppStateSelector';

export const slice = createSlice<ActivitiesListAppState, SliceCaseReducers<ActivitiesListAppState>>({
    name: 'activitiesList',
    initialState: {
        activities: {
            APPLY_FOR: [],
            FIND: [],
            PAY_FOR: [],
            REPORT: [],
            REGISTER_FOR: [],
            RENT: [],
            SIGN_UP_FOR: [],
            MAPS: [],
        },
        loading: false,
    },
    reducers: {
        setActivities: (state, action) => {
            state.activities = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        }
    }
});

export const useAppStateActivitiesListSelector = buildSubStateSelector(state => state.activitiesList);
export const { setActivities, setLoading } = slice.actions;
export const activitiesListReducer = slice.reducer;
