/**
* Group object array by property
 * Example, groupBy(array, ( x: Props ) => x.id );
 * @param array
 * @param property
 */
export const groupBy = <T>(array: Array<T>, property: (x: T) => string): { [key: string]: Array<T> } =>
  array.reduce((memo: { [key: string]: Array<T> }, x: T) => {
    if (!memo[property(x)]) {
      memo[property(x)] = [];
    }
    memo[property(x)].push(x);
    return memo;
  }, {});

export const setFavicon = (src: string) => {
  let link = document.createElement('link');
  let oldLink = document.getElementById('dynamic-favicon');
  link.id = 'dynamic-favicon';
  link.rel = 'shortcut icon';
  link.href = src;
  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const checkUserBrowser = () => {
  if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
    return 'Opera';
  } else if (navigator.userAgent.indexOf("Edg") !== -1) {
    return 'Edge';
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return 'Chrome';
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return 'Safari';
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return 'Firefox';
  } else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.DOCUMENT_NODE === true)) {
    return 'IE';
  } else {
    return 'unknown';
  }
}

export default groupBy;