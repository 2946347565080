import React, { useEffect, useRef, useState } from 'react';
import { sendSamlAssertion } from './samlActionCreators';
import { Spinner } from '../../components/spinner/Spinner';

export const SAML = () => {
    const formRef = useRef<any>();
    const [samlResponse, setSamlResponse] = useState<string | undefined>();
    const [formUrl, setFormUrl] = useState<string | undefined>();

    const sendAssertion = async (saml: string | null) => {
        const response = await sendSamlAssertion(undefined);
        setSamlResponse(response?.samlResponse);
        setFormUrl(response?.assertionConsumerURL);
    }

    useEffect(() => {
        sendAssertion(null);
    }, []);

    useEffect(() => {
        if (formRef && samlResponse && formUrl) {
            formRef.current.submit();
        }
    }, [formRef, samlResponse, formUrl]);

    return (
        <>
            <Spinner isLoading={true}/>
            <form ref={formRef} method='POST' action={formUrl}>
                <input type="hidden" name='SAMLResponse' value={samlResponse} />
            </form>
        </>
    )
}
