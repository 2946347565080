import {createSlice, SliceCaseReducers} from '@reduxjs/toolkit';
import {CounterAppState} from './counterAppState';
import {buildSubStateSelector} from '../../store/utilities/useAppStateSelector';

export const slice = createSlice<CounterAppState, SliceCaseReducers<CounterAppState>>({
    name: 'counter',
    initialState: {
        value: 0,
        incrementAmount: "2"
    },
    reducers: {
        increment: state => {
            // Redux Toolkit allows us to 'mutate' the state. It doesn't actually
            // mutate the state because it uses the immer library, which detects
            // changes to a "draft state" and produces a brand new immutable state
            // based off those changes
            state.value += 1;
        },
        decrement: state => {
            state.value -= 1;
        },
        incrementByAmount: (state, action) => {
            state.value += action.payload.amount;
        },
        setIncrementAmount: (state, action) => {
            state.incrementAmount = action.payload.value;
        }
    },
});


export const useAppStateCounterSelector = buildSubStateSelector(state => state.counter);
export const { increment, decrement, incrementByAmount, setIncrementAmount } = slice.actions;
export const counterReducer = slice.reducer;