import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppStateSignupSelector, setEmail, setPassword, setSignupError, setConfirmEmail, setConfirmPassword, clearSignup } from './signupSlice'
import styles from './Signup.module.scss';
import { Input, Message } from 'semantic-ui-react';
import { useHistory, NavLink } from 'react-router-dom';
import { Reasoning, ErrorValidationLabel } from '../../components/errorValidationLabel/ErrorValidationLabel';
import { signup } from './signupActionCreators';
import { Button } from '../../components/button/Button';
import { Spinner } from '../../components/spinner/Spinner';
import { useTranslation } from 'react-i18next';

export function Signup() {
    const { email, confirmEmail, password, confirmPassword, signupError, loading } = useAppStateSignupSelector(state => state);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    const [invalidEmail, setInvalidEmail] = useState<Reasoning | null>(null);
    const [emailMatchError, setEmailsMatch] = useState<Reasoning | null>(null);
    const [invalidPassword, setInvalidPassword] = useState<Reasoning | null>(null);
    const [passwordMatchError, setPasswordsMatch] = useState<Reasoning | null>(null);

    useEffect(() => {
        dispatch(clearSignup(null));
        clearValidators();
    }, [dispatch])

    const handleSignup = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let valid = validateForm();
        dispatch(setSignupError(null));
        if (valid) {
            dispatch(signup(email, password, navigateToDashboard));
        }
    }

    const navigateToDashboard = () => {
        history.push('/dashboard');
    }

    const validateForm = () => {
        clearValidators();
        let validForm = true;

        // eslint-disable-next-line no-useless-escape
        const emailRegex = new RegExp("^[^@]+@[^@]+\.[^@]+$");
        const passwordRegex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$");

        if (email.length === 0) {
            setInvalidEmail('Required');
            validForm = false;
        } else if (email.match(emailRegex) === null) {
            setInvalidEmail('Invalid');
            validForm = false;
        }

        if (password.length === 0) {
            setInvalidPassword('Required');
            validForm = false;
        } else if (password.match(passwordRegex) === null) {
            setInvalidPassword('RequirementsNotMet');
            validForm = false;
        }

        //Kick out before match checking if either email or password is missing
        if (!validForm) return validForm;

        if (email !== confirmEmail) {
            setEmailsMatch('Match');
            validForm = false;
        }

        if (password !== confirmPassword) {
            setPasswordsMatch('Match');
            validForm = false;
        }

        return validForm;
    }

    const clearValidators = () => {
        setInvalidEmail(null);
        setInvalidPassword(null);
        setEmailsMatch(null);
        setPasswordsMatch(null);
    }

    return (
        <div className={styles.page}>
            <div className={styles.form}>
                {signupError ? 
                    <div className={styles.row}>
                        <Message negative>
                            <Message.Header>{t(signupError)}</Message.Header>
                        </Message>
                    </div>
                : null }
                <div className={styles.row}>
                    <div className='headerContainer'>
                        <h2>Signup</h2>
                        <Spinner isLoading={loading} />
                    </div>
                </div>
                <form onSubmit={handleSignup}>
                    <div className={styles.row}>
                        {t('Login.EmailAddress')}
                        <Input
                            value={email}
                            onChange={(e: any) => dispatch(setEmail(e.target.value))}
                            type="email"
                        />
                        {invalidEmail ? <ErrorValidationLabel fieldDescription={t('Login.EmailAddress')} reasoning={invalidEmail} /> : null}
                    </div>           
                    <div className={styles.row}>
                        {t('Login.ConfirmEmailAddress')}
                        <Input
                            value={confirmEmail}
                            onChange={(e: any) => dispatch(setConfirmEmail(e.target.value))}
                            type="email"
                        />
                        {emailMatchError ? <ErrorValidationLabel fieldDescription={t('Login.EmailAddress')} reasoning={emailMatchError} /> : null}
                    </div>
                    <div className={styles.row}>
                        {t('Login.Password')}
                        <Input
                            value={password}
                            onChange={(e: any) => dispatch(setPassword(e.target.value))}
                            type="password"
                        />
                        {invalidPassword ?
                            <ErrorValidationLabel
                                fieldDescription={t('Login.Password')}
                                reasoning={invalidPassword}
                                requirements={[
                                    'ErrorValidation.MinimumEightCharacters',
                                    'ErrorValidation.OneUpperCaseLetter',
                                    'ErrorValidation.OneLowerCaseLetter',
                                    'ErrorValidation.OneNumber',
                                    'ErrorValidation.OneSpecialCharacter',
                                ]}
                            />
                        : null}
                    </div>
                    <div className={styles.row}>
                        {t('Login.ConfirmPassword')}
                        <Input
                            value={confirmPassword}
                            onChange={(e: any) => dispatch(setConfirmPassword(e.target.value))}
                            type="password"
                        />
                        {passwordMatchError ? <ErrorValidationLabel fieldDescription={t('Login.Password')} reasoning={passwordMatchError} /> : null}
                    </div>
                    <div className={styles.row}>
                        <Button color="blue" type="submit" data-testid="signupButton">
                            {t('Login.Signup')}
                        </Button>
                    </div>
                </form>
                <div className={styles.row}>
                    <NavLink exact to="/login" className={styles.center}>Return to Login</NavLink>
                </div>
            </div>
        </div>
    );
}