import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { MessagesAppState } from './messagesAppState';
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';

export const slice = createSlice<MessagesAppState, SliceCaseReducers<MessagesAppState>>({
    name: 'messages',
    initialState: {
        messages: [],
        activityMessages: [],
        loadMessagesError: '',
        deleteMessageError: '',
        loading: false,
    },
    reducers: {
        setMessages: (state, action) => {
            state.messages = action.payload;
        },
        setActivityMessages: (state, action) => {
            state.activityMessages = action.payload;
        },
        setLoadMessagesError: (state, action) => {
            state.loadMessagesError = action.payload;
        },
        setDeleteMessageError: (state, action) => {
            state.deleteMessageError = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    }
});

export const useAppStateMessagesSelector = buildSubStateSelector(state => state.messages);
export const {
    setMessages,
    setActivityMessages,
    setLoadMessagesError,
    setDeleteMessageError,
    setLoading,
} = slice.actions;
export const messagesReducer = slice.reducer;