import React from 'react';
import { Button } from '../button/Button';
import { Icon } from '../icon/Icon';
import styles from './DataList.module.scss';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../spinner/Spinner';
import { useAppStateBillingSettingsSelector } from '../../features/account/billing/billingSettingsSlice';

export interface ListItem {
    Id: string;
    Label: string;
    Data: any;
    Tag?: string;
};

export enum DataListTypes {
    navilineAccounts = 'NavilineAccounts',
    savedPaymentMethods = 'SavedPaymentMethods',
    sFPublicFields = 'SFPublicFields',
}

export interface DataListProps {
    items: ListItem[];
    type: DataListTypes;
    title?: string;
    hideAddButton?: boolean;
    hideManageButton?: boolean;
    addCallback?: () => void;
    editCallback?: (Id: string, Data: any) => void;
    deleteCallback?: (Id: string, Data: any) => void;
    tagClickCallback?: (Id: string, Data: any) => void;
}

export function DataList({ items, type, title, hideAddButton, hideManageButton, addCallback, editCallback, deleteCallback, tagClickCallback }: DataListProps) {
    const { t } = useTranslation();
    const {
        loadingStoredPayments,
        loadingNavilineAccounts,
    } = useAppStateBillingSettingsSelector((s) => s);

    return (
        <div className={styles.tableContainer}>
            <div className={styles.header}>
                <div className={styles.titleContainer}>
                    {title && <h3>{title}</h3>}
                    {(((type === DataListTypes.navilineAccounts) && loadingNavilineAccounts) ||
                        ((type === DataListTypes.savedPaymentMethods) && loadingStoredPayments)) &&
                        <Spinner isLoading={true} />
                    }
                </div> 
                {addCallback != null &&
                    <div className={styles.addContainer}>
                        <div>
                            {!hideAddButton &&
                                <Button color="green" onClick={addCallback}><Icon icon="plus" color="white"/></Button>
                            }
                        </div>
                    </div>
                }
            </div>
            {(!items.length &&
                !((type === DataListTypes.navilineAccounts) && loadingNavilineAccounts) &&
                !((type === DataListTypes.savedPaymentMethods) && loadingStoredPayments)) &&
                <p>
                    {t('No data associated with this user.')}
                </p>
            }
            {!((type === DataListTypes.navilineAccounts) && loadingNavilineAccounts) &&
                !((type === DataListTypes.savedPaymentMethods) && loadingStoredPayments) &&
                items.map(item =>
                <div className={`listItemColor ${styles.item}`} key={item.Id}>
                    <div>
                        {item.Label}
                        {item.Tag && (!hideManageButton || (hideManageButton && item.Tag === 'Default')) &&
                            <div className={`${styles.tag} ${tagClickCallback ? styles.clickable : ''}`}
                                onClick={() => tagClickCallback ? tagClickCallback(item.Id, item.Data) : null}>
                                {item.Tag}
                            </div>
                        }
                        {item?.Tag?.toLowerCase() === 'manage autopay' &&
                            <div className={`${styles.tag} ${styles.deleteAccount} ${styles.clickable}`}
                                onClick={() => deleteCallback ? deleteCallback(item.Id, item.Data) : null}>
                                {t('Billing.RemoveAutopay')}
                            </div>
                        }
                    </div>

                    <div className={styles.buttonContainer}>
                        {editCallback != null && <Icon icon="pencil" onClick={() => editCallback(item.Id, item.Data)} color="blue"/>}
                        {item?.Tag?.toLowerCase() !== 'manage autopay' && deleteCallback != null && <Icon icon="trash" onClick={() => deleteCallback(item.Id, item.Data)} color="red"/>}                    
                    </div>
                </div>
            )}
        </div>
    );
};