import React from 'react';
import styles from './UserIcon.module.scss';

export interface UserIconProps {
    border?: boolean;
    numNotifications?: number;
    size?: 'small' | 'medium' | 'large';
    iconUrl: string | null;
    onClick?: () => void;
}

const fallbackIcon = '/img/user.png';

export const UserIcon = ({
    border = false,
    numNotifications = 0,
    size = 'small',
    iconUrl = fallbackIcon,
    onClick,
}: UserIconProps) => {
    const dynamicIconStyles = {
        backgroundImage: `url(${iconUrl}), url(${fallbackIcon})`,
        border: border ? '1px solid #FFF' : 'none',
    };
    return (
        <div className={styles.iconContainer}>
            <div
                className={`${styles.icon} ${styles[size]} ${
                    onClick ? styles.clickable : null
                }`}
                style={dynamicIconStyles}
                onClick={onClick}
            >
                {numNotifications ? (
                    <div className={styles.badge}>{numNotifications}</div>
                ) : null}
            </div>
            {onClick ? (
                <div className={styles.editIcon}>
                    <i className={'far fa-edit'} />
                </div>
            ) : null}
        </div>
    );
};
