import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';
import { User } from '../../models/user';
import { personalOrganizationKey } from '../../constants';
import { Organization } from '../../models/organization';

export type AuthInitializationState = "not_initialized" | "initializing" | "initialized" | "error";

const initialState = {
    initializationState: "not_initialized" as AuthInitializationState,
    authUser: null as User | null,
    isAuthenticated: false,
    isAdmin: false,
    loading: false,
    selectedOrganization: personalOrganizationKey,
    selectedOrganizationInfo: null as Organization | null,
};

export type AuthAppState = typeof initialState;

export const slice = createSlice<AuthAppState, {
    setInitializing: CaseReducer<AuthAppState>,
    setInitialized: CaseReducer<AuthAppState, PayloadAction<{authUser: User | null, isAuthenticated: boolean, isAdmin: boolean}>>,
    setErrorInitializing: CaseReducer<AuthAppState>,
    setAuthUserInfo: CaseReducer<AuthAppState, PayloadAction<{authUser: User | null}>>,
    setLoading: CaseReducer<AuthAppState, PayloadAction<boolean>>,
    setProfilePictureUrl: CaseReducer<AuthAppState, PayloadAction<string>>,
    resetInitialState: CaseReducer<AuthAppState>,
}>({
    name: "auth",
    initialState,
    reducers: {
        setInitializing: (state) => {
            state.initializationState = "initializing";
        },
        setInitialized: (state, {payload}) => {
            state.initializationState = "initialized";
            state.authUser = payload.authUser;
            // selectedOrg gets key so that you don't have to remember to do this coalesce every time selectedOrganization is referenced
            state.selectedOrganization = payload.authUser?.selectedOrganization ?? personalOrganizationKey;
            state.selectedOrganizationInfo = payload.authUser?.selectedOrganization && payload.authUser.selectedOrganizationInfo ? payload.authUser.selectedOrganizationInfo : null;
            state.isAuthenticated = payload.isAuthenticated;
            state.isAdmin = payload.authUser?.Admin ?? false;
        },
        setErrorInitializing: (state) => {
            state.initializationState = "error";
        },
        setAuthUserInfo: (state, {payload}) => {
            state.authUser = payload.authUser;
            // selectedOrg gets key so that you don't have to remember to do this coalesce every time selectedOrganization is referenced
            state.selectedOrganization = payload.authUser?.selectedOrganization ?? personalOrganizationKey;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setProfilePictureUrl: (state, action) => {
            if (state.authUser)
                state.authUser.ProfilePictureUrl = action.payload;
        },
        resetInitialState: (state) => {
            state.initializationState = "not_initialized";
            state.authUser = null;
            state.isAuthenticated = false;
            state.isAdmin = false;
            state.loading = false;
            state.selectedOrganization = personalOrganizationKey;
            state.selectedOrganizationInfo = null;
        }
    }
});

export const useAuthSelector = buildSubStateSelector<AuthAppState>(state => state.auth);
export const authActions = slice.actions;
export const authReducer = slice.reducer;
