import React, { useEffect } from 'react';
import { Dropdown, DropdownOptions } from '../../../../components/dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setCodeViolationLocationDescription, setCodeViolationType, useAppStateReportSelector, setCategoryCanSubmit } from '../reportSlice';

export const CodeViolations = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const state = useAppStateReportSelector(state => state.codeViolation);

    const locationDescriptionOptions: DropdownOptions[] = [
        {
            value: "RESIDENTIAL",
            key: "RESIDENTIAL",
            text: t('Report.Residential'),
        },
        {
            value: "COMMERCIAL",
            key: "COMMERCIAL",
            text: t('Report.Commercial'),
        }
    ];

    const typeOptions: DropdownOptions[] = [
        {
            value: "STORAGE_OF_JUNK",
            key: "STORAGE_OF_JUNK",
            text: t('Report.StorageOfJunk'),
        },
        {
            value: "STORAGE_ON_PORCH",
            key: "STORAGE_ON_PORCH",
            text: t('Report.StorageOnPorch'),
        },
        {
            value: "DEBRIS_ON_PROPERTY",
            key: "DEBRIS_ON_PROPERTY",
            text: t('Report.DebrisOnProperty'),
        },
        {
            value: "PEELING_PAINT",
            key: "PEELING_PAINT",
            text: t('Report.PeelingPaint'),
        },
        {
            value: "WASTE_RECYCLING_TOTER_PLACEMENT",
            key: "WASTE_RECYCLING_TOTER_PLACEMENT",
            text: t('Report.WasteRecyclingToterPlacement'),
        },
        {
            value: "INOPERABLE_VEHICLE_PRIVATE_PROPERTY",
            key: "INOPERABLE_VEHICLE_PRIVATE_PROPERTY",
            text: t('Report.InoperableVehiclePrivateProperty'),
        },
        {
            value: "LANDSCAPING",
            key: "LANDSCAPING",
            text: t('Report.Landscaping'),
        },
        {
            value: "DEAD_DISEASED_PRIVATE_TREE",
            key: "DEAD_DISEASED_PRIVATE_TREE",
            text: t('Report.DeadDiseasedPrivateTree'),
        },
        {
            value: "HOLIDAY_DECORATIONS",
            key: "HOLIDAY_DECORATIONS",
            text: t('Report.HolidayDecorations'),
        },
        {
            value: "DOORS_SCREENS",
            key: "DOORS_SCREENS",
            text: t('Report.DoorsScreens'),
        },
        {
            value: "DRAINAGE",
            key: "DRAINAGE",
            text: t('Report.Drainage'),
        },
        {
            value: "BUILDING_STRUCTURE",
            key: "BUILDING_STRUCTURE",
            text: t('Report.BuildingStructure'),
        },
        {
            value: "LIGHTING",
            key: "LIGHTING",
            text: t('Report.Lighting'),
        },
        {
            value: "PRIVATE_TREES_OBSTRUCTING_SIDEWALK_VIEW",
            key: "PRIVATE_TREES_OBSTRUCTING_SIDEWALK_VIEW",
            text: t('Report.PrivateTreesObstructing'),
        },
        {
            value: "RETAINING_WALL",
            key: "RETAINING_WALL",
            text: t('Report.RetainingWall'),
        },
        {
            value: "WINDOWS_DOORS",
            key: "WINDOWS_DOORS",
            text: t('Report.WindowsDoors'),
        },
    ];

    useEffect(() => {
        if (state.type && state.locationDescription) {
            dispatch(setCategoryCanSubmit(true));
        }
        else {
            dispatch(setCategoryCanSubmit(false));
        }
    }, [dispatch, state.type, state.locationDescription]);

    return (
        <>
            {t('Report.LocationDescription')}:
            <Dropdown
                placeholder={t('Report.LocationDescription')}
                options={locationDescriptionOptions}
                value={state.locationDescription}
                onChange={(e: any, data: any) => dispatch(setCodeViolationLocationDescription(data.value))}
            />
            {t('Report.NatureOfReport')}:
            <Dropdown
                placeholder={t('Report.NatureOfReport')}
                options={typeOptions}
                value={state.type}
                onChange={(e: any, data: any) => dispatch(setCodeViolationType(data.value))}
            />
        </>
    )
}