import React, { useEffect, useState } from 'react';
import styles from './UserEdit.module.scss';
import { PageContainer } from '../../../components/pageContainer/PageContainer';
import { Panel } from '../../../components/panel/Panel';
import { PanelRow } from '../../../components/panel/PanelRow';
import { useTranslation } from 'react-i18next';
import { User } from '../../../models/user';
import { userService } from '../../../services/userService';
import { useHistory, useParams } from 'react-router';
import { Spinner } from '../../../components/spinner/Spinner';
import { Text } from '../../../components/text/Text';
import { Checkbox } from '../../../components/checkbox/Checkbox';
import { Button } from '../../../components/button/Button';
import { ErrorValidationLabel, Reasoning } from '../../../components/errorValidationLabel/ErrorValidationLabel';
import { Message } from 'semantic-ui-react';
import { openMessageModal } from '../../../components/statusModal/messageModalActionCreators';
import { useDispatch } from 'react-redux';

export const UserEdit = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState<User | null>(null);
    const {id} = useParams() as any;
    const dispatch = useDispatch();

    const [invalidFirstName, setInvalidFirstName] = useState<Reasoning | null>(null);
    const [invalidLastName, setInvalidLastName] = useState<Reasoning | null>(null);
    const [invalidPhoneNumber, setInvalidPhoneNumber] = useState<Reasoning | null>(null);
    const [invalidSalesforceId, setInvalidSalesforceId] = useState<Reasoning | null>(null);
    const [containsError, setContainsError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        (async() => {
            var user = await userService.getUser(id);
            setUser(user);
            setLoading(false);
        })();
    }, [setLoading, setUser, id]);

    const onSubmit = async () => {
        if (validateForm()) {
            try {
                await userService.updateUserInfo({
                    FirstName: user!.FirstName,
                    LastName: user?.LastName!,
                    PhoneNumber: user?.PhoneNumber!,
                    SalesforceId: user?.SalesforceId!,
                    Admin: user?.Admin
                }, id);
                dispatch(openMessageModal('success-update', 'success'));
                history.push('/users');
            } catch(e) {
                setContainsError(true);
                setErrorMessage('Failed to update user');
                dispatch(openMessageModal('error-remove', 'error'));
            }
        }
    }

    const clearValidators = () => {
        setInvalidFirstName(null);
        setInvalidLastName(null);
        setInvalidPhoneNumber(null);
        setInvalidSalesforceId(null);
        setContainsError(false);
    }

    const validateForm = () => {
        clearValidators();
        let validForm = true;

        if (user?.FirstName!.length === 0) {
            setInvalidFirstName('Required');
            validForm = false;
        }
        if (user?.LastName!.length === 0) {
            setInvalidLastName('Required');
            validForm = false;
        }
        if (user?.PhoneNumber!.length === 0) {
            setInvalidPhoneNumber('Required');
            validForm = false;
        }
        if (user?.SalesforceId!.length === 0) {
            setInvalidSalesforceId('Required');
            validForm = false;
        }
        if (!validForm) {
            setContainsError(true);
        }

        return validForm;
    }

    if (errorMessage) {
        return <Message error header={"Error"} content={errorMessage} />;
    }

    return (
        <PageContainer>
            <div className={styles.pageHeader}>
                <div className='headerContainer'>
                    <h1 className={styles.heading}>{t('Users')}</h1>
                    <Spinner isLoading={loading} />
                </div>
                <div className={styles.submitContainer}>
                    <Button color='blue' onClick={onSubmit}>{t('Util.Submit')}</Button>
                </div>
            </div>
            {containsError && <p className={styles.topError}>{t('Util.FormContainsErrors')}</p>}
            {user &&
                <PanelRow>
                    <Panel>
                        <div className={styles.innerPanelContainer}>
                            <div className={styles.row}>
                                <div className={styles.inputLabel}>{t('Users.FirstName')}</div>
                                <Text value={user.FirstName!} onChange={(e: any, data: any) => setUser({...user, FirstName: data.value})} />
                                {invalidFirstName ? <ErrorValidationLabel fieldDescription={'FirstName'} /> : null}
                            </div>
                            <div className={styles.row}>
                                <div className={styles.inputLabel}>{t('Users.LastName')}</div>
                                <Text value={user.LastName!} onChange={(e: any, data: any) => setUser({...user, LastName: data.value})} />
                                {invalidLastName ? <ErrorValidationLabel fieldDescription={'LastName'} /> : null}
                            </div>
                            <div className={styles.row}>
                                <div className={styles.inputLabel}>{t('Users.Email')}</div>
                                <div>{user.Email}</div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.inputLabel}>{t('Users.PhoneNumber')}</div>
                                <Text value={user.PhoneNumber!} onChange={(e: any) => setUser({...user, PhoneNumber: e.target.value})} mask="(999) 999-9999" />
                                {invalidPhoneNumber ? <ErrorValidationLabel fieldDescription={'PhoneNumber'} /> : null}
                            </div>
                            <div className={styles.row}>
                                <div className={styles.inputLabel}>{t('Users.SalesforceId')}</div>
                                <Text value={user.SalesforceId!} onChange={(e: any, data: any) => setUser({...user, SalesforceId: data.value})} />
                                {invalidSalesforceId ? <ErrorValidationLabel fieldDescription={'SalesforceId'} /> : null}
                            </div>
                            <div className={styles.row}>
                                <div className={styles.inputLabel}>{t('Users.Admin')}</div>
                                <Checkbox value={user.Admin} onChange={() => setUser({ ...user, Admin: !user.Admin })} text={'Administrator'} />
                            </div>
                        </div>
                    </Panel>
                </PanelRow>
            }
        </PageContainer>
    )
};
