import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { ActivitiesWizardAppState } from './activitiesWizardAppState';
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';
import { Activity } from '../../features/activities/list/activitiesListAppState';

export const slice = createSlice<ActivitiesWizardAppState, SliceCaseReducers<ActivitiesWizardAppState>>({
    name: 'activitiesWizard',
    initialState: {
        activities: {
            APPLY_FOR: [],
            FIND: [],
            PAY_FOR: [],
            REPORT: [],
            REGISTER_FOR: [],
            RENT: [],
            SIGN_UP_FOR: [],
            MAPS: [],
        },
        actions: [],
        items: [],
        popularItems: [],
        action: '',
        item: '',
        selectedItem: {} as Activity,
        errorMessage: '',
        loadingActivities: false,
    },
    reducers: {
        clearSelection: state => {
            state.selectedItem = {} as Activity;
            state.action = '';
            state.item = '';
            state.errorMessage = '';
        },
        setActivities: (state, action) => {
            state.activities = action.payload;
        },
        setActions: (state, action) => {
            state.actions = action.payload;
        },
        setItems: (state, action) => {
            state.items = action.payload;
        },
        setPopularItems: (state, action) => {
            state.popularItems = action.payload;
        },
        setAction: (state, action) => {
            state.action = action.payload;
            state.item = '';
            state.selectedItem = {} as Activity;
        },
        setItem: (state, action) => {
            state.item = action.payload.item;
            state.selectedItem = action.payload.selectedItem;
        },
        start: (state, action) => {
            state.errorMessage = '';
        },
        startFailed: (state, action) => {
            state.errorMessage = action.payload.errorMessage;
        },
        setLoadingActivities: (state, action) => {
            state.loadingActivities = action.payload;
        },
    }
});

export const useAppStateActivitiesWizardSelector = buildSubStateSelector(state => state.activitiesWizard);
export const {
    clearSelection,
    setActivities,
    setActions,
    setItems,
    setPopularItems,
    setAction,
    setItem,
    startFailed,
    setLoadingActivities,
} = slice.actions;
export const activitiesWizardReducer = slice.reducer;
