import { AnyAction, Dispatch } from "redux";
import { axios } from "../../../services/axiosService";
import { BoardOrCommissionDetails } from "../boardsAndCommissionsSlice";
import { AdditionalInformation, BoardOrCommissionAttendance, BoardOrCommissionMember, Meeting, MeetingMember, boardOrCommissionViewActions } from "./boardOrCommissionViewSlice";
import { openMessageModal } from "../../../components/statusModal/messageModalActionCreators";

export const loadBoardOrCommissionAttendanceAndDetailsAndMembers = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(boardOrCommissionViewActions.setLoading(true));

    const apiResponseDetails = (await axios.get("/salesforce/boardsAndCommissions/details")).data as BoardOrCommissionDetails[];
    const boardOrCommissionDetails = apiResponseDetails.find(({ Id }) => (Id === id));

    if (boardOrCommissionDetails) {
      dispatch(boardOrCommissionViewActions.setDetail(boardOrCommissionDetails));

      const additionalInformation = getAdditionalInformation(boardOrCommissionDetails);
      dispatch(boardOrCommissionViewActions.setAdditonalInformation(additionalInformation));

      const boardOrCommissionName = boardOrCommissionDetails.Name;
      const apiResponseAttendanceAndMembers = (await axios.get(`/salesforce/boardsAndCommissions/attendanceAndMembers/${boardOrCommissionName}`)).data;

      const apiResponseAttendance = apiResponseAttendanceAndMembers.boardOrCommissionAttendance as BoardOrCommissionAttendance[];
      if (apiResponseAttendance) {
        const meetingDetails = getMeetingDetails(apiResponseAttendance);
        dispatch(boardOrCommissionViewActions.setMeetings(meetingDetails));
      }

      const apiResponseMembers = apiResponseAttendanceAndMembers.boardOrCommissionMembers as BoardOrCommissionMember[];
      if (apiResponseMembers) {
        dispatch(boardOrCommissionViewActions.setMembers(apiResponseMembers));
      }
    }
  } catch {
    dispatch(openMessageModal(null, "error") as unknown as AnyAction);
  } finally {
    dispatch(boardOrCommissionViewActions.setLoading(false));
  }
};

const getMeetingDetails = (meetingAttendanceRecords: BoardOrCommissionAttendance[]) => {
  const meetingDates: string[] = [];
  const meetingDetails: Meeting[] = [];

  for (let record in meetingAttendanceRecords) {
    const meetingDateAlreadyDetected = meetingDates.find((date) => date === meetingAttendanceRecords[record].Meeting_Date__c);

    if (!meetingDateAlreadyDetected) {
      meetingDates.push(meetingAttendanceRecords[record].Meeting_Date__c);
    }
  }

  for (let date in meetingDates) {
    const meetingMembers: MeetingMember[] = [];

    for (let record in meetingAttendanceRecords) {
      if (meetingDates[date] === meetingAttendanceRecords[record].Meeting_Date__c) {
        meetingMembers.push({ 
          name: meetingAttendanceRecords[record].Member__r.Full_Name__c, 
          attendance: meetingAttendanceRecords[record].Attendance__c 
        });
      }
    }

    if (meetingMembers.length) {
      const meetingMembersSorted = sortMeetingMembers(meetingMembers, "name");

      meetingDetails.push({ 
        date: meetingDates[date], 
        members: meetingMembersSorted
      });
    }
    
  }

  return meetingDetails;
};

const sortMeetingMembers = (
  array: any[], 
  property: string
) => {
  const apiResponseSorted = array.sort((record1, record2) => {
      if (record1[property] > record2[property]) {
          return 1;
      } else if (record1[property] < record2[property]) {
          return -1;
      } else {
          return 0;
      }
  });

  return apiResponseSorted;
};

const getAdditionalInformation = (apiResponse: BoardOrCommissionDetails) => {
  const additionalInformation: AdditionalInformation[] = [
    { label: apiResponse.Label_Additional_Information1__c, url: apiResponse.Additional_Information1__c },
    { label: apiResponse.Label_Additional_Information2__c, url: apiResponse.Additional_Information2__c },
    { label: apiResponse.Label_Additional_Information3__c, url: apiResponse.Additional_Information3__c },
    { label: apiResponse.Label_Additional_Information4__c, url: apiResponse.Additional_Information4__c },
    { label: apiResponse.Label_Additional_Information5__c, url: apiResponse.Additional_Information5__c },
    { label: apiResponse.Label_Additional_Information6__c, url: apiResponse.Additional_Information6__c },
    { label: apiResponse.Label_Additional_Information7__c, url: apiResponse.Additional_Information7__c },
    { label: apiResponse.Label_Additional_Information8__c, url: apiResponse.Additional_Information8__c },
    { label: apiResponse.Label_Additional_Information9__c, url: apiResponse.Additional_Information9__c },
    { label: apiResponse.Label_Additional_Information10__c, url: apiResponse.Additional_Information10__c }
  ];

  return additionalInformation;
};