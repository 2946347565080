import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';

import useWindowDimensions from '../../../store/utilities/useWindowDimensions';

import { AdditionalInformation, Meeting, MeetingMember, boardOrCommissionViewActions, useAppStateBoardOrCommissionViewSelector } from './boardOrCommissionViewSlice';
import { loadBoardOrCommissionAttendanceAndDetailsAndMembers } from './boardOrCommissionViewActionCreators';

import { PageContainer } from '../../../components/pageContainer/PageContainer';
import { Panel } from '../../../components/panel/Panel';
import { PanelRow } from '../../../components/panel/PanelRow';
import { Icon } from '../../../components/icon/Icon';
import { Button } from '../../../components/button/Button';
import { Spinner } from '../../../components/spinner/Spinner';
import { BackArrowDarkBlue } from '../../../components/backArrow/BackArrowDarkBlue';

import styles from "./BoardOrCommissionView.module.scss";

export const BoardOrCommissionView = () => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const mobileScreenBreakPoint = 750;
  const { detail, members, meetings, meetingDetails, additionalInformation, modal, loading } = useAppStateBoardOrCommissionViewSelector((state) => state);

  const { id } = useParams() as { id: string };

  useEffect(() => {
    dispatch(loadBoardOrCommissionAttendanceAndDetailsAndMembers(id));
  }, [dispatch, id]);

  const handleOnMeetingDateSelect = (meetingDate: string, meetingMembers: MeetingMember[]) => {
    dispatch(boardOrCommissionViewActions.setModal(true));
    dispatch(boardOrCommissionViewActions.setMeetingDetails({ date: meetingDate, members: meetingMembers }));
  };

  const handleOnModalClose = () => {
    dispatch(boardOrCommissionViewActions.setModal(false));
  };

  const handleOnRedirect = (url: string) => {
    window.open(url, "_blank");
  };

  const OverviewAndMeetings = () => {
    return (   
      <div className={styles.detailContainer}>
        <div className={styles.detailSubContainer}>
          <div className={styles.subTitle}>
              { t("BoardOrCommissionView.OverviewTitle") }
          </div>
        </div>
        <div className={styles.detailSubContainer}>
          <div className={styles.detailHeading}>
              { t("BoardOrCommissionView.DescriptionHeading") }
          </div>
          <div className={styles.detailRow}>
            { detail.Overview__c }
          </div>
        </div>
        <div className={styles.detailSubContainer}>
          <div className={styles.detailHeading}>
              { t("BoardOrCommissionView.StaffLiaisonHeading") }
          </div>
          <div className={styles.detailRow}>
              { detail.Staff_Liaison__r.Name }   
          </div>
          <div className={styles.detailRow}>
              { detail.Staff_Liaison_Email__c }
          </div>
          <div className={styles.detailRow}>
              { detail.Staff_Liaison_Phone__c }   
          </div> 
        </div>
        <div className={styles.detailSubContainer}>
          <div className={styles.detailHeading}>
              { t("BoardOrCommissionView.VacanciesHeading") }
          </div>
          <div className={styles.detailRow}>
              { `${detail.Vacancies__c} ${t("BoardOrCommissionView.CurrentVacanciesCountDescription")}` }  
          </div>
        </div>
        <div className={styles.detailSubContainer}>
          <div className={styles.gridContainer}> 
            <div>
              <div className={styles.detailHeading}>
                { t("BoardOrCommissionView.SizeHeading") }
              </div>
              <div className={styles.detailRow}>
                { `${detail.Size__c} ${t("BoardOrCommissionView.SizeDescription")}` }
              </div>
            </div>
            <div>
              <div className={styles.detailHeading}>
                { t("BoardOrCommissionView.TermLengthHeading") }
              </div>
              <div className={styles.detailRow}>
                { detail.Term_Length__c }
              </div>
            </div>
            <div>
              <div className={styles.detailHeading}>
                { t("BoardOrCommissionView.TermLimitHeading") }
              </div>
              <div className={styles.detailRow}>
                { detail.Term_Limit__c } 
              </div>
            </div>
          </div>
        </div>
        <div className={styles.detailSubContainer}>
          <div className={styles.subTitle}>
            { t("BoardOrCommissionView.MeetingsTitle") }
          </div>
        </div>
        <div className={styles.detailSubContainer}>
          <div className={styles.gridContainer}>
            <div>
              <div className={styles.detailHeading}>
                { t("BoardOrCommissionView.DayHeading") }
              </div>
              <div className={styles.detailRow}>
                { detail.Meeting_Date__c }   
              </div> 
            </div>
            <div>
              <div className={styles.detailHeading}>
                { t("BoardOrCommissionView.TimeHeading") }
              </div>
              <div className={styles.detailRow}>
                { detail.Meeting_Time__c }   
              </div> 
            </div>
            <div>
              <div className={styles.detailHeading}>
                { t("BoardOrCommissionView.LocationHeading") }
              </div>
              <div className={styles.detailRow}>
                { detail.Meeting_Location__c }   
              </div> 
            </div>  
          </div>
        </div>
      </div> 
    );
  };

  const PowersAndDuties = () => {
    return (
      <div className={styles.detailContainer}>
        <div className={styles.detailSubContainer}>
          <div className={styles.subTitle}>
            { t("BoardOrCommissionView.PowersAndDutiesTitle") }
          </div>
        </div>
        {detail.Powers_Duties__c.split("$").map((powerOrDuty, index) => (
          <div className={styles.detailSubContainer} key={index}>
            <div className={styles.detailRow}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className={styles.bulletPoint}>•</div>
                  { powerOrDuty }
              </div>
            </div>
          </div>
        ))}
      </div>
    );  
  };

  const MembersTable = () => {
    return (
      <div className={styles.detailContainer}>
        <div className={styles.detailSubContainer}>
          <div className={styles.subTitle}>
              { t("BoardOrCommissionView.MembersTitle") }
          </div>
        </div>
        <div className={styles.detailSubContainer}>
          <div className={styles.detailRow}>
              { t("BoardOrCommissionView.MembersDescription") } 
          </div>
        </div>
        <div className={styles.detailSubContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.tableHeader}>
                  { t("BoardOrCommissionView.MembersTableNameHeader") }
                </th>
                <th className={styles.tableHeader}>
                  { t("BoardOrCommissionView.MembersTableAttendanceRateHeader") }
                </th>
              </tr>
            </thead>
            <tbody>
              {members.map((member, index) => (
                <tr className={styles.tableRow} key={index}>
                  <td className={styles.tableData}>
                    { member.Full_Name__c }
                  </td>
                  <MembersTableAttendancePercentage attendancePercentage={member.Attendance_Percentage__c}/>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>   
    ); 
  };

  const MembersTableAttendancePercentage = (props: { attendancePercentage: number }) => {
    const { attendancePercentage } = props;

    if (attendancePercentage !== null) {
      return (
        <td className={styles.tableData}>
          { `${attendancePercentage}%` }
        </td>
      );
    } else {
      return (
        <td className={styles.tableData}>
          { "N/A" }
        </td>
      );
    }
  };

  const AttendanceTable = () => {
    return (
      <div className={styles.detailContainer}>
        <div className={styles.detailSubContainer}>
          <div className={styles.subTitle}>
              { t("BoardOrCommissionView.AttendanceTitle") }
          </div>
        </div>
        <div className={styles.detailSubContainer}>
          <div className={styles.detailRow}>
            { t("BoardOrCommissionView.AttendanceDescription") }
          </div>
        </div>
        <div className={styles.detailSubContainer}>
          <AttendanceTableMeetings meetings={meetings}/>
        </div>
      </div>
    );
  };

  const AttendanceTableMeetings = (props: { meetings: Meeting[] }) => {
    const { meetings } = props;

    if (meetings.length) {
      return (
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.tableHeader}>
                { t("BoardOrCommissionView.MeetingsTableHeader") }
              </th>
            </tr>
          </thead>
          <tbody>
            {meetings.map((meeting, index) => (
              <tr className={styles.tableRowSelectable} key={index}>
                <td className={styles.tableData} onClick={() => handleOnMeetingDateSelect(meeting.date, meeting.members)}>
                  { meeting.date }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    } else {
      return (
        <div className={styles.detailRow}>
          { t("BoardOrCommissionView.MeetingsTableHiddenDescription") }
        </div>
      );
    }
  };

  const MembersAndAttendance = () => {
    if (width) {
      if (width < mobileScreenBreakPoint) {
        return (
          <>
            <PanelRow>
              <Panel>
                <div className={styles.panelContainer}>
                  <MembersTable/>
                </div>
              </Panel>
            </PanelRow>
            <PanelRow>
              <Panel>
                <div className={styles.panelContainer}>
                  <AttendanceTable/>
                </div>
              </Panel>
            </PanelRow>
          </>
        );
      } else {
        return (
          <PanelRow>
            <Panel>
              <div className={styles.panelContainer}>
                <MembersTable/>
              </div>
            </Panel>
            <Panel>
              <div className={styles.panelContainer}>
                <AttendanceTable/>
              </div>
            </Panel>
          </PanelRow>
        );
      }
    } else {
      return null;
    }
  };

  const ModalSelection = () => {
    return (
      <Modal open={modal} onClose={handleOnModalClose} size="small">
        <Modal.Header>
          <div className={styles.detailSubContainer}>
            <span className={styles.subTitle}>
              { t("BoardOrCommissionView.MeetingModalTitle") }
            </span>
            <span className={styles.modalIcon}>
              <Icon icon="x" color="black" size="large" onClick={handleOnModalClose}/>
            </span>
          </div>
        </Modal.Header>
        <Modal.Content>
          <div className={styles.detailContainer}>
            <div className={styles.detailSubContainer}>
              <span className={styles.detailHeading}>
                { t("BoardOrCommissionView.MeetingModalMeetingDateHeading") }
              </span>
              <span className={styles.detailRow}>
                { meetingDetails.date }
              </span>
            </div>
            {meetingDetails.members.map((member, index) => (
              <div className={styles.detailSubContainer} key={index}>
                <span className={styles.detailHeading}>
                  { `${member.name}: ` }
                </span>
                <span className={styles.detailRow}>
                  { member.attendance }
                </span>
              </div>
            ))} 
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" onClick={handleOnModalClose}>
            { "OK" }
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  const ResourcesAndInformationGrid = () => {
    return (
      <>
        <div className={styles.detailSubContainer}>
          <div className={styles.subTitle}>
            { t("BoardOrCommissionView.ResourcesAndInformationTitle") }
          </div>
        </div>
        <div className={styles.detailSubContainer}>
          <div className={styles.gridContainer}>
            {additionalInformation.map((informationItem, index) => (
              <AdditionalInformationItems key={index} item={{ label: informationItem.label, url: informationItem.url }}/>
            ))}
          </div>
        </div>
      </> 
    );
  };

  const AdditionalInformationItems = (props: { item: AdditionalInformation }) => {
    const { item: { label, url } } = props;

    if (label && url) {
      return (
        <div className={styles.gridItem}>
          <div className={styles.gridItemServiceIcon}></div>
          <span className={styles.gridItemText} onClick={() => handleOnRedirect(url)}>
            { label }
          </span>
          <div className={styles.gridItemCarrotIcon}></div> 
        </div>
      );
    } else {
      return null;
    }
  };

  const Header = () => {
    if (detail.Name) {
      return (
        <div className={styles.headerContainer}>
          <div className={styles.backArrowContainer}>
            <BackArrowDarkBlue url="/boards-and-commissions" text={`${t("BoardOrCommissionView.BackArrowText")}`}/>
          </div>
          <div className={styles.headerTextContainer}>
            <h2>
              { detail.Name }
            </h2>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  if (loading) { return <Spinner isLoading={loading} fullPage/> }

  return (
    <PageContainer style={{ maxWidth: 1000, width: "100%" }}>
      <Header/>
      <PanelRow>
        <Panel>
          <div className={styles.panelContainer}>
            <OverviewAndMeetings/>
          </div>
        </Panel>
      </PanelRow>
      <PanelRow>
        <Panel>
          <div className={styles.panelContainer}>
            <PowersAndDuties/>
          </div>
        </Panel>
      </PanelRow>
      <MembersAndAttendance/>
      <PanelRow>
        <Panel>
          <div className={styles.panelContainer}>
            <ResourcesAndInformationGrid/>
          </div>
        </Panel>
      </PanelRow>
      <ModalSelection/>
    </PageContainer>
  );
};