import axiosStatic from "axios";
import {ClientAuthError} from 'msal';
import { config } from '../config';
import { firebaseService } from "./firebaseService";

export const axios = axiosStatic.create({
    baseURL: config.apiUrl
});

axios.interceptors.request.use(async requestConfig => {
    if (requestConfig.url?.startsWith('/')) {
        try {
            const accessToken = await firebaseService.auth().currentUser?.getIdToken();
            requestConfig.headers["Authorization"] = `Bearer ${accessToken}`;
        } catch (e) {
            // if the error is ClientAuthError, we do NOT want to throw
            // instead, perform the request without an Authorization header
            if ((e as ClientAuthError).name !== "ClientAuthError") {
                throw e;
            }
        }
    }
    return requestConfig;
});

axios.interceptors.response.use(async response => {
    return response;
});

export class AxiosService {
    private static staticAxios = axios;

    protected get axios() {
        return AxiosService.staticAxios;
    }

    protected constructor() {}
}