import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import styles from './MessageModal.module.scss';
import { messageModalActions, useAppStateMessageModalSelector } from './messageModalViewSlice';
import { Button } from '../messageModalButton/MessageModalButton';

interface MessageModal {
    modalOpen: boolean;
    onConfirmationButtonClick?(): void;
    onCloseButtonClick?(): void;
}

export const MessageModal = ({modalOpen, onConfirmationButtonClick, onCloseButtonClick}: MessageModal) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { image, header, body, buttonColor, firstButtonText, caseNumber, paymentNumber, containsTwoButtons } = useAppStateMessageModalSelector(state => state);

    const close = () => {
        dispatch(messageModalActions.setMessageModalOpen(false));
    }

    return (
        <Modal open={modalOpen} size='tiny' className={styles.modalBackgroundStyle}>
            <div className={containsTwoButtons ? styles.messageHeaderWarning : styles.messageHeader}>
                {image === 'error' &&
                    <div className={`${styles.messageHeaderImg} ${styles.messageHeaderImgError}`}></div>
                }
                {image === 'success' &&
                    <div className={`${styles.messageHeaderImg} ${styles.messageHeaderImgSuccess}`}></div>
                }
                {image === 'warning' &&
                    <div className={`${styles.messageHeaderImg} ${styles.messageHeaderImgWarning}`}></div>
                }

                {containsTwoButtons ?
                    <p className={styles.messageHeaderInnerContainerWarning}>{t(header)}</p>
                    :
                    <p className={styles.messageHeaderInnerContainer}>{t(header)}</p>
                }
                
            </div>
            <div className={styles.modalContent}>
                <div>
                    <span className={containsTwoButtons ? styles.messageBodyWarning : styles.messageBody}>
                        {body.trim() !== '' &&
                            t(body)
                        }
                        {caseNumber.trim() !== '' &&
                            <p>{t('Notification.SuccessCaseSubmitted', {caseNumber: caseNumber})}</p>
                        }
                        {paymentNumber.trim() !== '' &&
                            <p>{t('Notification.SuccessPaymentSubmitted', {paymentNumber: paymentNumber})}</p>
                        }
                    </span>
                    <div className={styles.buttonContainer}>
                        {containsTwoButtons &&
                            <div className={`${styles.buttonInnerContainer} ${styles.buttonInnerContainerDualButtons}`}>
                                <div>
                                    <Button
                                        onClick={onConfirmationButtonClick}
                                        color={buttonColor}
                                        fluid={false}
                                    >
                                        {t(firstButtonText)}
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        onClick={onCloseButtonClick}
                                        color='gray'
                                        fluid={false}
                                    >
                                        {t('Util.Close')}
                                    </Button>
                                </div>
                            </div>
                        }
                        {!containsTwoButtons &&
                        <div className={`${styles.buttonInnerContainer} ${styles.buttonInnerContainerSingleButton}`}>
                            <div>
                                <Button
                                    onClick={close}
                                    color={buttonColor}
                                    fluid={false}
                                >
                                    {t(firstButtonText)}
                                </Button>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
}