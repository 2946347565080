import {createSlice, SliceCaseReducers} from '@reduxjs/toolkit';
import {buildSubStateSelector} from '../../store/utilities/useAppStateSelector';
import { SignupAppState } from './signupAppState';

export const slice = createSlice<SignupAppState, SliceCaseReducers<SignupAppState>>({
    name: 'signup',
    initialState: {
        email: '',
        confirmEmail: '',
        password: '',
        confirmPassword: '',
        signupError: null,
        loading: false,
    },
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setConfirmEmail: (state, action) => {
            state.confirmEmail = action.payload;
        },
        setPassword: (state, action) => {
            state.password = action.payload;
        },
        setConfirmPassword: (state, action) => {
            state.confirmPassword = action.payload;
        },
        clearSignup: (state, action) => {
            state.email = '';
            state.confirmEmail = '';
            state.password = '';
            state.confirmPassword = '';
        },
        setSignupError: (state, action) => {
            state.signupError = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    }
});

export const useAppStateSignupSelector = buildSubStateSelector(state => state.signup);
export const { setEmail, setConfirmEmail, setPassword, setConfirmPassword, clearSignup, setSignupError, setLoading } = slice.actions;
export const signupReducer = slice.reducer;
