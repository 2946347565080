import React, { useEffect, useState } from 'react';
import { PageContainer } from '../../components/pageContainer/PageContainer';
import { useAppStatePaymentSelector } from './paymentSlice';
import styles from './Payment.module.scss';
import { useTranslation } from 'react-i18next';
import { navilinePaymentTypes } from '../../constants';
import { PanelRow } from '../../components/panel/PanelRow';
import { Panel } from '../../components/panel/Panel';
import { useDispatch } from 'react-redux';
import { getPaymentusIframeURL, loadPaymentProfile } from './paymentActionCreators';
import { Button } from '../../components/button/Button';
import { Checkbox } from '../../components/checkbox/Checkbox';
import { PaymentusTokenizationForm } from '../../components/paymentusTokenizationForm/PaymentusTokenizationForm';
import { Text } from '../../components/text/Text';
import { useHistory } from 'react-router-dom';
import { axios } from '../../services/axiosService';
import { useQuery } from '../../hooks/useQuery';
import { useAuthSelector } from '../auth/authSlice';
import { openMessageModal } from '../../components/statusModal/messageModalActionCreators';
import { AnyAction } from 'redux';

interface PaymentSuccessDetails {
    amount: number;
    paymentType: string;
    authNetTransId: string;
    navilineRoutingId: string;
}

const PaymentConfirmation = ({ successDetails }: { successDetails: PaymentSuccessDetails }) => {
    const { amount, paymentType, authNetTransId, navilineRoutingId } = successDetails;
    const { t } = useTranslation();
    return (
        <div className={styles.paymentConfirmationContainer}>
            <h3>
                <span className={`far fa-check-circle ${styles.successIcon}`} />
                {t('Payment.ThankYouForPayment')}
            </h3>
            <p className={styles.confirmationDetail}><b>{t('Payment.PaymentAmount')}: </b>${amount.toFixed(2)}</p>
            <p className={styles.confirmationDetail}><b>{t('Payment.PaymentType')}: </b>{paymentType}</p>
            <p className={styles.confirmationDetail}><b>{t('Payment.TransactionId')}: </b>{authNetTransId}</p>
            <p className={styles.confirmationDetail}><b>{t('Payment.RoutingId')}: </b>{navilineRoutingId}</p>
        </div>
    )
}

enum PaymentStatus {
    NONE = 'NONE',
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
}

export const Payment = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const [apiError] = useState('');
    const [autoPay, setAutoPay] = useState<boolean>(false);
    const [autoPayDisabled, setAutoPayDisabled] = useState<boolean>(false);
    const [successDetails] = useState<PaymentSuccessDetails>();
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(PaymentStatus.NONE);
    const querystring = useQuery();
    const accountNumber = querystring.get("accountNumber");
    const accountType = querystring.get("accountType") ? querystring.get('accountType') : 'UTILITY';
    const amount = querystring.get("amount") ? parseFloat(querystring.get("amount")!) : 0;
    const penaltyAmount = querystring.get("penaltyAmount") ? parseFloat(querystring.get("penaltyAmount")!) : 0;
    const { isAuthenticated } = useAuthSelector(s => s);
    const { customerProfile, iframeURL } = useAppStatePaymentSelector(state => state);
    const [paymentAmount, setPaymentAmount] = useState<number | null>(Math.max(amount + penaltyAmount, 1));
    const [selectedPaymentProfile, setSelectedPaymentProfile] = useState<string>(!isAuthenticated ? '0' : '');

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(loadPaymentProfile());
        }

        dispatch(getPaymentusIframeURL('SINGLE', accountNumber, querystring.get("amount") ? amount : undefined, accountType ? accountType! : undefined));
    }, [dispatch, accountNumber, isAuthenticated, querystring, amount, accountType]);

    const afterSubmit = async () => {
        history.replace('/dashboard');
    }

    const onSubmit = async () => {
        try {
            setPaymentStatus(PaymentStatus.LOADING);
            const selectedProfile = customerProfile?.find(x => x.Id === selectedPaymentProfile);
            const response = await axios.post('/payment', {
                nonce: selectedProfile!.Token,
                amount: paymentAmount,
                appCode: accountType,
                paymentDesc: `${accountType} Payment`,
                navilineCustomerId: accountNumber?.split('-')[0],
                navilineLocationId: accountNumber?.split('-')[1],
                autoPay,
            });
            dispatch(openMessageModal('success-submit-payment', 'success', {
                paymentNumber: response.data.authNetTransId
            }) as unknown as AnyAction);
            afterSubmit();
        } catch (e) {
            console.error(e);
            dispatch(openMessageModal('error-add', 'error') as unknown as AnyAction);
            setPaymentStatus(PaymentStatus.NONE);
        }
    }


    useEffect(() => {
        const defaultPaymentProfile = customerProfile ? customerProfile.find(x => x.Default) : null;

        if (defaultPaymentProfile) {
            setSelectedPaymentProfile(defaultPaymentProfile.Id);
        }
    }, [customerProfile]);

    useEffect(() => {
        if (selectedPaymentProfile === '0') {
            setAutoPay(false);
            setAutoPayDisabled(true);
        } else {
            setAutoPayDisabled(false);
        }
    }, [selectedPaymentProfile])

    return (
        <PageContainer style={isAuthenticated ? {} : { maxWidth: 700, width: '100%' }}>
            <div className="headerContainer">
                <h2>{t('Payment.Payment')}</h2>
            </div>

            {isAuthenticated ?
                <PanelRow>
                    <Panel>
                        {successDetails ?
                            <PaymentConfirmation successDetails={successDetails} />
                            :
                            <>
                                {accountType 
                                    && accountType !== navilinePaymentTypes.businessLicenseRenewal 
                                    && accountType !== navilinePaymentTypes.utility && 
                                    <p>{t('Payment.Account')}: {accountType}</p>
                                }
                                {accountNumber && accountType === navilinePaymentTypes.utility &&
                                    <p>Account Number: {accountNumber}</p>
                                }
                                {accountNumber && accountType === navilinePaymentTypes.businessLicenseRenewal &&
                                    <p>License Number: {accountNumber}</p>
                                }
                                {penaltyAmount ?
                                    <>
                                        {/* If the user is applying for permits, check if there is a penalty amount */}
                                        <p>{t('Payment.PermitDue')}: ${amount.toFixed(2)}</p>
                                        <p style={{color: '#BF0000'}}>{t('Payment.PenaltyDue')}: ${penaltyAmount.toFixed(2)}</p>
                                        <p>{t('Payment.TotalDue')}: ${(amount+penaltyAmount).toFixed(2)}</p>
                                    </>
                                    :
                                    <p>{t('Payment.TotalDue')}: ${amount.toFixed(2)}</p>
                                }
                            </>
                        }
                    </Panel>
                </PanelRow>
                : null}
            {!successDetails &&
                <PanelRow>
                    <Panel>
                        {isAuthenticated ?
                            <>
                                <div>
                                    <p><strong>Enter your payment amount:</strong></p>

                                    <div>
                                        Payment: $<Text
                                            value={paymentAmount ?? 0}
                                            fluid={false}
                                            step="0.01"
                                            min={1}
                                            type={'number'}
                                            onChange={(event: any, data: any) => setPaymentAmount(parseFloat(data.value))} />
                                    </div>
                                </div>
                                <div className={styles.orSpacer}></div>
                                <p><strong>{t('Payment.SelectAccount')}</strong></p>
                                {customerProfile &&
                                    customerProfile.map(profile => (
                                        <div key={profile.Id}
                                            className={`${styles.paymentProfileContainer} ${selectedPaymentProfile === profile.Id ? styles.selected : null}`}
                                        >
                                            <div className={styles.checkboxRow}>
                                                <Checkbox
                                                    value={selectedPaymentProfile === profile.Id}
                                                    text={`${profile.MaskedAccountNumber} - ${profile.Type}${profile.ProfileDescription ? ` (${profile.ProfileDescription})` : ""}`}
                                                    onChange={() => selectedPaymentProfile === profile.Id ? setSelectedPaymentProfile('') : setSelectedPaymentProfile(profile.Id)}
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                                {selectedPaymentProfile !== '0' &&
                                    <>
                                        <div>
                                            <Checkbox
                                                value={autoPay}
                                                text={t(`Payment.AutoPay`)}
                                                onChange={() => setAutoPay(!autoPay)}
                                                isDisabled={autoPayDisabled} />
                                        </div>
                                    </>
                                }
                                {selectedPaymentProfile !== '0' &&
                                    <div className={styles.submitButtonContainer}>
                                        <Button onClick={() => onSubmit()}
                                            loading={paymentStatus === PaymentStatus.LOADING}
                                            disabled={!selectedPaymentProfile || paymentStatus === PaymentStatus.LOADING}
                                            color={'blue'} >
                                            {t('Util.Submit')}
                                        </Button>
                                    </div>
                                }
                                <div className={styles.orSpacer}>
                                    OR
                                </div>
                                <div className={`${styles.paymentProfileContainer} ${selectedPaymentProfile === '0' ? styles.selected : null}`}
                                    onClick={() => setSelectedPaymentProfile('0')}>
                                    <span>{t('Payment.OneTimePaymentMethod')}</span>
                                </div>
                            </> : null
                        }
                        {selectedPaymentProfile === '0' && iframeURL &&
                            <PaymentusTokenizationForm
                                transactionType={'SINGLE'}
                                afterToken={afterSubmit}
                                fullToken={false}
                                accountNumber={accountNumber}
                                amount={paymentAmount!.toFixed(2)}
                                paymentType={accountType as unknown as navilinePaymentTypes} />
                        }
                        {(apiError !== '') &&
                            <p className={styles.errorMessage}>{apiError}</p>
                        }
                    </Panel>
                </PanelRow>
            }

        </PageContainer>
    )
}