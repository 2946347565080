import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import Parser from 'rss-parser';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { config } from '../../config';
import { useAuthSelector } from '../../features/auth/authSlice';
import useWindowDimensions from '../../store/utilities/useWindowDimensions';
import { openMessageModal } from '../statusModal/messageModalActionCreators';
import { Icon } from '../icon/Icon';

import styles from './Events.module.scss';

type EventItem = {
    title: string;
    link: string;
    pubDate: string;
    content: string;
    contentSnippet: string;
    'calendarEvent:EventDates': string;
    'calendarEvent:EventTimes': string;
    'calendarEvent:Location': string;
};

export const Events = () => {
    const { t } = useTranslation();
    const { isAuthenticated } = useAuthSelector(state => state);
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    const [latestEvents, setLatestEvents] = useState<EventItem[]>([]);
    
    useEffect(() => {
        const fetchNews = async () => {
            try {
                const parser = new Parser<EventItem>({
                    customFields: {
                        item: [
                            'calendarEvent:EventDates',
                            'calendarEvent:EventTimes',
                            'calendarEvent:Location'
                        ]
                    }
                });
    
                const response = (await (parser.parseURL(`${config.apiUrl}/feeds/events`))).items as EventItem[];
                
                setLatestEvents(response.slice(0, 6));
            } catch {
                dispatch(openMessageModal(null, "error") as unknown as AnyAction);
            }
        };

        fetchNews();
    }, [dispatch]);

    const Tower = () => {
        if (isAuthenticated) {
            return null;
        } else {
            return (
                <div className={styles.elginTowerContainer}>
                    <img className={styles.elginTowerImage} src="https://elginil.gov/ImageRepository/Document?documentID=80625" alt="City of Elgin logo"/>
                </div>
            );
        }
    };

    const Header = () => {
        return (
            <div className={styles.headerContainer}>
                <h2>
                    { t('Home.WhatsHappening') }
                </h2>
            </div>
        );
    };

    const EventTitle = (props: { event: EventItem }) => {
        const { event } = props;

        return (
            <div className={styles.eventTitleContainer}>
                { event.title }
            </div>
        );
    };

    const EventDateTime = (props: { event: EventItem }) => {
        const { event } = props;
        const dateFormatted = moment(event['calendarEvent:EventDates'], "MMMM D, YYYY").format('ddd, MMM. D');

        return (
            <div className={styles.eventDateTimeParentContainer}>
                <div className={styles.eventCalendarIconContainer}>
                    <Icon icon='calendar alternate outline' color="black" size="large"/>
                </div>
                <div className={styles.eventDateTimeSubContainer}>
                    <div className={styles.eventDateText}>
                        { dateFormatted }
                    </div>
                    <div>
                        { event['calendarEvent:EventTimes'] }
                    </div>
                </div>
            </div>
        );
    };

    const EventLocation = (props: { event: EventItem }) => {
        const { event } = props;
        const locationCleaned = event['calendarEvent:Location'].replace(/<br>/g, " ").replace("Elgin", " Elgin");

        return (
            <div className={styles.eventLocationParentContainer}>
                <div className={styles.eventMarkerIconContainer}>
                    <Icon icon='map marker alternate' color="black" size="large"/>
                </div>
                <div className={styles.eventLocationSubContainer}>
                    { locationCleaned }
                </div>
            </div>
        );
    };

    const getColumnsCountInEventsGrid = () => {
        const mobilePhoneBreakPoint = 900;
        const mobileTabletBreakPoint = 1400;

        if (width) {
            if ((isAuthenticated || !isAuthenticated) && width < mobilePhoneBreakPoint) {
                return 1;
            } else if ((isAuthenticated || !isAuthenticated) && width < mobileTabletBreakPoint) {
                return 2;
            } else if (isAuthenticated) {
                return 2;
            } else if (!isAuthenticated) {
                return 3;
            } 
        } else {
            return 1;
        }
    };
    
    const Events = () => {
        return (
            <div 
                className={styles.gridContainer} style={{ gridTemplateColumns: `repeat(${getColumnsCountInEventsGrid()}, minmax(0, 1fr))` }}>
                {latestEvents.map((event: EventItem, index: number) => (
                    <a className={styles.eventContainer} href={event.link} target="blank" rel="noopener noreferrer" key={index}>
                        <EventTitle event={event}/>
                        <div className={styles.eventDetailsContainer}>
                            <EventDateTime event={event}/>
                            <EventLocation event={event}/>
                        </div>
                    </a>
                ))}
            </div>
        );
    };

    const AllEvents = () => {
        return (
            <div className={styles.allEventsContainer}>
                <a href="https://elginil.gov/calendar.aspx?CID=15" target="blank" rel="noopener noreferrer">
                    <div className={styles.allEventsButton}>
                        { t('Home.AllCalendarEventsRedirect') }
                    </div>
                </a>
            </div>
        );
    };

    const addBackgroundColorWhenNotAuthenticated = () => {
        if (isAuthenticated) {
            return "";
        } else {
            return "#f0f2f9";
        }
    };

    return (
        <div className={styles.eventsParentContainer}>
            <Tower/>
            <div className={styles.eventsSubContainer} style={{ backgroundColor: `${addBackgroundColorWhenNotAuthenticated()}` }}>
                <Header/>
                <Events/>
                <AllEvents/>
            </div>
        </div>
    );
};