import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';
import { BillingHistory, DelinquentInformationResponse, FlattenedPaymentHistory, NavilinePaymentHistory, NavilinePaymentHistoryV2 } from '../../models/navilinePaymentHistory';

const initialState = {
    paymentHistory: null as NavilinePaymentHistory[] | null | undefined,
    flattenedPaymentHistory: null as FlattenedPaymentHistory[] | null | undefined,
    totalAmountForFlattenedHistory: 0,
    billingHistory: null as BillingHistory[] | null | undefined,
    paymentHistoryV2: null as NavilinePaymentHistoryV2 | null | undefined,
    billingAccountSelectionModalOpen: false,
    activitiesRequiringAction: 0,
    showAddBillingHelp: false,
    loadingPaymentInformation: false,
    totalAmountDue: 0 as number,
    delinquentStatuses: [] as DelinquentInformationResponse[],
};

export type DashboardAppState = typeof initialState;

export const slice = createSlice<DashboardAppState, {
    setPaymentHistory: CaseReducer<DashboardAppState, PayloadAction<NavilinePaymentHistory[] | null | undefined>>,
    setFlattenedPaymentHistory: CaseReducer<DashboardAppState, PayloadAction<FlattenedPaymentHistory[] | null | undefined>>,
    setTotalAmountForFlattenedHistory: CaseReducer<DashboardAppState, PayloadAction<number>>,
    setBillingHistory: CaseReducer<DashboardAppState, PayloadAction<BillingHistory[] | null | undefined>>,
    setPaymentHistoryV2: CaseReducer<DashboardAppState, PayloadAction<NavilinePaymentHistoryV2 | null | undefined>>,
    setBillingAccountSelectionModalOpen: CaseReducer<DashboardAppState, PayloadAction<boolean>>,
    setActivitiesRequiringAction: CaseReducer<DashboardAppState, PayloadAction<number>>,
    setShowAddBillingHelp: CaseReducer<DashboardAppState, PayloadAction<boolean>>,
    setLoadingPaymentInformation: CaseReducer<DashboardAppState, PayloadAction<boolean>>,
    setTotalAmountDue: CaseReducer<DashboardAppState, PayloadAction<number>>,
    setDelinquentStatuses: CaseReducer<DashboardAppState, PayloadAction<DelinquentInformationResponse[]>>,
}>({
    name: 'dashboard',
    initialState,
    reducers: {
        setPaymentHistory: (state, action) => {
            state.paymentHistory = action.payload;
        },
        setFlattenedPaymentHistory: (state, action) => {
            state.flattenedPaymentHistory = action.payload;
        },
        setTotalAmountForFlattenedHistory: (state, action) => {
            state.totalAmountForFlattenedHistory = action.payload;
        },
        setBillingHistory: (state, action) => {
            state.billingHistory = action.payload;
        },
        setPaymentHistoryV2: (state, action) => {
            state.paymentHistoryV2 = action.payload;
        },
        setBillingAccountSelectionModalOpen: (state, action) => {
            state.billingAccountSelectionModalOpen = action.payload;
        },
        setActivitiesRequiringAction: (state, action) => {
            state.activitiesRequiringAction = action.payload;
        },
        setShowAddBillingHelp: (state, action) => {
            state.showAddBillingHelp = action.payload;
        },
        setLoadingPaymentInformation: (state, action) => {
            state.loadingPaymentInformation = action.payload;
        },
        setTotalAmountDue: (state, action) => {
            state.totalAmountDue = action.payload;
        },
        setDelinquentStatuses: (state, action) => {
            state.delinquentStatuses = action.payload;
        },
    }
});

export const useAppStateDashboardSelector = buildSubStateSelector(state => state.dashboard);
export const {
    setPaymentHistory,
    setFlattenedPaymentHistory,
    setTotalAmountForFlattenedHistory,
    setBillingHistory,
    setPaymentHistoryV2,
    setBillingAccountSelectionModalOpen,
    setActivitiesRequiringAction,
    setShowAddBillingHelp,
    setLoadingPaymentInformation,
    setTotalAmountDue,
    setDelinquentStatuses,
} = slice.actions;
export const dashboardReducer = slice.reducer;
