import { CoreSavedPaymentsResponse } from '../models/coreSavedPaymentsResponse';
import { AxiosService } from './axiosService';

export class CoreService extends AxiosService {
    public constructor() {
        super();
    }

    async getAddStoredPaymentHTML() {
        try {
            const route = `/core/payment-methods/iframe`;
            const { data } = await this.axios.get<string>(route);
            return data;
        } catch (e) {
            console.log(e);
            throw(e);
        }
    }

    async getUserStoredPayments(userId?: string) {
        try {
            const route = `/core/payment-methods${userId ? `?userId=${userId}` : ''}`;
            const { data } = await this.axios.get<CoreSavedPaymentsResponse>(route);
            return data;
        } catch (e) {
            console.log(e);
            throw(e);
        }
    }

    async deleteUserStoredPayment(storedPaymentId: string, userId: string) {
        try {
            const route = `/core/payment-methods/${storedPaymentId}/${userId}`;
            await this.axios.delete(route);
        } catch (e) {
            console.log(e);
            throw(e);
        }
    }
}

export const coreService = new CoreService();
