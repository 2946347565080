import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit'; 
import { buildSubStateSelector } from '../../../../store/utilities/useAppStateSelector';
import { InProgressFormatted } from '../activitiesViewSlice';

export interface SFFormDetailDisplayItem {
    label: string;
    data: string; // may need to support date, bool, etc
}

export interface SFFormDetailDisplay {
    label: string;
    data: SFFormDetailDisplayItem[] | InProgressFormatted[];
}

export interface SFActivityView {
    activityType: string;
    name: string;
    status: string;
    statuses: string[];
    actionRequired: boolean;
    actionRequiredMessage: string;
    paymentRequired: boolean;
    paymentAmount?: number;
    paymentAmountDisplay?: string;
    paymentCode?: string;
    isCancelable: boolean;
    userActivityId: string;
    formDetails: SFFormDetailDisplay[];
}

const initialState = {
    loading: false,
    activity: {} as SFActivityView,
    additionalInformationText: '',
    boxActivityLog: [] as string[],
};

export type ActivitiesViewItemAppState = typeof initialState;

export const slice = createSlice<ActivitiesViewItemAppState, {
    setLoading: CaseReducer<ActivitiesViewItemAppState, PayloadAction<boolean>>,
    setActivity: CaseReducer<ActivitiesViewItemAppState, PayloadAction<SFActivityView>>,
    setAdditionalInformationText: CaseReducer<ActivitiesViewItemAppState, PayloadAction<string>>,
    pushBoxActivityLog: CaseReducer<ActivitiesViewItemAppState, PayloadAction<string>>,
}>({
    name: 'activitiesViewItem',
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setActivity: (state, { payload }) => {
            state.activity = payload;
        },
        setAdditionalInformationText: (state, { payload }) => {
            state.additionalInformationText = payload;
        },
        pushBoxActivityLog: (state, { payload }) => {
            state.boxActivityLog = [...state.boxActivityLog, payload];
        },
    }
});

export const useAppStateActivitiesViewItemSelector = buildSubStateSelector<ActivitiesViewItemAppState>(state => state.activitiesViewItem);
export const activitiesViewItemActions = slice.actions;
export const activitiesViewItemReducer = slice.reducer;