import  {AnyAction, Dispatch } from 'redux';
import { createActivityFailed, resetActivityCreateState, setLaserficheForms, getLaserficheFormsFailed } from './activitiesCreateSlice'
import {axios} from '../../../services/axiosService';
import { LaserficheForm } from './activitiesCreateAppState';
import { setLoading } from './activitiesCreateSlice';
import { activityTypes } from '../../../constants';
import { AppState } from '../../../store/appState';
import { sortBy } from 'lodash';
import { openMessageModal } from '../../../components/statusModal/messageModalActionCreators';

export const create = (history: any, action: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const {
        name,
        type,
        order,
        description,
        published,
        salesforceObjectId,
        laserficheProcessId,
        externalLink,
        content,
        calendlyLink,
        paymentRequired,
        guestEnabled,
        popularService,
        popularServiceOrder,
    } = state.activitiesCreate;
    try {
        dispatch(setLoading(true));
        const payload = {
            item: name,
            type: type.toUpperCase(),
            order,
            description,
            published,
            action: action.toUpperCase(),
            calendlyLink,
            paymentRequired: paymentRequired ?? false,
            guestEnabled,
            popularService,
            popularServiceOrder: popularServiceOrder,
        };

        switch (type) {
            case activityTypes.CONTENT:
                Object.assign(payload, {content});
                break;
            case activityTypes.EXTERNAL_LINK:
                Object.assign(payload, {externalLink});
                break;
            case activityTypes.LASERFICHE_FORM:
                Object.assign(payload, {salesforceObjectId, laserficheProcessId});
        }

        const activityCreateResponse = (await axios.post('/activities', payload)).data;
        let imagefile = document.getElementById('activityIconFile') as any;
        if (imagefile && popularService && imagefile.files.length) {
            let formData = new FormData();
            formData.append(`image`, imagefile.files[0]);
            const response = (await axios.put(`/activities/setPopularService/${activityCreateResponse.Id}`, formData)).data;
            console.log('Icon set to this image: ', response.urlResponse);
        }
        dispatch(resetActivityCreateState({}));
        dispatch(openMessageModal('success-add', 'success') as unknown as AnyAction);
        history.push('/activities');
    } catch (err) {
        dispatch(openMessageModal('error-add', 'error') as unknown as AnyAction);
        console.error(err);
        dispatch(createActivityFailed({errorMessage: 'Create Activity Failed'}));
    } finally {
        dispatch(setLoading(false));
    }
};

export const getLaserficheForms = () => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        const getLaserficheFormsResponse = (await axios.get('/laserfiche/forms')).data as LaserficheForm;
        console.log(getLaserficheFormsResponse);
        dispatch(setLaserficheForms(getLaserficheFormsResponse));
    } catch (err) {
        console.error(err);
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        dispatch(getLaserficheFormsFailed({errorMessage: 'Get Laserfiche Forms Failed'}));
    } finally {
        dispatch(setLoading(false));
    }
}

export const getActivitySObjectFields = (sObjectType: string) => async (dispatch: Dispatch) => {
    try {
        const activityFields = (await axios.get(`/salesforce/activity/${sObjectType}/fields`)).data;
        const sortedFields = sortBy(activityFields, 'value');
        console.log(sortedFields);
    } catch (err) {
        console.error(err);
    }
}
