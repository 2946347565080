import { AnyAction, Dispatch } from 'redux';
import { authService } from '../../services/authService';
import { authActions } from './authSlice';
import { firebaseService } from '../../services/firebaseService';
import { openMessageModal } from '../../components/statusModal/messageModalActionCreators';

export const authActionCreators = {
    loadAuthUser: () => async (dispatch: Dispatch<any>) => {
        try {
            dispatch(authActions.setLoading(true));
            dispatch(authActions.setInitializing());
            let authUser = null;
            let isAuthenticated = false;
            const isAdmin = true; // TODO: replace this with actual check
            const accessToken = await firebaseService.auth().currentUser?.getIdToken();
            if (accessToken) {
                authUser = await authService.getAuthUser();
                isAuthenticated = authUser != null;

                if (isAuthenticated && (authUser!.FirstName === null && authUser!.LastName === null && authUser!.PhoneNumber === null)) {
                    dispatch(authActions.setInitialized({authUser, isAuthenticated, isAdmin}));
                    return;
                }
            }
            return dispatch(authActions.setInitialized({authUser, isAuthenticated, isAdmin}));
        } catch (error) {
            dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
            dispatch(authActions.setErrorInitializing());
            console.error(error);
        } finally {
            dispatch(authActions.setLoading(false));
        }
    },

    initializeAuth: () => async (dispatch: Dispatch<any>) => {
        dispatch(authActionCreators.loadAuthUser());
    }
}