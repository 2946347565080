import React, { useCallback, useEffect, useState } from 'react';
import 'semantic-ui-css/semantic.min.css';
import styles from './BulkPushNotificationsDashboard.module.scss';
import { Button, Modal } from 'semantic-ui-react';
import { Icon } from '../../components/icon/Icon';
import { BulkPushNotification } from './BulkPushNotifications';
import { PageContainer } from '../../components/pageContainer/PageContainer';
import { axios } from '../../services/axiosService';
import moment from 'moment';

export const BulkPushNotificationDashboard = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pushNotifications, setPushNotifications] = useState([]);
    const [resending, setResending] = useState(null);

    const loadNotifications = useCallback(async () => {
        setLoading(true);
        const response = await axios.get('/notifications/bulk');
        setPushNotifications(response.data);
        setLoading(false);
    }, []);

    const resendNotification = useCallback(
        async (pushNotificationId) => {
            setResending(pushNotificationId);
            await axios.post('/notifications/bulk', {
                pushNotificationId,
            });
            loadNotifications();
            setResending(null);
        },
        [loadNotifications],
    );

    useEffect(() => {
        if (loading) {
            loadNotifications();
        }
    }, [loading, loadNotifications]);

    return (
        <PageContainer>
            <div className={styles.buttonHeader}>
                <Button sty onClick={() => setModalOpen(true)}>
                    Send Notification
                </Button>
            </div>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                size="small"
            >
                <Modal.Header>
                    Send Bulk Push Notification
                    <div style={{ float: 'right' }}>
                        <Icon
                            icon="x"
                            color="black"
                            size="large"
                            onClick={() => setModalOpen(false)}
                        />
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <BulkPushNotification
                        onClose={() => {
                            setModalOpen(false);
                            loadNotifications();
                        }}
                    />
                </Modal.Content>
            </Modal>

            <div>
                <div className={styles.action_container}>
                    <h1 className={styles.title}>Bulk Push Notifications</h1>

                    <table>
                        <tr className={styles.tableHeader}>
                            <th className={styles.alertEnglish}>
                                English Message
                            </th>
                            <th className={styles.alertSpanish}>
                                Spanish Message
                            </th>
                            <th className={styles.timestamp}>Last Sent Date</th>
                            <th className={styles.timestamp}>Created Date</th>
                            <th className={styles.actions}>Actions</th>
                        </tr>
                        {pushNotifications?.map((notification: any, index) => (
                            <tr
                                className={`listItemColor ${styles.item}`}
                                key={notification.Id}
                            >
                                <td className={styles.alertText}>
                                    <span className={styles.alertHeader}>
                                        {notification.EnglishTitle}
                                    </span>
                                    <br />
                                    <span>{notification.EnglishBody}</span>
                                </td>
                                <td className={styles.alertText}>
                                    <span className={styles.alertHeader}>
                                        {notification.SpanishTitle}
                                    </span>
                                    <br />
                                    <span>{notification.SpanishBody}</span>
                                </td>
                                <td className={styles.timestamp}>
                                    {moment(notification.updated_at)
                                        .tz('America/Chicago')
                                        .format('MM-DD-YY h:mm A')}
                                </td>
                                <td className={styles.timestamp}>
                                    {moment(notification.created_at)
                                        .tz('America/Chicago')
                                        .format('MM-DD-YY h:mm A')}
                                </td>
                                <td className={styles.actions}>
                                    <Button
                                        loading={resending === notification.Id}
                                        disabled={
                                            resending != null &&
                                            resending !== notification.Id
                                        }
                                        onClick={() => {
                                            resendNotification(notification.Id);
                                        }}
                                    >
                                        Resend
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
            </div>
        </PageContainer>
    );
};
