import moment from 'moment';
import { AnyAction, Dispatch } from 'redux';
import { axios } from '../../../../services/axiosService';
import { AppState } from '../../../../store/appState';
import { getStatusInformation } from '../activitiesViewActionCreators';
import { InProgressFormatted, InProgressStatusOptions } from '../activitiesViewSlice';
import { activitiesViewItemActions, SFActivityView, SFFormDetailDisplay } from './activitiesViewItemSlice';
import { openMessageModal } from '../../../../components/statusModal/messageModalActionCreators';

export const loadActivity = (activitySObjectType: string, sfId: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(activitiesViewItemActions.setLoading(true));
        let activity = (await axios.get(`/salesforce/activity/${activitySObjectType}/${sfId}`)).data as SFActivityView;

        if (activity.paymentAmount) {
            const formattedAmount = Math.abs(activity.paymentAmount).toFixed(2);
            const paymentAmountDisplay = `$${formattedAmount}`;
            activity = {...activity, paymentAmountDisplay}
        }

        const relatedPermitsIndex = activity.formDetails.findIndex(fd => fd.label === "Related Permits");
        if (relatedPermitsIndex > -1) {
            const formattedRelatedPermits = (activity.formDetails[relatedPermitsIndex].data as InProgressFormatted[]).map(rp => {
                rp.lastModifiedDisplay = moment(rp.lastModified).format("MM/DD/YYYY h:mm A");
                rp.statusInformation = getStatusInformation(rp.status as InProgressStatusOptions);
                return rp;
            });

            // remove from index at bottom of page
            delete activity.formDetails[relatedPermitsIndex];

            const permitFormDetails: SFFormDetailDisplay = {
                label: "Related Permits",
                data: formattedRelatedPermits
            };

            // place related permits in 4th slot
            activity.formDetails.splice(3, 0, permitFormDetails);
        }
        dispatch(activitiesViewItemActions.setActivity(activity));
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(activitiesViewItemActions.setLoading(false));
    }
}

export const submitAdditionalInformation = (sfType: string, sfId: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    try {
        dispatch(activitiesViewItemActions.setLoading(true));
        const payload = {
            additionalInformationText: state.activitiesViewItem.additionalInformationText,
            boxActivityLog: state.activitiesViewItem.boxActivityLog,
        };
        const submissionResponse = (await axios.post(`/salesforce/activity/${sfType}/${sfId}/additionalInformation`, payload)).data as SFActivityView;

        console.log(submissionResponse);
        dispatch(activitiesViewItemActions.setActivity({...state.activitiesViewItem.activity, actionRequired: false }));
        dispatch(openMessageModal('success-submit-additional-information', 'success') as unknown as AnyAction);
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(activitiesViewItemActions.setLoading(false));
    }
}

export const cancelActivity = (sfType: string, sfId: string, callback: () => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(activitiesViewItemActions.setLoading(true));
        const submissionResponse = (await axios.delete(`/salesforce/activity/${sfType}/${sfId}`)).data as SFActivityView;
        console.log(submissionResponse);
        dispatch(openMessageModal('success-cancel-activity', 'success') as unknown as AnyAction);
        callback();
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(activitiesViewItemActions.setLoading(false));
    }
}