import React, { useEffect } from 'react';
import { PageContainer } from '../../../components/pageContainer/PageContainer';
import { PanelRow } from '../../../components/panel/PanelRow';
import { Panel } from '../../../components/panel/Panel';
import styles from './WaterBillPaymentHistoryRecords.module.scss';
import { useDispatch } from 'react-redux';
import { loadPaymentHistory } from '../../dashboard/dashboardActionCreators';
import { useAppStateDashboardSelector } from '../../dashboard/dashboardSlice';
import { Spinner } from '../../../components/spinner/Spinner';
import { BackArrowDarkBlue } from '../../../components/backArrow/BackArrowDarkBlue';
import { useTranslation } from 'react-i18next';

export const PaymentHistory = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { loadingPaymentInformation, flattenedPaymentHistory } = useAppStateDashboardSelector(state => state);

    const PaymentHistoryRecordDesktop = (
        {
            type,
            amount,
            paymentDate,
        }:
        {
            type: string, 
            amount: string,
            paymentDate: string,
        }) => {
        return (
            <tr className={styles.tableRow}>
                <td>
                    <p className={styles.priceHistoryTableDate}>{paymentDate}</p>
                </td>
                <td>
                    <p className={styles.priceHistoryTableType}>{type}</p>
                </td>
                <td>
                    <p className={styles.priceHistoryTableAmount}>{amount}</p>
                </td>
            </tr>
        );
    };

    const PaymentHistoryRecordMobile = (
        {
            type,
            amount,
            paymentDate,
        }: 
        {
            type: string, 
            amount: string,
            paymentDate: string,
        }) => {
        return (
            <div className={styles.paymentInfoContainer}>
                <div className={styles.mobileDateText}>{paymentDate}</div>
                <div className={styles.mobileTypeText}>
                    <p>{type}</p>
                </div>
                <div className={styles.mobilePaymentPriceText}>
                    <p>{amount}</p>
                </div>
            </div>
        );
    };
    
    useEffect(() => {
        if (!flattenedPaymentHistory) {
            dispatch(loadPaymentHistory(false, true));
        }
    }, [dispatch, flattenedPaymentHistory]);

    return (
        <PageContainer className={styles.pageContainer}>
            <div className={styles.arrowContainer}>
                <BackArrowDarkBlue url="/payment/water-bill-payment-records" text={t('Payment.BackToBilling')} />
            </div>
            <PanelRow>
                <Panel>
                    <div>
                        <h2 className={styles.paymentHistoryHeader}>{t('Payment.PaymentHistory')}</h2>
                    </div>

                    <table className={styles.containerWeb}>
                        <colgroup>
                            <col className={styles.colGroupPaymentDate} />
                            <col className={styles.colGroupPaymentType} />
                            <col className={styles.colGroupPaymentAmount} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th className={`${styles.paymentHistoryTableHeader} ${styles.paymentHistoryDateHeader}`}>
                                    {t('Payment.Date')}
                                </th>
                                <th className={styles.paymentHistoryTableHeader}>
                                    {t('Payment.AccountNumber')}
                                </th>
                                <th className={styles.paymentHistoryTableHeader}>
                                    {t('Payment.PaymentAmount')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {flattenedPaymentHistory && flattenedPaymentHistory.map((record, index) => {
                                return (
                                    <PaymentHistoryRecordDesktop 
                                        key={index}
                                        type={record.type}
                                        amount={record.paymentAmount}
                                        paymentDate={record.paymentDate}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                    <div className={styles.containerMobile}>
                        <Spinner isLoading={loadingPaymentInformation} />
                        {flattenedPaymentHistory && flattenedPaymentHistory.map((record, index) => {
                            return (
                                <PaymentHistoryRecordMobile
                                    key={index}
                                    type={record.type}
                                    amount={record.paymentAmount}
                                    paymentDate={record.paymentDate}
                                />
                            );
                        })}
                    </div>
                    <div className={styles.containerWeb}>
                        <Spinner isLoading={loadingPaymentInformation} />
                    </div>
                </Panel>
            </PanelRow>
        </PageContainer>
    )
}
