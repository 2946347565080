import { AnyAction, Dispatch } from 'redux';
import { axios } from '../../services/axiosService';
import { AppState } from '../../store/appState';
import { addElginTodayPosts, setElginTodayEndReached, setElginTodayLoading, setOffset } from './elginTodaySlice';
import { openMessageModal } from '../../components/statusModal/messageModalActionCreators';

const limit = 10;

export const getElginToday = () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const { offset, elginTodayLoading, elginTodayEndReached } = state.elginToday;
    console.log('elginTodayEndReached', elginTodayEndReached)
    if ((elginTodayLoading && offset !== 0) || elginTodayEndReached) return; // Don't get more if the previous results haven't shown up yet
    dispatch(setElginTodayLoading(true));
    try {
        const getElginTodayResult = await axios.get(`/salesforce/elginToday?offset=${offset}&limit=${limit}`);
        console.log(getElginTodayResult)
        console.log(getElginTodayResult.data.length);
        if (!getElginTodayResult.data || getElginTodayResult.data.length === 0) {
            dispatch(setElginTodayEndReached(true));
        }
        dispatch(addElginTodayPosts(getElginTodayResult.data));
        dispatch(setOffset(offset + limit));
    } catch(err) {
        console.log(err);
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
    } finally {
        dispatch(setElginTodayLoading(false));
    }
};
