import { AnyAction, Dispatch } from "redux";
import { axios } from "../../../services/axiosService";
import { openMessageModal } from "../../../components/statusModal/messageModalActionCreators";

export const submitLaserficheToPortal = (portalSubmissionId: string, bpid: string) => async (dispatch: Dispatch) => {
    try {
        const response = await (await (axios.post('/laserfiche/submitToPortal', { portalSubmissionId, bpid }))).data;
        dispatch(openMessageModal('success-submit-form', 'success') as unknown as AnyAction);
        console.log(response);
    } catch (err) {
        console.error(err);
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
    }
}