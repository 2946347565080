import React, { useEffect, useRef, memo } from 'react';
import { loadModules } from 'esri-loader';
import debounce from 'lodash.debounce';
import { axios } from '../../services/axiosService';
import { SalesforceLandParcel } from '../../features/customForms/zoningInquiry/zoningInquiryAppState';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { elginBounds } from '../../constants';
import { config } from '../../config';

export interface InternalKeyedSelectedLandParcel {
  salesforceId: string,
  address: string
}

interface ArcGISMapViewProps {
  width: string,
  height: string,
  setSalesforceLandParcel?: (payload: InternalKeyedSelectedLandParcel | null) => void,
  reportCurrentZoomLevel?: React.Dispatch<React.SetStateAction<any>>,
}

const layerVisibilityZoomBreakpoint = 8500;

const elginCenter = {
  lat: 42.039580,
  lng: -88.284811
};

export const ArcGISMapView = memo(({ width, height, setSalesforceLandParcel, reportCurrentZoomLevel = () => null }: ArcGISMapViewProps) => {
    const mapRef = useRef();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
      // This is an optional prop in case we're just using it to display a location
      const dispatchLandParcel = (payload: InternalKeyedSelectedLandParcel | null) => setSalesforceLandParcel ? dispatch(setSalesforceLandParcel(payload)) : null;
      // lazy load the required ArcGIS API for JavaScript modules and CSS
      (loadModules([
        'esri/Map',
        'esri/views/MapView',
        'esri/layers/FeatureLayer',
        "esri/widgets/Search",
        "esri/widgets/Search/LocatorSearchSource",
        "esri/tasks/Locator",
        "esri/geometry/Extent"], { css: true }) as Promise<[
          typeof import('esri/Map'),
          typeof import('esri/views/MapView'),
          typeof import('esri/layers/FeatureLayer'),
          typeof import('esri/widgets/Search'),
          typeof import('esri/widgets/Search/LocatorSearchSource'),
          typeof import('esri/tasks/Locator'),
          typeof import('esri/geometry/Extent')
        ]>
      )
      .then(([ArcGISMap, MapView, FeatureLayer, Search, LocatorSearchSource, Locator, Extent]) => {
        const map = new ArcGISMap({
          basemap: 'topo'
        });

        const zoningLayer = new FeatureLayer({
          url: config.arcGIS.layerUrls.zoning,
          maxScale: layerVisibilityZoomBreakpoint,
        });
        map.add(zoningLayer);

        const cookParcelLayer = new FeatureLayer({
          url: config.arcGIS.layerUrls.cookParcel,
          minScale: layerVisibilityZoomBreakpoint,
        });
        map.add(cookParcelLayer);

        const kaneParcelLayer = new FeatureLayer({
          url: config.arcGIS.layerUrls.kaneParcel,
          minScale: layerVisibilityZoomBreakpoint,
        });
        map.add(kaneParcelLayer);


        const masterAddressLayer = new FeatureLayer({
          url: config.arcGIS.layerUrls.masterAddress,
          outFields: ["FULL_ADR", "Current_Land_Use", "Historic_District", "Zoning", "LandParcelMasterAddress", "SF_ID"],
          popupTemplate: {
            title: "{FULL_ADR}",
            content: `
              <p style="margin-bottom: 5px;"><b>${t('Zoning.ParcelZoning')}:</b> {Zoning}</p>
              <p style="margin-bottom: 5px;"><b>${t('Zoning.CurrentLandUse')}:</b> {Current_Land_Use}</p>
              <p style="margin-bottom: 5px;"><b>${t('Zoning.HistoricDistrict')}:</b> {Historic_District}</p>
            ` // Tried to use callback for this instead, like on Search, but some fields were missing?
          },
          minScale: layerVisibilityZoomBreakpoint
        });
        map.add(masterAddressLayer);

        const view = new MapView({
          container: mapRef.current,
          map: map,
          center: [elginCenter.lng, elginCenter.lat],
          zoom: 17,
          constraints: {
            minZoom: 10,
            maxZoom: 21
          }
        });

        const generatePopupContent = (zoning: string, landUse: string, historicDistrict: string) => `
          <p style="margin-bottom: 5px;"><b>${t('Zoning.ParcelZoning')}:</b> ${zoning}</p>
          <p style="margin-bottom: 5px;"><b>${t('Zoning.CurrentLandUse')}:</b> ${landUse}</p>
          <p style="margin-bottom: 5px;"><b>${t('Zoning.HistoricDistrict')}:</b> ${historicDistrict}</p>
        `;

        const noMatchesPopupContent = `<p>${t('Zoning.NoLandParcelMatchesPopupContent')}</p>`;
        const errorPopupContent = `<p>${t('Zoning.MapSearchErrorPopupContent')}</p>`

        const search = new Search({
          view: view,
          includeDefaultSources: false,
          sources: [
            new LocatorSearchSource({
              locator: new Locator({ url: "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer" }),
              placeholder: t('Zoning.MapSearchPrompt'),
              outFields: ["AddNum", "StName", "StType", "Postal", "UnitName", "ShortLabel", "LongLabel"],
              filter: {
                geometry: new Extent({
                  ymax: elginBounds.north,
                  ymin: elginBounds.south,
                  xmax: elginBounds.east,
                  xmin: elginBounds.west
                })
              },
              popupTemplate: {
                title: "{LongLabel}",
                content: async ({ graphic }: any) => {
                  if (graphic && graphic.attributes) {
                    const { AddNum, StName, StType, Postal, UnitName, ShortLabel } = graphic.attributes;
                    const sfSearchParams = { params: { AddNum, StName, StType, Postal, UnitName } };

                    try {

                      const getSalesforceLandParcelResponse = (await axios.get('/salesforce/landParcelMaster', sfSearchParams)).data as SalesforceLandParcel;
                      if (getSalesforceLandParcelResponse) {
                        const { Parcel_Primary_Zoning__c, Parcel_Current_Land_Use__c, Historic_District__c, Id } = getSalesforceLandParcelResponse;
                        dispatchLandParcel({salesforceId: Id, address: ShortLabel});
                        return generatePopupContent(Parcel_Primary_Zoning__c ?? '', Parcel_Current_Land_Use__c ?? '', Historic_District__c ?? '');
                      } else {
                        dispatchLandParcel(null);
                        return noMatchesPopupContent;
                      }

                    } catch (err) {
                      console.log(err)
                      dispatchLandParcel(null);
                      return errorPopupContent;
                    }

                  } else {
                    dispatchLandParcel(null);
                    return noMatchesPopupContent;
                  }
                }
              }
            })
          ]
        });
        view.ui.add(search, "top-right");

        view.on("click", (event) => {
          const screenPoint = {
            x: event.x,
            y: event.y
          };

          view.hitTest(screenPoint).then(({ results }) => {
            if (results.length) {

              const filteredResults = results.filter((results) => {
                return results?.graphic?.layer === masterAddressLayer;
              })

              if (filteredResults.length) {
                const { graphic } = filteredResults[0];
                const { attributes } = graphic ?? {};
                if (attributes?.SF_ID && attributes?.FULL_ADR) {
                  dispatchLandParcel({salesforceId: attributes.SF_ID, address: attributes.FULL_ADR});
                }
              }
            }
          });
        });

        view.watch('zoom', debounce((zoomLevel: any) => {
          reportCurrentZoomLevel(zoomLevel);
        }, 300))

        return () => {
          if (view) {
            // @ts-ignore destroy the map view
            view.container = null;
          }
        };

      })
      .catch((err: any) => {
          console.log('Caught mapview error', err);
          alert('Could not load map');
      })
    }, [dispatch, reportCurrentZoomLevel, setSalesforceLandParcel, t]);
    // @ts-ignore
    return <div style={{width, height}} className="webmap" ref={mapRef} />;
});