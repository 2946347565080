import React, { useRef, useEffect, useState } from 'react';
import styles from './LaserficheEmbed.module.scss';
import {
    useAppStateLaserficheEmbedSelector,
    setFormSubmitted,
    setIsLoading,
    setUnrecoverableErrorMessage,
    setInitialForm
} from './laserficheEmbedSlice';
import { useDispatch } from 'react-redux';
import { config } from '../../../config';
import { Spinner } from '../../../components/spinner/Spinner';
import { Message } from 'semantic-ui-react';
import { useAuthSelector } from '../../auth/authSlice';
import { BackArrow } from '../../../components/backArrow/BackArrow';
import { setAccountNumber, setAccountType, setAmount, setPaymentCode } from '../../payment/paymentSlice';
import { useHistory } from 'react-router-dom';
import { submitLaserficheToPortal } from './laserficheEmbedActionCreators';
import { useTranslation } from 'react-i18next';

declare const LaserficheForms: any;

export function LaserficheEmbed({location} : {location: Location}) {
    const { t } = useTranslation();
    const [height, setHeight] = useState('500px');
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();

    const urlQueryParams = new URLSearchParams(location.search);
    const qsFormTitle = urlQueryParams.get('formTitle');
    const qsBpid = urlQueryParams.get('bpid');
    const qsPaymentCode = urlQueryParams.get('paymentCode');
    const qsParams = urlQueryParams.get('params');
    const bpid = qsBpid; // Set immediately to allow LF to load
    useEffect(() => {
        dispatch(setInitialForm({formTitle: qsFormTitle, bpid: qsBpid, params: JSON.parse(qsParams ?? "{}")}));
    }, [dispatch, qsFormTitle, qsBpid, qsParams]);

    const { authUser, selectedOrganizationInfo }= useAuthSelector(state => state)
    const {
        portalSubmissionId,
        formSubmitted,
        formTitle,
        isLoading,
        unrecoverableErrorMessage,
    } = useAppStateLaserficheEmbedSelector(state => state);

    const formSubmissionRedirect = (feeTotalString?: string) => {
        // feeTotal will be gotten from 
        const feeTotal = parseFloat(feeTotalString ?? '0');
        if (feeTotal > 0) {
            // set payment state
            dispatch(setAmount(feeTotal));
            dispatch(setAccountType('Form Fee'));
            dispatch(setAccountNumber('000000000-000000000'))
            dispatch(setPaymentCode(qsPaymentCode));
            // redirect to payment
            history.push('/payment')
            return;
        }
        // handling for non-payment forms, right now we stay on the page
        return;
    }

    const iframeMessageListener = (e: MessageEvent) => {
        if (e.data.source !== 'LASERFICHE_FORM') return;
        if (e.data.height) {
            const pixelHeight = (e.data.height + 100) + 'px';
            if (height !== pixelHeight) {
                setHeight(pixelHeight);
            }
        }
        if (e.data.scroll) {
            window.scrollTo(0,0);
        }
        if (e.data.submitSuccess) {
            dispatch(setFormSubmitted(true));
            formSubmissionRedirect(e.data.feeTotal);
            setHeight('100%');
        }
    }

    useEffect(() => {
        dispatch(setIsLoading(true));
        window.addEventListener("message", iframeMessageListener);

        const params = {
            ...JSON.parse(qsParams ?? "{}"),
            portalSubmissionId,
            SFDC_ContactID: selectedOrganizationInfo && selectedOrganizationInfo.SalesforceId ? selectedOrganizationInfo.SalesforceId : authUser?.SalesforceId,
        } as any;
        const lfQueryParams = params ? Object.keys(params).map(key => key + '=' + params[key]).join('&') : null;
        const formOptions = {
            bpid: bpid,
            host: config.laserfiche.formUrl,
            params: lfQueryParams,
            onloadCallback: () => dispatch(setIsLoading(false)),
        }
        const lfembed = new LaserficheForms.Form(formRef.current, formOptions);

        if (!lfembed) {
            console.error(`Failed to load LF form bpid: ${bpid}`);
            dispatch(setIsLoading(false));
            dispatch(setUnrecoverableErrorMessage('Sorry, an error was encountered and the form could not be loaded'));
        }
        return (() => window.removeEventListener("message", iframeMessageListener));
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (formSubmitted) {
            dispatch(submitLaserficheToPortal(portalSubmissionId, bpid!));
        }
    }, [dispatch, formSubmitted, portalSubmissionId, bpid]);

    return (
        <div className={styles.page_container} style={{ height: height ?? '500px' }}>
            <div className='headerContainer'>
                <h2>{formTitle}</h2>
                <Spinner isLoading={isLoading} />
            </div>
            <BackArrow />
            { formSubmitted ? 
                <>
                    <h3>{t('Activity.ThankYouForSubmitting')}</h3>
                    <p>{t('Activity.TrackYourActivityProgress')}</p>
                </>
            : unrecoverableErrorMessage ?
                <Message error header={"Error"} content={unrecoverableErrorMessage} />
            :
                <div
                    id='LFembed'
                    className={styles.iframe_container}
                    ref={formRef}
                />
            }
        </div>
    )
}