import {AxiosService} from './axiosService';
import {AxiosError} from 'axios';
import {BillingHistoryResponse, DelinquentInformationResponse, NavilinePaymentHistory, NavilinePaymentHistoryV2} from '../models/navilinePaymentHistory';

export class NavilineService extends AxiosService {
    public constructor() { super(); }

    async getPaymentHistory(organizationId: string | null) {
        try {
            const route = organizationId ? `/naviline/paymenthistory?organizationId=${organizationId}` : '/naviline/paymenthistory';
            const {data} = await this.axios.get<NavilinePaymentHistory[]>(route);
            return data;
        } catch (e) {
            // if the status code is a 401 we do NOT want to throw
            if ((e as AxiosError)?.response?.status !== 401) {
                throw e;
            }
        }
        return null;
    }

    async getPaymentHistoryV2(organizationId: string | null) {
        try {
            const route = organizationId ? `/naviline/paymenthistoryv2?organizationId=${organizationId}` : '/naviline/paymenthistoryv2';
            const {data} = await this.axios.get<NavilinePaymentHistoryV2>(route);
            return data;
        } catch (e) {
            // if the status code is a 401 we do NOT want to throw
            if ((e as AxiosError)?.response?.status !== 401) {
                throw e;
            }
        }
        return null;
    }

    async getBillingHistory() {
        try {
            const route = `/naviline/getbillinghistory`;
            const {data} = await this.axios.get<BillingHistoryResponse>(route);
            return data;
        } catch (e) {
            // if the status code is a 401 we do NOT want to throw
            if ((e as AxiosError)?.response?.status !== 401) {
                throw e;
            }
        }
        return null;
    }

    async getBusinessLicenseFeesDue(year: string, accountNumber: string, pin: string) {
        try {
            const route = `/licenses/renew/calc`;
            const {data} = await this.axios.post<{success: boolean, message?: string, fees?: number, penalty?: number}>(route, {
                licenseYear: year.slice(-2),
                licenseNumber: accountNumber,
                licensePIN: pin,
            });
            return data;
        } catch(e) {
            // if the status code is a 401 we do NOT want to throw
            if ((e as AxiosError)?.response?.status !== 401) {
                throw e;
            }
        }
        return null;
    }

    async getDelinquencyInformation() {
        try {
            const route = `/naviline/getdelinquentstatus`;
            const {data} = await this.axios.get<DelinquentInformationResponse[]>(route);
            return data;
        } catch (e) {
            // if the status code is a 401 we do NOT want to throw
            if ((e as AxiosError)?.response?.status !== 401) {
                throw e;
            }
        }
        return null;
    }
}

export const navilineService = new NavilineService();
