import React, { useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { Message } from 'semantic-ui-react';
import styles from './LanguagesEdit.module.scss';
import { getTranslations, saveTranslations } from './languagesEditActionCreators';
import { useAppStateLanguagesEditSelector, setTranslation, setFieldErrors, clearFieldErrors } from './languagesEditSlice';
import { Translation, Plurality } from './languagesEditAppState';
import { Button } from '../../../components/button/Button';
import { Textarea } from '../../../components/textArea/Textarea';
import { ErrorValidationLabel } from '../../../components/errorValidationLabel/ErrorValidationLabel';
import { PanelRow } from '../../../components/panel/PanelRow';
import { Panel } from '../../../components/panel/Panel';
import { BackArrow } from '../../../components/backArrow/BackArrow';
import { Spinner } from '../../../components/spinner/Spinner';
import { PageContainer } from '../../../components/pageContainer/PageContainer';

interface TranslationGroupProps {
    group: string,
    translations: {
        [uuid: string]: Translation
    }
}

interface TranslationPairProps {
    translation: Translation,
    index: number
}

interface TranslationTextareaProps {
    label: string,
    plurality: Plurality,
    translation: Translation
}

const TranslationGroup = ({ group, translations }: TranslationGroupProps) => (
    <PanelRow>
        <Panel>
            <h3>{group}</h3>
            <div className={styles.listing_table}>
                {Object.values(translations).map((translation, index) => (
                    <MemoizedTranslationPair translation={translation} index={index} key={translation.Id}/>
                ))}
            </div>
        </Panel>
    </PanelRow>
);

const TranslationPair = ({ translation, index }: TranslationPairProps) => {
    return (
        <div className={`${styles.list_item} listItemColor`}>
            <p>{translation.LanguageKey}</p>
            <div className={styles.inner_panel_container}>
                <TranslationTextarea label="Singular Form" plurality="SingularForm" translation={translation} />
                <TranslationTextarea label="Plural Form" plurality="PluralForm" translation={translation} />
            </div>
        </div>
    );
}

const MemoizedTranslationPair = memo(TranslationPair, (prevProps, nextProps) => {
    return prevProps.translation.SingularForm === nextProps.translation.SingularForm &&
        prevProps.translation.PluralForm === nextProps.translation.PluralForm
});

const TranslationTextarea = ({ label, plurality, translation } : TranslationTextareaProps) => {
    const fieldErrors = useAppStateLanguagesEditSelector(state => state.fieldErrors);
    const dispatch = useDispatch();
    const { Id, Group } = translation;
    const onChange = (e: any, data: any) => {
        dispatch(setTranslation({
            value: data.value,
            Id,
            Group,
            plurality
        }))
    };
    return (
        <div className={styles.textarea_container}>
            <div>{label}</div>
            <Textarea value={translation[plurality] ?? ""} onChange={onChange} />
            { plurality === 'SingularForm' && fieldErrors.hasOwnProperty(Id) &&
                <ErrorValidationLabel fieldDescription={label} reasoning="Required" />
            }
        </div>
    );
}


export function LanguagesEdit() {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const language = location.pathname.split("languages/")[1];

    useEffect(() => {
        dispatch(getTranslations(language));
        return () => {
            dispatch(clearFieldErrors({}));
        }
    }, [dispatch, language]);

    const groupedTranslations = useAppStateLanguagesEditSelector(state => state.translations);
    const { errorMessage, loading } = useAppStateLanguagesEditSelector(state => state);

    if (errorMessage) {
        return <Message error header={"Error"} content={errorMessage} />;
    }

    const onSubmit = () => {
        dispatch(clearFieldErrors({}));
        const errors : { [Id: string]: boolean }= {};
        Object.values(groupedTranslations).forEach(translations => {
            Object.values(translations).forEach(translation => {
                // Trim to remove possibility of deleting word but leaving whitespace
                if (!translation.SingularForm.trim()) {
                    errors[translation.Id] = true;
                }
            })
        })
        dispatch(setFieldErrors({ errors }));
        if (Object.keys(errors).length) return;

        dispatch(saveTranslations(groupedTranslations));
        history.push('/languages');
    }

    const TranslationGroups = Object.entries(groupedTranslations).map(([group, translations]) => (
        <TranslationGroup group={group} translations={translations} key={group} />
    ));

    return (
        <PageContainer>
            <div className={styles.page_header}>
                <div className='headerContainer'>
                    <h2>{language}</h2>
                    <Spinner isLoading={loading} />
                </div>
                <div className={styles.submit_container}>
                    <Button color='blue' onClick={onSubmit}>Submit</Button>
                </div>
            </div>
            <BackArrow url="/languages" text="Back to Languages" />
            {TranslationGroups}
        </PageContainer>
    )
}