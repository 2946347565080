import { axios } from '../../services/axiosService';
import { Dispatch } from "redux";
import { AppState } from "../../store/appState";
import { setLoading, setSearchResults } from "./searchSlice";

export const loadSearchResults = (term: string, page: number) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        dispatch(setLoading(true));
        const searchResponse = (await axios.get(`/search?term=${term}&page=${page}`)).data as any;
        dispatch(setSearchResults(searchResponse));
        dispatch(setLoading(false));
    } catch(e) {
        dispatch(setLoading(false));
    }
};
