import React, { useEffect } from 'react';
import styles from '../ActivitiesView.module.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PageContainer } from '../../../../components/pageContainer/PageContainer';
import { Panel } from '../../../../components/panel/Panel';
import { PanelRow } from '../../../../components/panel/PanelRow';
import { Spinner } from '../../../../components/spinner/Spinner';
import { loadInProgressActivities } from '../activitiesViewActionCreators';
import { InProgressFormatted, useAppStateActivitiesInProgressSelector } from '../activitiesViewSlice';
import { Link } from 'react-router-dom';
import { Icon } from '../../../../components/icon/Icon';

interface ActivityTableProps {
    items: InProgressFormatted[],
}

export function ActivityTable ({ items }: ActivityTableProps) {
    const { t } = useTranslation();
    return (
        <div className={styles.tableContainer}>
            <div className={styles.tableHeader}>
                <div className={styles.name}>
                    {t('InProgress.Activity')}
                </div>
                <div className={styles.status}>
                    {t('InProgress.Status')}
                </div>
                <div className={styles.timestamp}>
                    {t('InProgress.LastModified')}
                </div>
            </div>
            {items?.map(item =>
                <Link to={`/activity-view/${item.type}/${item.id}`} className={`listItemColor ${styles.item}`} key={item.id}>
                    <div className={styles.name}>
                        {item.actionRequired &&
                            <Icon 
                                icon="exclamation triangle"
                                color="orange"
                            />
                        }
                        {item.type} ({item.activityName})
                    </div>
                    <div className={styles.status}>
                        <div className={`${styles.statusTag} ${styles[item.statusInformation.color]}`}>
                            {t(`InProgress.${item.statusInformation.translationKey}`)}
                        </div>
                    </div>
                    <div className={styles.timestamp}>
                        {item.lastModifiedDisplay}
                    </div>
                </Link>
            )}
        </div>
    )
}

export const ActivitiesInProgress = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(loadInProgressActivities());
    }, [dispatch]);

    const { loading, active } = useAppStateActivitiesInProgressSelector(state => state);
    
    return (
        <PageContainer>
            <div className='headerContainer'>
                <h2>{t('InProgress.InProgress')}</h2>
                <Spinner isLoading={loading} /> 
            </div>
            <PanelRow>
                <Panel>
                    <ActivityTable items={active} />
                </Panel>
            </PanelRow>
        </PageContainer>
    );
}