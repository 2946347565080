import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab } from 'semantic-ui-react';
import { NavilinePaymentHistory } from '../../models/navilinePaymentHistory';
import { BoxIntegration } from '../boxIntegration/BoxIntegration';

const RecordPane = ({ paymentHistoryRecord }: { paymentHistoryRecord: NavilinePaymentHistory }) => {
    const utilityAccountNumber = paymentHistoryRecord.accountInfo.customerId + '-' + paymentHistoryRecord.accountInfo.locationId;
    return (
        <Tab.Pane
            attached={false}
        >
            <BoxIntegration
                readonly
                utilityAccount={utilityAccountNumber}
            />
        </Tab.Pane>
    );
}

export const BillPayRecords = ({ paymentHistory }: { paymentHistory: NavilinePaymentHistory[] | null | undefined }) => {
    const { t } = useTranslation();

    const panes = useMemo(() => {
        if (!paymentHistory) return null;
        return paymentHistory.map(ph => {
            const utilityAccountNumber = ph.accountInfo.customerId + '-' + ph.accountInfo.locationId;
            return {
                menuItem: utilityAccountNumber,
                render: () => <RecordPane paymentHistoryRecord={ph} />
            }
        })
    }, [paymentHistory]);

    if (!panes) return null;

    return (
        <>
            <h3>{t('Dashboard.BillingHistoryRecords')}</h3>
            <Tab
                menu={{
                    secondary: true,
                    pointing: true,
                }}
                panes={panes}
                renderActiveOnly={true}
                defaultActiveIndex={0}
            />
            {/* {panes?.map(p => p.render())} */}
        </>
    )
}
