import React from 'react';
import { firebaseService } from '../../services/firebaseService';
import styles from './Header.module.scss';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppStateHeaderSelector } from './headerSlice';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react';
import { setLanguage } from './headerActionCreators';
import { useTranslation } from 'react-i18next';
import { UserIcon } from '../userIcon/UserIcon';
import { useAuthSelector } from '../../features/auth/authSlice';
import { useAppStateMessagesSelector } from '../../features/messages/messagesSlice';

export const HeaderUserDropdown = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { languageOptions } = useAppStateHeaderSelector((state) => state);
    const { messages } = useAppStateMessagesSelector((state) => state);
    const { authUser } = useAuthSelector((state) => state);

    const changeLanguage = (e: any, data: any) => {
        i18n.changeLanguage(data.value);
        dispatch(setLanguage(data.value));
    };

    const logout = async () => {
        await firebaseService.auth().signOut();
    };

    const numNotifications = messages
        ? messages.reduce(
              (acc, msg) => (msg.ReadAt === null ? acc + 1 : acc),
              0,
          )
        : 0;

    return (
        <SemanticDropdown
            floating
            button
            labeled
            direction="left"
            trigger={
                <UserIcon
                    numNotifications={numNotifications}
                    iconUrl={authUser?.ProfilePictureUrl ?? null}
                    border
                />
            }
        >
            <SemanticDropdown.Menu className={styles.userDropdown}>
                <SemanticDropdown.Header className={styles.userDropdownHeader}>
                    <div className={styles.userDropdownHeaderTop}>
                        <UserIcon
                            iconUrl={authUser?.ProfilePictureUrl ?? null}
                        />
                        <div className={styles.userDropdownHeaderInfo}>
                            <div className={styles.userDropdownHeaderName}>
                                {authUser!.FirstName + ' ' + authUser!.LastName}
                            </div>
                            <div className={styles.userDropdownHeaderEmail}>
                                {authUser!.Email}
                            </div>
                        </div>
                    </div>
                    <Link
                        to={`/account/${authUser?.Id}`}
                        className={styles.userDropdownHeaderManageAccount}
                    >
                        {t('Menu.ManageMyAccount')}
                    </Link>
                </SemanticDropdown.Header>
                <SemanticDropdown.Divider
                    className={styles.userDropdownDivider}
                />
                <SemanticDropdown.Item
                    className={styles.userDropdownItem}
                    as={Link}
                    to="/messages"
                >
                    {t('MobileMenu.Message_plural')}
                </SemanticDropdown.Item>
                <SemanticDropdown.Divider
                    className={styles.userDropdownDivider}
                />
                <SemanticDropdown.Item
                    id="userNestedDropdownContainer"
                    className={styles.userDropdownItem}
                >
                    <SemanticDropdown
                        className={styles.userDropdownNestedInner}
                        text={t('SideBar.Language')}
                        options={languageOptions}
                        onChange={changeLanguage}
                        icon={null}
                    />
                </SemanticDropdown.Item>
                <SemanticDropdown.Divider
                    className={styles.userDropdownDivider}
                />
                <SemanticDropdown.Item
                    className={styles.userDropdownItem}
                    onClick={logout}
                >
                    {t('Login.Logout')}
                </SemanticDropdown.Item>
            </SemanticDropdown.Menu>
        </SemanticDropdown>
    );
};
