import { Dispatch } from "redux";
import { axios } from "../../services/axiosService";
import { AppState } from "../../store/appState";
import { resetState, setLoading, setError, setSearchLoading, setSearchResults, setSearchValue } from './organizationSearchSlice';

export const businessSearch = (name: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setSearchValue(name));
    dispatch(setError(false));
    if (name.length >= 3) {
        try {
            dispatch(setSearchLoading(true));

            const state = getState();
            const currentOrganizationIds = state.accountCreate.businessInformation.myOrganizations.length ? state.accountCreate.businessInformation.myOrganizations.map(mc => mc.taxId) : [];

            const businessResults = (await axios.get(`/salesforce/organizations?name=${encodeURIComponent(name)}`)).data.searchRecords as any[]; // TODO: abstract to service and type
            const formattedResults = businessResults.filter(br => !currentOrganizationIds.includes(br.Id)).slice(0, 7).map((br: any) => ({title: br.Name, id: br.Id}));

            dispatch(setSearchResults(formattedResults));
            dispatch(setSearchLoading(false));
        } catch (err) {
            console.error(err);
            dispatch(setSearchResults([]));
            dispatch(setSearchLoading(false));
        }
    }
}

export const submitBusinessInformation = (callback: () => void) => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const businessInformation = state.accountCreate.businessInformation;
    dispatch(setLoading(true));
    try {
        dispatch(resetState(true));
        const organizations = businessInformation.myOrganizations.map(org => {
            const formattedOrg = {
                name: org.name,
                taxId: org.taxId,
                businessLicenseId: org.businessLicenseId,
                sfId: org.sfId,
                address1: org.address1,
                address2: org.address2,
                city: org.city,
                state: org.state,
                zip: org.zip
            };

            return formattedOrg;
        });


        const userOrgResult = (await axios.post('/salesforce/matchOrCreateOrgsToSalesforceOrgs', {organizations}))
        if (userOrgResult.data) {
            dispatch(setLoading(false));
            callback();
        } else {
            console.error(userOrgResult);
            dispatch(setError(true));
            dispatch(setLoading(false));
        }
    } catch (err) {
        console.error(err);
        dispatch(setLoading(false));
    }
}