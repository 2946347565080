import { Dispatch } from 'redux';
import { messageModalActions } from './messageModalViewSlice';

interface options {
    caseNumber?: string;
    paymentNumber?: string;
}

export const openMessageModal = (messageContents: null | undefined | string, messageType: 'error' | 'success' | 'warning', messageOptions?: options) => async (dispatch: Dispatch) => {
    if (messageType !== 'warning') {
        dispatch(messageModalActions.setMessageModalOpen(true));
    }
    dispatch(messageModalActions.setCaseNumber(messageOptions ? (messageOptions.caseNumber ? messageOptions.caseNumber : '') : ''));
    dispatch(messageModalActions.setPaymentNumber(messageOptions ? (messageOptions.paymentNumber ? messageOptions.paymentNumber : '') : ''));

    switch(messageType) {
        case 'error':
            dispatch(messageModalActions.setImage('error'));
            dispatch(messageModalActions.setHeader('Notification.Oops'));
            dispatch(messageModalActions.setBody('Notification.GenericError'));
            dispatch(messageModalActions.setButtonColor('red'));
            dispatch(messageModalActions.setFirstButtonText('Util.Close'));
            dispatch(messageModalActions.setContainsTwoButtons(false));
            break;
        case 'success':
            dispatch(messageModalActions.setImage('success'));
            dispatch(messageModalActions.setHeader('Notification.Success'));
            dispatch(messageModalActions.setBody('Notification.ChangesSaved'));
            dispatch(messageModalActions.setButtonColor('green'));
            dispatch(messageModalActions.setFirstButtonText('Util.Okay'));
            dispatch(messageModalActions.setContainsTwoButtons(false));
            break;
        case 'warning':
            dispatch(messageModalActions.setImage('warning'));
            dispatch(messageModalActions.setButtonColor('red'));
            dispatch(messageModalActions.setContainsTwoButtons(true));
            break;
    }

    switch(messageContents) {
        // generic success/error messages
        case null || undefined:
            break;
        case 'success-add':
            dispatch(messageModalActions.setBody('Notification.SuccessAdd'));
            break;
        case 'success-remove':
            dispatch(messageModalActions.setBody('Notification.SuccessRemove'));
            break;
        case 'success-update':
            dispatch(messageModalActions.setBody('Notification.SuccessUpdate'));
            break;
        case 'error-add':
            dispatch(messageModalActions.setHeader('Notification.Error'));
            dispatch(messageModalActions.setBody('Notification.ErrorAdd'));
            break;
        case 'error-remove':
            dispatch(messageModalActions.setHeader('Notification.Error'));
            dispatch(messageModalActions.setBody('Notification.ErrorRemove'));
            break;
        case 'error-update':
            dispatch(messageModalActions.setHeader('Notification.Error'));
            dispatch(messageModalActions.setBody('Notification.ErrorUpdate'));
            break;
        
        // specific success/error messages
        case 'add-system-alert':
            dispatch(messageModalActions.setHeader('Alert.AlertCreatedHeader'));
            dispatch(messageModalActions.setBody('Alert.AlertCreatedBody'));
            break;
        case 'update-system-alert':
            dispatch(messageModalActions.setHeader('Alert.AlertUpdatedHeader'));
            dispatch(messageModalActions.setBody('Alert.AlertUpdatedBody'));
            break;
        case 'delete-system-alert':
            dispatch(messageModalActions.setHeader('Alert.AlertDeletedHeader'));
            dispatch(messageModalActions.setBody('Alert.AlertDeletedBody'));
            break;
        case 'naviline-account-mismatch':
            dispatch(messageModalActions.setHeader('Util.Error'));
            dispatch(messageModalActions.setBody('Notification.ErrorUtilityAccountNoMatch'));
            break;
        case 'check-your-email':
            dispatch(messageModalActions.setBody('Login.CheckYourEmail'));
            break;
        case 'duplicate-payment-account':
            dispatch(messageModalActions.setHeader('Billing.AddWaterBillingAccountInformation'));
            dispatch(messageModalActions.setBody('Account.AddDuplicateAccountError'));
            break;
        case 'success-cancel-activity':
            dispatch(messageModalActions.setBody('Notification.SuccessActivityCancel'));
            break;
        case 'success-submit-additional-information':
            dispatch(messageModalActions.setHeader('Notification.InformationSubmitted'));
            dispatch(messageModalActions.setBody('Notification.SuccessInfoSubmitted'));
            break;
        case 'success-submit-case':
            dispatch(messageModalActions.setHeader('Notification.CaseSubmitted'));
            dispatch(messageModalActions.setBody(''));
            break;
        case 'success-submit-payment':
            dispatch(messageModalActions.setHeader('Notification.PaymentSubmitted'));
            dispatch(messageModalActions.setBody(''));
            break;
        case 'success-submit-payment-without-paymentNumber':
            dispatch(messageModalActions.setHeader('Notification.PaymentSubmitted'));
            dispatch(messageModalActions.setBody('Notification.SuccessPaymentSubmittedWithoutNumber'));
            break;
        case 'success-sumbit-form':
            dispatch(messageModalActions.setBody('Notification.SuccessFormSubmitted'));
            break;
        case 'error-sf-account-exists':
            dispatch(messageModalActions.setHeader('Util.Error'));
            dispatch(messageModalActions.setBody('Notification.SFAccountExists'));
            break;
        case 'success-delete-account':
            dispatch(messageModalActions.setBody('Notification.SuccessDeleteAccount'));
            break;

        // two button options
        case 'warning-delete-account':
            dispatch(messageModalActions.setHeader('Notification.ConfirmDeleteTitle'));
            dispatch(messageModalActions.setBody('Notification.ActionCannotBeUndone'));
            dispatch(messageModalActions.setButtonColor('red'));
            dispatch(messageModalActions.setFirstButtonText('Util.Delete'));
            break;
        case 'warning-delete-saved-card-with-autopay':
            dispatch(messageModalActions.setHeader('Notification.ConfirmDeleteSavedCardTitle'));
            dispatch(messageModalActions.setBody('User.ConfirmDeleteSavedCardBodyAdmin'));
            dispatch(messageModalActions.setButtonColor('red'));
            dispatch(messageModalActions.setFirstButtonText('Util.Agree'));
            break;
        case 'warning-delete-user-account':
            dispatch(messageModalActions.setHeader('Are you sure you want to delete this account?'));
            dispatch(messageModalActions.setBody('Notification.ActionCannotBeUndone'));
            dispatch(messageModalActions.setButtonColor('red'));
            dispatch(messageModalActions.setFirstButtonText('Util.Delete'));
            break;
        case 'warning-remove-autopay-admin':
            dispatch(messageModalActions.setHeader('Account.RemoveUtilityAccount'));
            dispatch(messageModalActions.setBody('User.RemoveUtilityAccountDescAdmin'));
            dispatch(messageModalActions.setButtonColor('red'));
            dispatch(messageModalActions.setFirstButtonText('Util.Agree'));
            break;
        case 'warning-delete-saved-card':
            dispatch(messageModalActions.setHeader('Notification.DeleteSavedCardWOutAutopayTitle'));
            dispatch(messageModalActions.setBody('Notification.DeleteSavedCardWOutAutopayBody'));
            dispatch(messageModalActions.setButtonColor('red'));
            dispatch(messageModalActions.setFirstButtonText('Util.Agree'));
            break;
        case 'warning-remove-autopay':
            dispatch(messageModalActions.setHeader('Account.RemoveUtilityAccount'));
            dispatch(messageModalActions.setBody('Account.RemoveUtilityAccountDesc'));
            dispatch(messageModalActions.setButtonColor('red'));
            dispatch(messageModalActions.setFirstButtonText('Util.Agree'));
            break;
        default:
            break;
    }
};
