import React, { useEffect, useState } from 'react';
import styles from './UserViewV2.module.scss';
import { PageContainer } from '../../../components/pageContainer/PageContainer';
import { Panel } from '../../../components/panel/Panel';
import { PanelRow } from '../../../components/panel/PanelRow';
import { useTranslation } from 'react-i18next';
import { User } from '../../../models/user';
import { userService } from '../../../services/userService';
import { useParams } from 'react-router';
import { Spinner } from '../../../components/spinner/Spinner';
import { openMessageModal } from '../../../components/statusModal/messageModalActionCreators';
import { useDispatch } from 'react-redux';
import { BackArrowDarkBlue } from '../../../components/backArrow/BackArrowDarkBlue';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { PayBillButton as ButtonV2 } from '../../../components/button/PayBillButton';

export const UserViewV2 = () => {
    const fallbackIcon = '/img/user.png';
    const { t } = useTranslation();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState<User | null>(null);
    const [paymentHistory, setPaymentHistory] = useState<any[]>([]);
    const [userActivityHistory, setUserActivityHistory] = useState<any[]>([]);
    const { id, prev_page } = useParams() as any;
    const dispatch = useDispatch();

    const displayActivityDescription = (record: any) => {
        if (record.EventName === 'CreateActivity') {
            return t(`Activity.${record.Action}_${record.Item}`);
        } else if (record.EventName === 'UserSession') {
            const jsonData = JSON.parse(record.EventData);
            return (
                <>
                    <p className={styles.activityUserSessionDescriptionParagraphs}>{`${t('User.IPAddress')}: ${jsonData.ipAddress}`}</p>
                    <p className={styles.activityUserSessionDescriptionParagraphs}>{`${t('User.Time')}: ${moment(record.created_at).tz('America/Chicago').format('MM/DD/YY h:mm:ss A')}`}</p>
                    <p className={styles.activityUserSessionDescriptionParagraphs}>{`${t('User.UserAgent')}: ${jsonData.userAgent}`}</p>
                </>
            );
        } else {
            return record.EventData;
        }
    }

    const sendForgetPasswordEmail = async () => {
        setLoading(true);
        if (user?.Email) {
            try {
                await userService.sendForgetPasswordEmail(user?.Email);
                dispatch(openMessageModal(null, 'success'));
            } catch (err) {
                console.log('Error sending user forgot password email: ', err);
                dispatch(openMessageModal(null, 'error'));
            }
        } else {
            console.log('No email for the user was found!');
            dispatch(openMessageModal(null, 'error'));
        }
        setLoading(false);
    }

    const sendVerificationEmail = async () => {
        if (user?.Email) {
            try {
                await userService.sendVerificationEmail(user?.Email);
                dispatch(openMessageModal(null, 'success'));
            } catch (err) {
                console.log('Error sending user verification email: ', err);
                dispatch(openMessageModal(null, 'error'));
            }
        } else {
            console.log('No email for the user was found!');
            dispatch(openMessageModal(null, 'error'));
        }
    }

    useEffect(() => {
        (async() => {
            try {
                const userDetails = await userService.getUserProfileDetails(id);
                setUser(userDetails.user);
                setPaymentHistory(userDetails.paymentHistory);
                setUserActivityHistory(userDetails.userActivities);
                setLoading(false);
            } catch(err) {
                console.log('Error fetching user: ', err);
                dispatch(openMessageModal(null, 'error'));
            }
        })();
    }, [setLoading, setUser, id, dispatch]);

    return (
        <PageContainer>
            {prev_page &&
                <div className={styles.backToHomepageArrow} style={{ width: 'fit-content' }}>
                    {prev_page === 'users' &&
                        <BackArrowDarkBlue url={`/${prev_page}`} text={t('User.BackToUserList')} />
                    }
                </div>
            }

            <div className={styles.pageHeader}>
                <div className='headerContainer'>
                </div>
            </div>

            {user &&
                <PanelRow>
                    <Panel style={{ display: 'flex', flexDirection: 'row', padding: 0, position: 'relative' }}>
                        <div className={`${styles.innerPanelContainer} ${styles.userInfoColumn}`}>
                            {user.ProfilePictureUrl && user.ProfilePictureUrl !== '' ?
                                <img src={user.ProfilePictureUrl} alt='visual of selected user' className={styles.profilePicture} />
                                :
                                <img src={fallbackIcon} alt='visual of selected user' className={styles.profilePicture} />
                            }
                            <h3 className={styles.fullName}>{(user.FirstName ? (user.FirstName + ' ') : '') + (user.LastName ? user.LastName : '')}</h3>
                            {user.created_at &&
                                <p className={styles.smallerUserDetails}>{`${t('User.MemberSince')} ${moment(user.created_at).tz('America/Chicago').format('MMMM, YYYY')}`}</p>
                            }
                            {user.Email &&
                                <p className={styles.smallerUserDetails}>{user.Email}</p>
                            }
                            {user.PhoneNumber &&
                                <p className={styles.smallerUserDetails}>{user.PhoneNumber}</p>
                            }
                        </div>
                        
                        <div className={`${styles.innerPanelContainer} ${styles.userViewColumn}`}>
                            <div className={styles.outerViewUserPanelHeader}>
                                <div className={styles.innerViewUserPanelHeader}>
                                    <h1 className={styles.editUserHeader}>{user.Verified ? t('Payment.PaymentHistory') : t('User.UserHistory')}</h1>
                                    <div style={{ marginLeft: -50, marginTop: -10 }}>
                                        <Spinner isLoading={loading} />
                                    </div>
                                    
                                    <div className={styles.submitButton}>
                                        {user.Verified &&
                                            <ButtonV2
                                                onClick={() => history.push(`/users/edit/${user.Id}/users-view`)}
                                                color='white'
                                                longerButton={true}
                                            >
                                                {t('User.EditUser')}
                                            </ButtonV2>
                                        }
                                        <div className={styles.spacer}></div>
                                        {user.Verified ?
                                            <ButtonV2
                                                color='midnightBlue'
                                                longerButton={true}
                                                onClick={sendForgetPasswordEmail}
                                            >
                                                {t('User.SendForgetPasswordEmail')}
                                            </ButtonV2>
                                            :
                                            <ButtonV2
                                                color='midnightBlue'
                                                longerButton={true}
                                                onClick={sendVerificationEmail}
                                            >
                                                {t('User.ResendRegistrationEmail')}
                                            </ButtonV2>
                                        }
                                    </div>
                                </div>
                            </div>
                            
                            {/* table for payment history */}
                            {user.Verified &&
                                <>
                                    <table className={styles.containerWeb}>
                                    <colgroup>
                                        <col className={styles.colGroupPaymentDate} />
                                        <col className={styles.colGroupPaymentType} />
                                        <col className={styles.colGroupPaymentAmount} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th className={`${styles.paymentHistoryTableHeader} ${styles.paymentHistoryDateHeader} ${styles.colHeaderText}`}>
                                                {t('Payment.Date')}
                                            </th>
                                            <th className={`${styles.paymentHistoryTableHeader} ${styles.colHeaderText}`}>
                                                {t('Payment.AccountNumber')}
                                            </th>
                                            <th className={`${styles.paymentHistoryTableHeader} ${styles.colHeaderText}`}>
                                                {t('Payment.PaymentAmount')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paymentHistory && paymentHistory.length > 0 && paymentHistory.map((record, index) => {
                                            return (
                                                <tr className={styles.tableRow} key={index}>
                                                    <td>
                                                        <p className={styles.priceHistoryTableDate}>{record.created_at ? moment(record.created_at).tz('America/Chicago').format('MM/DD/YY') : 'N/A'}</p>
                                                    </td>
                                                    <td>
                                                        <p className={styles.priceHistoryTableType}>{`${record.CustomerId}-${record.LocationId}`}</p>
                                                    </td>
                                                    <td>
                                                        <p className={styles.priceHistoryTableAmount}>{`$${record.FinalPaymentAmount}`}</p>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {(!paymentHistory || !paymentHistory.length) && user.FirstName && user.LastName &&
                                    <p className={`${styles.noRecordsAvailableText} ${styles.noRecordsAvailableMargin}`}>{`${t('User.NoPaymentDataAvailable')} ${(user.FirstName ? (user.FirstName.trim() + ' ') : '') + (user.LastName ? user.LastName.trim() : '')}.`}</p>
                                }
                                {(!paymentHistory || !paymentHistory.length) && !(user.FirstName && user.LastName) &&
                                    <p className={`${styles.noRecordsAvailableText} ${styles.noRecordsAvailableMargin}`}>{`${t('User.NoPaymentDataAvailable')} ${user.Email}.`}</p>
                                }

                                {/* table for activity history */}
                                <h1 className={styles.activityHistoryHeader}>{t('User.ActivityHistory')}</h1>
                                <table className={styles.containerWeb}>
                                    <colgroup>
                                        <col className={styles.colGroupActivityDate} />
                                        <col className={styles.colGroupActivityType} />
                                        <col className={styles.colGroupActivityDescription} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th className={`${styles.paymentHistoryTableHeader} ${styles.activityHistoryTextAlign} ${styles.colHeaderText}`}>
                                                {t('Payment.Date')}
                                            </th>
                                            <th className={`${styles.paymentHistoryTableHeader} ${styles.activityHistoryTextAlign} ${styles.colHeaderText}`}>
                                                {t('User.Action')}
                                            </th>
                                            <th className={`${styles.paymentHistoryTableHeader} ${styles.activityHistoryTextAlign} ${styles.colHeaderText}`}>
                                                {t('User.Description')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(!userActivityHistory || !userActivityHistory.length) &&
                                            <tr></tr>
                                        }
                                        {userActivityHistory && userActivityHistory.length > 0 && userActivityHistory.map((record, index) => {
                                            return (
                                                <tr className={styles.tableRow} key={index}>
                                                    <td>
                                                        <p className={styles.priceHistoryTableDate}>{record.created_at ? moment(record.created_at).tz('America/Chicago').format('MM/DD/YY') : 'N/A'}</p>
                                                    </td>
                                                    <td>
                                                        <p className={styles.activityHistoryTableText}>{t(`Activity.${record.EventName}`)}</p>
                                                    </td>
                                                    <td>
                                                        <p className={styles.activityHistoryTableText}>
                                                            {displayActivityDescription(record)}
                                                        </p>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {(!userActivityHistory || !userActivityHistory.length) && user.FirstName && user.LastName &&
                                    <p className={`${styles.noRecordsAvailableText} ${styles.noRecordsAvailableMargin}`}>{`${t('User.NoActivityDataAvailable')} ${(user.FirstName ? (user.FirstName.trim() + ' ') : '') + (user.LastName ? user.LastName.trim() : '')}.`}</p>
                                }
                                {(!userActivityHistory || !userActivityHistory.length) && !(user.FirstName && user.LastName) &&
                                    <p className={`${styles.noRecordsAvailableText} ${styles.noRecordsAvailableMargin}`}>{`${t('User.NoActivityDataAvailable')} ${user.Email}.`}</p>
                                }
                                </>
                            }
                            {!user.Verified &&
                                <p>{t('User.UserDataUnavailable')}</p>
                            }
                        </div>
                    </Panel>
                </PanelRow>
            }
        </PageContainer>
    )
};
