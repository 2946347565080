import { Dispatch } from 'redux';
import { CustomerPaymentProfile } from '../../models/customerPaymentProfile';
import { axios } from '../../services/axiosService';
import { AppState } from '../../store/appState';
import {
    setCoreIframeHtml,
    setCustomerPaymentProfile,
    setDefaultPaymentProfile,
    setIframeURL,
} from './paymentSlice';

export const loadPaymentProfile =
    () => async (dispatch: Dispatch, getState: () => AppState) => {
        try {
            const result = (await axios.get('/payment-methods'))
                .data as CustomerPaymentProfile[];
            dispatch(setCustomerPaymentProfile(result));

            if (result) {
                const defaultProfile = result.find((x) => x.Default);
                if (defaultProfile) {
                    dispatch(setDefaultPaymentProfile(defaultProfile.Id));
                }
            }
        } catch (err) {
            console.error(err);
        }

        return;
    };

export const getPaymentusIframeURL =
    (
        type: string,
        accountNumber: string | null,
        amount?: string | number,
        appCode?: string,
        activityId?: string,
    ) =>
    async (dispatch: Dispatch) => {
        try {
            const params = { transactionType: type, accountNumber } as {
                transactionType: string;
                accountNumber: string | null;
                amount?: string | number;
                appCode?: string;
                activityId?: string;
            };
            if (amount) {
                params.amount = amount;
            }
            if (appCode) {
                params.appCode = appCode;
            }
            if (activityId) {
                params.activityId = activityId;
            }
            const result = (await axios.get('/paymentus-token', { params }))
                .data.iframeURL as string;
            dispatch(setIframeURL(result));
        } catch (err) {
            console.error(err);
        }

        return;
    };

export const getCoreIframeURL =
    (
        type: string,
        accountNumber: string | null,
        amount?: string | number,
        appCode?: string,
        activityId?: string,
    ) =>
    async (dispatch: Dispatch) => {
        try {
            const params = { transactionType: type, accountNumber } as {
                transactionType: string;
                accountNumber: string | null;
                amount?: string | number;
                appCode?: string;
                activityId?: string;
            };
            if (amount) {
                params.amount = amount;
            }
            if (appCode) {
                params.appCode = appCode;
            }
            if (activityId) {
                params.activityId = activityId;
            }
            const result = (
                await axios.get('/core/bill-pay/iframe', { params })
            ).data as string;
            dispatch(setCoreIframeHtml(result));
        } catch (err) {
            console.error(err);
        }

        return;
    };
