import React, { useEffect } from 'react';
import {  useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './PopularServices.module.scss';
import { useAppStateActivitiesWizardSelector, clearSelection } from '../activitiesWizard/activitiesWizardSlice';
import { loadActivities } from '../activitiesWizard/activitiesWizardActionCreators';
import { useAuthSelector } from '../../features/auth/authSlice';
import { Activity } from '../../features/activities/list/activitiesListAppState';
import { Spinner } from '../spinner/Spinner';
import { customFormMap, navigateToActivity } from '../../services/activityService';
import { DropdownOptions } from '../dropdown/Dropdown';

export const PopularServices = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const {
        popularItems
    } = useAppStateActivitiesWizardSelector(state => state);
    const { authUser } = useAuthSelector(state => state);
    const { loadingActivities } = useAppStateActivitiesWizardSelector(state => state);

    useEffect(() => {
        dispatch(clearSelection(null));
        dispatch(loadActivities(authUser?.Id, 'all'));
    }, [dispatch, authUser]);

    let handleOnStart = (selectedItem: Activity) => {
        if (!authUser && selectedItem.Id.toUpperCase() === customFormMap.WATER_BILL.toUpperCase()) {
            history.push(`/login?redirectto=payment&guest_enabled=true`);
            return;
        }

        if(!authUser && selectedItem.Id.toUpperCase() === customFormMap.QUICK_PERMIT.toUpperCase()) {
            history.push(`/login`);
            return;
        }

        if (!authUser?.Email && !selectedItem.GuestEnabled) {
            history.push(`/login?activityId=${selectedItem.Id}`);
            return;
        }

        const { historyPushLocation, dispatchFunction } = navigateToActivity(selectedItem as Activity);

        if (dispatchFunction) dispatch(dispatchFunction);
        if (historyPushLocation) history.push(historyPushLocation);
    }

    const displayItemText = (item: DropdownOptions) => {
        // todo: add translations
        if (item.item?.Action === 'APPLY_FOR') {
            return t('Activity.ApplyForA') + ' ' + t(item.translation!);
        }
        if (item.key === 'WATER_BILL') {
            return t('Activity.PayYourWaterBill');
        }
        if (item.key === 'OPEN_PERMITS') {
            return t('Activity.FindOpenPermits');
        }
        if (item.key === 'GARBAGE_RECYCLING_MISSED_COLLECTION') {
            return t('Activity.ReportMissedGarbageCollection');
        }
        if (item.key === 'CITY_ISSUED_TICKET') {
            return t('Activity.PayCityIssuedCitation');
        }

        return t(item.translation!);
    }

    return (
        <>
        {
            loadingActivities ?
            <div className={`${styles.topInnerPanelContainer} ${styles.spinnerContainer}`}>
                <Spinner isLoading={loadingActivities} />
            </div>
            :
            <div className={styles.topInnerPanelContainer}>
                <h1 className={styles.title}>{t('Activity.PopularServices')}</h1>
                <div className={styles.gridContainer}>
                    {popularItems[0]?.translation && popularItems[0]?.item &&
                        popularItems.map((item, i) => {
                            return (
                                <div className={styles.gridItem} key={i} onClick={() => handleOnStart(item.item!)}>
                                    <div className={styles.gridItemServiceIcon} style={item.item?.PopularServiceIconURL ? { background: `url('${item.item?.PopularServiceIconURL}') no-repeat center`, backgroundSize: 'contain', width: '32px', height: '32px' } : {}}></div>
                                    <span className={styles.gridItemText}>{displayItemText(item)}</span>
                                    <div className={styles.gridItemCarrotIcon}></div>
                                </div>
                            );
                        })
                    }
                </div>
                <div className={styles.viewAllLinkContainer}>
                    <span className={styles.viewAllLink} onClick={() => history.push('/view-all-services')}>{t('Activity.ViewAllServices')}</span>
                </div>
            </div>
        }
        </>
    );
}
