import { AnyAction, Dispatch } from 'redux';
import { axios } from '../../../services/axiosService';
import { AppState } from '../../../store/appState';
import { setPrimaryAddress, setIsLoading, setSearchValue, setSearchResults } from './accountCreateSlice';
import { authActions } from '../../auth/authSlice';
import { salesforceService } from '../../../services/salesforceService';
import { setLoading } from './accountCreateSlice';
import { userService } from '../../../services/userService';
import { User } from '../../../models/user';
import { openMessageModal } from '../../../components/statusModal/messageModalActionCreators';

export const submitAddress = (missingUserData: boolean) => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const address = state.accountCreate.address;
    try {
        dispatch(setLoading(true));
        if (missingUserData) {
            const {firstName, lastName, phoneNumber} = state.accountCreate.personalInformation;
            let payload = {};
            if (firstName) Object.assign(payload, {firstName});
            if (lastName) Object.assign(payload, {lastName});
            if (phoneNumber) Object.assign(payload, {phoneNumber});

            const updateUserInfoResponse = (await userService.updateUserInfo(payload)) as User;
            dispatch(authActions.setAuthUserInfo({ authUser: updateUserInfoResponse }));
        }
        dispatch(setPrimaryAddress(address));
    } catch (err) {
        console.error(err);
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
    } finally {
        dispatch(setLoading(false));
    }
}

export const submitAccountInformation = (callback: () => void) => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const accountInformation = state.accountCreate.accountInformation;
    const address = state.accountCreate.address;
    try {
        dispatch(setLoading(true));
        let matchResponse = await salesforceService.matchToSalesforceContact(
            address.address1,
            address.address2,
            address.city,
            address.state,
            address.zip,
            accountInformation.utilityCustomerId.length === 0 ? null : accountInformation.utilityCustomerId,
            accountInformation.utilityStreetNumber.length === 0 ? null : accountInformation.utilityStreetNumber
            );

        if (matchResponse?.success) {
            const authUser = matchResponse!.authUser as User;
            dispatch(authActions.setInitialized({authUser, isAuthenticated: state.auth.isAuthenticated, isAdmin: state.auth.isAdmin}));
            dispatch(setPrimaryAddress(null));
            callback();
        } else if (matchResponse?.error === 'utility-account-street-number-mismatch') {
            console.log('utility account & street number do not match in naviline');
            dispatch(openMessageModal('naviline-account-mismatch', 'error') as unknown as AnyAction);
        }
    } catch (err) {
        console.error(err);
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
    } finally {
        dispatch(setLoading(false));
    }
}

export const businessSearch = (name: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setSearchValue(name));
    if (name.length >= 3) {
        try {
            dispatch(setIsLoading(true));

            const state = getState();
            const currentOrganizationIds = state.accountCreate.businessInformation.myOrganizations.length ? state.accountCreate.businessInformation.myOrganizations.map(mc => mc.taxId) : [];

            const businessResults = (await axios.get(`/salesforce/organizations?name=${encodeURIComponent(name)}`)).data.searchRecords as any[]; // TODO: abstract to service and type
            const formattedResults = businessResults.filter(br => !currentOrganizationIds.includes(br.Id)).slice(0, 7).map((br: any) => ({title: br.Name, id: br.Id}));

            dispatch(setSearchResults(formattedResults));
            dispatch(setIsLoading(false));
        } catch (err) {
            console.error(err);
            dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
            dispatch(setSearchResults([]));
            dispatch(setIsLoading(false));
        }
    }
}

export const submitBusinessInformation = () => async (dispatch: Dispatch, getState: () => AppState) => {
    console.log('submitBusinessInformation');
    const state = getState();
    const businessInformation = state.accountCreate.businessInformation;
    try {
        const organizations = businessInformation.myOrganizations.map(org => {
            const formattedOrg = {
                name: org.name,
                taxId: org.taxId,
                businessLicenseId: org.businessLicenseId,
                sfId: org.sfId,
                address1: org.address1,
                address2: org.address2,
                city: org.city,
                state: org.state,
                zip: org.zip
            };

            return formattedOrg;
        });

        const userOrgResult = (await axios.post('/salesforce/matchOrCreateOrgsToSalesforceOrgs', {organizations}))
        console.log(userOrgResult);
    } catch (err) {
        console.error(err);
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
    }
}