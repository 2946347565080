import {createSlice, SliceCaseReducers} from '@reduxjs/toolkit';
import {LaserficheEmbedAppState} from './laserficheEmbedAppState';
import {buildSubStateSelector} from '../../../store/utilities/useAppStateSelector';
import {v4 as uuid} from 'uuid'

export const slice = createSlice<LaserficheEmbedAppState, SliceCaseReducers<LaserficheEmbedAppState>>({
    name: 'laserficheEmbed',
    initialState: {
        portalSubmissionId: uuid(),
        formSubmitted: false,
        formTitle: '',
        bpid: '',
        params: {},
        isLoading: false,
        unrecoverableErrorMessage: '',
    },
    reducers: {
        setInitialForm: (state, action) => {
            state.formTitle = action.payload.formTitle;
            state.bpid = action.payload.bpid;
            state.params = action.payload.params;
            state.formSubmitted = false;
        },
        setFormSubmitted: (state, action) => {
            state.formSubmitted = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setUnrecoverableErrorMessage: (state, action) => {
            state.unrecoverableErrorMessage = action.payload;
        }
    }
});

export const useAppStateLaserficheEmbedSelector = buildSubStateSelector(state => state.laserficheEmbed);
export const {
    setInitialForm,
    setFormSubmitted,
    setIsLoading,
    setUnrecoverableErrorMessage,
} = slice.actions;
export const laserficheEmbedReducer = slice.reducer;
