import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';

export interface FeatureFlag {
    id: string,
    name: string,
    enabled: boolean
};

const initialState = {
    featureFlags: [] as FeatureFlag[],
    loadingFeatureFlags: false,
};

export type FeatureFlagAppState = typeof initialState;

export const slice = createSlice<FeatureFlagAppState, {
    setFeatureFlags : CaseReducer<FeatureFlagAppState, PayloadAction<FeatureFlag[]>>,
    setLoadingFeatureFlags : CaseReducer<FeatureFlagAppState, PayloadAction<boolean>>,
}>({
    name: "auth",
    initialState,
    reducers: {
        setFeatureFlags: (state, action) => {
            state.featureFlags = action.payload;
        },
        setLoadingFeatureFlags: (state, action) => {
            state.loadingFeatureFlags = action.payload;
        },
    }
});

export const useAppStateFeatureFlagSelector = buildSubStateSelector<FeatureFlagAppState>(state => state.featureFlag);
export const {
    setFeatureFlags,
    setLoadingFeatureFlags,
} = slice.actions;
export const featureFlagReducer = slice.reducer;
