import React, { FunctionComponent } from 'react';
import styles from './ColoredCircle.module.scss';

export interface ColoredCircleProps  { 
    color: 'green' | 'purple' | 'blue' | 'orange' | 'gray';
    value: string;
    size?: 'xs' | 'small' | 'large';
    opaque?: boolean;
    onClick?: () => void;
}

export const ColoredCircle: FunctionComponent<ColoredCircleProps> = ({color, size, opaque, value, onClick}) => {
    const clickable = onClick ? true : false;
    let getColor = () => {
        switch (color) {
            case 'green':
                return styles.green;
            case 'purple':
                return styles.purple
            case 'blue':
                return styles.blue;
            case 'orange':
                return styles.orange;
            case 'gray':
                return styles.gray;
        }
    }

    let getSize = () => {
        switch (size) {
            case 'xs':
                return styles.coloredCircleExtraSmall;
            case 'small':
                return styles.coloredCircleSmall;
            case 'large':
                return styles.coloredCircleLarge
            default:
                return ''
        }
    }
    return (
        <div
            className={`${styles.circle} ${clickable ? styles.clickable : null} ${!opaque ? styles.blur : styles.opaque} ${getSize()} ${getColor()}`}
            onClick={onClick}>
            <span>{value}</span>
        </div>
    );
}

ColoredCircle.defaultProps = {
    size: 'small',
    opaque: false
};