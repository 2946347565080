import React, { useCallback, useEffect, useState } from 'react';
import { PageContainer } from '../../../components/pageContainer/PageContainer';
import styles from './GuestPayment.module.scss';
import { useTranslation } from 'react-i18next';
import { navilinePaymentTypes } from '../../../constants';
import { PanelRow } from '../../../components/panel/PanelRow';
import { Panel } from '../../../components/panel/Panel';
import { Button } from '../../../components/button/Button';
import { PaymentusTokenizationForm } from '../../../components/paymentusTokenizationForm/PaymentusTokenizationForm';
import { Text } from '../../../components/text/Text';
import { useHistory } from 'react-router-dom';
import { useAuthSelector } from '../../auth/authSlice';
import { UtilityHelpModal } from '../../account/create/AccountCreateAccountInformation';
import { axios } from '../../../services/axiosService';
import moment from 'moment';
import { useQuery } from '../../../hooks/useQuery';
import { useFeatureFlag } from '../../featureFlags/FeatureFlag';
import { CoreBillPayForm } from '../../../components/coreIframe/CoreBillPayForm';
import { PaymentEventCallType } from '../CorePayment';
import { Spinner } from '../../../components/spinner/Spinner';
import { useDispatch } from 'react-redux';
import { setPreviousAuthStateForPayments, useAppStatePaymentSelector } from '../paymentSlice';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

enum PaymentStatus {
    NONE = 'NONE',
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
}

export const GuestPayment = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [apiError] = useState('');
    const [accountError, setAccountError] = useState('');
    const { isAuthenticated } = useAuthSelector((s) => s);
    const [paymentAmount, setPaymentAmount] = useState<number>();
    const [paymentAmountEntered, setPaymentAmountEntered] = useState(false);
    const [dueDate, setDueDate] = useState<string>();
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(
        PaymentStatus.NONE,
    );
    const [utilityHelpModalOpen, setUtilityHelpModalOpen] = useState(false);
    const querystring = useQuery();
    const accountNumber = querystring.get('accountNumber') ?? '';
    const accountType = querystring.get('accountType')
        ? querystring.get('accountType')
        : 'UTILITY';
    const amount = querystring.get('amount')
        ? parseFloat(querystring.get('amount')!)
        : 0;
    const penaltyAmount = querystring.get('penaltyAmount')
        ? parseFloat(querystring.get('penaltyAmount')!)
        : 0;
    const [navilineAccountNumber, setNavilineAccountNumber] =
        useState(accountNumber);
    const [amountDue, setAmountDue] = useState<number>(amount);
    const [isAccountValid, setIsAccountValid] = useState<boolean>(
        !!accountNumber && !!accountType,
    );
    const coreFF = useFeatureFlag('EnableCORE');
    const [loading, setLoading] = useState(false);
    const [initialLoad, setInitialLoad] = useState(false);
    const { previousAuthStateForPayments } = useAppStatePaymentSelector((state) => state);

    const afterSubmit = async () => {
        history.replace('/dashboard');
    };

    const scrollToTopOfIFrame = useCallback((diff) => {
        const iframe = document.getElementById('billPayIframe');
        if (iframe) {
            const iframeTop = iframe.getBoundingClientRect().top - diff;
            setTimeout(() => {
                window.scrollTo({
                    top: iframeTop,
                    behavior: 'smooth',
                });
            }, 250);
        }
    }, []);

    const validateAccount = async () => {
        setPaymentStatus(PaymentStatus.LOADING);

        try {
            const response = await axios.get(
                `/naviline/account?accountNumber=${navilineAccountNumber}`,
            );
            const data = response.data.OutputParms;

            if (data.ErrorCode !== '0000') {
                setAccountError(
                    'Could not find account. Please make sure you entered the account information correctly.',
                );
                return;
            }

            const negativeControl =
                data.AmountDueWithoutPending[
                    data.AmountDueWithoutPending.length - 1
                ] === '-'
                    ? -1.0
                    : 1.0;
            setAmountDue(
                (parseFloat(data.AmountDueWithoutPending) / 100.0) *
                    negativeControl,
            );
            setDueDate(data.DueDateOfLastBill);
            setPaymentAmount((parseFloat(data.AmountDueWithoutPending) / 100.0) * negativeControl >= 0 ? (parseFloat(data.AmountDueWithoutPending) / 100.0) : 0);
            setIsAccountValid(true);
        } catch (e) {
            setAccountError(
                'Could not find account. Please make sure you entered the account information correctly.',
            );
        } finally {
            setPaymentStatus(PaymentStatus.NONE);
        }
    };

    const processEvent = useCallback(
        (event) => {
            const data = event?.data;
            console.log(data);
            const callType = data?.calltype;
            if (callType === PaymentEventCallType.OVERLAY_ON) {
                setLoading(true);
            }

            if (callType === PaymentEventCallType.OVERLAY_OFF) {
                setLoading(false);
                if (!initialLoad) {
                    setInitialLoad(true);
                    scrollToTopOfIFrame(75);
                }
            }

            if (callType === PaymentEventCallType.PAYMENT_SUCCESS) {
                const iframe = document.getElementById('billPayIframe');
                if (iframe != null) {
                    // Account for different screen sizes when recieving the confirmation message
                    iframe.setAttribute('scrolling', 'yes');
                }
            }
        },
        [scrollToTopOfIFrame, initialLoad],
    );

    // Re-load the page if an authenticated user logs out
    useEffect(() => {
        if (isAuthenticated !== previousAuthStateForPayments) {
            // set user auth state again
            dispatch(setPreviousAuthStateForPayments(isAuthenticated));
            // Redirect the user to the initial payment page
            history.push(`/login?redirectto=payment&guest_enabled=true`);
        }
    }, [dispatch, history, isAuthenticated, previousAuthStateForPayments]);

    useEffect(() => {
        window.addEventListener('message', processEvent, false);

        // If there is an iframe error modal, scroll to the top to show it
        const iframe = document.getElementById('billPayIframe') as HTMLIFrameElement;
        if (iframe) {
            const saveChangesButton = iframe.contentDocument?.getElementById(
                'eGov_functionComplete',
            );
            if (saveChangesButton) {
                saveChangesButton.addEventListener('click', () => scrollToTopOfIFrame(75));
            }
        }

        return () => {
            window.removeEventListener('message', processEvent);
        };
    }, [processEvent, scrollToTopOfIFrame]);

    const afterPaymentAmountEntered = async () => {
        setPaymentAmountEntered(true);
    };

    return (
        <PageContainer
            style={isAuthenticated ? { } : { maxWidth: 700, width: '100%', marginTop: 50 }}
        >
            <div className="headerContainer">
                <h2>{t('Payment.Payment')}</h2>
            </div>
            <Spinner isLoading={loading} fullPage />
            {!isAccountValid ? (
                <PanelRow>
                    <Panel>
                        <div>
                            <div className={styles.accountNumberTitleContainer}>
                                {t('Account.CreateCustomerID')} (
                                {t('Account.CreateCustomerIDNote')})
                                <span style={{ color: 'red' }}>*</span>{' '}
                                <span
                                    role="button"
                                    className={styles.linkText}
                                    onClick={() => setUtilityHelpModalOpen(true)}
                                >
                                    {t('Account.CreateWhatsThis')}
                                </span>
                            </div>
                            
                            <UtilityHelpModal
                                modalOpen={utilityHelpModalOpen}
                                text={{
                                    header: t(
                                        'Account.CreateFindYourCustomerId',
                                    ),
                                    line1: t(
                                        'Account.CreateToFindYourCustomerId',
                                    ),
                                    line2: t(
                                        'Account.CreatePleaseEnterAsItAppears',
                                    ),
                                }}
                                onClose={() => setUtilityHelpModalOpen(false)}
                            />
                            <div className={styles.accountNumberInputContainer}>
                                <Text
                                    value={navilineAccountNumber}
                                    onChange={(e: any) =>
                                        setNavilineAccountNumber(e.target.value)
                                    }
                                    placeholder={t('Account.CreateCustomerID')}
                                />
                            </div>
                            <Button
                                onClick={() => validateAccount()}
                                loading={
                                    paymentStatus === PaymentStatus.LOADING
                                }
                                disabled={
                                    paymentStatus === PaymentStatus.LOADING
                                }
                                color={'blue'}
                            >
                                {t('Util.Next')}
                            </Button>
                            {accountError !== '' && (
                                <p className={styles.errorMessage}>
                                    {accountError}
                                </p>
                            )}
                        </div>
                    </Panel>
                </PanelRow>
            ) : null}

            {isAccountValid ? (
                <>
                    <PanelRow>
                        <Panel>
                            <div>
                                <table>
                                    <tbody>
                                        <tr>
                                            {accountType !==
                                            navilinePaymentTypes.businessLicenseRenewal ? (
                                                <td>
                                                    <strong>
                                                        Account Number:
                                                    </strong>
                                                </td>
                                            ) : (
                                                <td>
                                                    <strong>
                                                        License Number:
                                                    </strong>
                                                </td>
                                            )}
                                            <td>{navilineAccountNumber}</td>
                                        </tr>
                                        {penaltyAmount ? (
                                            <>
                                                {/* If the user is applying for permits, check if there is a penalty amount */}
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            {t(
                                                                'Payment.PermitDue',
                                                            )}
                                                            :
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {penaltyAmount
                                                            ? formatter.format(
                                                                  amountDue!,
                                                              )
                                                            : ''}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            color: '#BF0000',
                                                        }}
                                                    >
                                                        <strong>
                                                            {t(
                                                                'Payment.PenaltyDue',
                                                            )}
                                                            :
                                                        </strong>
                                                    </td>
                                                    <td
                                                        style={{
                                                            color: '#BF0000',
                                                        }}
                                                    >
                                                        {penaltyAmount
                                                            ? formatter.format(
                                                                  penaltyAmount!,
                                                              )
                                                            : ''}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            {t(
                                                                'Payment.TotalDue',
                                                            )}
                                                            :
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {penaltyAmount
                                                            ? formatter.format(
                                                                  amountDue! +
                                                                      penaltyAmount!,
                                                              )
                                                            : ''}
                                                    </td>
                                                </tr>
                                            </>
                                        ) : (
                                            <tr>
                                                <td>
                                                    <strong>
                                                        {t('Payment.TotalDue')}:
                                                    </strong>
                                                </td>
                                                <td>
                                                    {amountDue
                                                        ? formatter.format(
                                                              amountDue!,
                                                          )
                                                        : ''}
                                                </td>
                                            </tr>
                                        )}

                                        {dueDate ? (
                                            <tr>
                                                <td>
                                                    <strong>Due Date:</strong>
                                                </td>
                                                <td>
                                                    {moment(
                                                        dueDate,
                                                        'MMDDYYYY',
                                                    ).format('MM/DD/YYYY')}
                                                </td>
                                            </tr>
                                        ) : null}
                                    </tbody>
                                </table>
                            </div>
                        </Panel>
                    </PanelRow>
                    <PanelRow>
                        <Panel>
                            <div>
                                <p>
                                    <strong>Enter your payment amount:</strong>
                                </p>

                                <div>
                                    <span className={styles.slightMarginRight}>Payment: $</span>
                                    <Text
                                        value={paymentAmount ?? 0}
                                        fluid={false}
                                        step='0.01'
                                        min={1}
                                        type={'number'}
                                        disabled={paymentAmountEntered}
                                        onChange={(event: any, data: any) =>
                                            setPaymentAmount(
                                                parseFloat(data.value),
                                            )
                                        }
                                    />
                                </div>

                                <Button
                                    onClick={() => afterPaymentAmountEntered()}
                                    disabled={paymentAmountEntered || (paymentAmount ? paymentAmount : 0) <= 0}
                                    color={'blue'}
                                >
                                    {t('Util.Next')}
                                </Button>
                            </div>
                        </Panel>
                    </PanelRow>
                </>
            ) : null}

            {isAccountValid && paymentAmountEntered ? (
                <>
                    <PanelRow>
                        <Panel>
                            {coreFF ? (
                                coreFF.enabled ? (
                                    <CoreBillPayForm
                                        transactionType={'SINGLE'}
                                        accountNumber={navilineAccountNumber}
                                        amount={paymentAmount?.toFixed(2)}
                                        paymentType="UTILITY"
                                        appCode={
                                            accountType as unknown as navilinePaymentTypes
                                        }
                                        unauth={true}
                                    />
                                ) : (
                                    <PaymentusTokenizationForm
                                        transactionType={'SINGLE'}
                                        afterToken={afterSubmit}
                                        fullToken={false}
                                        amount={paymentAmount?.toFixed(2)}
                                        accountNumber={navilineAccountNumber}
                                        paymentType={
                                            accountType as unknown as navilinePaymentTypes
                                        }
                                    />
                                )
                            ) : null}
                            {apiError !== '' && (
                                <p className={styles.errorMessage}>
                                    {apiError}
                                </p>
                            )}
                        </Panel>
                    </PanelRow>
                </>
            ) : null}
        </PageContainer>
    );
};
