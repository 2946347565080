import React from 'react';
import styles from './OrganizationSearch.module.scss';
import { Business } from './organizatioSearchAppState';
import { useDispatch } from 'react-redux';
import { toggleAddOrganization, setBusinessAddress1, setBusinessZip, setBusinessState, setBusinessCity, setBusinessAddress2, setBusinessName, setBusinessTaxId, addOrganization, removeOrganization, setInvalidBusinessName, setInvalidBusinessTaxId, setInvalidBusinessAddress1, setInvalidBusinessCity, setInvalidBusinessState, setInvalidBusinessZip, setSearchValue, addExistingOrganization, setInvalidBusinessLicenseId, setBusinessLicenseId } from './organizationSearchSlice';
import { Text } from '../text/Text';
import { Button } from '../button/Button';
import { Dropdown } from '../dropdown/Dropdown';
import { Icon } from '../icon/Icon';
import { ErrorValidationLabel } from '../errorValidationLabel/ErrorValidationLabel';
import { submitBusinessInformation, businessSearch } from './organizationSearchActionCreators';
import { states } from '../../static/json/states';
import { SearchProps } from 'semantic-ui-react';
import { Search } from '../search/Search';
import { useTranslation } from 'react-i18next';
import { useAppStateOrganizationSearchSelector } from './organizationSearchSlice';
import { Spinner } from '../spinner/Spinner';
import { useHistory } from 'react-router-dom';
import { getActivity } from '../activitiesWizard/activitiesWizardActionCreators';
import { useAppStateActivitiesWizardSelector } from '../activitiesWizard/activitiesWizardSlice';
import { navigateToActivity } from '../../services/activityService';

export interface OrganizationSearchProps {
    postSignUp?: boolean;
}

export function OrganizationSearch(props: OrganizationSearchProps) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const { loading, error, showAddOrganization, searchLoading, searchResults, searchValue, newOrganization, myOrganizations } = useAppStateOrganizationSearchSelector(state => state);
    const postSignUp = props.postSignUp ?? false;
    const activity = useAppStateActivitiesWizardSelector(state => state.selectedItem);
    const activityRedirectId = localStorage.getItem('activityRedirectId');

    if (activityRedirectId && (!activity || !activity.Id)) {
        dispatch(getActivity(activityRedirectId));
    }

    const handleResultSelect = (e: React.MouseEvent, {result}: SearchProps) => {
        const organization: Business = {
            name: result.title,
            sfId: result.id,
            taxId: result.id,
            businessLicenseId: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            invalidName: false,
            invalidTaxId: false,
            invalidAddress1: false,
            invalidCity: false,
            invalidState: false,
            invalidZip: false,
        }
        dispatch(addExistingOrganization(organization))
        dispatch(setSearchValue(''))
    }

    const handleSearchChange = (e: React.MouseEvent, {value}: SearchProps) => {
        dispatch(businessSearch(value as string));
    }

    const redirectCallback = () => {
        if (activityRedirectId && activity && activity.Id) {
            const { historyPushLocation, dispatchFunction } = navigateToActivity(activity);

            localStorage.removeItem('activityRedirectId');

            if (dispatchFunction) dispatch(dispatchFunction);
            if (historyPushLocation) history.push(historyPushLocation);
        } else {
            history.push('/dashboard');
        }
    }

    const submit = () => {
        dispatch(submitBusinessInformation(redirectCallback));
    }

    const submitOrganization = () => {
        const valid = validateOrganizationForm();
        if (valid) {
            dispatch(addOrganization(null));
            dispatch(toggleAddOrganization(null));
        }
    }

    const clearOrganizationValidators = () => {
        dispatch(setInvalidBusinessName(null));
        dispatch(setInvalidBusinessTaxId(null));
        dispatch(setInvalidBusinessLicenseId(null));
        dispatch(setInvalidBusinessAddress1(null));
        dispatch(setInvalidBusinessCity(null));
        dispatch(setInvalidBusinessState(null));
        dispatch(setInvalidBusinessZip(null));
    }

    const validateOrganizationForm = () => {
        clearOrganizationValidators();

        let validForm = true;

        if (newOrganization.name.length === 0) {
            dispatch(setInvalidBusinessName('Required'));
            validForm = false;
        }
        if (newOrganization.taxId.length === 0) {
            dispatch(setInvalidBusinessTaxId('Required'));
            validForm = false;
        }
        if (newOrganization.businessLicenseId.length === 0) {
            dispatch(setInvalidBusinessLicenseId('Required'));
            validForm = false;
        }
        if (newOrganization.address1.length === 0) {
            dispatch(setInvalidBusinessAddress1('Required'));
            validForm = false;
        }
        if (newOrganization.city.length === 0) {
            dispatch(setInvalidBusinessCity('Required'));
            validForm = false;
        }
        if (newOrganization.state.length === 0) {
            dispatch(setInvalidBusinessState('Required'));
            validForm = false;
        }
        if (newOrganization.zip.length === 0) {
            dispatch(setInvalidBusinessZip('Required'));
            validForm = false;
        }

        return validForm;
    }

    return (
        <div className={styles.formContainer}>
            { myOrganizations.length ? 
                <>
                    <p className={styles.label}>{t('Account.CreateMyOrganizations')}</p>
                    <div className={styles.myOrganizationsContainer}>
                        {myOrganizations.map(c => (
                            <div key={c.taxId} className={styles.myOrganizationsItem}>
                                <div>{c.name}</div>
                                <Icon
                                    icon='x'
                                    color='red'
                                    size="large"
                                    onClick={() => dispatch(removeOrganization(c.taxId))}
                                />
                            </div>
                        ))}
                    </div>
                </>
            : null }

            { !showAddOrganization ?
                <>
                    <div className={styles.searchContainer}>
                        <p className={styles.label}>{t('Account.CreateOrganizationName')}</p>
                        <Search
                            loading={searchLoading}
                            onResultSelect={handleResultSelect}
                            onSearchChange={handleSearchChange}
                            results={searchResults}
                            value={searchValue}
                            minCharacters={3}
                            fluid
                            placeholder={t('Account.CreateOrganizationSearch')}
                        />
                    </div>
                    <div className={styles.buttons}>
                        <div className={styles.buttonContainer}>
                            <Button
                                onClick={() => dispatch(toggleAddOrganization(null))}
                                color="blue"
                            >
                                {t('Account.CreateAddNewOrganization')}
                            </Button>
                        </div>
                        <div style={{flexGrow: 2}}></div>
                        {myOrganizations.length ?
                            <div className={styles.buttonContainer}>
                                <Button
                                    color="green"
                                    onClick={() => submit()}
                                >
                                    {postSignUp ? t('Account.AddOrganizations') : t('Account.CreateFinishProfile')}
                                </Button>
                            </div>
                        :
                            <div style={{flexGrow: 1, textAlign: 'right', color: 'red'}}>
                                { error ? t('Util.ErrorTryAgainLater') : <Spinner isLoading={loading}/> }
                            </div>
                        }
                    </div>
                </>
            :
                <>
                    <div>
                        <div className={styles.label}>{t('Account.CreateOrganizationName')}</div>
                        <Text
                            value={newOrganization.name}
                            onChange={(e: any) => dispatch(setBusinessName({ value: e.target.value }))}
                            placeholder={t('Account.CreateOrganizationName')}
                        />
                        { newOrganization.invalidName ? <ErrorValidationLabel fieldDescription="Organization Name" /> : null }
                    </div>

                    <div>
                        <div className={styles.label}>{t('Account.CreateTaxID')}</div>
                        <Text
                            value={newOrganization.taxId}
                            onChange={(e: any) => dispatch(setBusinessTaxId({ value: e.target.value }))}
                            placeholder="Tax ID"
                        />
                        { newOrganization.invalidTaxId ? <ErrorValidationLabel fieldDescription="Tax ID" /> : null }
                    </div>

                    <div>
                        <div className={styles.label}>{t('Account.CreateBusinessLicenseID')}</div>
                        <Text
                            value={newOrganization.businessLicenseId}
                            onChange={(e: any) => dispatch(setBusinessLicenseId({ value: e.target.value }))}
                            placeholder={t('Account.CreateBusinessLicenseID')}
                        />
                        { newOrganization.invalidTaxId ? <ErrorValidationLabel fieldDescription={t('Account.CreateBusinessLicenseID')} /> : null }
                    </div>

                    <div>
                        <div className={styles.label}>{t('Account.CreateAddress')}</div>
                        <Text
                            value={newOrganization.address1}
                            onChange={(e: any) => dispatch(setBusinessAddress1({ value: e.target.value }))}
                            placeholder={t('Account.CreateAddressLine1')}
                        />
                        { newOrganization.invalidAddress1 ? <ErrorValidationLabel fieldDescription="Address 1" /> : null }
                        <Text
                            value={newOrganization.address2}
                            onChange={(e: any) => dispatch(setBusinessAddress2({ value: e.target.value }))}
                            placeholder={t('Account.CreateAddressLine2')}
                        />
                        <div className={styles.cityStateZip}>
                            <div className={styles.city}>
                                <Text
                                    value={newOrganization.city}
                                    onChange={(e: any) => dispatch(setBusinessCity({ value: e.target.value }))}
                                    placeholder={t('Account.CreateCity')}
                                />
                                { newOrganization.invalidCity ? <ErrorValidationLabel fieldDescription="City" /> : null }
                            </div>
                            <div className={styles.state}>
                                <Dropdown
                                    placeholder={t('Account.CreateState')}
                                    value={newOrganization.state}
                                    options={states}
                                    onChange={(e: any, data: any) => dispatch(setBusinessState({ value: data.value }))}
                                />
                                { newOrganization.invalidState ? <ErrorValidationLabel fieldDescription="State" /> : null }
                            </div>
                            <div className={styles.zip}>
                                <Text
                                    value={newOrganization.zip}
                                    onChange={(e: any) => dispatch(setBusinessZip({ value: (e.target.value.match(/^\d+$/) || e.target.value === '') && e.target.value.length <= 5 ? e.target.value : newOrganization.zip }))}
                                    placeholder={t('Account.CreateZip')}
                                />
                                { newOrganization.invalidZip ? <ErrorValidationLabel fieldDescription="Zip" /> : null }
                            </div>
                        </div>
                    </div>
                    <div className={styles.buttons}>
                        <div className={styles.buttonContainer}>
                            <Button
                                onClick={submitOrganization}
                                color="blue"
                            >
                                {t('Util.Submit')}
                            </Button>
                        </div>
                        <div className={styles.buttonContainer}>
                            <Button
                                onClick={() => dispatch(toggleAddOrganization(null))}
                                color="grey"
                            >
                                {t('Util.Cancel')}
                            </Button>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}