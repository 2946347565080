import React, { FunctionComponent } from 'react';
import styles from './Panel.module.scss';

export interface PanelProps  { 
    size?: number;
    noPadding?: boolean;
    style?: object;
    highlighted?: boolean;
 }

export const Panel: FunctionComponent<PanelProps> = ({size, children, noPadding, style, highlighted}) => {
    let inlineStyles: React.CSSProperties = {
        flex: `${size ? size : 1}`,
        padding: noPadding ? 0 : '',
        ...style
    };

    return (
        <div
            className={`${styles.panel} ${highlighted ? styles.panelHighlighted : null}`}
            style={inlineStyles}
        >
            {children}
        </div>
    );
}

Panel.defaultProps = {
    noPadding: false,
    style: {},
    highlighted: false,
};