import React, { useEffect, useState } from 'react';
import styles from './VerificationRequired.module.scss';
import { PageContainer } from '../../../components/pageContainer/PageContainer';
import { Panel } from '../../../components/panel/Panel';
import { PanelRow } from '../../../components/panel/PanelRow';
import { useAuthSelector } from '../../auth/authSlice';
import { authService } from '../../../services/authService';
import { setEmailResent, useAppStateVerificationRequiredSelector } from './verificationRequiredSlice';
import { Spinner } from '../../../components/spinner/Spinner';
import { useDispatch } from 'react-redux';
import { checkVerification } from './verificationRequiredActionCreators';
import { Button } from '../../../components/button/Button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const VerificationRequired = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { authUser } = useAuthSelector(state => state);
    const { loading, isVerified, emailResent, sfAccountExists } = useAppStateVerificationRequiredSelector(state => state);
    const history = useHistory();
    const timeoutLength = 60;
    const [ emailCounter, setEmailCounter ] = useState(timeoutLength);

    useEffect(() => {
        if (emailCounter < timeoutLength && emailCounter > 0) {
            setTimeout(() => {
                setEmailCounter(emailCounter-1);
            }, 1000);
        } else {
            dispatch(setEmailResent(false));
        }
    }, [emailCounter, dispatch]);

    const resendVerificationEmail = () => {
        dispatch(setEmailResent(true));
        setTimeout(() => {
            setEmailCounter(timeoutLength-1);
        }, 1000);
        authService.sendVerificationLinkToEmail();
    }

    if (isVerified) window.location.assign('/account/create');

    useEffect(() => {
        dispatch(setEmailResent(true));
        setEmailCounter(timeoutLength-1);
        
        if (sfAccountExists) {
            history.push('/');
        }
        setTimeout(() => {
            dispatch(checkVerification());
        }, 2000);
    }, [dispatch, history, sfAccountExists]);

    return (
        <div className={styles.page}>
            <Spinner isLoading={loading} fullPage />
            <div className={styles.panelContainer}>
                <PageContainer>
                    <PanelRow className={styles.panelStyle}>
                        <Panel>
                            <h1>{t('Verification.EmailVerificationRequired')}</h1>
                            <p></p>
                            <p>{t('Verification.CheckEmailForLink', {email: authUser?.Email})}</p>
                            <p>{t('Verification.VerificationIsRequired')}</p>
                            {emailResent && emailCounter !== 0 ? 
                            (
                                <>
                                    <p>{t('Verification.NotSeeingIt')}</p>
                                    <Button
                                        color="blue"
                                        onClick={resendVerificationEmail}
                                        disabled={true}
                                    >
                                        {t('Verification.ResendEmail') + ' (' + emailCounter + 's)'}
                                    </Button>
                                </>
                            )
                            :
                            (
                                <>
                                    <p>{t('Verification.NotSeeingIt')}</p>
                                    <Button
                                        color="blue"
                                        onClick={resendVerificationEmail}
                                    >
                                        {t('Verification.ResendEmail')}
                                    </Button>
                                </>
                            )
                            }
                        </Panel>
                    </PanelRow>
                </PageContainer>
            </div>
        </div>
    );
};
