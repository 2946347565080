import { AnyAction, Dispatch } from 'redux';
import { AppState } from '../../../store/appState';
import { axios } from '../../../services/axiosService';
import { setFileTooLarge, setLoadingLandParcel, setLandParcel, toggleModalOpen, setUpdating, setModalLoading } from './accountEditSlice';
import { authActions } from '../../auth/authSlice';
import { User } from '../../../models/user';
import { openMessageModal } from '../../../components/statusModal/messageModalActionCreators';

export const saveAccount = () => async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setUpdating(true));
    const appState = getState();
    const { firstName, lastName, phoneNumber, address1, address2, city, state, zip } = appState.accountEdit;

    try {
        const payload = { firstName, lastName, phoneNumber, address1, address2, city, state, zip };
        const result = (await axios.put(`/users/profile`, payload)).data;
        console.log(result);
        dispatch(authActions.setAuthUserInfo({ authUser: result.userResponse }));
        dispatch(openMessageModal('success-update', 'success') as unknown as AnyAction);
    } catch (err) {
        dispatch(openMessageModal('error-update', 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setUpdating(false));
    }
}

export const savePicture = (file: any) => async (dispatch: Dispatch) => {
    try {
        dispatch(setModalLoading(true));
        const formData = new FormData();

        formData.append(`image`, file);

        if (file.size > 104857600) {
            dispatch(setFileTooLarge(true));
        }
        const response = (await axios.put(`/users/image`, formData)).data;
        console.log(response);

        dispatch(authActions.setProfilePictureUrl(response.urlResponse));

        dispatch(toggleModalOpen(null));
        dispatch(openMessageModal('success-add', 'success') as unknown as AnyAction);
    } catch (err) {
        console.error(err);
        if (err?.response?.status === 413) {
            console.error('File Upload too Large');
            dispatch(setFileTooLarge(true));
        } else {
            dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        }
    } finally {
        dispatch(setModalLoading(false));
    }
}

export const getAddressArcGIS = (authUser: User | null) => async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setLoadingLandParcel(true));
    try {
        const { address1, address2, city, state, zip } = authUser?.salesforceContact ?? {};
        if (address1 && city && state && zip) {
            const addressString = `${address1} ${address2 ?? ''} ${city} ${state} ${zip}`;
            const response = (await axios.get(`/salesforce/landParcelMaster/${addressString}`)).data;
            dispatch(setLandParcel(response));
        }
    } catch (err) {
        dispatch(setLandParcel(null));
        console.error(err);
    } finally {
        dispatch(setLoadingLandParcel(false))
    }
}