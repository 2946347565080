import React, { FunctionComponent } from 'react';
import styles from './Button.module.scss';
import { Button as SemanticButton } from 'semantic-ui-react'

export interface ButtonProps  {
    color?: 'midnightBlue' | 'white' | 'whiteUnavailable';
    fluid?: boolean;
    disabled?: boolean;
    onClick?(): void;
    style?: object;
    loading?: boolean;
    type?: string;
    longerButton?: boolean;
}

export const PayBillButton: FunctionComponent<ButtonProps> = ({children, color, fluid, disabled, style, longerButton, onClick, ...rest}) => {
    let getColor = () => {
        switch (color) {
            case 'midnightBlue':
                return styles.midnightBlue;
            case 'white':
                return styles.white;
            case 'whiteUnavailable':
                return styles.whiteUnavailable;
        }
    }
    return (
        <SemanticButton 
            className={`${longerButton ? styles.longerPayBillButton : styles.payBillButton} ${getColor()} ${style}`}  
            fluid={fluid}
            disabled={disabled}
            onClick={onClick}
            {...rest}>
                {children}
        </SemanticButton>
    );
}

PayBillButton.defaultProps = {
    color: 'midnightBlue',
};