import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { ZoningInquiryAppState } from './zoningInquiryAppState';
import { buildSubStateSelector } from '../../../store/utilities/useAppStateSelector';
import { customFormMap } from '../../../services/activityService';

export const slice = createSlice<ZoningInquiryAppState, SliceCaseReducers<ZoningInquiryAppState>>({
    name: 'zoningInquiry',
    initialState: {
        salesforceLandParcel: null,
        backLocation: '/dashboard',
        activityId: customFormMap.ZONING_INFORMATION,
        proposedUsage: '',
        proposedUsageErrorMessage: '',
        allThatApply: [
            { key: 'ModifyMyBusiness', salesforceValue: 'Modify my business', checked: false },
            { key: 'MoveMyExistingBusiness', salesforceValue: 'Move my existing business', checked: false },
            { key: 'ChangeBusinessOwnership', salesforceValue: 'Change business ownership', checked: false },
            { key: 'ExpandMyBusiness', salesforceValue: 'Expand my business', checked: false },
            { key: 'KnowPermittedUses', salesforceValue: 'Know permitted uses at property', checked: false },
            { key: 'KnowSiteRestrictions', salesforceValue: 'Know site restrictions at property', checked: false }
        ],
        allThatApplyErrorMessage: '',
        isHomeBasedBusiness: false,
        isNewBusiness: false,
        submitErrorMessage: '',
        submitLoading: false,
    },
    reducers: {
        setSalesforceLandParcel: (state, action) => {
            state.salesforceLandParcel = action.payload;
        },
        setBackLocation: (state, action) => {
            state.backLocation = action.payload;
        },
        setActivityId: (state, action) => {
            // do not set to null
            state.activityId = action.payload ?? state.activityId;
        },
        setProposedUsage: (state, action) => {
            state.proposedUsage = action.payload;
            state.proposedUsageErrorMessage = '';
        },
        setProposedUsageErrorMessage: (state, action) => {
            state.proposedUsageErrorMessage = action.payload;
        },
        toggleAllThatApply: (state, action) => {
            const key = action.payload;
            state.allThatApply = state.allThatApply.map(option => {
                if (option.key === key) {
                    option.checked = !option.checked;
                }
                return option;
            });
            state.allThatApplyErrorMessage = '';
        },
        setAllThatApplyErrorMessage: (state, action) => {
            state.allThatApplyErrorMessage = action.payload;
        },
        toggleIsHomeBasedBusiness: (state) => {
            state.isHomeBasedBusiness = !state.isHomeBasedBusiness;
        },
        toggleIsNewBusiness: (state) => {
            state.isNewBusiness = !state.isNewBusiness;
        },
        setSubmitErrorMessage: (state, action) => {
            state.submitErrorMessage = action.payload;
        },
        setSubmitLoading: (state, action) => {
            state.submitLoading = action.payload;
        },
    }
});

export const useAppStateZoningInquirySelector = buildSubStateSelector(state => state.zoningInquiry);
export const {
    setSalesforceLandParcel,
    setBackLocation,
    setActivityId,
    setProposedUsage,
    setProposedUsageErrorMessage,
    toggleAllThatApply,
    setAllThatApplyErrorMessage,
    toggleIsHomeBasedBusiness,
    toggleIsNewBusiness,
    setSubmitErrorMessage,
    setSubmitLoading,
} = slice.actions;
export const zoningInquiryReducer = slice.reducer;
