import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { AccountEditAppState } from './accountEditAppState';
import { buildSubStateSelector } from '../../../store/utilities/useAppStateSelector';

export const slice = createSlice<AccountEditAppState, SliceCaseReducers<AccountEditAppState>>({
    name: 'accountEdit',
    initialState: {
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        updating: false,

        invalidFirstName: undefined,
        invalidLastName: undefined,
        invalidPhoneNumber: undefined,
        invalidAddress1: undefined,
        invalidCity: undefined,
        invalidState: undefined,
        invalidZip: undefined,

        landParcel: undefined,
        loadingLandParcel: false,

        modalOpen: false,
        modalLoading: false,
        fileTooLarge: false,

        toggleEmailNotifications: true,
        togglePhoneNotifications: true,
    },
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setFirstName: (state, action) => {
            state.firstName = action.payload;
        },
        setLastName: (state, action) => {
            state.lastName = action.payload;
        },
        setPhoneNumber: (state, action) => {
            state.phoneNumber = action.payload;
        },
        setAddress1: (state, action) => {
            state.address1 = action.payload;
        },
        setAddress2: (state, action) => {
            state.address2 = action.payload;
        },
        setCity: (state, action) => {
            state.city = action.payload;
        },
        setState: (state, action) => {
            state.state = action.payload;
        },
        setZip: (state, action) => {
            state.zip = action.payload;
        },
        setUpdating: (state, action) => {
            state.updating = action.payload;
        },

        setInvalidFirstName: (state, action) => {
            state.invalidFirstName = action.payload;
        },
        setInvalidLastName: (state, action) => {
            state.invalidLastName = action.payload;
        },
        setInvalidPhoneNumber: (state, action) => {
            state.invalidPhoneNumber = action.payload;
        },
        setInvalidAddress1: (state, action) => {
            state.invalidAddress1 = action.payload;
        },
        setInvalidCity: (state, action) => {
            state.invalidCity = action.payload;
        },
        setInvalidState: (state, action) => {
            state.invalidState = action.payload;
        },
        setInvalidZip: (state, action) => {
            state.invalidZip = action.payload;
        },

        setLandParcel: (state, action) => {
            state.landParcel = action.payload;
        },
        setLoadingLandParcel: (state, action) => {
            state.loadingLandParcel = action.payload;
        },

        toggleModalOpen: (state) => {
            state.modalOpen = !state.modalOpen;
        },
        setModalLoading: (state, { payload }) => {
            state.modalLoading = payload;
        },
        setFileTooLarge: (state, action) => {
            state.fileTooLarge = action.payload;
        },

        setToggleEmailNotifications: (state, action) => {
            state.toggleEmailNotifications = action.payload;
        },
        setTogglePhoneNotifications: (state, action) => {
            state.togglePhoneNotifications = action.payload;
        },
    }
});

export const useAppStateAccountEditSelector = buildSubStateSelector(state => state.accountEdit);
export const {
    setEmail,
    setFirstName,
    setLastName,
    setPhoneNumber,
    setAddress1,
    setAddress2,
    setCity,
    setState,
    setZip,
    setUpdating,

    setInvalidFirstName,
    setInvalidLastName,
    setInvalidPhoneNumber,
    setInvalidAddress1,
    setInvalidCity,
    setInvalidState,
    setInvalidZip,

    setLandParcel,
    setLoadingLandParcel,

    toggleModalOpen,
    setModalLoading,
    setFileTooLarge,

    setToggleEmailNotifications,
    setTogglePhoneNotifications,
} = slice.actions;
export const accountEditReducer = slice.reducer;
