import {createSlice, SliceCaseReducers} from '@reduxjs/toolkit';
import {ActivitiesCreateAppState, LaserficheForm} from './activitiesCreateAppState';
import {buildSubStateSelector} from '../../../store/utilities/useAppStateSelector';

const initialState = {
    name: '',
    type: 'LASERFICHE_FORM',
    description: '',
    order: 0,
    published: false,
    salesforceObjectId: '',
    laserficheProcessId: '',
    externalLink: '',
    content: '',
    errorMessage: '',
    laserficheForms: null,
    loading: false,
    calendlyLink: '',
    paymentRequired: false,
    paymentCode: '',
    salesforcePaymentField: '',
    guestEnabled: false,
    popularService: false,
    popularServiceOrder: 0,
};

export const slice = createSlice<ActivitiesCreateAppState, SliceCaseReducers<ActivitiesCreateAppState>>({
    name: 'activitiesCreate',
    initialState,
    reducers: {
        setName: (state, action) => {
            state.name = action.payload.value;
        },
        setType: (state, action) => {
            state.type = action.payload.value;
        },
        setDescription: (state, action) => {
            state.description = action.payload.value;
        },
        setOrder: (state, action) => {
            state.order = parseInt(action.payload.value);
        },
        setPublished: (state, action) => {
            state.published = action.payload.value;
        },
        setSalesforceObject: (state, action) => {
            state.salesforceObjectId = action.payload.value;
        },
        setLaserficheProcess: (state, action) => {
            state.laserficheProcessId = action.payload.value;
        },
        setCalendlyLink: (state, action) => {
            state.calendlyLink = action.payload.value;
        },
        setExternalLink: (state, action) => {
            state.externalLink = action.payload.value;
        },
        setContentConfiguration: (state, action) => {
            state.content = action.payload.value;
        },
        resetActivityCreateState: (state, action) => {
            return initialState;
        },
        createActivityFailed: (state, action) => {
            state.errorMessage = action.payload.errorMessage;
        },
        setLaserficheForms: (state, action) => {
            state.laserficheForms = action.payload as LaserficheForm;
        },
        getLaserficheFormsFailed: (state, action) => {
            state.errorMessage = action.payload.errorMessage;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setPaymentRequired: (state, {payload}) => {
            state.paymentRequired = payload;
        },
        setPaymentCode: (state, {payload}) => {
            state.paymentCode = payload;
        },
        setSalesforcePaymentField: (state, {payload}) => {
            state.salesforcePaymentField = payload;
        },
        setGuestEnabled: (state, {payload}) => {
            state.guestEnabled = payload;
        },
        setPopularService: (state, {payload}) => {
            state.popularService = payload;
        },
        setPopularServiceOrder: (state, action) => {
            state.popularServiceOrder = parseInt(action.payload.value);
        },
    }
});

export const useAppStateActivitiesCreateSelector = buildSubStateSelector(state => state.activitiesCreate);
export const {
    setName,
    setType,
    setDescription,
    setOrder,
    setPublished,
    setSalesforceObject,
    setLaserficheProcess,
    setCalendlyLink,
    setExternalLink,
    setContentConfiguration,
    resetActivityCreateState,
    createActivityFailed,
    setLaserficheForms,
    getLaserficheFormsFailed,
    setLoading,
    setPaymentRequired,
    setPaymentCode,
    setSalesforcePaymentField,
    setGuestEnabled,
    setPopularService,
    setPopularServiceOrder,
} = slice.actions;
export const activitiesCreateReducer = slice.reducer;
