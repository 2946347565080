import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { VerificationRequiredAppState } from './verificationRequiredAppState';
import { buildSubStateSelector } from '../../../store/utilities/useAppStateSelector';

export const slice = createSlice<VerificationRequiredAppState, SliceCaseReducers<VerificationRequiredAppState>>({
    name: 'verificationRequired',
    initialState: {
        loading: true,
        isVerified: false,
        emailResent: false,
        sfAccountExists: false,
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setIsVerified: (state, action) => {
            state.isVerified = action.payload;
        },
        setEmailResent: (state, action) => {
            state.emailResent = action.payload;
        },
        setSfAccountExists: (state, action) => {
            state.sfAccountExists = action.payload;
        },
    },
});


export const useAppStateVerificationRequiredSelector = buildSubStateSelector(state => state.verificationRequired);
export const { setLoading, setIsVerified, setEmailResent, setSfAccountExists } = slice.actions;
export const verificationRequiredReducer = slice.reducer;