import React, { useEffect, useState } from 'react';
import styles from './UserEditV2.module.scss';
import { PageContainer } from '../../../components/pageContainer/PageContainer';
import { Panel } from '../../../components/panel/Panel';
import { PanelRow } from '../../../components/panel/PanelRow';
import { useTranslation } from 'react-i18next';
import { User } from '../../../models/user';
import { userService } from '../../../services/userService';
import { useParams } from 'react-router';
import { Spinner } from '../../../components/spinner/Spinner';
import { Text } from '../../../components/text/Text';
import { Checkbox } from '../../../components/checkbox/Checkbox';
import {
    ErrorValidationLabel,
    Reasoning,
} from '../../../components/errorValidationLabel/ErrorValidationLabel';
import { Message } from 'semantic-ui-react';
import { openMessageModal } from '../../../components/statusModal/messageModalActionCreators';
import { useDispatch } from 'react-redux';
import { BackArrowDarkBlue } from '../../../components/backArrow/BackArrowDarkBlue';
import moment from 'moment';
import { PayBillButton as ButtonV2 } from '../../../components/button/PayBillButton';
import { DataList, DataListTypes, ListItem } from '../../../components/dataList/DataList';
import {
    setAutopayCustomerId,
    setAutopayLocationId,
    setOrganizationId,
    setUserId,
    useAppStateBillingSettingsSelector,
} from '../../account/billing/billingSettingsSlice';
import {
    loadPaymentInformation,
    loadPaymentProfile,
    removePaymentInformation,
    removePaymentMethod,
    removePaymentMethodAdmin,
} from '../../account/billing/billingSettingsActionCreators';
import { axios } from '../../../services/axiosService';
import { MessageModal } from '../../../components/statusModal/MessageModal';
import {
    setLoading,
    useAppStateUserTableSelector,
} from '../list/UserSearchV2Slice';
import { useFeatureFlag } from '../../featureFlags/FeatureFlag';
import { NotificationSettings } from '../../../components/notificationSettings/NotificationSettings';

export const UserEditV2 = () => {
    const fallbackIcon = '/img/user.png';
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [user, setUser] = useState<User | null>(null);
    const { id, prev_page } = useParams() as any;
    const [deleteCardWarningOpen, setDeleteCardWarningOpen] = useState(false);
    const [deleteAutopayModalOpen, setDeleteAutopayModalOpen] = useState(false);
    const [deletedCardId, setDeletedCardId] = useState('');
    const [paymentMethods, setPaymentMethods] = useState<ListItem[]>([]);
    const coreFF = useFeatureFlag('EnableCORE');

    const [invalidFirstName, setInvalidFirstName] = useState<Reasoning | null>(
        null,
    );
    const [invalidLastName, setInvalidLastName] = useState<Reasoning | null>(
        null,
    );
    const [invalidPhoneNumber, setInvalidPhoneNumber] =
        useState<Reasoning | null>(null);
    const [invalidSalesforceId, setInvalidSalesforceId] =
        useState<Reasoning | null>(null);
    const [containsError, setContainsError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const {
        autopayCustomerId,
        autopayLocationId,
        coreStoredPayments,
        paymentProfile,
        navilineListData,
        loadingStoredPayments,
    } = useAppStateBillingSettingsSelector((s) => s);
    const { loading } = useAppStateUserTableSelector((state) => state);
    const delinquentBillingNotificationsFF = useFeatureFlag(
        'EnableDelinquentBillingNotifications',
    );

    const removeCard = async (id: string) => {
        // Show warning if card is attached to autopay
        const result = (await axios.get(`/payment-methods/${id}`)).data;
        setDeletedCardId(id);
        setDeleteCardWarningOpen(true);
        // Show warning if card is attached to autopay
        if (result) {
            dispatch(
                openMessageModal(
                    'warning-delete-saved-card-with-autopay',
                    'warning',
                ),
            );
        } else {
            dispatch(openMessageModal('warning-delete-saved-card', 'warning'));
        }
    };

    useEffect(() => {
        (async () => {
            dispatch(setLoading(true));
            if (coreFF) {
                const user = await userService.getUser(id);
                setUser(user);
                dispatch(setUserId(user?.Id));
                dispatch(setOrganizationId(user?.selectedOrganizationInfo?.Id));
                dispatch(loadPaymentInformation());
                dispatch(loadPaymentProfile(user.Id, coreFF?.enabled));
                dispatch(setLoading(false));
            }
        })();
    }, [setUser, id, coreFF, dispatch]);

    useEffect(() => {
        if (coreFF) {
            let resultingPaymentMethods;
            if (coreFF?.enabled) {
                resultingPaymentMethods = coreStoredPayments
                    ? coreStoredPayments.map((paymentMethod) => {
                          return {
                              Id: paymentMethod.id,
                              Label: `${
                                  paymentMethod.name
                                      ? paymentMethod.name + ' - '
                                      : ''
                              } ${paymentMethod.card_type} - ${
                                  paymentMethod.accountnum
                              }`,
                              Data: paymentMethod,
                              Tag: undefined,
                          };
                      })
                    : [];
            } else {
                resultingPaymentMethods = paymentProfile
                    ? paymentProfile.map((profile: any) => {
                          return {
                              Id: profile.Id,
                              Label: `${profile.Type} - ${
                                  profile.MaskedAccountNumber
                              }${
                                  profile.ProfileDescription
                                      ? ` (${profile.ProfileDescription})`
                                      : ''
                              }`,
                              Data: profile,
                              Tag: undefined,
                          };
                      })
                    : [];
            }
            setPaymentMethods(resultingPaymentMethods);
        }
    }, [coreFF, coreStoredPayments, paymentProfile, loadingStoredPayments]);

    const onSubmit = async () => {
        dispatch(setLoading(true));
        if (validateForm()) {
            try {
                await userService.updateUserInfo(
                    {
                        FirstName: user!.FirstName,
                        LastName: user?.LastName!,
                        PhoneNumber: user?.PhoneNumber!,
                        SalesforceId: user?.SalesforceId!,
                        Admin: user?.Admin,
                    },
                    id,
                );
                dispatch(openMessageModal('success-update', 'success'));
            } catch (e) {
                setContainsError(true);
                setErrorMessage(t('User.FailedToUpdateUser'));
                dispatch(openMessageModal('error-remove', 'error'));
            }
        }
        dispatch(setLoading(false));
    };

    const clearValidators = () => {
        setInvalidFirstName(null);
        setInvalidLastName(null);
        setInvalidPhoneNumber(null);
        setInvalidSalesforceId(null);
        setContainsError(false);
    };

    const validateForm = () => {
        clearValidators();
        let validForm = true;

        if (!user?.FirstName || user?.FirstName!.length === 0) {
            setInvalidFirstName('Required');
            validForm = false;
        }
        if (!user?.LastName || user?.LastName!.length === 0) {
            setInvalidLastName('Required');
            validForm = false;
        }
        if (!user?.PhoneNumber || user?.PhoneNumber!.length === 0) {
            setInvalidPhoneNumber('Required');
            validForm = false;
        }
        if (!user?.SalesforceId || user?.SalesforceId!.length === 0) {
            setInvalidSalesforceId('Required');
            validForm = false;
        }
        if (!validForm) {
            setContainsError(true);
        }

        return validForm;
    };

    return (
        <PageContainer>
            {/* Remove card modal */}
            <MessageModal
                modalOpen={deleteCardWarningOpen}
                onConfirmationButtonClick={() => {
                    setDeleteCardWarningOpen(false);
                    if (coreFF?.enabled) {
                        dispatch(
                            removePaymentMethod(
                                deletedCardId,
                                coreFF.enabled,
                                user!.Id,
                            ),
                        );
                    } else {
                        dispatch(
                            removePaymentMethodAdmin(deletedCardId, user!.Id),
                        );
                    }
                }}
                onCloseButtonClick={() => {
                    setDeleteCardWarningOpen(false);
                }}
            />

            {/* Remove naviline account modal */}
            <MessageModal
                modalOpen={deleteAutopayModalOpen}
                onConfirmationButtonClick={() => {
                    dispatch(
                        removePaymentInformation(
                            autopayCustomerId,
                            autopayLocationId,
                        ),
                    );
                    setDeleteAutopayModalOpen(false);
                }}
                onCloseButtonClick={() => {
                    setDeleteAutopayModalOpen(false);
                }}
            />

            {prev_page && (
                <div
                    className={styles.backToHomepageArrow}
                    style={{ width: 'fit-content' }}
                >
                    {prev_page === 'users' && (
                        <BackArrowDarkBlue
                            url={`/${prev_page}`}
                            text={t('User.BackToUserList')}
                        />
                    )}
                    {prev_page !== 'users' && user && (
                        <BackArrowDarkBlue
                            url={`/users/view/${user.Id}/users`}
                            text={t('User.BackToUserView')}
                        />
                    )}
                </div>
            )}

            {errorMessage && (
                <Message error header={'Error'} content={errorMessage} />
            )}

            {user && (
                <PanelRow>
                    <Panel
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            padding: 0,
                            position: 'relative',
                        }}
                    >
                        <div
                            className={`${styles.innerPanelContainer} ${styles.userInfoColumn}`}
                        >
                            {user.ProfilePictureUrl &&
                            user.ProfilePictureUrl !== '' ? (
                                <img
                                    src={user.ProfilePictureUrl}
                                    alt="visual of selected user"
                                    className={styles.profilePicture}
                                />
                            ) : (
                                <img
                                    src={fallbackIcon}
                                    alt="visual of selected user"
                                    className={styles.profilePicture}
                                />
                            )}
                            <h3 className={styles.fullName}>
                                {(user.FirstName ? user.FirstName + ' ' : '') +
                                    (user.LastName ? user.LastName : '')}
                            </h3>
                            {user.created_at && (
                                <p className={styles.smallerUserDetails}>{`${t(
                                    'User.MemberSince',
                                )} ${moment(user.created_at)
                                    .tz('America/Chicago')
                                    .format('MMMM, YYYY')}`}</p>
                            )}
                            {user.Email && (
                                <p className={styles.smallerUserDetails}>
                                    {user.Email}
                                </p>
                            )}
                            {user.PhoneNumber && (
                                <p className={styles.smallerUserDetails}>
                                    {user.PhoneNumber}
                                </p>
                            )}
                        </div>

                        <div
                            className={`${styles.innerPanelContainer} ${styles.userEditColumn}`}
                        >
                            <div className={styles.outerEditUserPanelHeader}>
                                <div
                                    className={styles.innerEditUserPanelHeader}
                                >
                                    <div style={{ display: 'flex' }}>
                                        <h1 className={styles.editUserHeader}>
                                            {t('User.EditUser')}
                                        </h1>
                                        <div style={{ marginTop: -10 }}>
                                            <Spinner isLoading={loading} />
                                        </div>
                                    </div>
                                    <div className={styles.submitButton}>
                                        <ButtonV2
                                            onClick={onSubmit}
                                            color="midnightBlue"
                                            longerButton={true}
                                        >
                                            {t('Util.Save')}
                                        </ButtonV2>
                                    </div>
                                </div>
                                {containsError && (
                                    <p className={styles.topError}>
                                        {t('User.CouldNotUpdateUser')}
                                    </p>
                                )}
                            </div>

                            <div className={styles.row}>
                                <div className={styles.inputLabel}>
                                    {t('Account.FirstName')}
                                </div>
                                <Text
                                    value={user.FirstName ? user.FirstName : ''}
                                    onChange={(e: any, data: any) =>
                                        setUser({
                                            ...user,
                                            FirstName: data.value,
                                        })
                                    }
                                    fontFamilyLato={true}
                                    style={{ marginBottom: 0 }}
                                />
                                {invalidFirstName ? (
                                    <ErrorValidationLabel
                                        darkRedError={true}
                                        fieldDescription={t(
                                            'Account.FirstName',
                                        )}
                                    />
                                ) : null}
                            </div>
                            <div className={styles.row}>
                                <div className={styles.inputLabel}>
                                    {t('Account.LastName')}
                                </div>
                                <Text
                                    value={user.LastName ? user.LastName : ''}
                                    onChange={(e: any, data: any) =>
                                        setUser({
                                            ...user,
                                            LastName: data.value,
                                        })
                                    }
                                    fontFamilyLato={true}
                                    style={{ marginBottom: 0 }}
                                />
                                {invalidLastName ? (
                                    <ErrorValidationLabel
                                        darkRedError={true}
                                        fieldDescription={t('Account.LastName')}
                                    />
                                ) : null}
                            </div>
                            <div className={styles.row}>
                                <div className={styles.inputLabel}>
                                    {t('Account.PhoneNumber')}
                                </div>
                                <Text
                                    value={
                                        user.PhoneNumber ? user.PhoneNumber : ''
                                    }
                                    onChange={(e: any) =>
                                        setUser({
                                            ...user,
                                            PhoneNumber: e.target.value,
                                        })
                                    }
                                    style={{ marginBottom: 0 }}
                                    fontFamilyLato={true}
                                    mask="(999) 999-9999"
                                />
                                {invalidPhoneNumber ? (
                                    <ErrorValidationLabel
                                        darkRedError={true}
                                        fieldDescription={t(
                                            'Account.PhoneNumber',
                                        )}
                                    />
                                ) : null}
                            </div>
                            <div className={styles.row}>
                                <div className={styles.inputLabel}>
                                    {t('Account.SalesforceID')}
                                </div>
                                <Text
                                    value={
                                        user.SalesforceId
                                            ? user.SalesforceId
                                            : ''
                                    }
                                    onChange={(e: any, data: any) =>
                                        setUser({
                                            ...user,
                                            SalesforceId: data.value,
                                        })
                                    }
                                    style={{ marginBottom: 0 }}
                                    fontFamilyLato={true}
                                />
                                {invalidSalesforceId ? (
                                    <ErrorValidationLabel
                                        darkRedError={true}
                                        fieldDescription={t(
                                            'Account.SalesforceID',
                                        )}
                                    />
                                ) : null}
                            </div>
                            <div className={styles.row}>
                                <div className={styles.inputLabel}>
                                    {t('Account.Email')}
                                </div>
                                <div>{user.Email ? user.Email : 'N/A'}</div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.inputLabel}>
                                    {t('Account.Admin')}
                                </div>
                                <Checkbox
                                    value={user.Admin ? user.Admin : false}
                                    onChange={() =>
                                        setUser({ ...user, Admin: !user.Admin })
                                    }
                                    text={t('Account.Administrator')}
                                />
                            </div>

                            {/* Ability to delete stored payments and disable autopay */}
                            <h1 className={styles.editPaymentHeader}>
                                {t('User.UserPaymentSettings')}
                            </h1>
                            <DataList
                                title={t('Billing.WaterBillingAccount', {
                                    count: navilineListData.length,
                                })}
                                type={DataListTypes.navilineAccounts}
                                hideAddButton={true}
                                hideManageButton={true}
                                items={navilineListData}
                                deleteCallback={(_id, data) => {
                                    dispatch(
                                        openMessageModal(
                                            'warning-remove-autopay-admin',
                                            'warning',
                                        ),
                                    );
                                    setDeleteAutopayModalOpen(true);
                                    dispatch(
                                        setAutopayCustomerId(data.CustomerId),
                                    );
                                    dispatch(
                                        setAutopayLocationId(data.LocationId),
                                    );
                                }}
                            />
                            <DataList
                                title={t('Billing.StoredPaymentMethod', {
                                    count: paymentMethods.length,
                                })}
                                type={DataListTypes.savedPaymentMethods}
                                hideAddButton={true}
                                hideManageButton={true}
                                items={paymentMethods}
                                deleteCallback={(_id, data) => removeCard(_id)}
                            />
                            {delinquentBillingNotificationsFF?.enabled && (
                                <NotificationSettings
                                    userId={id}
                                    asModal={false}
                                    viewOnly
                                />
                            )}
                        </div>
                    </Panel>
                </PanelRow>
            )}
        </PageContainer>
    );
};
