import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom"
import { Activity } from '../../features/activities/list/activitiesListAppState';
import { useAuthSelector } from '../../features/auth/authSlice';
import { loadActivities } from '../activitiesWizard/activitiesWizardActionCreators';
import { useAppStateActivitiesWizardSelector } from '../activitiesWizard/activitiesWizardSlice';
import { navigateToActivity } from '../../services/activityService';

export const ActivityStart = ({location} : {location: Location}) => {
    const dispatch = useDispatch();
    const {
        activities
    } = useAppStateActivitiesWizardSelector(state => state);
    const { authUser } = useAuthSelector(state => state);
    const originalVal = new URLSearchParams(location.search).get("activityId");
    const [newLocation, setNewLocation] = useState<string>('');
    const [fetchedActivites, setFetchedActivites] = useState<boolean>(false);

    useEffect(() => {
        dispatch(loadActivities(authUser?.Id));
    }, [dispatch, authUser]);

    useEffect(() => {
        if (!activities.APPLY_FOR.length &&
            !activities.FIND.length &&
            !activities.MAPS.length &&
            !activities.PAY_FOR.length &&
            !activities.REGISTER_FOR.length &&
            !activities.RENT.length &&
            !activities.REPORT.length &&
            !activities.SIGN_UP_FOR.length) {
            return;
        }

        setFetchedActivites(true);

        // find the selected activity
        let selectedActivity : Activity = null as unknown as Activity;
        const matchedApply = activities.APPLY_FOR.find(x => x.Id === originalVal);
        selectedActivity = matchedApply ?? null as unknown as Activity;

        if (!selectedActivity) {
            const matchedFind = activities.FIND.find(x => x.Id === originalVal);
            selectedActivity = matchedFind ?? null as unknown as Activity;
        }

        if (!selectedActivity) {
            const matchedMaps = activities.MAPS.find(x => x.Id === originalVal);
            selectedActivity = matchedMaps ?? null as unknown as Activity;
        }

        if (!selectedActivity) {
            const matchedPay = activities.PAY_FOR.find(x => x.Id === originalVal);
            selectedActivity = matchedPay ?? null as unknown as Activity;
        }

        if (!selectedActivity) {
            const matchedRegister = activities.REGISTER_FOR.find(x => x.Id === originalVal);
            selectedActivity = matchedRegister ?? null as unknown as Activity;
        }

        if (!selectedActivity) {
            const matchedRent = activities.RENT.find(x => x.Id === originalVal);
            selectedActivity = matchedRent ?? null as unknown as Activity;
        }

        if (!selectedActivity) {
            const matchedReport = activities.REPORT.find(x => x.Id === originalVal);
            selectedActivity = matchedReport ?? null as unknown as Activity;
        }

        if (!selectedActivity) {
            const matchedSign = activities.SIGN_UP_FOR.find(x => x.Id === originalVal);
            selectedActivity = matchedSign ?? null as unknown as Activity;
        }

        if (!selectedActivity) {
            setNewLocation('/');
        }

        debugger;
        if (!authUser?.Email && !selectedActivity.GuestEnabled) {
            setNewLocation(`/login?activityId=${selectedActivity.Id}`);
            return;
        }

        const { historyPushLocation, dispatchFunction } = navigateToActivity(selectedActivity as Activity);

        if (dispatchFunction) dispatch(dispatchFunction);
        if (historyPushLocation) {
            setNewLocation(historyPushLocation);
        } else {
            setNewLocation('/');
        }

    }, [originalVal, activities, dispatch, authUser])

    if (!activities.APPLY_FOR.length &&
        !activities.FIND.length &&
        !activities.MAPS.length &&
        !activities.PAY_FOR.length &&
        !activities.REGISTER_FOR.length &&
        !activities.RENT.length &&
        !activities.REPORT.length &&
        !activities.SIGN_UP_FOR.length) {
        return null;
    }

    if (!newLocation || !fetchedActivites) {
        return null;
    }

    return (
        <Redirect to={newLocation!} />
    )
}
