import { AnyAction, Dispatch } from 'redux';
import { forgotPassword, setLoading } from './forgotPasswordSlice';
import { authService } from '../../services/authService';
import { openMessageModal } from '../../components/statusModal/messageModalActionCreators';

export const resetRequest = (email: string, redirectCallback: () => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        await authService.forgotPassword(email);
        dispatch(forgotPassword(null));
        dispatch(openMessageModal('check-your-email', 'success') as unknown as AnyAction);
        redirectCallback();
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.log(err);
    } finally {
        dispatch(setLoading(false));
    }
};