import React, { useEffect } from 'react';
import { Dropdown, DropdownOptions } from '../../../../components/dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setGraffitiOffensive, setGraffitiLocation, useAppStateReportSelector, setCategoryCanSubmit } from '../reportSlice';

export const Graffiti = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const state = useAppStateReportSelector(state => state.graffiti);

    const offensiveOptions: DropdownOptions[] = [
        {
            value: "Yes",
            text: t('Util.Yes'),
            key: "Yes"
        },
        {
            value: "No",
            text: t('Util.No'),
            key: "No"
        }
    ];

    const locationOptions: DropdownOptions[] = [
        {
            value: "Private residence",
            text: t('Report.PrivateResidence'),
            key: "Private residence"
        },
        {
            value: "Business",
            text: t('Report.Business'),
            key: "Business"
        },
        {
            value: "Street or sidewalk",
            text: t('Report.StreetOrSidewalk'),
            key: "Street or sidewalk"
        },
        {
            value: "City sign",
            text: t('Report.CitySign'),
            key: "City sign"
        },
        {
            value: "Park",
            text: t('Report.Park'),
            key: "Park"
        },
        {
            value: "Utility box",
            text: t('Report.UtilityBox'),
            key: "Utility box"
        },
        {
            value: "Other",
            text: t('Report.Other'),
            key: "Other"
        },
    ];

    useEffect(() => {
        if (state.offensive && state.location) {
            dispatch(setCategoryCanSubmit(true));
        }
        else {
            dispatch(setCategoryCanSubmit(false));
        }
    }, [dispatch, state.offensive, state.location]);

    return (
        <>
            {t('Report.IsItOffensive')}
            <Dropdown
                placeholder={t('Report.IsItOffensive')}
                options={offensiveOptions}
                value={state.offensive}
                onChange={(e: any, data: any) => dispatch(setGraffitiOffensive(data.value))}
            />
            {t('Report.WhereIsItLocated')}
            <Dropdown
                placeholder={t('Report.WhereIsItLocated')}
                options={locationOptions}
                value={state.location}
                onChange={(e: any, data: any) => dispatch(setGraffitiLocation(data.value))}
            />
        </>
    )
}