import React, { useState, useEffect } from 'react';
import styles from './ActivitiesEdit.module.scss';
import { PanelRow } from '../../../components/panel/PanelRow';
import { Panel } from '../../../components/panel/Panel';
import { Dropdown, DropdownOptions } from '../../../components/dropdown/Dropdown';
import { Button } from '../../../components/button/Button';
import { useDispatch } from 'react-redux';
import {
    setName,
    setType,
    setDescription,
    setOrder,
    setPublished,
    setSalesforceObject,
    useAppStateActivitiesEditSelector,
    setLaserficheProcess,
    setCalendlyLink,
    setExternalLink,
    setContentConfiguration,
    setPaymentRequired,
    setGuestEnabled,
    setToggleInAppBrowser,
    setPopularService,
    setPopularServiceOrder,
} from './activitiesEditSlice'
import {
    editActivity,
    getActivity,
    deleteActivity,
    getLaserficheForms,
    getActivitySObjectFields
} from './activitiesEditActionCreators';
import { Text } from '../../../components/text/Text';
import { Textarea } from '../../../components/textArea/Textarea';
import { Toggle } from '../../../components/toggle/Toggle';
import { ActivitiesEditAppState } from './activitiesEditAppState';
import { Editor } from '@tinymce/tinymce-react';
import { ErrorValidationLabel, Reasoning } from '../../../components/errorValidationLabel/ErrorValidationLabel';
import { useLocation, useHistory } from 'react-router';
import { Modal, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { BackArrow } from '../../../components/backArrow/BackArrow';
import { Spinner } from '../../../components/spinner/Spinner';
import { PageContainer } from '../../../components/pageContainer/PageContainer';
import { urlRegex, activityTypes } from '../../../constants';
import { useFeatureFlag } from '../../featureFlags/FeatureFlag';

export function ActivitiesEdit() {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const popularServicesFF = useFeatureFlag('EnablePopularServices');

    const id = location.pathname.split('activities/')[1];
    useEffect(() => {
        dispatch(getLaserficheForms());
        dispatch(getActivity(id));
    }, [dispatch, id]);

    const state: ActivitiesEditAppState = useAppStateActivitiesEditSelector(state => state);
    const {
        name,
        type,
        description,
        order,
        published,
        salesforceObjectId,
        laserficheProcessId,
        calendlyLink,
        externalLink,
        content,
        errorMessage,
        laserficheForms,
        loading,
        paymentRequired,
        guestEnabled,
        toggleInAppBrowser,
        popularService,
        popularServiceUrl,
        popularServiceOrder,
    } = state;

    let [invalidName, setInvalidName] = useState<Reasoning | null>(null);
    let [invalidType, setInvalidType] = useState<Reasoning | null>(null);
    let [invalidDescription, setInvalidDescription] = useState<Reasoning | null>(null);
    let [invalidOrder, setInvalidOrder] = useState<Reasoning | null>(null);
    let [invalidSalesforceObject, setInvalidSalesforceObject] = useState<Reasoning | null>(null);
    let [invalidLaserficheProcess, setInvalidLaserficheProcess] = useState<Reasoning | null>(null);
    let [invalidCalendlyLink, setInvalidCalendlyLink] = useState<Reasoning | null>(null);
    let [invalidExternalLink, setInvalidExternalLink] = useState<Reasoning | null>(null);
    let [invalidContent, setInvalidContent] = useState<Reasoning | null>(null);
    const [containsError, setContainsError] = useState<boolean>(false);
    const [localFile, setLocalFile] = useState<any>();

    let [deleteModalOpen, setDeleteModalOpen] = useState(false);

    let activityTypesDropdownOptions: DropdownOptions[] = [
        {
            key: "1",
            text: "Laserfiche Form",
            value: activityTypes.LASERFICHE_FORM,
        },
        {
            key: "2",
            text: "External Link",
            value: activityTypes.EXTERNAL_LINK,
        },
        {
            key: "3",
            text: "Content",
            value: activityTypes.CONTENT,
        },
        {
            key: "4",
            text: "Custom Form",
            value: activityTypes.CUSTOM_FORM,
        }
    ];

    let salesforceObjects: DropdownOptions[] = [
        {
            key: "Test",
            text: "Test",
            value: "Test"
        },
        {
            key: "Case",
            text: "Case",
            value: "Case"
        },
        {
            key: "COE_Permit__c",
            text: "COE Permit",
            value: "COE_Permit__c"
        },
        {
            key: "Zoning_Inquiry__c",
            text: "Zoning Inquiry",
            value: "Zoning_Inquiry__c"
        },
    ];

    let laserficheProcesses: DropdownOptions[] = laserficheForms
        ? laserficheForms.businessProcesses.map((businessProcess, i) => {
            return {
                key: businessProcess.id.toString(),
                text: businessProcess.name,
                value: businessProcess.id.toString()
            }
        })
        : [];

    let handleDeleteActivity = async () => {
        await dispatch(deleteActivity(id));
        history.push('/activities');
    }

    let handleOpenDeleteModal = () => {
        setDeleteModalOpen(true);
    }

    let handleCloseDeleteModal = () => {
        setDeleteModalOpen(false);
    }

    let handleSObjectChange = (value: string) => {
        dispatch(getActivitySObjectFields(value))
        dispatch(setSalesforceObject({ value }))
    }

    let handleOnSubmit = async () => {
        if (validateForm()) {
            dispatch(editActivity(history, id));
        }
    }

    let clearValidators = () => {
        setInvalidName(null);
        setInvalidType(null);
        setInvalidDescription(null);
        setInvalidOrder(null);
        setInvalidSalesforceObject(null);
        setInvalidLaserficheProcess(null);
        setInvalidCalendlyLink(null);
        setInvalidExternalLink(null);
        setInvalidContent(null);
        setContainsError(false);
    }

    let validateForm = () => {
        clearValidators();
        let validForm = true;

        if (name.length === 0) {
            setInvalidName('Required');
            validForm = false;
        }
        if (type.length === 0) {
            setInvalidType('Required');
            validForm = false;
        }
        if (!description || description.length === 0) {
            if (popularServicesFF?.enabled) {
                dispatch(setDescription({ value: 'Press "start" to continue.' }));
            }
            else {
                setInvalidDescription('Required');
                validForm = false;
            }
        }
        if (order.toString().length === 0) {
            setInvalidOrder('Required');
            validForm = false;
        }
        if (calendlyLink && calendlyLink.length && !urlRegex.test(calendlyLink)) {
            setInvalidCalendlyLink('Invalid');
            validForm = false;
        }
        if (type === activityTypes.LASERFICHE_FORM) {
            if (!salesforceObjectId) {
                setInvalidSalesforceObject('Required');
                validForm = false;
            }

            if (!laserficheProcessId) {
                setInvalidLaserficheProcess('Required');
                validForm = false;
            }
        }
        if (type === activityTypes.EXTERNAL_LINK) {
            if (!externalLink) {
                setInvalidExternalLink('Required');
                validForm = false;
            } else if (!urlRegex.test(externalLink)) {
                setInvalidExternalLink('Invalid');
                validForm = false;
            }
        }
        if (type === activityTypes.CONTENT && !content.length) {
            setInvalidContent('Required');
            validForm = false;
        }
        if (!validForm) {
            setContainsError(true);
        }

        return validForm;
    }

    let imgUpload = (e: any) => {
        setLocalFile(URL.createObjectURL(e.target.files[0]));
    }

    if (errorMessage) {
        return <Message error header={"Error"} content={errorMessage} />;
    }

    return (
        <PageContainer>
            <div className={styles.pageHeader}>
                <div className='headerContainer'>
                    <h2>{t('Activity.EditActivity')}</h2>
                    <Spinner isLoading={loading} />
                </div>
                <div className={styles.submitContainer}>
                    <Button color='blue' onClick={handleOnSubmit}>{t('Util.Submit')}</Button>

                    <Modal
                        trigger={<Button color='red' onClick={handleOpenDeleteModal}>{t('Util.Delete')}</Button>}
                        open={deleteModalOpen}
                        onClose={handleCloseDeleteModal}
                        basic
                        size='small'>
                        <Modal.Content>
                            <h3>{t('Activity.AreYouSureYouWantToDeleteThisActivity')}</h3>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color='grey' onClick={handleCloseDeleteModal}>{t('Util.Cancel')}</Button>
                            <Button color='red' onClick={handleDeleteActivity}>{t('Util.Delete')}</Button>
                        </Modal.Actions>
                    </Modal>
                </div>
            </div>
            {containsError && <p className={styles.topError}>{t('Util.FormContainsErrors')}</p>}
            <BackArrow url="/activities" text={t('Activity.BackToActivities')} />
            <PanelRow>
                <Panel>
                    <div className={styles.innerPanelContainer}>
                        <h3>{t('Activity.GeneralInformation')}</h3>
                        <div className={styles.row}>
                            <div className={styles.inputLabel}>{t('Activity.ActivityName')}</div>
                            <Text
                                value={name}
                                onChange={(e: any) => dispatch(setName({ value: e.target.value }))}
                            />
                            {invalidName ? <ErrorValidationLabel fieldDescription={'Name'} /> : null}
                        </div>
                        <div className={styles.row}>
                            <div className={styles.inputLabel}>{t('Activity.ActivityType')}</div>
                            <Dropdown value={type} options={activityTypesDropdownOptions} placeholder={activityTypesDropdownOptions[0].text} onChange={(e: any, data: any) => dispatch(setType({ value: data.value }))} disabled />
                            {invalidType ? <ErrorValidationLabel fieldDescription={'Type'} /> : null}
                        </div>
                        <div className={styles.row} style={popularServicesFF?.enabled ? {display: 'none'} : {}}>
                            <div className={styles.inputLabel}>{t('Activity.ActivityDescription')}</div>
                            <Textarea value={description} onChange={(e: any, data: any) => dispatch(setDescription({ value: data.value }))} />
                            {invalidDescription ? <ErrorValidationLabel fieldDescription={'Description'} /> : null}
                        </div>
                        <div className={styles.row}>
                            <div className={styles.orderContainer}>
                                <div className={styles.toggleButtons}>
                                    { (type === activityTypes.EXTERNAL_LINK || id === "4021C613-E8F7-4947-A1CF-AF41A38BE725") ?
                                        <>
                                            <Toggle value={toggleInAppBrowser} onChange={(e: any, data: any) => dispatch(setToggleInAppBrowser({ value: data.checked }))} text={t('Activity.ToggleInAppBrowser')} />
                                            <br />
                                        </>
                                        :
                                        null
                                    }
                                    <Toggle value={guestEnabled} onChange={(e: any, data: any) => dispatch(setGuestEnabled({ value: data.checked }))} text={t('Activity.GuestEnabled')} />
                                    <br />
                                    <Toggle value={published} onChange={(e: any, data: any) => dispatch(setPublished({ value: data.checked }))} text={t('Activity.Published')} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.row} style={popularServicesFF?.enabled ? {display: 'none'} : {}}>
                            <div className={styles.inputLabel}>{t('Activity.Order')}</div>
                            <div className={styles.orderContainer}>
                                <Text value={order} onChange={(e: any, data: any) => dispatch(setOrder({ value: data.value }))} type='number' min={0} />
                            </div>
                            {invalidOrder ? <ErrorValidationLabel fieldDescription={'Order'} /> : null}
                        </div>
                        <div className={styles.row} style={!popularServicesFF?.enabled ? {display: 'none'} : {}}>
                            <div>
                                <div className={styles.inputLabel} style={{ marginBottom: '10px' }}>{t('Activity.PopularService')}</div>
                                <Toggle value={popularService} onChange={(e: any, data: any) => dispatch(setPopularService({ value: data.checked }))} text={t('Activity.IsPopularService')} />
                                <br/>
                                <div style={!popularService ? {display: 'none'} : {}}>
                                    <div className={styles.inputLabel}>{t('Popular Service Icon')}</div>
                                    <div className={styles.inputLabelNote}>{'Please note that the recommended image size for icons is 32 by 32 pixels.'}</div>
                                </div>
                                <div className={styles.buttonWrap} style={!popularService ? {display: 'none'} : {}}>
                                    {((popularServiceUrl && popularService) || localFile) &&
                                        <img src={localFile ? localFile : popularServiceUrl} alt='activity icon' className={styles.activityIcon} />
                                    }
                                    <>
                                        <label className={styles.fileUploadButton} htmlFor="activityIconFile">{(popularServiceUrl || localFile) ? 'Upload New Icon For Service' : t('Activity.UploadIconForService')}</label>
                                        <input type="file" id="activityIconFile" name="upload" onChange={(e) => imgUpload(e)} />
                                    </>
                                </div>
                                <div style={!popularService ? {display: 'none'} : {}}>
                                    <br />
                                    <div className={styles.inputLabel} style={{ marginBottom: '10px' }}>{t('Popular Service Order')}</div>
                                    <div className={styles.orderContainer}>
                                        <Text value={popularServiceOrder ? popularServiceOrder : 0} onChange={(e: any, data: any) => dispatch(setPopularServiceOrder({ value: data.value }))} type='number' min={0} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Panel>
            </PanelRow>
            {(type === activityTypes.LASERFICHE_FORM || type === activityTypes.CUSTOM_FORM) &&
                <PanelRow>
                    <Panel>
                        <div className={styles.innerPanelContainer}>
                            <h3>{t('Activity.CalendlyLink')}</h3>
                            <div className={styles.row}>
                                <div className={styles.inputLabel}>{t('Activity.URL')}</div>
                                <Text value={calendlyLink} onChange={(e: any, data: any) => dispatch(setCalendlyLink({ value: data.value }))} />
                                {invalidCalendlyLink ? <ErrorValidationLabel fieldDescription={'URL'} reasoning={invalidCalendlyLink} /> : null}
                            </div>
                        </div>
                    </Panel>
                </PanelRow>
            }
            {type === activityTypes.LASERFICHE_FORM &&
                <>
                    <PanelRow>
                        <Panel>
                            <div className={styles.innerPanelContainer}>
                                <h3>{t('Activity.SalesforceConfiguration')}</h3>
                                <div className={styles.row}>
                                    <div className={styles.inputLabel}>{t('Activity.SalesforceObject')}</div>
                                    <Dropdown value={salesforceObjectId} options={salesforceObjects} placeholder={`${t('Activity.SalesforceObject')}...`} onChange={(e: any, data: any) => handleSObjectChange(data.value)} />
                                    {invalidSalesforceObject ? <ErrorValidationLabel fieldDescription={t('Activity.SalesforceObject')} /> : null}
                                </div>
                            </div>
                        </Panel>
                    </PanelRow>
                    <PanelRow>
                        <Panel>
                            <div className={styles.innerPanelContainer}>
                                <h3>{t('Activity.LaserficheFormConfiguration')}</h3>
                                <div className={styles.row}>
                                    <div className={styles.inputLabel}>{t('Activity.LaserficheProcess')}</div>
                                    <Dropdown value={laserficheProcessId} options={laserficheProcesses} placeholder={`${t('Activity.LaserficheProcess')}...`} onChange={(e: any, data: any) => dispatch(setLaserficheProcess({ value: data.value }))} />
                                    {invalidLaserficheProcess ? <ErrorValidationLabel fieldDescription={t('Activity.LaserficheProcess')} /> : null}
                                </div>
                            </div>
                        </Panel>
                    </PanelRow>
                </>
            }
            {type === activityTypes.EXTERNAL_LINK &&
                <PanelRow>
                    <Panel>
                        <div className={styles.innerPanelContainer}>
                            <h3>{t('Activity.ExternalLinkConfiguration')}</h3>
                            <div className={styles.row}>
                                <div className={styles.inputLabel}>{t('Activity.URL')}</div>
                                <Text value={externalLink} onChange={(e: any, data: any) => dispatch(setExternalLink({ value: data.value }))} />
                                {invalidExternalLink ? <ErrorValidationLabel fieldDescription={'URL'} reasoning={invalidExternalLink} /> : null}
                            </div>
                        </div>
                    </Panel>
                </PanelRow>
            }
            {type === activityTypes.CONTENT &&
                <PanelRow>
                    <Panel>
                        <div className={styles.innerPanelContainer}>
                            <h3>{t('Activity.ContentConfiguration')}</h3>
                            <div className={styles.row}>
                                <div className={styles.inputLabel}>{t('Activity.Content')}</div>
                                <Editor value={content} onEditorChange={(content, editor) => dispatch(setContentConfiguration({ value: content }))} />
                                {invalidContent ? <ErrorValidationLabel fieldDescription={'Content'} /> : null}
                            </div>
                        </div>
                    </Panel>
                </PanelRow>
            }
            <PanelRow>
                <Panel>
                    <div className={styles.innerPanelContainer}>
                        <h3>{t('Activity.PaymentConfiguration')}</h3>
                        <div className={styles.row}>
                            <Toggle value={paymentRequired} onChange={(e: any, data: any) => dispatch(setPaymentRequired(data.checked))} text={t('Activity.PaymentRequired')} />
                        </div>
                    </div>
                </Panel>
            </PanelRow>
        </PageContainer>
    );
}
