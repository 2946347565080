import React, { FunctionComponent } from 'react';
import styles from './UserSearch.module.scss';

export interface UserSearchProps  { 
    placeholder?: string;
    onChange?(event: any, data?: any): void;
    onKeyDown?(event: any) : void;
    value: string | number;
    disabled?: boolean;
}

export const UserSearch: FunctionComponent<UserSearchProps> = ({placeholder, onChange, value, disabled}) => {
    
    return (
        <div className={styles.searchWrapper}>
            <i className={`far fa-search ${styles.searchIcon}`} aria-hidden='true'></i>
            <input className={styles.searchInput} type='text' value={value} onChange={onChange} disabled={disabled} placeholder={placeholder} />
        </div>
    );
}
