import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    listActivities
} from './activitiesListActionCreators';
import { useAppStateActivitiesListSelector } from './activitiesListSlice';
import { Activity, ActivityActions } from './activitiesListAppState';
import styles from './ActivitiesList.module.scss';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../components/spinner/Spinner';
import { PageContainer } from '../../../components/pageContainer/PageContainer';
import { useAuthSelector } from '../../auth/authSlice';

interface ActivityListingProps {
    activity: Activity;
    index: number;
}


interface ActionContainerProps {
    title: string;
    items: Activity[];
}

const ActivityListing = (props: ActivityListingProps) => {
    const { t } = useTranslation();
    return (
        <div className="listItemColor">
            <Link to={`/activities/${props.activity.Id}`} style={{color: "inherit"}}>
                <div className={styles.list_item}>
                    <div className={styles.item_text}>
                        {t(`Activity.${props.activity.Action}_${props.activity.Item}`)}
                    </div>
                    <div className={[styles.tag, styles[props.activity.Type]].join(' ')}>
                        {props.activity.Type.toLowerCase().replace('_', ' ')}
                    </div>
                </div>
            </Link>
        </div>
    );
}

const ActionContainer = (props: ActionContainerProps) => {
    const { t } = useTranslation();
    return (
        <div className={styles.action_container}>
            <h3 className={styles.action_container_heading}>{props.title.toLocaleLowerCase().replace('_', ' ')}</h3>
            <Link to={`/activities/create?action=${props.title as ActivityActions}`} className={"ui button"}>{t('Activity.AddActivity')}</Link>
            <div className={styles.listing_table}>
                {props.items.map((activity, index) => (
                    <ActivityListing key={activity.Id} activity={activity} index={index} />
                ))}
                {!props.items.length ? 
                    <div className={styles.item_text}>
                        {t('Activity.NoActivities')}
                    </div>
                : null}
            </div>
        </div>
    );
}

export function ActivitiesList() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { authUser } = useAuthSelector(state => state);
    // Load Data on page load
    useEffect(() => {
        dispatch(listActivities(authUser?.Id));
    }, [dispatch, authUser]);

    const { activities, loading } = useAppStateActivitiesListSelector(state => state);

    let ActionContainers = [];
    for (const action in activities) {
        if (activities.hasOwnProperty(action)) {
            const actionActivites = activities[action as ActivityActions];
            ActionContainers.push(<ActionContainer key={action} title={action} items={actionActivites} />)
        }
    }

    return (
        <PageContainer>
            <div className='headerContainer'>
                <h1 className={styles.heading}>{t('Activity.ActivityManagement')}</h1>
                <Spinner isLoading={loading} />
            </div>
            <div>
                {ActionContainers}
            </div>
        </PageContainer>
    );
}