import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Icon, Input, Modal } from 'semantic-ui-react';
import styles from './SystemAlerts.module.scss';
import { setAddAlertModalOpen, setDeleteAlertModalOpen, setEditAlertModalOpen, useAppStateSystemAlertsListSelector } from './systemAlertsListSlice';
import { createAlert, deleteAlert, updateAlert } from './systemAlertsActionCreators';
import RichTextEditor from '../../components/textEditor/textEditor';
import { Toggle } from '../../components/toggle/Toggle';
import { Alert } from './systemAlertsAppState';

interface AlertModal {
    modalOpen: boolean;
    alert: Alert | null;
    index?: number;
}

export const AlertModal = ({modalOpen, alert, index}: AlertModal) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [active, setActive] = useState(alert ? alert.Active : true);
    const [titleEnglish, setTitleEnglish] = useState(alert ? alert.TitleAlertEnglish : '');
    const [titleSpanish, setTitleSpanish] = useState(alert ? alert.TitleAlertSpanish : '');
    const [englishEditorContent, setEnglishEditorContent] = useState(alert ? alert.TextEnglish : '<p></p>');
    const [spanishEditorContent, setSpanishEditorContent] = useState(alert ? alert.TextSpanish : '<p></p>');
    const [englishWhiteSpace, setEnglishWhiteSpace] = useState(alert === null);
    const [spanishWhiteSpace, setSpanishWhiteSpace] = useState(alert === null);

    const { addAlertModalOpen, editAlertModalOpen, deleteAlertModalOpen } = useAppStateSystemAlertsListSelector(state => state);

    const submit = () => {
        if (addAlertModalOpen) {
            dispatch(createAlert(titleEnglish, titleSpanish, englishEditorContent, spanishEditorContent));
            setTitleEnglish('');
            setTitleSpanish('');
            setEnglishEditorContent('<p></p>');
            setSpanishEditorContent('<p></p>');
            dispatch(setAddAlertModalOpen(false));
        } else if (index !== undefined && editAlertModalOpen[index]) {
            dispatch(updateAlert(alert!.Id, titleEnglish, titleSpanish, englishEditorContent, spanishEditorContent, active));
            closeAlertEditing();
        } else if (index !== undefined && deleteAlertModalOpen[index]) {
            dispatch(deleteAlert(alert!.Id));
            closeAlertDeleting();
        }
    }

    const close = () => {
        if (addAlertModalOpen) {
            setTitleEnglish('');
            setTitleSpanish('');
            setEnglishEditorContent('<p></p>');
            setSpanishEditorContent('<p></p>');
            dispatch(setAddAlertModalOpen(false));
        } else if (index !== undefined && editAlertModalOpen[index]) {
            if (alert !== null) {
                setTitleEnglish(alert.TitleAlertEnglish);
                setTitleSpanish(alert.TitleAlertSpanish);
                setEnglishEditorContent(alert.TextEnglish);
                setSpanishEditorContent(alert.TextSpanish);
                setActive(alert.Active);
            }
            closeAlertEditing();
        } else if (index !== undefined && deleteAlertModalOpen[index]) {
            closeAlertDeleting();
        }
    }

    const closeAlertEditing = () => {
        const arrCopy: boolean[] = [...editAlertModalOpen];
        arrCopy.splice(index!, 1, false);
        dispatch(setEditAlertModalOpen(arrCopy));
    }

    const closeAlertDeleting = () => {
        const arrCopy: boolean[] = [...deleteAlertModalOpen];
        arrCopy.splice(index!, 1, false);
        dispatch(setDeleteAlertModalOpen(arrCopy));
    }

    const onEnglishTitleChange = (e: any) => {
        setTitleEnglish(e.target.value);
    };
    const onSpanishTitleChange = (e: any) => {
        setTitleSpanish(e.target.value);
    };

    const onEnglishEditorChange = (editorContent: string) => {
        setEnglishEditorContent(editorContent);
    };
    const onSpanishEditorChange = (editorContent: string) => {
        setSpanishEditorContent(editorContent);
    };

    function onWhiteSpaceEnglish(whiteSpace: boolean) {
        setEnglishWhiteSpace(whiteSpace);
    }

    function onWhiteSpaceSpanish(whiteSpace: boolean) {
        setSpanishWhiteSpace(whiteSpace);
    }

    function setModalTitle(): string {
        if (addAlertModalOpen) {
            return t('Alert.AddAlert');
        } else if (index !== undefined && editAlertModalOpen[index]) {
            return t('Alert.EditAlert');
        } else if (index !== undefined && deleteAlertModalOpen[index]) {
            return t('Alert.DeleteAlert') + ' "' + alert?.TitleAlertEnglish + '?"';
        }
        return 'N/A';
    }

    return (
        <Modal open={modalOpen} size={(index !== undefined && deleteAlertModalOpen[index]) ? 'mini' : 'large'}>
            <Modal.Header>
                {setModalTitle()}
                <div style={{'float': 'right'}}>
                    <Icon
                        icon='x'
                        color='black'
                        size="large"
                        onClick={() => close()}
                    />
                </div>
            </Modal.Header>
            <Modal.Content>
                <div className={styles.modalContent}>
                    <div>
                        {index !== undefined && editAlertModalOpen[index] &&
                            <div className={styles.toggleContainer}>
                                <h3>{t('Alert.SetActive')}</h3>
                                {(active !== null || active !== undefined) &&
                                    <Toggle value={active} onChange={(e: any, data: any) => setActive(data.checked)} text={t('Alert.Active')} />
                                }
                            </div>
                        }
                        {((index !== undefined && editAlertModalOpen[index]) || addAlertModalOpen) &&
                            <div className={styles.textEditorContainer}>
                                <h3>{t('Alert.AlertTitleEnglish')}</h3>
                                {englishEditorContent && 
                                    <Input value={titleEnglish} onChange={onEnglishTitleChange} className={styles.titleContainer}/>
                                }
                            </div>
                        }
                        {((index !== undefined && editAlertModalOpen[index]) || addAlertModalOpen) &&
                            <div className={styles.textEditorContainer}>
                                <h3>{t('Alert.EnglishText')}</h3>
                                {englishEditorContent && 
                                    <RichTextEditor text={englishEditorContent} onEditorChange={onEnglishEditorChange} onWhiteSpace={onWhiteSpaceEnglish} />
                                }
                            </div>
                        }
                        {((index !== undefined && editAlertModalOpen[index]) || addAlertModalOpen) &&
                            <div className={styles.textEditorContainer}>
                                <h3>{t('Alert.AlertTitleSpanish')}</h3>
                                {spanishEditorContent && 
                                    <Input value={titleSpanish} onChange={onSpanishTitleChange} className={styles.titleContainer}/>
                                }
                            </div>
                        }
                        {((index !== undefined && editAlertModalOpen[index]) || addAlertModalOpen) &&
                            <div className={styles.textEditorContainer}>
                                <h3>{t('Alert.SpanishText')}</h3>
                                {spanishEditorContent &&
                                    <RichTextEditor text={spanishEditorContent} spanish={true} onEditorChange={onSpanishEditorChange} onWhiteSpace={onWhiteSpaceSpanish} />
                                }
                            </div>
                        }
                        {(index !== undefined && deleteAlertModalOpen[index]) &&
                            <div className={styles.alertDeleteModalBody}>
                                <p>{t('Are you sure you want to delete this alert?')}</p>
                            </div>
                        }
                        <div className={styles.submitContainer}>
                            <div className={styles.buttonRight}>
                                <Button
                                    onClick={submit}
                                    color={(index !== undefined && deleteAlertModalOpen[index]) ? 'red' : 'blue'}
                                    fluid={false}
                                    disabled={
                                        englishWhiteSpace || 
                                        spanishWhiteSpace || 
                                        titleEnglish.trim().length === 0 ||
                                        titleSpanish.trim().length === 0
                                    }
                                >
                                    {(index !== undefined && deleteAlertModalOpen[index]) ? t('Util.Yes') : t('Util.Save')}
                                </Button>
                            </div>
                            <div className={styles.buttonLeft}>
                                <Button
                                    onClick={close}
                                    color={(index !== undefined && deleteAlertModalOpen[index]) ? 'blue' : 'red'}
                                    fluid={false}
                                >
                                    {t('Util.Cancel')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Content>
        </Modal>
    );
}