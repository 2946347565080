import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    setPassword,
    setConfirmPassword,
    useAppStateResetPasswordSelector,
    setExpiredUrl
} from './resetPasswordSlice'
import {
    resetPasswordRequest,
} from './resetPasswordActionCreators';
import styles from './ResetPassword.module.scss';
import { Input, Message } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { firebaseService } from '../../services/firebaseService';
import { Reasoning, ErrorValidationLabel } from '../../components/errorValidationLabel/ErrorValidationLabel';
import { Button } from '../../components/button/Button';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../components/spinner/Spinner';

export function ResetPassword() {
    const {
        password,
        confirmPassword,
        expiredUrl,
        success,
        loading
    } = useAppStateResetPasswordSelector(state => state);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    let [invalidPassword, setInvalidPassword] = useState<Reasoning | null>(null);
    let [invalidConfirmPassword, setInvalidConfirmPassword] = useState<Reasoning | null>(null);

    const oobCode = new URLSearchParams(window.location.search).get('oobCode');
    const mode = new URLSearchParams(window.location.search).get('mode')?.toLowerCase();

    useEffect(() => {
        firebaseService.auth().verifyPasswordResetCode(oobCode!)
        .then(() => {
            if (mode === 'verifyemail') {
                window.location.assign('/dashboard');
            } else {
                dispatch(setExpiredUrl(false));
            }
        }).catch((err) => {
            dispatch(setExpiredUrl(true));
        });
    }, [dispatch, oobCode, mode]);

    let handleResetPassword = () => {
        let valid = validateForm();
        if (valid) {
            dispatch(resetPasswordRequest(oobCode!, password));
        }
    }

    let validateForm = () => {
        clearValidators();
        let validForm = true;

        const passwordRegex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$");

        if (password.length === 0) {
            setInvalidPassword('Required');
            validForm = false;
        } else if (password.match(passwordRegex) === null) {
            setInvalidPassword('RequirementsNotMet');
            validForm = false;
        }

        if (confirmPassword.length === 0) {
            setInvalidConfirmPassword('Required');
            validForm = false;
        } else if (confirmPassword !== password) {
            setInvalidConfirmPassword('Match');
            validForm = false;
        }

        return validForm;
    }

    let clearValidators = () => {
        setInvalidPassword(null);
    }

    if (expiredUrl) {
        return(
            <div className={styles.page}>
                <Message negative>
                    <Message.Header>{t('Login.UrlHasExpired')}</Message.Header>
                </Message>
            </div>
        );
    }

    return (
        expiredUrl === false ?
            <div className={styles.page}>
                <div className={styles.form}>
                    {success ? 
                        <Message positive>
                            <Message.Header>{t('Login.PasswordResetSuccessfully')}</Message.Header>
                        </Message>
                    : null}
                    <div className={styles.row}>
                        <div className='headerContainer'>
                            <h2>{t('Login.ResetPassword')}</h2>
                            <Spinner isLoading={loading}/>
                        </div>
                    </div>
                    
                    <div className={styles.row}>
                        {t('Login.NewPassword')}
                        <Input
                            value={password}
                            onChange={(e: any) => dispatch(setPassword({ value: e.target.value }))}
                            type="password"
                        />
                        {invalidPassword ?
                            <ErrorValidationLabel
                                fieldDescription={t('Login.NewPassword')}
                                reasoning={invalidPassword}
                                requirements={[
                                    'ErrorValidation.MinimumEightCharacters',
                                    'ErrorValidation.OneUpperCaseLetter',
                                    'ErrorValidation.OneLowerCaseLetter',
                                    'ErrorValidation.OneNumber',
                                    'ErrorValidation.OneSpecialCharacter',
                                ]}
                            />
                        : null}
                    </div>
                    
                    <div className={styles.row}>
                        {t('Login.ConfirmPassword')}
                        <Input
                            value={confirmPassword}
                            onChange={(e: any) => dispatch(setConfirmPassword({ value: e.target.value }))}
                            type="password"
                        />
                        {invalidConfirmPassword ? <ErrorValidationLabel fieldDescription={t('Login.ConfirmPassword')} reasoning={invalidConfirmPassword} /> : null}
                    </div>

                    {!success ?
                        <div className={styles.row}>
                            <Button color='blue' onClick={handleResetPassword} >
                                {t('Login.ResetPassword')}
                            </Button>
                        </div>
                    :
                        <div className={styles.row}>
                            <NavLink exact to="/login" className={styles.center}>{t('Login.ReturnToLogin')}</NavLink>
                        </div>
                    }
                </div>
            </div>
        : null
    );
}