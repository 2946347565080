import React, { useEffect, useCallback, useState } from 'react';
import styles from './AccountEdit.module.scss';
import { useAuthSelector } from '../../auth/authSlice';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PanelRow } from '../../../components/panel/PanelRow';
import { Panel } from '../../../components/panel/Panel';
import { UserIcon } from '../../../components/userIcon/UserIcon';
import { Text } from '../../../components/text/Text';
import {
    useAppStateAccountEditSelector,
    setFirstName,
    setLastName,
    setAddress1,
    setAddress2,
    setCity,
    setState,
    setZip,
    setPhoneNumber,
    setInvalidFirstName,
    setInvalidLastName,
    setInvalidPhoneNumber,
    setInvalidAddress1,
    setInvalidCity,
    setInvalidState,
    setInvalidZip,
    toggleModalOpen,
    setFileTooLarge,
    setEmail,
    setUpdating,
} from './accountEditSlice';
import { Dropdown } from '../../../components/dropdown/Dropdown';
import { states } from '../../../static/json/states';
import { Button } from '../../../components/button/Button';
import {
    getAddressArcGIS,
    saveAccount,
    savePicture,
} from './accountEditActionCreator';
import { ErrorValidationLabel } from '../../../components/errorValidationLabel/ErrorValidationLabel';
import { Modal } from 'semantic-ui-react';
import { Icon } from '../../../components/icon/Icon';
import { useDropzone } from 'react-dropzone';
import { PageContainer } from '../../../components/pageContainer/PageContainer';
import { BillingSettings } from '../billing/BillingSettings';
import { Spinner } from '../../../components/spinner/Spinner';
import { openMessageModal } from '../../../components/statusModal/messageModalActionCreators';
import { MessageModal } from '../../../components/statusModal/MessageModal';
import { userService } from '../../../services/userService';
import { firebaseService } from '../../../services/firebaseService';
import { useFeatureFlag } from '../../featureFlags/FeatureFlag';
import { NotificationSettings } from '../../../components/notificationSettings/NotificationSettings';

interface PictureEditModalProps {
    modalOpen: boolean;
    iconUrl: string | null;
    onClose(): void;
}

const PictureEditModal = (props: PictureEditModalProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [files, setFiles] = useState<any | null>([]);
    const { modalLoading: loading, fileTooLarge } =
        useAppStateAccountEditSelector((s) => s);

    const onDrop = useCallback(
        (acceptedFiles) => {
            dispatch(setFileTooLarge(false));
            let totalFileSize = 0;
            acceptedFiles.forEach((file: any) => {
                totalFileSize += file.size;
            });
            if (totalFileSize > 104857600) {
                dispatch(setFileTooLarge(true));
            }

            setFiles(
                acceptedFiles.map((file: any) =>
                    Object.assign(file, { preview: URL.createObjectURL(file) }),
                ),
            );
        },
        [dispatch],
    );

    const onDropRejected = useCallback(
        (rejectedFiles) => {
            rejectedFiles.forEach((file: any) => {
                if (file.errors && file.errors[0].code === 'file-too-large') {
                    dispatch(setFileTooLarge(true));
                }
            });
        },
        [dispatch],
    );

    const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
        useDropzone({
            onDrop,
            onDropRejected,
            multiple: false,
            maxSize: 104857600,
        });

    const imageSubmit = () => {
        if (acceptedFiles.length) {
            dispatch(savePicture(acceptedFiles[0]));
        }
    };

    return (
        <Modal open={props.modalOpen} onClose={props.onClose}>
            <Modal.Header>
                Update Profile Picture
                <div style={{ float: 'right' }}>
                    <Icon
                        icon="x"
                        color="black"
                        size="large"
                        onClick={props.onClose}
                    />
                </div>
            </Modal.Header>
            <Modal.Content>
                <div className={styles.modalContent}>
                    <div>
                        {files.length && files[0].preview ? (
                            <img
                                src={(files[0] as any).preview}
                                className={styles.filePreview}
                                alt="file upload preview"
                            />
                        ) : (
                            <UserIcon size="large" iconUrl={props.iconUrl} />
                        )}
                    </div>
                    <div className={styles.modalFileUploadContainer}>
                        <div {...getRootProps()} className={styles.fileUpload}>
                            <input {...getInputProps()} />
                            {isDragActive ? (
                                <p>{t('Account.EditDropPicture')}</p>
                            ) : (
                                <p>{t('Util.DragDropHere')}</p>
                            )}
                        </div>
                        <p
                            className={
                                fileTooLarge
                                    ? styles.modalFileSizeLimitError
                                    : styles.modalFileSizeLimit
                            }
                        >
                            {t('Util.FileSizeLimit', { count: 100 })}
                        </p>
                    </div>
                </div>
                <Button onClick={imageSubmit} loading={loading}>
                    {t('Util.Submit')}
                </Button>
            </Modal.Content>
        </Modal>
    );
};

export function AccountEdit() {
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();
    const history = useHistory();
    const [deleteAccountWarningOpen, setDeleteAccountWarningOpen] =
        useState(false);

    const delinquentBillingNotificationsFF = useFeatureFlag(
        'EnableDelinquentBillingNotifications',
    );

    const id = location.pathname.split('account/')[1];
    const { authUser } = useAuthSelector((state) => state);
    const {
        email,
        firstName,
        lastName,
        phoneNumber,
        address1,
        address2,
        city,
        state,
        zip,
        updating,
        invalidFirstName,
        invalidLastName,
        invalidPhoneNumber,
        invalidAddress1,
        invalidCity,
        invalidState,
        invalidZip,
        landParcel,
        loadingLandParcel,
        modalOpen,
    } = useAppStateAccountEditSelector((state) => state);

    useEffect(() => {
        dispatch(getAddressArcGIS(authUser));
        dispatch(setEmail(authUser?.Email));
        dispatch(setFirstName(authUser?.FirstName));
        dispatch(setLastName(authUser?.LastName));
        dispatch(setPhoneNumber(authUser?.PhoneNumber));
        dispatch(setAddress1(authUser?.salesforceContact?.address1));
        dispatch(setAddress2(authUser?.salesforceContact?.address2));
        dispatch(setCity(authUser?.salesforceContact?.city));
        dispatch(setState(authUser?.salesforceContact?.state));
        dispatch(setZip(authUser?.salesforceContact?.zip));
    }, [dispatch, authUser]);

    const submit = async () => {
        if (validateForm()) {
            dispatch(saveAccount());
        }
    };

    const deleteAccount = async () => {
        dispatch(setUpdating(true));
        try {
            await userService.deleteUser(id);
            try {
                await firebaseService.auth().signOut();
            } catch (e) {
                console.log(e);
            }
            dispatch(setUpdating(false));
            history.push('/');
            dispatch(openMessageModal('success-delete-account', 'success'));
        } catch (err) {
            dispatch(setUpdating(false));
            dispatch(openMessageModal('error-remove', 'error'));
            console.error(err);
        }
    };

    const confirmationClick = async () => {
        setDeleteAccountWarningOpen(false);
        deleteAccount();
    };

    const clearValidators = () => {
        dispatch(setInvalidFirstName(false));
        dispatch(setInvalidLastName(false));
        dispatch(setInvalidPhoneNumber(false));
        dispatch(setInvalidAddress1(false));
        dispatch(setInvalidCity(false));
        dispatch(setInvalidState(false));
        dispatch(setInvalidZip(false));
    };

    const validateForm = () => {
        clearValidators();

        let validForm = true;

        if (firstName.length === 0) {
            dispatch(setInvalidFirstName('Required'));
            validForm = false;
        }
        if (lastName.length === 0) {
            dispatch(setInvalidLastName('Required'));
            validForm = false;
        }
        if (!phoneNumber || phoneNumber.length === 0) {
            dispatch(setPhoneNumber(''));
        }
        if (
            (address1 && address1.length !== 0) ||
            (city && city.length !== 0) ||
            (state && state.length !== 0) ||
            (zip && zip.length !== 0)
        ) {
            if (!address1 || address1.length === 0) {
                dispatch(setInvalidAddress1('Required'));
                validForm = false;
            }
            if (!city || city.length === 0) {
                dispatch(setInvalidCity('Required'));
                validForm = false;
            }
            if (!state || state.length === 0) {
                dispatch(setInvalidState('Required'));
                validForm = false;
            }
            if (!zip || zip.length === 0) {
                dispatch(setInvalidZip('Required'));
                validForm = false;
            }
        }

        let phoneNumberRegex = new RegExp(
            /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/, // eslint-disable-line
        );
        if (phoneNumber && !phoneNumberRegex.test(phoneNumber)) {
            dispatch(setInvalidPhoneNumber('Invalid'));
            validForm = false;
        }

        return validForm;
    };

    return (
        <PageContainer>
            <MessageModal
                modalOpen={deleteAccountWarningOpen}
                onConfirmationButtonClick={() => {
                    confirmationClick();
                }}
                onCloseButtonClick={() => {
                    setDeleteAccountWarningOpen(false);
                }}
            />
            {authUser?.Id === id ? (
                <>
                    <div className={styles.submitContainer}>
                        <div>
                            <Button
                                onClick={() => {
                                    setDeleteAccountWarningOpen(true);
                                    dispatch(
                                        openMessageModal(
                                            'warning-delete-account',
                                            'warning',
                                        ),
                                    );
                                }}
                                color="red"
                                fluid={false}
                                loading={updating}
                                disabled={updating}
                            >
                                {t('Billing.DeleteAccount')}
                            </Button>
                            <div className={styles.buttonSpacer}></div>
                            <Button
                                onClick={() => submit()}
                                color="blue"
                                fluid={false}
                                loading={updating}
                                disabled={updating}
                            >
                                {t('Util.SaveChanges')}
                            </Button>
                        </div>
                    </div>
                    <PanelRow fluid>
                        <Panel size={1}>
                            <div className={styles.nameContainer}>
                                <div className={styles.iconContainer}>
                                    <UserIcon
                                        size="large"
                                        iconUrl={authUser.ProfilePictureUrl}
                                        onClick={() =>
                                            dispatch(toggleModalOpen(null))
                                        }
                                    />
                                    <PictureEditModal
                                        modalOpen={modalOpen}
                                        iconUrl={authUser.ProfilePictureUrl}
                                        onClose={() =>
                                            dispatch(toggleModalOpen(null))
                                        }
                                    />
                                </div>
                                <div>
                                    <p style={{ textAlign: 'center' }}>
                                        {email}
                                    </p>
                                    <Text
                                        value={firstName ?? ''}
                                        onChange={(e: any) =>
                                            dispatch(
                                                setFirstName(e.target.value),
                                            )
                                        }
                                        placeholder={t('Account.FirstName')}
                                        id="firstName"
                                    />
                                    {invalidFirstName ? (
                                        <ErrorValidationLabel
                                            fieldDescription={t(
                                                'Account.FirstName',
                                            )}
                                            reasoning={invalidFirstName}
                                        />
                                    ) : null}
                                    <Text
                                        value={lastName ?? ''}
                                        onChange={(e: any) =>
                                            dispatch(
                                                setLastName(e.target.value),
                                            )
                                        }
                                        placeholder={t('Account.LastName')}
                                        id="lastName"
                                    />
                                    {invalidLastName ? (
                                        <ErrorValidationLabel
                                            fieldDescription={t(
                                                'Account.FirstName',
                                            )}
                                            reasoning={invalidFirstName}
                                        />
                                    ) : null}
                                    <Text
                                        value={phoneNumber ?? ''}
                                        onChange={(e: any) =>
                                            dispatch(
                                                setPhoneNumber(e.target.value),
                                            )
                                        }
                                        placeholder={t('Account.PhoneNumber')}
                                        mask="(999) 999-9999"
                                        id="phoneField"
                                    />
                                    {invalidPhoneNumber ? (
                                        <ErrorValidationLabel
                                            fieldDescription={t(
                                                'Account.PhoneNumber',
                                            )}
                                            reasoning={invalidPhoneNumber}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </Panel>
                        <Panel size={2}>
                            {loadingLandParcel ? (
                                <div className={styles.centerContainer}>
                                    <Spinner isLoading />
                                </div>
                            ) : (
                                <div className={styles.addressContainer}>
                                    <div className={styles.addressForm}>
                                        <h1>Primary Address</h1>
                                        <div
                                            className={`${styles.slightMarginBottom} ${styles.slightMarginTop}`}
                                        >
                                            <Text
                                                value={address1 ?? ''}
                                                onChange={(e: any) =>
                                                    dispatch(
                                                        setAddress1(
                                                            e.target.value,
                                                        ),
                                                    )
                                                }
                                                placeholder={t(
                                                    'Account.CreateAddressLine1',
                                                )}
                                                style={{ marginBottom: 0 }}
                                                id="address1Field"
                                            />
                                            {invalidAddress1 ? (
                                                <ErrorValidationLabel
                                                    fieldDescription={t(
                                                        'Account.CreateAddressLine1',
                                                    )}
                                                    reasoning={invalidAddress1}
                                                />
                                            ) : null}
                                        </div>
                                        <Text
                                            value={address2 ?? ''}
                                            onChange={(e: any) =>
                                                dispatch(
                                                    setAddress2(e.target.value),
                                                )
                                            }
                                            placeholder={t(
                                                'Account.CreateAddressLine2',
                                            )}
                                            id="address2Field"
                                        />
                                        <div className={styles.cityStateZip}>
                                            <div className={styles.city}>
                                                <Text
                                                    value={city ?? ''}
                                                    onChange={(e: any) =>
                                                        dispatch(
                                                            setCity(
                                                                e.target.value,
                                                            ),
                                                        )
                                                    }
                                                    placeholder={t(
                                                        'Account.CreateCity',
                                                    )}
                                                    style={{ marginBottom: 0 }}
                                                    id="cityField"
                                                />
                                                {invalidCity ? (
                                                    <ErrorValidationLabel
                                                        fieldDescription={t(
                                                            'Account.CreateCity',
                                                        )}
                                                        reasoning={invalidCity}
                                                    />
                                                ) : null}
                                            </div>
                                            <div className={styles.state}>
                                                <Dropdown
                                                    placeholder={t(
                                                        'Account.CreateState',
                                                    )}
                                                    value={state ?? ''}
                                                    options={states}
                                                    onChange={(
                                                        e: any,
                                                        data: any,
                                                    ) =>
                                                        dispatch(
                                                            setState(
                                                                data.value,
                                                            ),
                                                        )
                                                    }
                                                    id="stateField"
                                                />
                                                {invalidState ? (
                                                    <ErrorValidationLabel
                                                        fieldDescription={t(
                                                            'Account.CreateState',
                                                        )}
                                                        reasoning={invalidState}
                                                    />
                                                ) : null}
                                            </div>
                                            <div className={styles.zip}>
                                                <Text
                                                    value={zip ?? ''}
                                                    onChange={(e: any) =>
                                                        dispatch(
                                                            setZip(
                                                                e.target.value,
                                                            ),
                                                        )
                                                    }
                                                    placeholder={t(
                                                        'Account.CreateZip',
                                                    )}
                                                    style={{ marginBottom: 0 }}
                                                    id="zipField"
                                                />
                                                {invalidZip ? (
                                                    <ErrorValidationLabel
                                                        fieldDescription={t(
                                                            'Account.CreateZip',
                                                        )}
                                                        reasoning={invalidZip}
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                        {!!landParcel && (
                                            <div
                                                className={
                                                    styles.landParcelContainer
                                                }
                                            >
                                                <p>
                                                    <b>
                                                        {t(
                                                            'Zoning.RefusePickupDay',
                                                        )}
                                                        :
                                                    </b>{' '}
                                                    {
                                                        landParcel.Refuse_Pickup_Day__c
                                                    }
                                                </p>
                                                <p>
                                                    <b>
                                                        {t(
                                                            'Zoning.LeafDistrict',
                                                        )}
                                                        :
                                                    </b>{' '}
                                                    {
                                                        landParcel.Leaf_District__c
                                                    }
                                                </p>
                                                <p>
                                                    <b>
                                                        {t(
                                                            'Zoning.ParcelZoning',
                                                        )}
                                                        :
                                                    </b>{' '}
                                                    {
                                                        landParcel.Parcel_Primary_Zoning__c
                                                    }
                                                </p>
                                                <p>
                                                    <b>
                                                        {t(
                                                            'Zoning.CurrentLandUse',
                                                        )}
                                                        :
                                                    </b>{' '}
                                                    {
                                                        landParcel.Parcel_Current_Land_Use__c
                                                    }
                                                </p>
                                                <p>
                                                    <b>
                                                        {t(
                                                            'Zoning.HistoricDistrict',
                                                        )}
                                                        :
                                                    </b>{' '}
                                                    {
                                                        landParcel.Historic_District__c
                                                    }
                                                </p>
                                                <p>
                                                    <b>
                                                        {t(
                                                            'Zoning.SchoolDistrict',
                                                        )}
                                                        :
                                                    </b>{' '}
                                                    {
                                                        landParcel.School_District__c
                                                    }
                                                </p>
                                                <p>
                                                    <b>
                                                        {t(
                                                            'Zoning.CountyBoardDistrict',
                                                        )}
                                                        :
                                                    </b>{' '}
                                                    {
                                                        landParcel.County_Board_District__c
                                                    }
                                                </p>
                                                <p>
                                                    <b>
                                                        {t(
                                                            'Zoning.StateRepresentativeDistrict',
                                                        )}
                                                        :
                                                    </b>{' '}
                                                    {
                                                        landParcel.State_Representative_District__c
                                                    }
                                                </p>
                                                <p>
                                                    <b>
                                                        {t(
                                                            'Zoning.StateSenateDistrict',
                                                        )}
                                                        :
                                                    </b>{' '}
                                                    {
                                                        landParcel.State_Senate_District__c
                                                    }
                                                </p>
                                                <p>
                                                    <b>
                                                        {t(
                                                            'Zoning.USCongressionalDistrict',
                                                        )}
                                                        :
                                                    </b>{' '}
                                                    {
                                                        landParcel.U_S_Congressional_District__c
                                                    }
                                                </p>
                                                <p>
                                                    <b>
                                                        {t(
                                                            'Zoning.WaterBillingCycleDates',
                                                        )}
                                                        :
                                                    </b>{' '}
                                                    {
                                                        landParcel.Water_Billing_Cycle_Dates__c
                                                    }
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </Panel>
                    </PanelRow>
                    <PanelRow>
                        <Panel>
                            <BillingSettings UserId={authUser.Id} />
                        </Panel>
                    </PanelRow>
                    {delinquentBillingNotificationsFF?.enabled && (
                        <PanelRow>
                            <Panel>
                                <h1
                                    className={
                                        styles.billingNotificationSettingsHeader
                                    }
                                >
                                    {t('Notification.NotificationSettings')}
                                </h1>
                                <p
                                    className={
                                        styles.billingNotificationSettingsNote
                                    }
                                >
                                    {t('Notification.TwilioDisclaimer')}
                                </p>

                                <NotificationSettings
                                    asModal={false}
                                    viewOnly={false}
                                    userId={authUser.Id}
                                />
                            </Panel>
                        </PanelRow>
                    )}
                </>
            ) : (
                <div>Error: you may not edit an account of a different ID</div>
            )}
        </PageContainer>
    );
}
