import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit'; 
import { buildSubStateSelector } from '../../../store/utilities/useAppStateSelector';

export interface InProgressFormatted {
    id: string;
    type: "Business Application" | "Case" | "Permit" | "Zoning Inquiry"
    activityName: string; //enum?
    status: string; //enum?
    lastModified: Date;
    lastModifiedDisplay: string;
    actionRequired: boolean;
    data: any;
    statusInformation: {
        translationKey: string;
        color: string;
    }
}

export enum InProgressStatusOptions {
    "Cancelled" = 'Cancelled',
    "Complete" = 'Complete',
    "Closed" = 'Closed',
    "Issued" = 'Issued',
    "Open" = 'Open',
    "Rejected" = 'Rejected',
    "Scheduled" = 'Scheduled',
    "Submitted" = 'Submitted',
    "UnderReview" = 'UnderReview',
    "Under_Review" = 'Under Review',
    "New" = 'New',
    "IssuedPermittingAndLicensing" = 'Issued (permitting and licensing)',
    "Pending_Payment" = 'Pending Payment',
    "PendingPayment" = 'PendingPayment',
    "PermitIssued" = 'PermitIssued',
    "ReadyforIssuance" = 'ReadyforIssuance',
    "PermitIssuedwithconditions" = 'PermitIssuedwithconditions',
    "ScheduleInspection" = 'ScheduleInspection',
    "Closed_NotApproved" = 'Closed-NotApproved',
    "ClosedNotApproved" = 'ClosedNotApproved',
    "GeneratePermit" = 'GeneratePermit',
}

const initialState = {
    loading: false,
    active: [] as InProgressFormatted[],
    previous: [] as InProgressFormatted[],
};

export type ActivitiesInProgressAppState = typeof initialState;

export const slice = createSlice<ActivitiesInProgressAppState, {
    setLoading: CaseReducer<ActivitiesInProgressAppState, PayloadAction<boolean>>,
    setActive: CaseReducer<ActivitiesInProgressAppState, PayloadAction<InProgressFormatted[]>>,
    setPrevious: CaseReducer<ActivitiesInProgressAppState, PayloadAction<InProgressFormatted[]>>,
}>({
    name: 'inProgress',
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setActive: (state, { payload }) => {
            state.active = payload;
        },
        setPrevious: (state, { payload }) => {
            state.previous = payload;
        },
    }
});

export const useAppStateActivitiesInProgressSelector = buildSubStateSelector<ActivitiesInProgressAppState>(state => state.inProgress);
export const inProgressActions = slice.actions;
export const inProgressReducer = slice.reducer;