import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';
import { Checkbox } from '../../components/checkbox/Checkbox';

export interface SalesforceFieldCheckboxProps {
    filterValue: string;
    label: string;
    sfKey: string;
    initChecked: boolean;
    onUpdate: (key: string, include: boolean) => void;
}

const SalesforceFieldCheckbox = ({ filterValue, label, sfKey, initChecked, onUpdate }: SalesforceFieldCheckboxProps) => {
    const [checked, setChecked] = useState(initChecked);

    if (!filterValue || label.toLowerCase().includes(filterValue.toLowerCase())) {
        return (
            <div style={{marginTop: 10}}>
                <Checkbox
                    text={label}
                    value={checked}
                    onChange={() => {
                        onUpdate(sfKey, !checked);
                        setChecked(!checked);
                    }}
                />
            </div>
        )
    }
    return null;
}

export interface SalesforceFieldCheckboxesProps {
    value: string[];
    options: { key: string; value: string }[];
    onChange: (newValues: string[]) => void;
}

export const SalesforceFieldCheckboxes = ({ value, options, onChange }: SalesforceFieldCheckboxesProps) => {
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState("");

    const handleUpdate = (key: string, include: boolean) => {
        let newList = include ? value.concat(key) : value.filter(v => v !== key);
        onChange(newList);
    }

    if (!options) {
        return null;
    }

    return (
        <>
            <Input
                value={searchText}
                placeholder={`${t('Util.Filter')}...`}
                onChange={(e: any) => setSearchText(e.target.value)}
            />
            <div style={{maxHeight: '200px', overflowY: 'scroll'}}>
                {options.map(option =>
                    <SalesforceFieldCheckbox
                        filterValue={searchText}
                        key={option.key}
                        label={option.value}
                        sfKey={option.key}
                        initChecked={value.includes(option.key)}
                        onUpdate={handleUpdate}
                    />
                )}
            </div>
        </>
    );
}