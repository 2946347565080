import {AxiosService} from './axiosService';
import {AxiosError} from 'axios';
import { User } from '../models/user';
import { SalesforceKnowledgeArticle } from '../models/salesforceKnowledgeArticle';

export class SalesforceService extends AxiosService {
    public constructor() { super(); }

    async matchToSalesforceContact(address1: string, address2: string, city: string, state: string, zip: string, utilityAccountId: string | null, utilityStreetNumber: string | null) {
        try {
            const user: User = (await this.axios.post("/salesforce/matchToSalesforceContact", {address1, address2, city, state, zip, utilityAccountId, utilityStreetNumber})).data;
            return {
                success: true,
                authUser: user,
            };
        } catch (e) {
            // if the status code is a 401 we do NOT want to throw
            console.log('matchToSalesforceContact err', e)
            const axiosErr = e as AxiosError;
            if (axiosErr?.response?.status !== 401) {
                if (axiosErr?.response?.status === 400) {
                    if (axiosErr?.response?.data?.message === 'utility-account-street-number-mismatch') {
                        return {
                            success: false,
                            error: axiosErr?.response?.data?.message,
                        }
                    }
                    console.log(axiosErr?.response?.data);
                }

                throw e;
            }
        }
        return null;
    }

    async getTrendingKnowledgePosts() : Promise<SalesforceKnowledgeArticle[]>{
        try {
            const articles = (await this.axios.get('/salesforce/knowledgebase/trending')).data;
            return articles;
        } catch(e) {
            console.error('Failed to fetch trending articles:', e);
            return [];
        }
    }

    async searchKnowledgebase(term: string, page: number) : Promise<SalesforceKnowledgeArticle[]> {
        try {
            const articles = (await this.axios.get(`/salesforce/knowledgebase?term=${term}&page=${page}`, )).data;
            return articles;
        } catch(e) { 
            console.error('Failed to search knowledge articles: ', e);
            return [];
        }
    }

    async getKnowledgeArticle(id: string) : Promise<SalesforceKnowledgeArticle>{
        try {
            const article = (await this.axios.get(`/salesforce/knowledgebase/${id}`)).data;
            return article;
        } catch(e) {
            console.error(`Failed to get knowledge article: ${id}`, e);
            throw e;
        }
    }
    
}

export const salesforceService = new SalesforceService();