import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './Dashboard.module.scss';
import { PanelRow } from '../../components/panel/PanelRow';
import { Panel } from '../../components/panel/Panel';
import { Button } from '../../components/button/Button';
import { Spinner } from '../../components/spinner/Spinner';
import { ActivitiesWizard } from '../../components/activitiesWizard/ActivitiesWizard';
import { useAppStateActivitiesWizardSelector } from '../../components/activitiesWizard/activitiesWizardSlice';
import { setBillingAccountSelectionModalOpen, useAppStateDashboardSelector } from './dashboardSlice';
import { loadPaymentHistory, loadActivitiesPendingAction } from './dashboardActionCreators';
import { BillPayGraph } from '../../components/billPayGraph/BillPayGraph';
import { BillPayDetails } from '../../components/billPayDetails/billPayDetails';
import { PageContainer } from '../../components/pageContainer/PageContainer';
import { setAccountNumber, setAccountType, setAmount } from '../payment/paymentSlice';
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import { NavigationCircles } from '../../components/navigationCircles/NavigationCircles';
import { useAuthSelector } from '../auth/authSlice';
import { BillPayAccountSelection } from '../../components/billPayAccountSelection/billPayAccountSelection';
import { DashboardRecentActivities } from './DashboardRecentActivities';
import { News } from '../../components/news/News';
import { Events } from '../../components/events/Events';
import { BillPayRecords } from '../../components/billPayRecords/BillPayRecords';
import { axios } from '../../services/axiosService';
import { openMessageModal } from '../../components/statusModal/messageModalActionCreators';
import { PopularServices } from '../../components/popularServices/PopularServices';
import { useFeatureFlag } from '../featureFlags/FeatureFlag';
import { useAppStateFeatureFlagSelector } from '../featureFlags/featureFlagSlice';
import { BillPayGraphV2 } from '../../components/billPayGraph/BillPayGraphV2';
import { BillPayUpcomingPaymentsV2 } from '../../components/billPayDetails/billPayUpcomingPaymentsV2';
import { Button as ButtonV2 } from '../../components/messageModalButton/MessageModalButton';
import { NavilinePaymentHistoryV2 } from '../../models/navilinePaymentHistory';
import useWindowDimensions from '../../store/utilities/useWindowDimensions';

export function Dashboard() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const popularServicesFF = useFeatureFlag('EnablePopularServices');
    const waterBillRedesignFF = useFeatureFlag('EnableWaterBillRedesign');
    const { RecordId }: { RecordId?: string } = useParams();
    const { width } = useWindowDimensions();
    const mobileScreenBreakpoint = 1235;

    const { paymentHistory, paymentHistoryV2, billingAccountSelectionModalOpen, activitiesRequiringAction, showAddBillingHelp } = useAppStateDashboardSelector(state => state);
    const { loadingActivities } = useAppStateActivitiesWizardSelector(state => state);
    const { authUser, selectedOrganizationInfo } = useAuthSelector(state => state);
    const { loadingFeatureFlags } = useAppStateFeatureFlagSelector(state => state);

    const [highlightItem, setHighlightItem] = useState("");
    const [processingRedirect, setProcessingRedirect] = useState(false);

    useEffect(() => {
        if (waterBillRedesignFF) {
            dispatch(loadPaymentHistory(waterBillRedesignFF.enabled));
        }
        dispatch(loadActivitiesPendingAction());
    }, [dispatch, authUser, waterBillRedesignFF]);

    useEffect(() => {
        // lpi payment redirect (if user is logged in and if it is not an alternate route)
        const sfParentId = RecordId ? RecordId.trim() : RecordId;
        if (sfParentId 
            && !(
                sfParentId.indexOf('login') >= 0 ||
                sfParentId.indexOf('signup') >= 0 ||
                sfParentId.indexOf('resetPassword') >= 0 ||
                sfParentId.indexOf('forgotpassword') >= 0 ||
                sfParentId.indexOf('verificationRequired') >= 0
            )) {
            const userId = authUser ? authUser.Id : null;
            if (userId) {
                const organizationId = selectedOrganizationInfo ? selectedOrganizationInfo.Id : null;
                const activityId = '708A4B84-5674-4EE1-B794-2A8559FAD462'; // building permit id
                setProcessingRedirect(true);
                axios.post(`/payment/createLPIUserActivities`, {sfParentId, activityId, organizationId, userId})
                    .then(res => {
                        setProcessingRedirect(false);
                        if (res?.data) {
                            history.push('/paymentLPI?activityId=' + res.data.Id);
                            
                            // Ensure the user sees the success message w/ case number for their activity
                            dispatch(openMessageModal('success-submit-case', 'success', {
                                caseNumber: res.data.caseNumber
                            }));
                        }
                        else {
                            dispatch(openMessageModal(null, 'error'));
                        }
                    })
                    .catch(err => {
                        setProcessingRedirect(false);
                        dispatch(openMessageModal(null, 'error'));
                        console.log(err);
                    });
            }
        }
    }, [location, history, setHighlightItem, RecordId, authUser, dispatch, selectedOrganizationInfo]);

    useEffect(() => {
        // Scroll to and highlight water bill panel
        const urlParams = new URLSearchParams(location.search);
        setHighlightItem(urlParams.get("highlight") ?? "");
        setTimeout(() => {
            let highlightedPanel;
            if (mobileScreenBreakpoint < width!) {
                highlightedPanel = document.getElementsByClassName('Panel_panelHighlighted__2uz8J')[0];
            }
            else {
                highlightedPanel = document.getElementsByClassName('Panel_panelHighlighted__2uz8J')[1];
            }
            if (waterBillRedesignFF?.enabled && (highlightedPanel)) {
                highlightedPanel.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }
        }, 50);
    }, [location, waterBillRedesignFF, width]);

    const { t } = useTranslation();

    let handleViewItems = () => {
        history.push('/in_progress');
    }

    const handleOnPayBills = () => {
        dispatch(setBillingAccountSelectionModalOpen(true));
    }

    const handleOnPayBillsSelection = (customerId: string, locationId: string, accountType: string, accountNumber: string, amount: number) => {
        dispatch(setBillingAccountSelectionModalOpen(false));
        console.log(`${customerId}-${locationId}`);
        dispatch(setAccountType(accountType));
        dispatch(setAccountNumber(accountNumber));
        dispatch(setAmount(amount));
        history.push(`/payment?accountNumber=${accountNumber}&accountType=${accountType}&amount=${amount}`);
    }

    return (
        processingRedirect ? 
        <Spinner fullPage isLoading={processingRedirect} />
        :
        <PageContainer>
            <div className={styles.webDashboard}>
                <div className='headerContainer'>
                    <h2 data-testid="dashboardHeader">{t('Dashboard.Dashboard')}</h2>
                    {activitiesRequiringAction > 0 &&
                        <div className={styles.inlineAttentionContainer} onClick={handleViewItems}>
                            <i className={`far fa-exclamation-triangle`}></i>
                            <div className={styles.inlineAttentionText}>
                                {activitiesRequiringAction} {t('Dashboard.ItemRequiresAttention', { count: activitiesRequiringAction })}.
                            </div>
                        </div>
                    }
                    <Spinner isLoading={loadingFeatureFlags} />
                </div>
                <PanelRow>
                    <Panel style={loadingFeatureFlags || popularServicesFF?.enabled ? { minHeight: '297px' } : {}}>
                        <Spinner isLoading={loadingFeatureFlags} />
                        {!loadingFeatureFlags ?
                            popularServicesFF?.enabled ?
                                <PopularServices />
                                :
                                <ActivitiesWizard />
                            : null
                        }
                    </Panel>
                </PanelRow>
                {waterBillRedesignFF?.enabled || loadingFeatureFlags ?
                    <PanelRow>
                        <Panel size={1} style={showAddBillingHelp ? {} : { background: 'transparent', boxShadow: 'none', padding: 0 }}>
                            <BillPayAccountSelection
                                modalOpen={billingAccountSelectionModalOpen}
                                onClose={() => dispatch(setBillingAccountSelectionModalOpen(false))}
                                onSelect={handleOnPayBillsSelection}
                                paymentHistory={paymentHistory}
                                paymentHistoryV2={paymentHistoryV2 as unknown as NavilinePaymentHistoryV2}
                            />
                            <div>
                                {showAddBillingHelp ? (
                                    <div className={styles.billPayAddPaymentContainer}>
                                        <h3 className={styles.billPayHeaderV2}>{t('Dashboard.BillPay')}</h3>
                                        <div className={styles.billPayAddPaymentActionV2}>
                                            <div>
                                                <NavLink to={`/account/${authUser?.Id}`}>
                                                    {t('Dashboard.BillPayNoAccounts')}
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <PanelRow>
                                            <Panel size={5}>
                                                <div id="chartContainer">
                                                    <BillPayGraphV2 paymentHistory={paymentHistoryV2 as NavilinePaymentHistoryV2} />
                                                </div>
                                            </Panel>
                                            <Panel size={4} style={{ position: 'relative' }} highlighted={highlightItem === 'billing'}>
                                                <h3 className={styles.billPayHeaderV2} id="billingHighlight">{t('Dashboard.UpcomingBills')}</h3>
                                                <div className={styles.payBillButtonV2}>
                                                    <ButtonV2 color='limegreen' onClick={handleOnPayBills}>
                                                        {t('Dashboard.PayBill', { count: 2 })}
                                                    </ButtonV2>
                                                </div>
                                                <hr className={styles.billPayHr} />
                                                <h4 className={`${styles.upcomingBillHeaders} ${styles.upcomingBillHeadersSpacing}`}>{t('Dashboard.UpcomingPayment')}</h4>
                                                <BillPayUpcomingPaymentsV2 paymentHistory={paymentHistoryV2 as NavilinePaymentHistoryV2} />
                                            </Panel>
                                        </PanelRow>
                                    </>
                                    )}
                            </div>
                        </Panel>
                    </PanelRow>
                    : 
                    <PanelRow>
                        <Panel size={1} highlighted={highlightItem === 'billing'}>
                            <BillPayAccountSelection
                                modalOpen={billingAccountSelectionModalOpen}
                                onClose={() => dispatch(setBillingAccountSelectionModalOpen(false))}
                                onSelect={handleOnPayBillsSelection}
                                paymentHistory={paymentHistory}
                                paymentHistoryV2={paymentHistoryV2 as unknown as NavilinePaymentHistoryV2}
                            />
                            <div className={styles.billPayInnerPanelContainer}>
                                {showAddBillingHelp ? (
                                    <div className={styles.billPayAddPaymentContainer}>
                                        <div className={styles.billPayAddPaymentTitle}>
                                            <i className={`far fa-money-bill-wave-alt`}></i>
                                            <h3 className={styles.billPayHeader}>{t('Dashboard.BillPay')}</h3>
                                        </div>
                                        <div className={styles.billPayAddPaymentAction}>
                                            <div>
                                                <NavLink to={`/account/${authUser?.Id}`}>
                                                    {t('Dashboard.AddAccounts')}
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                        <>
                                            <div id="chartContainer" className={styles.billPayLeftContainer}>
                                                <BillPayGraph paymentHistory={paymentHistory} />
                                            </div>
                                            <div className={styles.billPayRightContainer}>
                                                <div className={styles.billPayRightTopContainer}>
                                                    <i className={`far fa-money-bill-wave-alt`}></i>
                                                    <h3 className={styles.billPayHeader}>{t('Dashboard.BillPay')}</h3>
                                                </div>
                                                <div className={styles.billPayRightMiddleContainer}>
                                                    <div className={styles.billpayButtonContainer}>
                                                        <Button color='green' onClick={handleOnPayBills}>
                                                            {t('Dashboard.PayBill', { count: 2 })}
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className={styles.billPayRightBottomContainer}>
                                                    <div className={styles.billPayRightUpcoming}>{t('Dashboard.Upcoming')}</div>
                                                    <div className={styles.billPayRightHrContainer}>
                                                        <hr />
                                                    </div>
                                                    <BillPayDetails paymentHistory={paymentHistory} />
                                                </div>
                                            </div>
                                        </>
                                    )}
                            </div>
                            <BillPayRecords paymentHistory={paymentHistory} />
                        </Panel>
                    </PanelRow>
                }
                <PanelRow>
                    <Panel size={1}>
                        <div className={styles.attentionInnerPanelContainer}>
                            <div className={styles.attentionTopContainer}>
                                <i className={`far fa-exclamation-triangle`}></i>
                                <h3 className={styles.attentionHeader}>{t('Dashboard.Attention')}</h3>
                            </div>
                            <div className={styles.attentionMiddleContainer}>
                                <div className={styles.itemsAttentionNumber}>{activitiesRequiringAction}</div>
                                <div className={styles.itemsAttentionText}>{t('Dashboard.ItemRequiresAttention', { count: activitiesRequiringAction })}.</div>
                            </div>
                            <div className={styles.attentionBottomContainer} onClick={handleViewItems}>
                                <div>{t('Dashboard.ViewItem', { count: activitiesRequiringAction })}</div>
                            </div>
                        </div>
                    </Panel>
                    <DashboardRecentActivities />
                </PanelRow>
                <NavigationCircles />
                <div className={styles.bottomPage}>
                    <News/>
                    <Events/>
                </div>
            </div>
            <div className={styles.mobileDashboard}>
                <div className={styles.mobileHeader}>
                    <div className='headerContainer'>
                        <h2>{t('Dashboard.Dashboard')}</h2>
                        <Spinner isLoading={loadingActivities} />
                    </div>
                </div>
                <PanelRow>
                    <Panel style={{ minHeight: '297px' }}>
                        <Spinner isLoading={loadingFeatureFlags} />
                        {!loadingFeatureFlags ?
                            popularServicesFF?.enabled ?
                                <PopularServices />
                                :
                                <ActivitiesWizard />
                            : null
                        }
                    </Panel>
                </PanelRow>
                {waterBillRedesignFF?.enabled || loadingFeatureFlags ?
                    <PanelRow>
                        <Panel size={1} style={showAddBillingHelp ? {} : { background: 'transparent', boxShadow: 'none', padding: 0 }}>
                            <BillPayAccountSelection
                                modalOpen={billingAccountSelectionModalOpen}
                                onClose={() => dispatch(setBillingAccountSelectionModalOpen(false))}
                                onSelect={handleOnPayBillsSelection}
                                paymentHistory={paymentHistory}
                                paymentHistoryV2={paymentHistoryV2 as unknown as NavilinePaymentHistoryV2}
                            />
                            <div>
                                {showAddBillingHelp ? (
                                    <div className={styles.billPayAddPaymentContainer}>
                                        <h3 className={styles.billPayHeaderV2}>{t('Dashboard.BillPay')}</h3>
                                        <div className={styles.billPayAddPaymentActionV2}>
                                            <div>
                                                <NavLink to={`/account/${authUser?.Id}`}>
                                                    {t('Dashboard.BillPayNoAccounts')}
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <PanelRow>
                                            <Panel size={1} style={{ position: 'relative' }} highlighted={highlightItem === 'billing'}>
                                                <h3 className={styles.billPayHeaderV2} id="billingHighlight">{t('Dashboard.UpcomingBills')}</h3>
                                                <div className={styles.payBillButtonV2}>
                                                    <ButtonV2 color='limegreen' onClick={handleOnPayBills}>
                                                        {t('Dashboard.PayBill', { count: 2 })}
                                                    </ButtonV2>
                                                </div>
                                                <hr className={styles.billPayHr} />
                                                <h4 className={`${styles.upcomingBillHeaders} ${styles.upcomingBillHeadersSpacing}`}>{t('Dashboard.UpcomingPayment')}</h4>
                                                <BillPayUpcomingPaymentsV2 paymentHistory={paymentHistoryV2 as NavilinePaymentHistoryV2} />
                                            </Panel>
                                        </PanelRow>
                                        <PanelRow style={{ marginTop: '30px' }}>
                                            <Panel size={1}>
                                                <div id="chartContainerMobile">
                                                    <BillPayGraphV2 paymentHistory={paymentHistoryV2 as NavilinePaymentHistoryV2} />
                                                </div>
                                            </Panel>
                                        </PanelRow>
                                    </>
                                )}
                            </div>
                        </Panel>
                    </PanelRow>
                    :
                    <PanelRow>
                        <Panel>
                            {showAddBillingHelp ? (
                                <div className={styles.billPayAddPaymentContainer}>
                                    <div className={styles.billPayAddPaymentTitle}>
                                        <i className={`far fa-money-bill-wave-alt`}></i>
                                        <h3 className={styles.billPayHeader}>{t('Dashboard.BillPay')}</h3>
                                    </div>
                                    <div className={styles.billPayAddPaymentAction}>
                                        <div>
                                            {t('Dashboard.AddAccounts')}
                                        </div>
                                        <div>
                                            <NavLink to={`/account/${authUser?.Id}`}>
                                                {t('Dashboard.AddAccountLink')}
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className={styles.billPayInnerPanelMobileContainer}>
                                    <div className={styles.billPayContainer}>
                                        <div className={styles.billPayTopContainer}>
                                            <i className={`far fa-money-bill-wave-alt`}></i>
                                            <h3 className={styles.billPayHeader}>Bill Pay</h3>
                                        </div>
                                        <div className={styles.billPayMiddleContainer}>
                                            <div className={styles.billpayButtonContainer}>
                                                <Button color='green' onClick={handleOnPayBills}>
                                                    {t('Dashboard.PayBill', { count: 2 })}
                                                </Button>
                                            </div>
                                        </div>
                                        <div className={styles.billPayBottomContainer}>
                                            <div className={styles.billPayUpcoming}>Upcoming</div>
                                            <div className={styles.billPayHrContainer}>
                                                <hr />
                                            </div>
                                            <BillPayDetails paymentHistory={paymentHistory} />
                                            <div id={'chartContainerMobile'} className={styles.billPayGraphMobileContainer}>
                                                <BillPayGraph paymentHistory={paymentHistory} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                        </Panel>
                    </PanelRow>
                }
                <PanelRow>
                    <Panel>
                        <div className={styles.attentionInnerPanelContainer}>
                            <div className={styles.attentionTopContainer}>
                                <i className={`far fa-exclamation-triangle`}></i>
                                <h3 className={styles.attentionHeader}>{t('Dashboard.Attention')}</h3>
                            </div>
                            <div className={styles.attentionMiddleContainer}>
                                <div className={styles.itemsAttentionNumber}>{activitiesRequiringAction}</div>
                                <div className={styles.itemsAttentionText}>{t('Dashboard.ItemRequiresAttention', { count: activitiesRequiringAction })}.</div>
                            </div>
                            <div className={styles.attentionBottomContainer} onClick={handleViewItems}>
                                <div>{t('Dashboard.ViewItem', { count: activitiesRequiringAction })}</div>
                            </div>
                        </div>
                    </Panel>
                </PanelRow>
                <PanelRow>
                    <DashboardRecentActivities />
                </PanelRow>
                <NavigationCircles />
                <div className={styles.bottomPage}>
                    <News/>
                    <Events/>
                </div>
            </div>
        </PageContainer>
    );
}
