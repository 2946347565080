import React from 'react';
import styles from './Footer.module.scss';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function Footer() {
    const { t } = useTranslation();
    return (
        <>
            <div className={styles.footer}>
                <div className={styles.footerWeb}>
                    <div className={styles.footerSpace} />
                    <div className={styles.footerLogo} />
                    <div className={styles.footerMiddleContainer}>
                        <div className={styles.footerMiddle}>
                            <div className={styles.footerLinkContainer}>
                                <div className={styles.footerLinkItemContainer}>
                                    <NavLink
                                        className={styles.footerLinkItem}
                                        exact
                                        to="/"
                                    >
                                        {t('Menu.Home')}
                                    </NavLink>
                                </div>
                                <div className={styles.footerLinkItemContainer}>
                                    <NavLink
                                        className={styles.footerLinkItem}
                                        exact
                                        to="/residents"
                                    >
                                        {t('Menu.Resident', { count: 2 })}
                                    </NavLink>
                                </div>
                                <div className={styles.footerLinkItemContainer}>
                                    <NavLink
                                        className={styles.footerLinkItem}
                                        exact
                                        to="/business"
                                    >
                                        {t('Menu.Business', { count: 2 })}
                                    </NavLink>
                                </div>
                            </div>
                            <div className={styles.footerLinkMiddleContainer}>
                                <div className={styles.footerLinkItemContainer}>
                                    <NavLink
                                        className={styles.footerLinkItem}
                                        exact
                                        to="/visitors"
                                    >
                                        {t('Menu.Visitor', { count: 2 })}
                                    </NavLink>
                                </div>
                                <div className={styles.footerLinkItemContainer}>
                                    <NavLink
                                        className={styles.footerLinkItem}
                                        exact
                                        to="/government"
                                    >
                                        {t('Menu.Government')}
                                    </NavLink>
                                </div>
                                <div className={styles.footerLinkItemContainer}>
                                    <NavLink
                                        className={styles.footerLinkItem}
                                        exact
                                        to="/about"
                                    >
                                        {t('Menu.AboutElgin')}
                                    </NavLink>
                                </div>
                            </div>
                            <div
                                className={styles.footerLinkContainer}
                                style={{ minWidth: 115 }}
                            >
                                <div className={styles.footerInfoItemContainer}>
                                    150 Dexter Ct Elgin, IL 60120
                                </div>
                                <div
                                    className={styles.footerInfoItemContainer}
                                ></div>
                                <div className={styles.footerInfoItemContainer}>
                                    847-931-6001
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.footerSpace}></div>
                </div>
                <div className={styles.footerMobile}>
                    <div className={styles.footerMobileBottom}>
                        <div className={styles.footerLinkContainer}>
                            <div
                                className={styles.footerMobileLinkItemContainer}
                            >
                                <NavLink
                                    className={styles.footerLinkItem}
                                    exact
                                    to="/"
                                >
                                    Home
                                </NavLink>
                            </div>
                            <div
                                className={styles.footerMobileLinkItemContainer}
                            >
                                <NavLink
                                    className={styles.footerLinkItem}
                                    exact
                                    to="/residents"
                                >
                                    Residents
                                </NavLink>
                            </div>
                            <div
                                className={styles.footerMobileLinkItemContainer}
                            >
                                <NavLink
                                    className={styles.footerLinkItem}
                                    exact
                                    to="/business"
                                >
                                    Business
                                </NavLink>
                            </div>
                        </div>
                        <div className={styles.footerLinkContainer}>
                            <div
                                className={styles.footerMobileLinkItemContainer}
                            >
                                <NavLink
                                    className={styles.footerLinkItem}
                                    exact
                                    to="/visitors"
                                >
                                    Visitors
                                </NavLink>
                            </div>
                            <div
                                className={styles.footerMobileLinkItemContainer}
                            >
                                <NavLink
                                    className={styles.footerLinkItem}
                                    exact
                                    to="/government"
                                >
                                    Government
                                </NavLink>
                            </div>
                            <div
                                className={styles.footerMobileLinkItemContainer}
                            >
                                <NavLink
                                    className={styles.footerLinkItem}
                                    exact
                                    to="/about"
                                >
                                    About Elgin
                                </NavLink>
                            </div>
                        </div>
                        <div
                            className={`${styles.footerLinkContainer} ${styles.footerLinkInfoContainer}`}
                        >
                            <div className={styles.footerMobileInfoItem}>
                                150 Dexter Ct
                                <br />
                                Elgin, IL 60120
                            </div>
                            <div className={styles.footerMobileInfoItem}></div>
                            <div className={styles.footerMobileInfoItem}>
                                847-931-6001
                            </div>
                        </div>
                    </div>
                    <div className={styles.footerMobileTop}>
                        <div className={styles.footerMobileLogo}></div>
                    </div>
                </div>
            </div>
        </>
    );
}
