import React, { ReactNode } from 'react';
import styles from './Report.module.scss';
import { ColoredCircle } from '../../../components/coloredCircle/ColoredCircle';
import { PanelRow } from '../../../components/panel/PanelRow';
import { Panel } from '../../../components/panel/Panel';

export const ReportStepContainer = ({ children, title, number, containsError }: { title: string, number: string, containsError: boolean, children: ReactNode }) => (
    <PanelRow>
        <Panel style={containsError ? { border: '2px solid red' } : {}}>
            <div className={styles.sectionHeader}>
                <ColoredCircle
                    size="xs"
                    color="blue"
                    value={number}
                    opaque
                />
                <div>
                    {title}
                </div>
            </div>
            <div className={styles.sectionContent}>
                {children}
            </div>
        </Panel>
    </PanelRow>
)