import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit'; 
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';

export interface SalesforcePublicFieldListItem {
    Id: string;
    Label: string;
    Data: any; // todo type data prop
}

const initialState = {
    loading: false,
    addModalOpen: false,
    editModalOpen: false,
    data: [] as SalesforcePublicFieldListItem[], // todo type Data prop
    selectedSObject: "",
    fieldsLoading: false,
    salesforceObjectFields: [] as string[],
    salesforceObjectFieldOptions: [] as any[],
};

export type SalesforcePublicFieldsAppState = typeof initialState;

export const slice = createSlice<SalesforcePublicFieldsAppState, {
    setLoading: CaseReducer<SalesforcePublicFieldsAppState, PayloadAction<boolean>>,
    setAddModalOpen: CaseReducer<SalesforcePublicFieldsAppState, PayloadAction<boolean>>,
    setEditModalOpen: CaseReducer<SalesforcePublicFieldsAppState, PayloadAction<boolean>>,
    setData: CaseReducer<SalesforcePublicFieldsAppState, PayloadAction<SalesforcePublicFieldListItem[]>>,
    setSelectedSObject: CaseReducer<SalesforcePublicFieldsAppState, PayloadAction<string>>,
    setFieldsLoading: CaseReducer<SalesforcePublicFieldsAppState, PayloadAction<boolean>>,
    setSalesforceObjectFields: CaseReducer<SalesforcePublicFieldsAppState, PayloadAction<string[]>>,
    setSalesforceObjectFieldsOptions: CaseReducer<SalesforcePublicFieldsAppState, PayloadAction<any[]>>,
}>({
    name: 'salesforcePublicFields',
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setAddModalOpen: (state, { payload }) => {
            state.addModalOpen = payload;
        },
        setEditModalOpen: (state, { payload }) => {
            state.editModalOpen = payload;
        },
        setData: (state, { payload }) => {
            state.data = payload;
        },
        setSelectedSObject: (state, { payload }) => {
            state.selectedSObject = payload;
        },
        setFieldsLoading: (state, { payload }) => {
            state.fieldsLoading = payload;
        },
        setSalesforceObjectFields: (state, { payload }) => {
            state.salesforceObjectFields = payload;
        },
        setSalesforceObjectFieldsOptions: (state, { payload }) => {
            state.salesforceObjectFieldOptions = payload;
        },
    }
});

export const useSalesforcePublicFieldsSelector = buildSubStateSelector<SalesforcePublicFieldsAppState>(state => state.salesforcePublicFields);
export const salesforcePublicFieldsListActions = slice.actions;
export const salesforcePublicFieldsListReducer = slice.reducer;