import React, { useEffect } from 'react';
import styles from './OrganizationManagement.module.scss';
import { PanelRow } from '../../components/panel/PanelRow';
import { Panel } from '../../components/panel/Panel';
import { PageContainer } from '../../components/pageContainer/PageContainer';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { loadMembers, verifyOrganizationMember, removeOrganizationMember } from './organizationManagementActionCreators';
import { useAppStateOrganizationManagementSelector } from './organizationManagementSlice';
import { Spinner } from '../../components/spinner/Spinner';
import { useAuthSelector } from '../auth/authSlice';
import { Message } from 'semantic-ui-react';
import { BillingSettings } from '../account/billing/BillingSettings';

const approvedMembers: any[] = [];
const pendingMembers: any[] = [];
for (let i = 0; i < 5; i++) {
    approvedMembers.push({
        name: `Person ${i}`,
        verified: true
    });
    pendingMembers.push({
        name: `Person ${i+5}`,
        verified: false
    });
}

export const OrganizationManagement = () => {
    const { t } = useTranslation();
    const { loading, members, organizationManagementErrorMessage } = useAppStateOrganizationManagementSelector(state => state);
    const { authUser } = useAuthSelector(state => state);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadMembers());
    }, [dispatch])
    const approvedMembers = members.filter(member => member.Verified === true);
    const pendingMembers = members.filter(member => member.Verified === false);
    return (
        <PageContainer>
            <div className="headerContainer">
                <h2>{t('SideBar.OrganizationManagement')}</h2>
                <Spinner isLoading={loading} />
            </div>
            {(organizationManagementErrorMessage !== '') &&
                <Message error header={t('Util.Error')} content={t(organizationManagementErrorMessage)} />
            }
            {members && members.length ?
                <>
                    <PanelRow>
                        <Panel>
                            <div className='headerContainer'>
                                <h3>{t('Organization.PendingSectionTitle')}</h3>
                            </div>
                            {
                                (!pendingMembers || !pendingMembers.length) ?
                                <p className={styles.noMembers}>{t('Organization.NoPendingMembers')}</p> :
                                <>
                                    <div className={`${styles.memberRow} ${styles.memberTableHeader}`}>
                                        <div className={styles.leftColumn}>
                                            {t('Util.Name')}
                                        </div>
                                        <div className={styles.rightColumn}>
                                            {t('Organization.ApprovePendingMember')}
                                        </div>
                                    </div>
                                    { pendingMembers.map((pendingMember, idx) => (
                                        <div className="listItemColor" key={pendingMember.UserId}>
                                            <div className={`${styles.memberTableItem} ${styles.memberRow}`}>
                                                <div className={styles.leftColumn}>
                                                    {pendingMember.FirstName + ' ' + pendingMember.LastName}
                                                </div>
                                                <div className={styles.rightColumn}>
                                                    <span
                                                        onClick={e => dispatch(verifyOrganizationMember(pendingMember.UserId))}
                                                        className={`far fa-check-circle ${styles.approveIcon}`}
                                                        />
                                                    <span
                                                        onClick={e => dispatch(removeOrganizationMember(pendingMember.UserId))}
                                                        className={`far fa-times-circle ${styles.denyIcon}`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            }
                        </Panel>
                    </PanelRow>

                    <PanelRow>
                        <Panel>
                            <div className='headerContainer'>
                                <h3>{t('Organization.ApprovedSectionTitle')}</h3>
                            </div>
                            <div className={`${styles.memberRow} ${styles.memberTableHeader}`}>
                                <div className={styles.leftColumn}>
                                    {t('Util.Name')}
                                </div>
                                <div className={styles.rightColumn}>
                                    {t('Organization.RemoveApprovedMember')}
                                </div>
                            </div>
                            { approvedMembers.map((approvedMember, idx) => (
                                <div className="listItemColor" key={approvedMember.UserId}>
                                    <div className={`${styles.memberTableItem} ${styles.memberRow}`}>
                                        <div className={styles.leftColumn}>
                                            {approvedMember.FirstName + ' ' + approvedMember.LastName}
                                        </div>
                                        <div className={styles.rightColumn}>
                                            { (authUser!.Id !== approvedMember.UserId) &&
                                                <span
                                                    onClick={e => dispatch(removeOrganizationMember(approvedMember.UserId))}
                                                    className={`far fa-times-circle ${styles.denyIcon}`}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Panel>
                    </PanelRow>
                </>
            : null }
            <PanelRow>
                <Panel>
                    <BillingSettings OrganizationId={authUser?.selectedOrganization} />
                </Panel>
            </PanelRow>

        </PageContainer>
    );
}