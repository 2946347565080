import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { PermitFormAppState, PermitOption } from './permitFormAppState';
import { buildSubStateSelector } from '../../../store/utilities/useAppStateSelector';

export enum PropertyTypes {
    NewSingleFamilyHome = 'NewSingleFamilyHome',
    NewCommercialIndustrial = 'NewCommercialIndustrial',
    SingleFamilyHomeOwner = 'SingleFamilyHomeOwner',
    SingleFamilyHomeNotOwner = 'SingleFamilyHomeNotOwner',
    MultiFamily = 'MultiFamily'
}

export const LaserfichePermitOptions = {
    BuildingShed: 'Shed',
    PuttingUpFence: 'Fence',
    InstallingHotTub: 'Hot_Tub',
    RepairReplaceRoof: 'Roof_repair',
    ReplaceFurnace: 'Furnace_replacement',
    ReplaceWaterHeater: 'Water_heater_replacement',
    InstallResidentialDriveway: 'Residential_driveway',
    InstallAboveGroundPool: 'Above_ground_pool',
    ReplaceWindow: 'Window_replacement',
    InstallPatio: 'Patio',
    InstallDeckUnder4Feet: 'Deck__less_than_4ft',
    RepairReplaceSiding: 'Siding',
    InstallPrivateSidewalk: 'Private_sidewalk',
    MinorHVACWork: 'Misc_HVAC',
    MinorElectricalWork: 'Misc_electrical',
    MinorPlumbingWork: 'Misc_plumbing',
    Other: '_other'
}

export const slice = createSlice<PermitFormAppState, SliceCaseReducers<PermitFormAppState>>({
    name: 'permitForm',
    initialState: {
        propertyType: '' as "" | PropertyTypes,
        paymentCode: "",
        permitOptions: {
            BuildingShed: false,
            PuttingUpFence: false,
            InstallingHotTub: false,
            RepairReplaceRoof: false,
            ReplaceFurnace: false,
            ReplaceWaterHeater: false,
            InstallResidentialDriveway: false,
            InstallAboveGroundPool: false,
            ReplaceWindow: false,
            InstallPatio: false,
            InstallDeckUnder4Feet: false,
            RepairReplaceSiding: false,
            InstallPrivateSidewalk: false,
            MinorHVACWork: false,
            MinorElectricalWork: false,
            MinorPlumbingWork: false,
            RemodelingStructure: false,
            Other: false
        }
    },
    reducers: {
        setPropertyType: (state, action) => {
            state.propertyType = action.payload as "" | PropertyTypes;
        },
        togglePermitOption: (state, action: { payload: PermitOption }) => {
            const { payload } = action;
            state.permitOptions[payload] = !state.permitOptions[payload];
        }
    }
});

export const useAppStatePermitFormSelector = buildSubStateSelector(state => state.permitForm);
export const { setPropertyType, togglePermitOption } = slice.actions;
export const permitFormReducer = slice.reducer;
