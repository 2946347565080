import {createSlice, SliceCaseReducers} from '@reduxjs/toolkit';
import {ResetPasswordAppState} from './resetPasswordAppState';
import {buildSubStateSelector} from '../../store/utilities/useAppStateSelector';

export const slice = createSlice<ResetPasswordAppState, SliceCaseReducers<ResetPasswordAppState>>({
    name: 'login',
    initialState: {
        password: '',
        confirmPassword: '',
        success: false,
        expiredUrl: null,
        loading: false,
    },
    reducers: {
        setPassword: (state, action) => {
            state.password = action.payload.value;
        },
        setConfirmPassword: (state, action) => {
            state.confirmPassword = action.payload.value;
        },
        resetPassword: (state) => {
            state.password = '';
            state.confirmPassword = '';
            state.success = true;
        },
        setExpiredUrl: (state, action) => {
            state.expiredUrl = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    }
});

export const useAppStateResetPasswordSelector = buildSubStateSelector(state => state.resetPassword);
export const {
    setPassword,
    setConfirmPassword,
    resetPassword,
    setExpiredUrl,
    setLoading
} = slice.actions;
export const resetPasswordReducer = slice.reducer;
