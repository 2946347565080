import React, { FunctionComponent, Suspense } from 'react'
import {useDispatch} from "react-redux";
import { App } from './App';
import { toggleSideBar } from '../components/header/headerActionCreators';
import { MobileSideBarMenu } from '../components/mobileSideBarMenu/MobileSideBarMenu';
import { useAppStateHeaderSelector } from '../components/header/headerSlice';
import { useAuthSelector } from '../features/auth/authSlice';

export const AppContainer: FunctionComponent<{}> = () => {
    const dispatch = useDispatch();
    const mobileSideBarVisibility = useAppStateHeaderSelector(state => state.mobileSideBarVisibility);
    const { isAuthenticated, authUser, selectedOrganization } = useAuthSelector(x => x);

    function closeSideBar() {
        if (mobileSideBarVisibility) {
            dispatch(toggleSideBar(false));
        }
    }

    return (
        <>
            <Suspense fallback="loading">
                <MobileSideBarMenu closeSideBar={closeSideBar} visible={mobileSideBarVisibility} isSignedIn={isAuthenticated} />
                <App isAuthenticated={isAuthenticated} authUser={authUser} selectedOrganization={selectedOrganization} />
            </Suspense>
        </>
    );
}