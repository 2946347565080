import React, { useEffect } from 'react';
import { Dropdown, DropdownOptions } from '../../../../components/dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setStreetUtilitiesRequestType, useAppStateReportSelector, setCategoryCanSubmit } from '../reportSlice';

export const StreetUtilitiesRequest = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const state = useAppStateReportSelector(state => state.streetUtilitiesRequest);

    const typeOptions: DropdownOptions[] = [
        {
            value: "STORM_DRAIN_MAINTENANCE",
            key: "STORM_DRAIN_MAINTENANCE",
            text: t('Report.StormDrainMaintenance'),
        },
        {
            value: "STREET_FLOODING",
            key: "STREET_FLOODING",
            text: t('Report.StreetFlooding'),
        },
        {
            value: "DAMAGED_FIRE_HYDRANT",
            key: "DAMAGED_FIRE_HYDRANT",
            text: t('Report.DamagedFireHydrant'),
        },
        {
            value: "STREET_SIGN_MAINTENANCE",
            key: "STREET_SIGN_MAINTENANCE",
            text: t('Report.StreetSignMaintenance'),
        },
        {
            value: "STOP_SIGN_MAINTENANCE",
            key: "STOP_SIGN_MAINTENANCE",
            text: t('Report.StopSignMaintenance'),
        },
        {
            value: "OTHER",
            key: "OTHER",
            text: t('Report.Other'),
        },
    ];
    
    useEffect(() => {
        if (state.type) {
            dispatch(setCategoryCanSubmit(true));
        }
        else {
            dispatch(setCategoryCanSubmit(false));
        }
    }, [dispatch, state.type]);

    return (
        <>
            {t('Report.NatureOfReport')}
            <Dropdown
                placeholder={t('Report.NatureOfReport')}
                options={typeOptions}
                value={state.type}
                onChange={(e: any, data: any) => dispatch(setStreetUtilitiesRequestType(data.value))}
            />
        </>
    )
}