import React, { ChangeEvent, useEffect } from 'react';
import styles from './Header.module.scss';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    setSearch,
    setMobileSearchVisibility,
    setMobileSideBarVisibility,
    useAppStateHeaderSelector,
} from './headerSlice';
import { HeaderAppState } from './headerAppState';
import { Dropdown } from '../dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { HeaderUserDropdown } from './HeaderUserDropdown';
import { loadLanguageOptions, setLanguage } from './headerActionCreators';
import { loadMessages } from '../../features/messages/messagesActionCreators';
import { useAuthSelector } from '../../features/auth/authSlice';
import { SiteSearch } from '../siteSearch/SiteSearch';
import { InputOnChangeData } from 'semantic-ui-react';

export interface HeaderProps {
    isSignedIn: boolean;
}

export function Header(props: HeaderProps) {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const state: HeaderAppState = useAppStateHeaderSelector((state) => state);
    const {
        mobileSearchVisibility,
        mobileSideBarVisibility,
        search,
        languageOptions,
        selectedLanguage,
    } = state;
    const authUser = useAuthSelector((state) => state.authUser);

    useEffect(() => {
        dispatch(loadLanguageOptions());
        if (authUser) {
            dispatch(loadMessages());
        }
    }, [dispatch, authUser]);

    const changeLanguage = (e: any, data: any) => {
        i18n.changeLanguage(data.value);
        dispatch(setLanguage(data.value));
    };

    const history = useHistory();

    const onSearch = () => {
        history.push(`/search?term=${search}`);
        dispatch(setSearch(''));
    };

    const handleSearchChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        data: InputOnChangeData,
    ) => {
        dispatch(setSearch(data.value));
    };

    return (
        <>
            <div className={`${styles.headerWeb} ${styles.menu}`}>
                <div className={styles.headerTopContainer}>
                    <div className={styles.menuLeftContainer}>
                        <a
                            href="https://www.cityofelgin.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.menuItem}
                        >
                            <div className={styles.menuLogo}></div>
                        </a>
                        <NavLink exact to="/" className={styles.menuItem}>
                            {t('Menu.Home')}
                        </NavLink>
                        <a
                            href="https://www.cityofelgin.org/59/Residents"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.menuItem}
                        >
                            {t('Menu.Resident', { count: 2 })}
                        </a>
                        <a
                            href="https://www.cityofelgin.org/35/Doing-Business"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.menuItem}
                        >
                            {t('Menu.Business', { count: 2 })}
                        </a>
                        <a
                            href="https://www.cityofelgin.org/76/Government"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.menuItem}
                        >
                            {t('Menu.Government')}
                        </a>
                        <a
                            href="https://www.cityofelgin.org/31/Explore-Elgin"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.menuItem}
                        >
                            {t('Menu.ExploreElgin')}
                        </a>
                    </div>
                    <div className={styles.headerSearchContainer}>
                        <SiteSearch
                            onChange={handleSearchChange}
                            className={styles.searchInput}
                            value={search}
                            onSearch={onSearch}
                        />
                    </div>
                    <div className={styles.menuRightContainer}>
                        {props.isSignedIn ? (
                            <div
                                className={`${styles.menuItem} ${styles.dropdown}`}
                            >
                                <HeaderUserDropdown />
                            </div>
                        ) : (
                            <>
                                <NavLink
                                    to="/login"
                                    className={styles.menuItem}
                                >
                                    {t('Login.Login')}
                                </NavLink>
                                <div
                                    className={`${styles.menuItem} ${styles.dropdown}`}
                                >
                                    <Dropdown
                                        selection={false}
                                        fluid={false}
                                        value={selectedLanguage}
                                        search={false}
                                        staticText={t('SideBar.Language')}
                                        placeholder={t('SideBar.Language')}
                                        options={languageOptions}
                                        onChange={changeLanguage}
                                    />
                                </div>
                            </>
                        )}
                        <div
                            className={`${
                                styles.menuHiddenWebSearchContainer
                            } ${
                                mobileSearchVisibility
                                    ? styles.mobileSearchVisible
                                    : ''
                            }`}
                            onClick={() =>
                                dispatch(
                                    setMobileSearchVisibility({
                                        value: !mobileSearchVisibility,
                                    }),
                                )
                            }
                        >
                            <div className={styles.headerSearchContainer}>
                                <i
                                    className={`${styles.mobileSearchButtonToggle} search icon`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {mobileSearchVisibility && (
                    <div className={styles.headerBottomContainer}>
                        <SiteSearch
                            onChange={handleSearchChange}
                            placeholder="Search..."
                            onSearch={onSearch}
                            value={search}
                        />
                    </div>
                )}
            </div>
            <div className={`${styles.headerMobile} ${styles.menu}`}>
                <div className={styles.headerMobileTopContainer}>
                    <div
                        className={styles.menuMobileLeftContainer}
                        onClick={() =>
                            dispatch(
                                setMobileSideBarVisibility({
                                    value: !mobileSideBarVisibility,
                                }),
                            )
                        }
                    >
                        <i
                            className={`${styles.headerHamburger} bars icon`}
                        ></i>
                    </div>
                    <div className={styles.menuMobileLogoContainer}>
                        <div className={styles.menuMobileLogo}></div>
                    </div>
                    <div
                        className={`${styles.menuMobileRightContainer} ${
                            mobileSearchVisibility
                                ? styles.mobileSearchVisible
                                : ''
                        }`}
                        onClick={() =>
                            dispatch(
                                setMobileSearchVisibility({
                                    value: !mobileSearchVisibility,
                                }),
                            )
                        }
                    >
                        <div className={styles.headerSearchContainer}>
                            <i
                                className={`${styles.mobileSearchButtonToggle} search icon`}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className={styles.headerMobileBottomContainer}
                    style={{
                        display: mobileSearchVisibility ? 'flex' : 'none',
                    }}
                >
                    <SiteSearch
                        onChange={handleSearchChange}
                        className={`${styles.searchInput} ${styles.mobileSearchInput}`}
                        placeholder="Search..."
                        onSearch={onSearch}
                        value={search}
                    />
                </div>
            </div>
        </>
    );
}
