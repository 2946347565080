import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import styles from './BackArrow.module.scss';

interface BackArrowProps {
    url?: string,
    text?: string
}

export const BackArrowDarkBlue = ({ url, text } : BackArrowProps) => {
    const history = useHistory();
    const { t } = useTranslation();
    if (url) {
        return (
            <Link to={url}>
                <div className={`${styles.container} ${styles.containerCenter}`}>
                    <div className={`${styles.arrow_container} ${styles.arrowImg}`}></div>
                    <div className={styles.arrowText}>{ text ? text : t('Util.Back') }</div>
                </div>
            </Link>
        )
    } else {
        return (
            <div onClick={() => history.goBack()}>
                <div className={styles.container}>
                <div className={`${styles.arrow_container} ${styles.arrowImg}`}></div>
                <div className={styles.arrowText}>{ text ? text : t('Util.Back') }</div>
                </div>
            </div>
        )
    }
};