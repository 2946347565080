import { useHistory } from 'react-router';

export const OpenMobileApp = () => {
    const history = useHistory();

    window.location.href = 'elgin-mobile://'; 
    setTimeout(() => {
        history.push('/dashboard')
    }, 5000);
    return null;
}